
import { Client } from "./clients/clients";
import { ImpreemDate } from "./date/impreem-date";
import { ResultsTypeReturn } from "./http-utilities/http-utilities";
import { ImpreemMethodDTO, MethodDTO, StepsDTO, TimeSeriesDTO } from "./methods";
import type { Periodicity } from "./shared/periodicity";
import { DatesSettings } from "./shared/utilites/dates.utilities";
import { createId } from "./shared/utilites/string.utilities";
import { InProgressDTO } from "./status/inprogress-dto";
import { StrategyDTO } from "./strategies/strategy";
import { TimeSeriesHelperValue } from "./timeseries/timeseries-models";
import { TransformationDTO } from "./transformations/transformations-dtos";

export class CollectionTypes {
    public economics = "economics";

    public indexes = "indexes";

    public news = "news";

    public swedishnews = "swedishnews";

    public googletrends = "googletrends";

    public commodities = "commodities";

    public uploaded = "timeserieshelpervalues";

    public newsWord = "newsword";

    public cryptocurrencies = "cryptocurrencies";

    public newsMentions = "newsmentions";

    public newsSentiments = "newssentiments";

    public avgfinancialratiosIncomeStatementQuarter = "avg-financial-ratios-IncomeStatementQuarter";

    public avgfinancialratiosRatiosQuarter = "avg-financial-ratios-RatiosQuarter";
}

export const CCollectionTypes = new CollectionTypes();
export const CCollectionTypesArray = [
    "economics",
    "indexes",
    "news",
    "swedishnews",
    "googletrends",
    "commodities",
    "uploaded",
    "timeserieshelpervalues",
    "newsword",
    "newsmentions",
    "newssentiments",
];

export class DecisionTreeDTO {
    public steps: StepsDTO[] = [];
}

export class IntersectionDTO {
    public methods: ImpreemMethodDTO[] = [];
}

export type OverallMethodCategory = "market-condition" | "method" | "ml-method" | "selection-filter";

export type PreviewCategoryType = "forecast" | "any";

export type FilterCategory = "topp";

export class RWorkerMethodDTO {
    public category: OverallMethodCategory;

    public date: ImpreemDate = null;

    public symbols: string[] = null;

    public returnType: ResultsTypeReturn = "https";

    public method: MethodDTO;

}

export class IntersectionFilteringDTO {
    public symbol: string;

    public image?: string;

    public R?: number;

    public isComparisonSeries?: boolean;

    public fullName?: string;

    public evaluationResult?: StatisticalProperties[];
}

export class SymbolMetaDTO {
    public symbol: string;

    public image: string;

    public fullName: string;
}

export class BacktestingSetting {
    public _id: string;

    public startDate: DatesSettings[];

    public endDate: DatesSettings[];

    public periodicity: Periodicity;

    public comparisonSeries: string[] = [];

    public evaluationMethods: any[] = [];

    public strategies: StrategyDTO[] = [];

    public allTimeseriesUsed: TimeSeriesDTO[] = [];

    public transformTimeseries: TransformationDTO[] = [];

    public methods: ImpreemMethodDTO[] = [];

    public clientId: string;

    // frontend helper
    public currentMethod: ImpreemMethodDTO = null;
}

export class PreviewMeta {
    public category: PreviewCategoryType;
}

export class Backtest {

    public client?: Client;

    public _id: string;

    public portfolio: Portfolio = null;

    public historicalPortfolios: Portfolio[] = [];

    public historicalR: Series[] = [];

    public buySignals: ImpreemDate[] = [];

    public inProgress: InProgressDTO = null;

    public comparisonSeries: ComparisonSeries[] = [];

    public name: string;

    public summary: Summary = null;

    public statisticsSummary: StatisticsSummary;

    public periodicity: Periodicity;

    public numberOfStepsTotal: number = null;

    public atStep: number = null;

    public atDate: string = null;

    public test: BacktestingSetting = new BacktestingSetting();

    public created: Date = null;

    public activeFrontend = false;

    public activeStrategyId: string;

    public comparisonIndex: string[] = [ "^NDX" ];

    public computeReturns: TimeSeriesDTO[] = [];

    public tags: string[] = [];

    public associationBacktests: Backtest[] = [];

    constructor(periodicity: Periodicity, created: Date, name: string, tags?: string[], computeReturns?: TimeSeriesDTO[]) {
        this.periodicity = periodicity;
        this.created = created;
        this.name = name;
        if (tags) {
            this.tags = tags;
        }
        if (computeReturns) {
            this.computeReturns = computeReturns;
        }
    }

}

export const defaultStoredBacktest = (backtest: Backtest, keepSummary?: boolean): Backtest => {
    const backtestDates = backtest.test.startDate.find(t => t.type === "backtest").dates.filter(x => x.name === "from")?.[0];
    backtest.atDate = backtestDates.date.label;
    backtest.historicalPortfolios = [];
    backtest.historicalR = [];
    backtest.buySignals = [];
    backtest.comparisonSeries = [];
    backtest.atStep = backtestDates.date.step;
    if (!keepSummary) {
        backtest.statisticsSummary = new StatisticsSummary();
        backtest.summary = new Summary();
    }
    return backtest;
};

export const GraphItemColor: string[] = [
    "#5ac995", // Green
    "#51a5db", // Blue
    "#8a63b4", // Purple
    "#f76767", // Red
    "#f39c12", // Orange
    "#f5e51b", // Yellow
    "#1abc9c", // Teal
    "#3498db", // Dark Blue
    "#9b59b6", // Dark Purple
    "#e74c3c", // Dark Red
];

export class GraphItem {
    public _id: string;
    
    public tab: any;

    public timeSeries: TimeSeriesDTO;

    public groupId: string = createId();

    public theoryActive = false;

    public theoryActiveMain = false;

    public editActive = false;

    public extras: TimeSeriesDTO[] = [];

    // frontend helper, for methods
    public active = false;

    public added: Date = new Date();

    public clientId: string;

    public color: string = GraphItemColor[0];
}

export class Series {
    public date: ImpreemDate;

    public R: number;

    public symbol?: string;

    constructor(d: ImpreemDate, r: number) {
        this.date = d;
        this.R = r;
    }
}

// Chart component
export class ComparisonSeries {
    public name: string;

    public R: Series[] = [];
}

export class Company {
    public symbol: string;

    public image: string;

    public description?: string;

    public fullName: string;

    public metaValue?: number;

    public R: number;
}

export class Portfolio {
    public companies: Company[] = [];

    public date: ImpreemDate;

    constructor(c: Company[], d: ImpreemDate) {
        this.companies = c;
        this.date = d;
    }
}

// Portfolio display component
export class PortfolioDisplayCompany {
    public image: string;

    public R: number;
}

export class PortfolioDisplayLog {
    public image: string;

    public symbol: string;
}

// Log component
export class LogDTO {
    public message: string;

    public meta: string;

    public step: number;

    public _id: string;

    public userId: string;

    public logId: string;
}

export type ProcessType = "buying" | "on-hold" | "selection" | "selling";

export class LogsSoftDTO {
    public sellingLog: SellingLogDTO;

    public buyingLog: BuyingLogDTO;

    public selectionLog: SelectionDTO;

    public type: ProcessType;
}

export class SellingLogDTO {
    public date: ImpreemDate;

    public R: number;
}

export class BuyingLogDTO {
    public date: ImpreemDate;

    public portfolioDisplay: PortfolioDisplayLog[] = [];
}

export class SelectionDTO {
    public date: ImpreemDate;

    public messages: string[] = [];
}

// Step-summary component
export class Summary {
    public atStep: number;

    public timeUnitsLeft: number;

    public numberOfCompaniesBought: number;

    public portfolioReturn: number;

    public comparisonReturn: number;

    public latestBacktestDate: ImpreemDate;

    public observationCount: number;

    public std: number;
}

// Process component

export class ProcessDisplayValue {
    public display: string;

    public value: ProcessType;

    constructor(display: string, value: ProcessType) {
        this.display = display;
        this.value = value;
    }
}

export const ProcessTypeValue = [ new ProcessDisplayValue("Selection", "selection"), new ProcessDisplayValue("Buying", "buying"), new ProcessDisplayValue("Selling", "selling"), new ProcessDisplayValue("Start over", "on-hold") ];

export const findProccessTypeValue = (value: ProcessType): ProcessDisplayValue => {
    for (let i = 0; i < ProcessTypeValue.length; i++) {
        if (ProcessTypeValue[i].value === value) {
            return ProcessTypeValue[i];
        }
    }
    return null;
};

export class ProcessDisplay {
    public atCurrentStep: number;

    public atCurrentDate: ImpreemDate;

    public atCurrentProcess: ProcessDisplayValue;

    public timeUnitsLeft: number;
}

export type StatisticalValueType = "backtest" | "comparison";

// Statistics component
export class StatisticalProperties {
    public value: number[] | number;

    public display: string;

    public type?: StatisticalValueType = "backtest";

    public id?: string;
}

export class StatisticsSummary {

    public periodicity: Periodicity;

    public evaluation: StatisticalProperties[] = [];
}

export class PortfolioMetaDTO {
    public historicalPortfolio: Portfolio[] = [];

    public historicalR: Series[] = [];
}

export class BacktestingMetaDTO {
    public takeTestFromBacktest?: boolean;

    public lastDate?: ImpreemDate;

    public firstDate?: ImpreemDate;

    public returnPortfolio?: boolean;

    public takeFirstValue?: boolean;

    public useCachedTimeSeries?: TimeSeriesDTO[];

    public calculateReturnsLast?: boolean;

    public clientId?: string;

    public take: number[] = [];

    public groupId?: string;

    public backtestOriginId: string;

    public id: string;

    public expectedNumberOfRes: number;

    public uniqueBacktestId: string;

    public shouldChache?: boolean;

    public addResultTo: string;
}

export class BacktestResultsDTO {

    public backtestId: string;

    public pastForecasts?: TimeSeriesDTO[];

    public comparisionSeries?: {
        R: Series[];
        name: string;
    }[];

    public historicalPortfolio?: Portfolio[];

    public historicalR?: Series[];

    public values?: TimeSeriesHelperValue[];

    public backtestName?: string;
}

export class tagsBacktest {
    public backtestName: string;
    public tag: string;
}
