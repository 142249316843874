import mongoose from "mongoose";
import { TimeSeriesHelperValue } from "./../../../../timeseries/timeseries-models";
const Schema = mongoose.Schema;

const timeserieshelpervaluesSchema = new Schema({
    value: { type: Number },
    date: { type: String, required: true },
    symbol: { type: String },
    timeSeriesKey: { type: String },
    transformationKey: { type: String },
    companies: [ Schema.Types.Mixed ],
    buy: { type: Boolean, default: false },
    isPastForecast: { type: Boolean, default: false },
    parameters: { type: Schema.Types.Mixed },
    d: { type: Date },
    price1: { type: Number },
    shouldCategorized: { type: Boolean },
    meta: Schema.Types.Mixed,
});

timeserieshelpervaluesSchema.loadClass(TimeSeriesHelperValue);

const timeserieshelpervalues = mongoose.model("timeserieshelpervalues", timeserieshelpervaluesSchema);

export default timeserieshelpervalues;
