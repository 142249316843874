export function createId(): string {
    return "id" + Math.random().toString(16).slice(2);
}

export function removeDotsAndDashes(inputString: string): string {
    return inputString.replace(/[.-]/g, "");
}

export function removeBadCharacters(str: string): string {
    return str.toLowerCase().replace(/\s/g, "");
}

export function removeSomeBadCharacters(str: string): string {
    return str.toLowerCase().replace(/[^a-z0-9+%/*\\&?@-]/g, "");
}

export function validateId(str: any): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return str.match(/^[0-9a-fA-F]{24}$/);
}

export function isRegexValid(regexPattern: string): boolean {
    try {
        new RegExp(regexPattern);
        return true;
    } catch (error) {
        return false;
    }
}

// create a function that split a string by capital Letter and add space between them
export const splitByCapitalLetter = (str: string): string => {
    if (!str) {
        return "";
    }
    return str.replace(/([A-Z])/g, " $1").replace(/^./, function (x: string) {
        return x.toUpperCase();
    });
};

export function mergeNestedCapitalLetters(str: string): string {
    if (!str) {
        return "";
    }
    let output = "";
    let lastUpperCase = false;
    const regex = /\s/;
    for (let i = 0; i < str.length; i++) {
        if (str[i].match(/[A-Z]/)) {
            // If the character is a capital letter, append it to the output string
            output += str[i];
            lastUpperCase = true;
        } else if (regex.test(str[i])) {
            // If the character is a space, skip it if the last character was also a space
            if (!lastUpperCase) {
                output += str[i];
            }
        } else {
            // Otherwise, append the character to the output string
            output += str[i];
            lastUpperCase = false;
        }
    }
    return output;
}

export function capitalizeFirstLetter(string: string | null | undefined): string {
    if (string == null) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getLevenshteinDistance(str1: string, str2: string): number {
    const matrix: number[][] = [];
    for (let i = 0; i <= str1.length; i++) {
        matrix[i] = [ i ];
    }
    for (let j = 0; j <= str2.length; j++) {
        matrix[0][j] = j;
    }
    for (let i = 1; i <= str1.length; i++) {
        for (let j = 1; j <= str2.length; j++) {
            const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
            matrix[i][j] = Math.min(
                matrix[i - 1][j] + 1,
                matrix[i][j - 1] + 1,
                matrix[i - 1][j - 1] + cost
            );
        }
    }
    return matrix[str1.length][str2.length];
}

export function stringSimilarity(str1: string, str2: string): number {
    const maxLength = Math.max(str1.length, str2.length);
    const distance = getLevenshteinDistance(str1, str2);
    const similarity = (maxLength - distance) / maxLength;
    return similarity * 2 - 1;
}
