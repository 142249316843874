import { Button, ButtonGroup, Container, Divider, Heading, Input, Popover, PopoverBody, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaInfo } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeSeries, priceTimeseries } from '../../action-creators/timeseries/time-series.action';
import { Backtest, GraphItem } from '../../backtesting-common-frontend';
import { isBacktestLicense } from '../../backtesting-common-frontend/http-utilities/auth/auth-http-utilities';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import { MethodsResultsManager } from '../../managers/methods/methods-manager';
import { TimeSeriesResultsManager } from '../../managers/time-series/time-series-manager';
import { setLoading } from '../../store/backtests/backtests';
import { AppState } from '../../store/store';

export default function AddMethodHelper({ callback, disabled, disabledText, callbackTest, testDisabled, useHoldValue, removeViewBuySignal }: 
    {callback: (name: string, update: boolean, holdValue: string) => void, useHoldValue: boolean, disabled: boolean, removeViewBuySignal?: boolean,  disabledText?: string, callbackTest?: (graphItem: GraphItem[], holdValue: string) => Promise<void>, testDisabled?: boolean}) {
    const dispatch = useDispatch();
    const selectedSymbols = useSelector(
        (state: AppState) => state.symbols.selectedSymbols
    );
    const graphItems = useSelector((state: AppState) => state.graphItems.graphItems);
    const backtestCtx = useSelector((state: AppState) => state.backtests.backtest);
    const { onOpen, onClose, isOpen } = useDisclosure();
    const { onOpen: onOpenHold, onClose: onCloseHold, isOpen: isOpenHold } = useDisclosure();
    const [ name, setName ] = useState<string>('');
    const tabs = useSelector((state: AppState) => state.tabs.currentMainTab);
    const currentTest = useSelector((state: AppState) => state.tests.test);
    const [ methodExists, setMethodExists ] = useState<boolean>(false);
    const [ clickedInfo, setClickedInfo ] = useState<boolean>(false);
    const [ backtest, setBacktest ] = useState<Backtest | null>(null);
    const [ timeSeriesResultsManager ] = useState<TimeSeriesResultsManager>(new TimeSeriesResultsManager());
    const [ holdValue, setHoldValue ] = useState<string>("0");

    useEffect(() => {
        if(backtestCtx){
            setBacktest(cloneDeep(backtestCtx));
        }
    }, [ backtestCtx ]);

    useEffect(() => {
        if(currentTest && tabs){
            const currentMethod = MethodsResultsManager.getCurrentMethod(currentTest, tabs);
            if(currentMethod){
                setName("");
                setMethodExists(true);
            }
        }
    }, [ currentTest, tabs ]);

    return (
        <>
            {!removeViewBuySignal && <Button mr={3} disabled={testDisabled ?? disabled} onClick={async() => {
                dispatch(setLoading(true));
                let useTs: GraphItem[] = [];
                if(graphItems.length === 0){
                    const priceTs = priceTimeseries;
                    useTs = await getTimeSeries(selectedSymbols, priceTs, timeSeriesResultsManager, dispatch, graphItems, backtest, "theory");
                }
                setTimeout(() => {
                    if(callbackTest){
                        callbackTest(useTs, holdValue).finally(() => {
                            dispatch(setLoading(false));
                        });
                    }
                }, 0);
            }}>
                {"View buy signals"}
            </Button>}
            {!removeViewBuySignal && !disabled && isBacktestLicense() ? <Popover
                isOpen={isOpenHold}
                onOpen={onOpenHold}
                onClose={onCloseHold}
                placement='right'
                closeOnBlur={false}
            >
                <PopoverTrigger>
                    <Button colorScheme={'gray'}>Add hold position ({holdValue ?? 0})</Button>
                </PopoverTrigger>
                <PopoverContent p={5}>
                    <PopoverHeader fontWeight='semibold'>Add hold position</PopoverHeader>
                    <PopoverBody>
                        <Heading size='xs' color={'gray'}>Hold</Heading>
                        <p>Add a hold value that holds the position for number of steps when the method is actived</p>
                        <Input
                            value={holdValue}
                            onChange={(e) => {
                                setHoldValue(e.target.value);
                            }}
                        />
                    </PopoverBody>
                    <PopoverFooter display='flex' justifyContent='flex-end'>
                        <ButtonGroup size='sm'>
                            <Button variant='outline' onClick={onCloseHold}>
                                        Cancel
                            </Button>
                            <Button colorScheme='blue' onClick={async() => {
                                onCloseHold();
                            }}>
                                        Save
                            </Button>
                        </ButtonGroup>
                    </PopoverFooter>
                </PopoverContent>
            </Popover> : null}
            <Divider mt={5} />
            {!disabled && isBacktestLicense() ? <Popover
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                placement='right'
                closeOnBlur={false}
            >
                <PopoverTrigger>
                    <Button bg={'teal'} colorScheme={'gray'}>Save method</Button>
                </PopoverTrigger>
                <PopoverContent p={5}>
                    <PopoverHeader fontWeight='semibold'>Add a note</PopoverHeader>
                    <PopoverBody>
                        <Input
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                        <Container w={'full'} mt={3}>
                            {methodExists && <>
                                <FaInfo cursor={'pointer'} color={'gray'} onClick={() => {
                                    setClickedInfo(!clickedInfo);
                                }} />
                                {clickedInfo && <span>Make sure that your method name explains what it is so that you know afterwards what configuration you choosed.</span>}
                            </>}
                        </Container>
                    </PopoverBody>
                    <PopoverFooter display='flex' justifyContent='flex-end'>
                        <ButtonGroup size='sm'>
                            <Button variant='outline' onClick={onClose}>
                                        Cancel
                            </Button>
                            <Button colorScheme='blue' onClick={async() => {
                                callback(name, false, holdValue);
                                onClose();
                            }}>
                                        Save new
                            </Button>
                        </ButtonGroup>
                    </PopoverFooter>
                </PopoverContent>
            </Popover> : isBacktestLicense() && <Tooltip label={disabledText ?? "Add a time series to the graph to enable this feature"} aria-label={"A tooltip"} placement={"top"}>
                <Button colorScheme={'gray'}>Save method</Button>
            </Tooltip>}
        </>
    );

}
