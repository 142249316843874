
export class GoogleTrendsDTO {
    public category: number;
    public keyword: string | string[];
    public geo: string;
    public endTime: Date;
    public startTime?: Date;
}

export class CountryDTO {
    public name: string;
    public code: string;
}

export class CountriesDTO {
    public list: CountryDTO[];
}

export class GTTimeSeriesDTO {
    public list: TimeSeriesValue[];
}

export class TimeSeriesValue {
    public value: number;
    public date: Date;
}

export class CategoryDTO {
    public name: string;
    public id: number;
}

export class GeoValuesDTO {
    public geoCode: string;
    public geoName: string;
    public values: number;
}

export class RelatedQueriesDTO {
    public query: string;
    public value: number;
    public hasData: boolean;
}

export class RelatedTopicsDTO {
    public title: string;
    public type: string;
    public value: number;
    public hasData: boolean;
    public mid: string;
}

export class AutoCompleteDTO {
    public mid: string;
    public title: string;
    public type: string;
}
