import { Box, Button, ButtonGroup, Flex, Heading, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, useDisclosure } from '@chakra-ui/react';

export default function LogoutScreen() {
    const { isOpen, onToggle, onClose } = useDisclosure();

    return (
        <div style={{  height: '100vh' }}>
            <Flex mt={4} p={2}>
                <Box flex="1" p="4"   
                    borderRadius="md"
                    marginInlineEnd={10}
                    boxShadow="md">

                </Box>

                <Box flex="2" p="4"   bg={'whiteAlpha.100'}
                    borderRadius="md"
                    marginInlineEnd={10}
                    boxShadow="md">
                    <Heading size="md">Logout?</Heading>

                    <Popover
                        isOpen={isOpen}
                        onClose={onClose}
                    >
                        <PopoverTrigger>
                            <Button onClick={onToggle}>Logout</Button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader>Sure you want to logout?</PopoverHeader>
                            <PopoverFooter
                                border='0'
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                                pb={4}
                            >
                                <ButtonGroup size='sm'>
                                    <Button variant='outline' onClick={onClose}>Cancel</Button>
                                    <Button colorScheme='blue' onClick={() => {
                                        localStorage.removeItem('user');
                                        // refresh website
                                        window.location.reload();
                                    }}>
              Yes
                                    </Button>
                                </ButtonGroup>
                            </PopoverFooter>
                        </PopoverContent>
                    </Popover>
                </Box>

                <Box flex="1" p="4"  
                    borderRadius="md"
                    marginInlineEnd={10}
                    boxShadow="md">
                </Box>
            </Flex>
        </div>
    );
    
}

