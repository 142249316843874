import _ from "lodash";
import { TimeSeriesDTO } from "../../methods";
import { TimeSeriesHelperValue } from "../timeseries-models";
import { ImpreemDate } from "./../../date/impreem-date";
import { Periodicity, convertPeriodictyToStep } from "./../../shared/periodicity";
import { makeObjectArrayToArrayOfArrayHelper } from "./../../shared/utilites/array.utilities";
import { cleanDateArray, getDateFromLabelUtilities, getDatesFromStepUtilities, leftJoinByDate, sortByDate } from "./../../shared/utilites/dates.utilities";

export class DatesService {

    public async getSteps(to: ImpreemDate, periodicity: Periodicity, from: ImpreemDate): Promise<ImpreemDate[]> {
        const interval = convertPeriodictyToStep(periodicity);
        // create a number sequence from end date to oldest date
        const sequence: number[] = [];
        for (let i = to.step; i >= from.step; i -= interval) {
            sequence.push(i);
        }

        const dates = await getDatesFromStepUtilities(sequence);
        return dates;
    }

    public async align(timeseries: TimeSeriesHelperValue[], endDate: ImpreemDate, periodicity: Periodicity, allTimeseriesUsed: TimeSeriesDTO[]): Promise<(TimeSeriesHelperValue | null)[][]> {
        if (timeseries.length === 0) {
            return [];
        }
        // find oldest date in timeseries
        let oldestDate = timeseries.reduce((prev, curr) => {
            return new Date(prev.date).getTime() < new Date(curr.date).getTime() ? prev : curr;
        }).date;

        // check if oldest date is before 2005-01-01 and if so, then let it be 2005-01-01
        const oldestDate2005 = new Date("2005-01-01");
        if (new Date(oldestDate).getTime() < oldestDate2005.getTime()) {
            oldestDate = "2005-01-01";
        }

        const oldestImpreemDate = await getDateFromLabelUtilities(oldestDate);

        if (oldestImpreemDate == null) {
            return [];
        }

        const { orderedDates, longestTimeseries, timeseriesGrouped }: { orderedDates: ImpreemDate[]; longestTimeseries: TimeSeriesHelperValue[]; timeseriesGrouped: TimeSeriesHelperValue[][] }
        = await this.createDateArray(endDate, periodicity, oldestImpreemDate, timeseries);

        const resultsGrouped: (TimeSeriesHelperValue)[] = this.createOneTimeSeriesWithDates(orderedDates, longestTimeseries, allTimeseriesUsed);

        const res = this.addTheOthers(timeseriesGrouped, longestTimeseries, resultsGrouped, allTimeseriesUsed);

        return res;

    }

    private async createDateArray(endDate: ImpreemDate, periodicity: Periodicity, startDate: ImpreemDate, timeseries: TimeSeriesHelperValue[]): Promise<{ orderedDates: ImpreemDate[]; longestTimeseries: TimeSeriesHelperValue[]; timeseriesGrouped: TimeSeriesHelperValue[][] }> {
        const dates = await this.getSteps(endDate, periodicity, startDate);

        // Group by transformationKey and symbol
        const groupedFirst = makeObjectArrayToArrayOfArrayHelper(_.groupBy(timeseries, "transformationKey")) as TimeSeriesHelperValue[][];
        const timeseriesGrouped: TimeSeriesHelperValue[][] = [];
        for (let i = 0; i < groupedFirst.length; i++) {
            const groupedSecond = makeObjectArrayToArrayOfArrayHelper(_.groupBy(groupedFirst[i], "symbol")) as TimeSeriesHelperValue[][];
            timeseriesGrouped.push(...groupedSecond);
        }

        const orderedDates = sortByDate(cleanDateArray(dates, "date"), "date");

        const longestTimeseries = timeseriesGrouped.reduce((prev, curr) => {
            return prev.length > curr.length ? prev : curr;
        });
        return { orderedDates, longestTimeseries, timeseriesGrouped };
    }

    private createOneTimeSeriesWithDates(orderedDates: ImpreemDate[], longestTimeseries: TimeSeriesHelperValue[], allTimeseriesUsed: TimeSeriesDTO[]): (TimeSeriesHelperValue)[] {
        // create reverse loop of dates
        const ts = longestTimeseries.map(e => {
            e.d = new Date(e.date);
            return e;
        });

        const fakeGraphValues = orderedDates.map(e => {
            const v = new TimeSeriesHelperValue();
            v.d = e.date;
            v.date = e.label;
            v.value = 1;
            return v;
        });

        const isCategorizedTimeSeries = allTimeseriesUsed.some(e => e.transformationKey === longestTimeseries[0].transformationKey && e.categorizeType != null);

        const joined = leftJoinByDate(fakeGraphValues, ts, false, undefined, !isCategorizedTimeSeries, isCategorizedTimeSeries);

        return joined[1];
    }

    private addTheOthers(timeseriesGrouped: TimeSeriesHelperValue[][], 
        longestTimeseries: TimeSeriesHelperValue[], 
        resultsGrouped: TimeSeriesHelperValue[],
        allTimeseriesUsed: TimeSeriesDTO[]): TimeSeriesHelperValue[][] {
        // find index by transformationKey and remove it
        const index = timeseriesGrouped.findIndex(e => JSON.stringify(e[0]) === JSON.stringify(longestTimeseries[0]));
        timeseriesGrouped.splice(index, 1);
        const res: TimeSeriesHelperValue[][] = [ resultsGrouped ];
        timeseriesGrouped.forEach((tsX) => {
            const isCategorizedTimeSeries = allTimeseriesUsed.some(e => e.transformationKey === tsX[0].transformationKey && e.categorizeType != null && !e.categorizeType.isOnFly);
            const joined = leftJoinByDate(resultsGrouped, tsX.map(e => {
                e.d = new Date(e.date);
                return e;
            }), false, undefined, !isCategorizedTimeSeries, isCategorizedTimeSeries);
            res.push(joined[1]);
        });
        return res;
    }
}
