/* eslint-disable @typescript-eslint/no-unsafe-return */
import { GraphItem } from "../../.";
import { GraphItemDocument } from "../../database/user-DB/backend/timeseries/graphitems";

export type graphActive = "edit" | "theory" | "selection-criteria" | "modern-portfolio-theory";

export class DisplayService {

    public handleGraphActive(type: graphActive, graphItems: GraphItemDocument[] | GraphItem[] | (GraphItem | GraphItemDocument)[], newItems: GraphItemDocument[] | GraphItem[] | (GraphItem | GraphItemDocument)[] | null) {
        const removeGraphItems: (GraphItem | GraphItemDocument)[] = [];
        switch (type) {
            case "modern-portfolio-theory": {
            // // set all graphItems with modern-portfolio-theory to editActive = false and theoryActive = false
            // graphItems = graphItems.map((item) => {
            //     if (item.groupId === "modern-portfolio-theory") {
            //         item.editActive = false;
            //         item.theoryActive = false;
            //     }
            //     return item;
            // });
            // Remove all modern-portfolio-theory
                removeGraphItems.push(...graphItems.filter((item) => item.groupId === "modern-portfolio-theory"));
                graphItems = graphItems.filter((item) => item.groupId !== "modern-portfolio-theory");
                break;
            }
            case "selection-criteria": {
            // remove all selection-criteria
                removeGraphItems.push(...graphItems.filter((item) => item.groupId === "selection-criteria"));
                graphItems = graphItems.filter((item) => item.groupId !== "selection-criteria");
                break;
            }
            case "edit": {
                if (newItems != null) {
                    graphItems = graphItems.map((item) => {
                        const any = newItems.some(t => JSON.stringify(t) === JSON.stringify(item));
                        if (any) {
                            item.editActive = true;
                        } else {
                            item.editActive = false;
                        }
                        return item;
                    });
                }
                break;
            }
            case "theory": {
                if (newItems != null) {
                // It can max be two theoryActive and one theoryActiveMain at the same time, toggle them by .added (Date)
                // it can only be one theoryActiveMain in newItems, set false if there are more than 1
                    const newItem = newItems.filter((item) => item.theoryActiveMain);
                    if (newItem.length > 1) {
                        newItem[0].theoryActiveMain = false;
                    }
                    if (newItems.some(t => t.theoryActiveMain)) {
                        graphItems = graphItems.map((item) => {
                            if (item.theoryActiveMain && JSON.stringify(item) !== JSON.stringify(newItem[1])) {
                                item.theoryActiveMain = false;
                            }
                            return item;
                        });
                    }
                    graphItems = graphItems.map((item) => {
                        const any = newItems.some(t => JSON.stringify(t) === JSON.stringify(item));
                        if (any) {
                            item.theoryActive = true;
                        }
                        return item;
                    });
                    // set theoryActive to false on the rest if there are more than two theoryActive
                    if (newItems.filter((item) => item.theoryActive).length > 2) {
                        const dateOrder = newItems.sort((a, b) => a.added.getTime() - b.added.getTime());
                        // remove newItems from dateOrder
                        const dateOrderWithoutNewItems = dateOrder.filter((item) => !newItems.some(t => JSON.stringify(t) === JSON.stringify(item)));
                        // loop graphItems and set theoryActive to false if there are in the dateOrderWithoutNewItems
                        graphItems = graphItems.map((item) => {
                            if (dateOrderWithoutNewItems.some(t => JSON.stringify(t) === JSON.stringify(item))) {
                                item.theoryActive = false;
                            }
                            return item;
                        });
                    }
                    // if there are only one item set to theoryActiveMain to true
                    if (newItems.length === 1) {
                        newItems[0].theoryActiveMain = true;
                    }
                }
                break;
            }
        }
        // remove the lasts 10 item.timeseries.graphValues by item index
        graphItems = graphItems.map((item, index) => {
            if (index > 9) {
                removeGraphItems.push(item);
            }
            return item;
        });

        return removeGraphItems;

    }
}
