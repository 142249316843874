import { Box, Container, Divider, HStack, Heading, Progress, Tooltip } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { FaArrowDown, FaArrowUp, FaTrash } from "react-icons/fa";
import { deleteProgress } from "../../backtesting-common-frontend/http-utilities/http-utilities/progress/progress.backend.service.";
import { round } from "../../backtesting-common-frontend/shared/utilites/math.utilities";
import { InProgressDTO } from "../../backtesting-common-frontend/status/inprogress-dto";

export default function ProgressMain({  progressDtos }: { progressDtos: InProgressDTO[] | null }) {
    const [ hide, setHide ] = React.useState<string[]>([]);
    const [ show, setShow ] = React.useState<boolean>(true);
    if (!progressDtos) {
        return null;
    }
    // create elements for each progress
    const progressElements = progressDtos.map((aProgress, index) => {
        if (!aProgress || aProgress.ended || hide.includes(aProgress._id)) {
            return;
        }
        let progressBar = aProgress.atStep / aProgress.totalSteps + (aProgress['progress'] ? aProgress['progress'] : 0.1) / 100;
        if (progressBar > 1) {
            progressBar = 1;
        }
        const timeDurationInSeconds = moment().diff(moment(aProgress.created), 'seconds');
        let displayDuration: string;

        // Check if the duration is more than 60 seconds
        if (timeDurationInSeconds > 60) {
            const timeDurationInMinutes = Math.floor(timeDurationInSeconds / 60);
            const remainingSeconds = timeDurationInSeconds % 60;
            displayDuration = `${timeDurationInMinutes} minutes ${remainingSeconds} seconds`;
        } else {
            displayDuration = `${timeDurationInSeconds} seconds`;
        }
        return (
            <Container key={index} mb={2} w={'full'} p={4} bg={aProgress.type === "failed" ? 'red.500' : undefined}>
                <Container flexDirection={"row"} style={{ display: 'flex' }}>
                    <Box flex={3/4}>
                        <Heading size="sm" color="white.500" mb={2}>
                            {aProgress.type === "failed" ? <>
                                {aProgress.description}
                            </> : <>
                                {aProgress.name} / {`${aProgress.atStep}/${aProgress.totalSteps}`} / {`${round(progressBar * 100, 0)}%`} / {aProgress.description}
                                <Divider />
                                {displayDuration} ago
                            </>}
                        </Heading>
                    </Box>
                    <Box flex={1/4}>
                        <FaTrash style={{ cursor: "pointer" }} onClick={() => {
                            setHide([ ...hide, aProgress._id ]);
                            deleteProgress(aProgress, aProgress.client);
                        }} />
                    </Box>
                </Container>
                <Progress value={round(progressBar * 100, 0)} />
            </Container>
        );
    });
    return (
        <>
            {progressElements?.length > 0 && <>
                {show ? <div style={{ width: '50vh', 
                    position: 'fixed', // Changed from 'absolute' to 'fixed'
                    bottom: '0px', // Changed from calculating top to simply setting bottom to 0
                    right: '0px', // Keep as is to stay at the right
                    backgroundColor: '#3b3831', 
                    zIndex: 1000000000,
                    overflowY: 'scroll',
                    maxHeight: '100vh',
                }}>
                    {progressElements}
                    <Tooltip label="Hide progress" aria-label="Hide progress">
                        <HStack m={5}>
                            <Heading size="sm" color="white.500" mb={2} onClick={() => setShow(!show)} cursor={'pointer'}>
                                Hide progress
                            </Heading>
                            <FaArrowDown style={{ cursor: "pointer", color: "white" }} onClick={() => setShow(!show)} />
                        </HStack>
                    </Tooltip>
                </div> : 
                    <div style={{ width: '50vh', 
                        position: 'fixed', // Changed from 'absolute' to 'fixed'
                        bottom: '0px', // Changed from calculating top to simply setting bottom to 0
                        right: '0px', // Keep as is to stay at the right
                        backgroundColor: '#3b3831', 
                        zIndex: 1000000000,
                    }}>
                        <Tooltip label="Show progress" aria-label="Show progress">
                            <HStack m={5}>
                                <Heading size="sm" color="white.500" mb={2} onClick={() => setShow(!show)} cursor={'pointer'}>
                                Show progress
                                </Heading>
                                <FaArrowUp style={{ cursor: "pointer", color: "white" }} onClick={() => setShow(!show)} />
                            </HStack>
                        </Tooltip>
                    </div>
                }
            </>}
        </>
    );
}
