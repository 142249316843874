import mongoose from "mongoose";
const Schema = mongoose.Schema;

const timeseriesmenuSchema = new Schema({
    categoryTitle: String,
    items: Schema.Types.Mixed,
    isActive: Boolean,
    logo: String,
    market: String,
    category: String
});

const timeseriesmenu = mongoose.model("timeseriesmenus", timeseriesmenuSchema);

export default timeseriesmenu;
