/* eslint-disable @typescript-eslint/no-loop-func */

import { TrackedNews } from "../../../database/user-DB/backend/trackednews";
import { sendPost } from "../frontend/http-utilities";

export async function getNews(): Promise<TrackedNews[] | null> {
    return sendPost("news/get-news", {}).then(e => {
        return e.data as TrackedNews[];
    }).catch(() => {
        return null;
    });
}
