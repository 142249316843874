import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Tab } from '../../components/tabs';
import { initMainTabs, settingsTabs } from './tabs-init';

class TabsResults {
    mainTabs: Tab[] = [];
    currentMainTab: Tab | null  = null;
    settingsTab: Tab[] = [];
    currentSettingsTab: Tab | null = null;
    isNoMlMethod = false;
    navigationTo = "";
}

const initialState: TabsResults = {
    mainTabs: initMainTabs,
    settingsTab: settingsTabs,
    currentMainTab: initMainTabs[0],
    currentSettingsTab: settingsTabs[0],
    isNoMlMethod: false,
    navigationTo: "",
};

const tabs = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        setMainTabs: (state, action: PayloadAction<Tab[]>) => {
            state.mainTabs = action.payload;
        },
        setSettingsTabs: (state, action: PayloadAction<Tab[]>) => {
            state.settingsTab = action.payload;
        },
        setCurrentMainTab: (state, action: PayloadAction<Tab>) => {
            const currentMainTab = action.payload;
            const isNoMlMethod = currentMainTab?.Id === "modern-portfolio-tab" || currentMainTab?.Id === "selection-filters" || currentMainTab?.Id === "api-methods";
            state.isNoMlMethod = isNoMlMethod;
            state.currentMainTab = action.payload;
        },
        setCurrentSettingsTab: (state, action: PayloadAction<Tab>) => {
            state.currentSettingsTab = action.payload;
        },
        setNavigationTo: (state, action: PayloadAction<string>) => {        
            state.navigationTo = action.payload;
        },
    },
});

export const updateMainTabs = tabs.actions.setMainTabs;
export const updateSettingsTabs = tabs.actions.setSettingsTabs;
export const updateCurrentMainTab = tabs.actions.setCurrentMainTab;
export const updateCurrentSettingsTab = tabs.actions.setCurrentSettingsTab;
export const updateNavigationTo = tabs.actions.setNavigationTo;
export const tabsReducer = tabs.reducer;