import { round } from "./math.utilities";

export const transformReturns = (r: number[] = []): number[] => {
    let a = 1;
    const b: number[] = [];
    for (let i = 0; i < r.length; i++) {
        a = (r[i] + 1) * a;
        b.push(a);
    }
    return b;
};

export const transformToPrecent = (transformedReturns: number[] = []): number[] => {
    const b: number[] = [];
    for (let i = 0; i < transformedReturns.length; i++) {
        b.push(round((transformedReturns[i] - 1) * 100, 3));
    }
    return b;
};

export const average = (aArray: number[] = []): number => {
    return aArray.reduce((a, b) => a + b, 0) / aArray.length;
};
