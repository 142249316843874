/* eslint-disable @typescript-eslint/no-unused-vars */
import { useColorMode } from '@chakra-ui/react';
import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { Backtest } from '../../backtesting-common-frontend';
import { round } from '../../backtesting-common-frontend/shared/utilites/math.utilities';
import { transformReturns, transformToPrecent } from '../../backtesting-common-frontend/shared/utilites/transform';
import { ColorsLight } from '../shared/constants/Colors';

export class AnnotationsDTO {
    public x: number;
    public y: number;
    public dataPoint?: number;
    public image?: any;
    public label?: any;

    constructor(xValue: number, yValue: number, imageValue: any) {
        this.x = xValue;
        this.y = yValue;
        this.image = imageValue;
    }
}

function ChartComponent({
    backtestResults,
    removeHelperSeries,
}: {
	backtestResults: Backtest;
    removeHelperSeries?: boolean;
}) {
    const [ colors ] = useState(new ColorsLight());
    const { colorMode } = useColorMode();
    const [ userHasClickedOnGraph, setUserHasClickedOnGraph ] = useState(false);
    const [ annotationsPoints, setAnnotationsPoints ] = useState<PointAnnotations[]>(
        []
    );
    let initAnnotationsPoints: PointAnnotations[] = [];
    const totalSteps: number = backtestResults?.numberOfStepsTotal
        ? backtestResults.numberOfStepsTotal
        : 0;

    const helpSeries: any[] = [];

    if (backtestResults == null) {
        return null;
    }
    const series = transformToPrecent(
        transformReturns(backtestResults.historicalR.map((e) => e.R))
    );

    const seriesWithDates: any[] = [];
    const customLabels: string[] = backtestResults.historicalR.map(e => e.date.label); // Assuming `date` is a string

    // reverse loop of series
    for (let i = series.length - 1; i >= 0; i--) {
        const date = backtestResults.historicalR[i]?.date ?? "2000-01-01"; // Using date string directly
        seriesWithDates.push({
            x: i, // Use index as x-value
            y: series[i],
        });
    }

    const seriesData = [
        {
            name: backtestResults.name || 'Your strategy',
            data: seriesWithDates,
        },
    ];

    const portfolios = backtestResults.historicalPortfolios;
    const comparisonChartSeries: ApexAxisChartSeries = [];
    backtestResults.comparisonSeries.forEach((e) => {
        const values = transformToPrecent(transformReturns(e.R.map((p) => p.R)));
        comparisonChartSeries.push({
            name: e.name,
            data: values.map((c, i) => {
                return { x: i, y: c }; // Use index as x-value
            }),
        });
    });

    const annotationsDTO: AnnotationsDTO[] = [];
    let offsetDirection = -1;
    portfolios.forEach((portfolio, i) => {
        const y = series[i];
        const x = i; // Use index as x-value
        if (comparisonChartSeries.length === 1) {
            const comparisonValue = comparisonChartSeries[0].data[i] as any;
            offsetDirection = comparisonValue == null || y > comparisonValue ? -1 : 1;
        }
        if (portfolio.companies.length === 0) {
            annotationsDTO.push({
                x: x,
                y: y,
                dataPoint: i + 1,
                label: {
                    text: 'The program did not buy any shares',
                    offsetX: 0,
                    offsetY: 0,
                },
            });
        } else {
            portfolio.companies.forEach((company, space) => {
                annotationsDTO.push({
                    x: x,
                    y: y,
                    dataPoint: i + 1,
                    image: {
                        path: company.image,
                        width: 25,
                        height: 25,
                        offsetX: 0,
                        offsetY:
							offsetDirection === -1? offsetDirection * ((space + 1) * 35 + (space + 1) * 10): offsetDirection * (space * 35 + (space + 1) * 10),
                    },
                    label: {
                        style: {
                            background: colorMode === "dark" ? 'black' : 'white',
                        },
                    },
                });
                annotationsDTO.push({
                    x: x,
                    y: y,
                    dataPoint: i + 1,
                    label: {
                        text: company.symbol,
                        offsetX: 0,
                        offsetY:
							offsetDirection === -1? offsetDirection * (space * 35 + (space + 1) * 10): offsetDirection * ((space + 1) * 35 + (space + 1) * 10),
                        style: {
                            background: colorMode === "dark" ? 'black' : 'white',
                        },
                    },
                });
                try {
                    annotationsDTO.push({
                        x: x,
                        y: y,
                        dataPoint: i + 1,
                        label: {
                            text: round(backtestResults.historicalR[i].R * 100, 2) + '%',
                            offsetX: 0,
                            offsetY: offsetDirection === -1 ? 30 : -30,
                            style: {
                                color:
									round(backtestResults.historicalR[i].R * 100, 2) < 0? colors.red: colors.green,
                                background: colorMode === "dark" ? 'black' : 'white',
                            },
                        },
                    });
                } catch (error) {
                    // empty
                }
            });
        }
    });

    if (!userHasClickedOnGraph) {
        const lastIndex = annotationsDTO[annotationsDTO.length - 1]?.x;
        if (lastIndex) {
            const secondIndex = annotationsDTO.find((x) => x.x === lastIndex - 1)?.x;
            const thirdIndex = annotationsDTO.find((x) => x.x === lastIndex - 2)?.x;
            initAnnotationsPoints = annotationsDTO.filter(
                (x) => x.x === lastIndex
            ) as PointAnnotations[];
        }
    }

    let highestValue = 0;
    let lowestValue = 0;
    if (offsetDirection === -1) {
        backtestResults.comparisonSeries.forEach((e) => {
            const returnsSeries: number[] = transformToPrecent(
                transformReturns(e.R.map((p) => p.R))
            );
            let nr = 0;
            for (let i = 0; i < returnsSeries.length; i++) {
                if (returnsSeries[i] > nr) {
                    nr = returnsSeries[i];
                }
            }
            if (nr > highestValue) {
                highestValue = nr;
            }
        });
        let nr = 0;
        series.forEach((e) => {
            if (e > nr) {
                nr = e;
            }
        });
        if (nr > highestValue) {
            highestValue = nr;
        }
    } else {
        backtestResults.comparisonSeries.forEach((e) => {
            const returnsSeries: number[] = transformToPrecent(
                transformReturns(e.R.map((p) => p.R))
            );
            let nr = 0;
            for (let i = 0; i < returnsSeries.length; i++) {
                if (returnsSeries[i] < nr) {
                    nr = returnsSeries[i];
                }
            }
            if (nr < lowestValue) {
                lowestValue = nr;
            }
        });
        let nr = 0;
        series.forEach((e) => {
            if (e < nr) {
                nr = e;
            }
        });
        if (nr > lowestValue) {
            lowestValue = nr;
        }
    }
    for (let i = 0; i < totalSteps; i++) {
        if (offsetDirection === -1) {
            const above = highestValue + 10;
            helpSeries.push({
                x: i, // Use index as x-value
                y: above,
            });
        } else {
            const below = lowestValue - 10;
            helpSeries.push({
                x: i, // Use index as x-value
                y: below,
            });
        }
    }

    const xAxis: any = {
        labels: {
            formatter: function(value) {
                return customLabels[value]; // Use customLabels array to get date labels
            },
            style: {
                colors: colorMode === 'dark' ? '#ffffff' : '#333', // Adjust label text color for dark mode
            },
        },
        tickAmount: 10,
    };

    const theData = [
        ...seriesData,
        ...comparisonChartSeries,
    ];

    // if(!removeHelperSeries){
    //     theData.push({
    //         name: '',
    //         color: 'transparent',
    //         data: helpSeries,
    //     });
    // }

    const chart = (
        <Chart
            options={{
                theme: {
                    mode: colorMode,
                },
                yaxis: {
                    labels: {
                        formatter: function(val) {
                            return val + '%';
                        },
                        style: {
                            colors: colorMode === 'dark' ? '#ffffff' : '#333', // Adjust label text color for dark mode
                        },
                    },
                },
                xaxis: xAxis,
                annotations: {
                    points: !userHasClickedOnGraph
                        ? initAnnotationsPoints
                        : annotationsPoints,
                },
                chart: {
                    animations: {
                        enabled: false,
                    },
                    background: colorMode === 'dark' ? '#0F172A' : '#ffffff', // Set chart background based on colorMode
                    id: 'basic-bar',
                    events: {
                        click: (event, chartContext, config) => {
                            if (config.seriesIndex === 0) {
                                const dp =
                                    seriesData[0].data.length - 1 - config.dataPointIndex + 1;
                                setUserHasClickedOnGraph(true);
                                const resultFromAnnotationsDTO = annotationsDTO
                                    .filter((e) => e.dataPoint === dp)
                                    .slice(0, 25);
                                const annotationsDataResult =
                                    resultFromAnnotationsDTO as PointAnnotations[];
                                setAnnotationsPoints(annotationsDataResult);
                            }
                        },
                    },
                },
                tooltip: {
                    theme: colorMode === 'dark' ? 'dark' : 'light', // Set tooltip theme based on colorMode
                },
            }}
            series={theData}
            type="line"
            width="100%"
        />
    );

    return <div style={{ flex: 1, width: '100%' }}>{chart}</div>;
}

const BacktestChart = React.memo(ChartComponent);

export default BacktestChart;
