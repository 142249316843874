/* eslint-disable @typescript-eslint/no-explicit-any */
import moment, { isDate } from "moment";
import { convertPeriodictyToStep, Periodicity } from "../periodicity";
import dates from "./../../database/user-DB/backend/dates";
import { ImpreemDate } from "./../../date/impreem-date";

export type DateSetting = "backtest" | "method-test" | "method-train" | "time-series";

export type DateMetaName = "from-test" | "from-train" | "from" | "to-test" | "to-train" | "to";

export class DatesSettingsMeta {
    public name: DateMetaName;

    public date: ImpreemDate;

    public useLatest = false;
}

export class DatesSettings {
    public type: DateSetting;

    public dates: DatesSettingsMeta[] = [];
}

export const addNextPeriod = (currentStep: number, periodicity: Periodicity): number => {
    return currentStep + convertPeriodictyToStep(periodicity);
};

export const getDateFromStepUtilities = async (step: number): Promise<ImpreemDate | null> => {
    const date = await dates.findOne({ step: step }).exec();
    if (!date || date.step == null || date.label == null) {
        return null;
    }
    const impreemDate = new ImpreemDate(date.step, date.label);
    impreemDate.date = new Date(impreemDate.label);
    return impreemDate;
};

export const getDatesFromStepUtilities = async (steps: number[]): Promise<ImpreemDate[]> => {
    const datesA = await dates.find({ step: { $in: steps } }).exec();
    const impreemDate = datesA.map(date => {
        const v = new ImpreemDate(date.step, date.label);
        v.date = new Date(v.label);
        return v;
    });
    return impreemDate;
};

export const getDatesFromLabelUtilities = async (d: Date[] | string[]): Promise<ImpreemDate[]> => {
    const m = d.map(p => moment(p).format("YYYY-MM-DD"));
    const datesA = await dates.find({ label: { $in: m } }).exec();
    const impreemDate = datesA.map(date => {
        const v = new ImpreemDate(date.step, date.label);
        v.date = new Date(v.label);
        return v;
    });
    return impreemDate;
};

export const getDateFromLabelUtilities = async (d: Date | string): Promise<ImpreemDate | null> => {
    const date = await dates.findOne({ label: isDate(d) ? moment(d).format("YYYY-MM-DD") : d }).exec();
    if (!date || date.step == null || date.label == null) {
        return null;
    }
    const impreemDate = new ImpreemDate(date.step, date.label);
    impreemDate.date = new Date(impreemDate.label);
    return impreemDate;
};

export function cleanDateArray<T>(array: T[], field: string | null): T[] {
    const seenDates = new Set<number>();
    const cleanedArray: T[] = [];

    for (const data of array) {
        const date = new Date(data[field]).getTime();
        if (!seenDates.has(date)) {
            seenDates.add(date);
            cleanedArray.push(data);
        }
    }

    return cleanedArray;
}

export function sortByDate<T>(array: T[], field: string | null): T[] {
    array.sort((a, b) => {
        return new Date(a[field]).getTime() - new Date(b[field]).getTime();
    });
    return array;
}

export function isSortedByDate<T extends { date: string }>(array: T[]): boolean {
    for (let i = 1; i < array.length; i++) {
        if (new Date(array[i].date) < new Date(array[i - 1].date)) {
            return false;
        }
    }
    return true;
}

export const cleanDateArrayNew = (array: any[], field: string): any[] => {
    const n: number[] = [];
    return array.filter((a) => {
        let success = true;
        if (n.find(x => x >= new Date(a[field]).getTime())) {
            success = false;
        }
        n.push(new Date(a[field]).getTime());
        return success;
    });
};

export function generateDateArray(latestDate: Date, startDate: Date, frequency: Periodicity): Date[] | undefined {
    if (!frequency) {
        return;
    }
    const dateArray: Date[] = [];
    const daysToSubtract = { day: 1, week: 7, month: 30 };
    const currentDate = latestDate;
    let newDate: Date = new Date(latestDate);
    const daysToSubtractForFrequency = daysToSubtract[frequency];
    for (let i = 0; currentDate.getTime() > startDate.getTime(); i++) {
        dateArray.push(newDate);
        currentDate.setDate(currentDate.getDate() - daysToSubtractForFrequency);
        newDate = new Date(currentDate);
    }
    return dateArray;
}

export const Months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const MonthIndexArray = Months.map((month, index) => {
    return {
        name: month,
        index: index,
    };
});

export const MonthsPrefix = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export function getYearsBetween(startDate: Date, endDate: Date): number[] {
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const years: number[] = [];
    for (let year = startYear; year <= endYear; year++) {
        years.push(year);
    }
    return years;
}

export function isOnSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear()
        && date1.getMonth() === date2.getMonth()
        && date1.getDate() === date2.getDate();
}

export function universialBinarySearch<T extends { d: Date; date: string }>(ZDate: Date, someObservations: T[], latestIndex?: number): number | null {
    const stop = someObservations.length - 1;

    // sort someObservations oldest to newest
    someObservations.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    // do a reverse loop to find a date that is less than or equal to whatEverDate
    for (let i = stop; i >= 0; i--) {
        if (latestIndex && i > latestIndex + 4) {
            continue;
        }
        const dateContext = someObservations[i].d;
        if (dateContext.getTime() <= ZDate.getTime()) {
            return i;
        }
    }

    return null;
}

// Do not use since it can lead to biases (always do the comparision as date <= targetDate), use universialBinarySearch instead.
export function binarySearch<T extends { d: Date; date: string }>(targetDate: Date, observations: T[]): number | null {
    const stop = observations.length - 1;
    let index = 0;

    while (index <= stop) {
        const dateContext = observations[index]?.d == null ? new Date(observations[index].date) : observations[index].d;

        if (isOnSameDay(dateContext, targetDate)) {
            return index;
        }

        if (dateContext.getTime() >= targetDate.getTime()) {
            return index;
        } else {
            index = index + 1;
        }
    }

    return null;
}

function findClosestDate<T extends { d: Date; date: string }>(dates: T[], targetDate: Date): number | null {
    let left = 0;
    let right = dates.length - 1;
    let result: number | null = null;

    while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        const midDate = dates[mid]?.d == null ? new Date(dates[mid].date) : dates[mid].d;

        if (midDate <= targetDate) {
            result = mid;
            left = mid + 1;
        } else {
            right = mid - 1;
        }
    }

    return result;
}

export function leftJoinByDate<T extends { d: Date; value: number | null; date: string; transformationKey: string }>(
    left: T[],
    right: T[],
    removeMissingValues?: boolean,
    replaceMissingValueWith?: number,
    takeLastObservedValue?: boolean,
    isCategorizedTimeSeries?: boolean,
    exact?: boolean
): T[][] {
    // Sort the left and right arrays
    left.sort((a, b) => a.d.getTime() - b.d.getTime());
    right.sort((a, b) => a.d.getTime() - b.d.getTime());

    // Initialize variables
    const A: T[] = [];
    const B: T[] = [];
    const rightRep = right[0];

    const getBasedOnLatestRightIndex = (index: number, date: Date) => {
        if (!exact && right?.[index + 1]?.d?.getTime() <= date.getTime() && !right.slice(index + 2, index + 4).some(x => x.d.getTime() <= date.getTime())) {
            return index + 1;
        } else {
            return (exact ? right.findIndex(x => isOnSameDay(date, x.d)) : universialBinarySearch(date, right));
        }
    };

    // Loop through the left array
    let latestRightIndex: number | null = null;
    for (let i = 0; i < left.length; i++) {
        const date = left[i].d;
        const leftItem = left[i];

        // Find the closest right item with a date less than or equal to the left item's date
        const rightIndex = (exact ? right.findIndex(x => isOnSameDay(date, x.d)) : findClosestDate(right, date));
        const rightItem = rightIndex != null && rightIndex !== -1 ? right[rightIndex] : null;

        latestRightIndex = rightIndex;

        if (rightItem && (isCategorizedTimeSeries && rightItem.d.getTime() <= moment(date).add(120, "days").toDate().getTime() || !isCategorizedTimeSeries)) {
            // Update properties of existing right item
            const newT = { ...rightItem };
            newT.d = date;
            newT.date = left[i].date;
            newT.value = rightItem?.value;
            newT.transformationKey = newT.transformationKey == null ? rightItem.transformationKey : newT.transformationKey;
            B.push(newT);
            A.push(leftItem);
        } else if (removeMissingValues == null || !removeMissingValues) {
            // Create a new item based on rightRep
            const newItem = { ...rightRep };
            newItem.transformationKey = newItem.transformationKey == null ? rightRep.transformationKey : newItem.transformationKey;
            if (takeLastObservedValue) {
                // set newItem.value = right[right.length - 1].value; if date is after right[right.length - 1].date
                const lastRightItem = right[right.length - 1];
                if (lastRightItem.d.getTime() < date.getTime()) {
                    newItem.value = lastRightItem.value;
                } else {
                    newItem.value = replaceMissingValueWith !== undefined ? replaceMissingValueWith : null;
                }
            } else {
                newItem.value = replaceMissingValueWith !== undefined ? replaceMissingValueWith : null;
            }
            newItem.d = date;
            newItem.date = left[i].date;
            B.push(newItem);
            A.push(leftItem);
        }
    }

    return [ A, B ];
}
