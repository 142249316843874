import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BacktestingSetting } from '../../backtesting-common-frontend';

export class TestState {
    test: BacktestingSetting | null | undefined = null;
}

const initialState: TestState = {
    test: null,
};

const tests = createSlice({
    name: 'tests',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<BacktestingSetting>) => {
            state.test = action.payload;
        },
    },
});

export const updateTestStore = tests.actions.add;
export const testReducer = tests.reducer;