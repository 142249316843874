import { createSlice } from '@reduxjs/toolkit';
import { GraphItem } from '../../backtesting-common-frontend';

class GraphItemResults {
    graphItems: GraphItem[] = [];
    theoryActive: string[] = [];
    editActive: string | null = null;
    theoryActiveMain: string | null = null;
    triggerTheoryCalculation = 0;
}

const initialState: GraphItemResults = {
    graphItems: [],
    theoryActive: [],
    editActive: null,
    theoryActiveMain: null,
    triggerTheoryCalculation: 0,
};

const graphitems = createSlice({
    name: 'graphitems',
    initialState,
    reducers: {
        // CRUD
        bulkOverwrite: (state, action) => {
            state.graphItems = action.payload;
        },
        // edit
        edit: (state, action) => {
            const index = state.graphItems.findIndex((item) => item._id === action.payload._id);
            state.graphItems[index] = action.payload;
        },
        // delete
        deleteGraphItem: (state, action) => {
            state.graphItems = state.graphItems.filter((item) => item._id !== action.payload._id);
        },
        // add one
        add: (state, action) => {
            state.graphItems.push(action.payload);
        },

        // set active
        // theory
        theoryActive: (state, action) => {
            state.theoryActive = action.payload;
        },
        // theory main
        theoryActiveMain: (state, action) => {
            state.theoryActiveMain = action.payload;
        },
        // edit
        editActive: (state, action) => {
            state.editActive = action.payload;
        },

        // trigger theory calculation
        triggerTheoryCalculation: (state) => {
            state.triggerTheoryCalculation++;
        },
    },
});

export const { bulkOverwrite: bulkAddAndOverwriteGraphItemRedux, edit: editGraphItemRedux, deleteGraphItem: deleteGraphItemRedux, add: addGraphItemRedux,
    theoryActive: setTheoryActive, theoryActiveMain: setTheoryActiveMainRedux, editActive: setEditActive, triggerTheoryCalculation } = graphitems.actions;
export const graphItemsReducer =  graphitems.reducer;