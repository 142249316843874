import { Box, Button, Flex, FormControl, FormLabel, Heading, Switch, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FaPercentage } from 'react-icons/fa';
import { GoAlertFill } from "react-icons/go";
import { LuGoal } from "react-icons/lu";
import { MdPieChart } from 'react-icons/md';
import { useDispatch } from 'react-redux';
// import ttest from "ttest";
import { Backtest, defaultStoredBacktest } from '../../backtesting-common-frontend';
import { removeUnboughtDays, shortIt, updateBacktest } from '../../backtesting-common-frontend/http-utilities/http-utilities/backtests/backtests-backend.service';
import { round } from '../../backtesting-common-frontend/shared/utilites/math.utilities';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import { transformReturns, transformToPrecent } from '../../backtesting-common-frontend/shared/utilites/transform';
import { setLoading } from '../../store/backtests/backtests';
import { ColorsLight } from '../shared/constants/Colors';

const SummaryComponent = ({ backtestResults }: {backtestResults: Backtest}) => {
    const dispatch = useDispatch();
    const [ isShort, setIsShort ] = useState(false);
    const [ isUnbought, setIsUnbought ] = useState(false);
    const [ colors ] = useState<ColorsLight>(new ColorsLight());
    const [ tTest, setTTest ] = useState<number | number[]>(1);
    const [ tTestLessThan, setTTestLessThan ] = useState<number | number[]>(1);

    const x = transformToPrecent(transformReturns(backtestResults.comparisonSeries.map(e => e.R.map(p => p.R))[0]));
    const portfolioReturn = backtestResults.summary?.portfolioReturn != null ? round(backtestResults.summary.portfolioReturn, 4) + '%' : '0 %';
    const std = backtestResults.summary?.std != null ? round(backtestResults.summary.std, 4) : '0';
    const comparisionSeriesReturn = backtestResults.comparisonSeries != null && backtestResults.comparisonSeries.length > 0 ?
        round(x[x.length - 1], 2) + '%' : '0%';

    React.useEffect(() => {
        const tTest1 = backtestResults.statisticsSummary?.evaluation?.find(x => x.display === 'T-test')?.value ?? 1;
        const tTestLessThan1 = backtestResults.statisticsSummary?.evaluation?.find(x => x.display === 'T-test (less than)')?.value ?? 1;
        setTTest(tTest1);
        setTTestLessThan(tTestLessThan1);
    }, [ backtestResults ]);

    const toggleHandler = () => {
        const newShort = !isShort;
        setIsShort(newShort);
        dispatch(setLoading(true));
        shortIt(backtestResults).finally(() => {
            dispatch(setLoading(false));
        });
    };

    const removeUnboughtDaysToggler = () => {
        const newUnboughtDays = !isUnbought;
        setIsUnbought(newUnboughtDays);
        dispatch(setLoading(true));
        removeUnboughtDays(backtestResults).finally(() => {
            dispatch(setLoading(false));
        });
    };

    return (
        <>
            {backtestResults != null && backtestResults?.summary != null &&<> <Box display="flex" justifyContent="space-between" mb={5}>
                <Box
                    borderRadius="md"
                    boxShadow="md"
                    p={4}
                    width="calc(50% - 5px)"
                >
                    <Box display="flex" justifyContent="space-between" mb={5}>
                        <>
                            <Box
                                bg={'whiteAlpha.100'}
                                borderRadius="md"
                                boxShadow="md"
                                p={4}
                                width="calc(25% - 5px)"
                                marginInlineEnd={10}
                            >
                                <Flex>
                                    <Heading size="sm" color="gray.500" mb={2}>
                                        Portfolio Return
                                    </Heading>
                                </Flex>
                                <Flex alignItems="center">
                                    <Box bg={'gray.900'} 
                                        boxShadow={'md'}
                                        p={4}
                                        borderRadius={'full'}
                                        mr={3}
                                    >
                                        <FaPercentage size={20} color={colors.blue} />
                                    </Box> 
                                    <Text color={round(backtestResults.summary.portfolioReturn, 2) > 0 ? colors.green : 'red.300'}>
                                        {portfolioReturn}
                                    </Text>
                                </Flex>
                            </Box>
                            <Box
                                bg={'whiteAlpha.100'}
                                borderRadius="md"
                                boxShadow="md"
                                p={4}
                                width="calc(25% - 5px)"
                                marginInlineEnd={10}
                            >
                                <Flex>
                                    <Heading size="sm" color="gray.500" mb={2}>
                                        Comparison Return
                                    </Heading>
                                </Flex>
                                <Flex alignItems="center">
                                    <Box bg={'gray.900'} 
                                        boxShadow={'md'}
                                        p={4}
                                        borderRadius={'full'}
                                        mr={3}
                                    >
                                        <MdPieChart size={20} color={colors.blue} />
                                    </Box> 
                                    <Text color={round(x[x.length - 1], 2)  ? colors.green : 'red.300'}>    
                                        {comparisionSeriesReturn}
                                    </Text>
                                </Flex>
                            </Box>
                            <Box
                                bg={'whiteAlpha.100'}
                                borderRadius="md"
                                boxShadow="md"
                                p={4}
                                width="calc(25% - 5px)"
                                marginInlineEnd={10}
                            >
                                <Flex justifyContent="space-between" alignItems="center">
                                    <Heading size="sm" color="gray.500" mb={2}>
                                        T-test / T-test (less than)
                                    </Heading>
                                    {/* Toggle for "Is short?" */}
                                    <FormControl display="flex" alignItems="center">
                                        <FormLabel htmlFor="is-short-toggle" mb="0">
                                            Is short?
                                        </FormLabel>
                                        <Switch id="is-short-toggle" colorScheme="blue" onChange={toggleHandler} isChecked={isShort} />
                                    </FormControl>
                                </Flex>
                                <Flex alignItems="center">
                                    <Box bg={'gray.900'} boxShadow={'md'} p={4} borderRadius={'full'} mr={3}>
                                        {/* Ensure LuGoal and colors are defined or imported correctly */}
                                        <LuGoal size={20} color={colors.blue} />
                                    </Box> 
                                    <Text color={tTest as number <= 0.05 ? colors.green : 'red.300'}>
                                        {round(tTest as number, 4)}
                                    </Text>
                                    <Text ml={1} color={tTestLessThan as number <= 0.05 ? colors.green : 'red.300'}>
          / {round(tTestLessThan as number, 4)}
                                    </Text>
                                </Flex>
                            </Box>
                            <Box
                                bg={'whiteAlpha.100'}
                                borderRadius="md"
                                boxShadow="md"
                                p={4}
                                width="calc(25% - 5px)"
                                marginInlineEnd={10}
                            >
                                <Flex>
                                    <Heading size="sm" color="gray.500" mb={2}>
                                        Risk (standard deviation)
                                    </Heading>
                                </Flex>
                                <Flex alignItems="center">
                                    <Box bg={'gray.900'} 
                                        boxShadow={'md'}
                                        p={4}
                                        borderRadius={'full'}
                                        mr={3}
                                    >
                                        <GoAlertFill size={20} color={colors.blue} />
                                    </Box> 
                                    {std}
                                </Flex>
                            </Box>
                        </>
                    </Box>
                </Box>
                <Box>
                    <FormControl flex={'auto'} alignItems="left">
                        <FormLabel htmlFor="is-short-toggle" mb="0">
                                            Remove unbought days (hard removal)
                        </FormLabel>
                        <Button id="is-short-toggle" colorScheme="blue" onClick={removeUnboughtDaysToggler}>
                            Remove
                        </Button>
                    </FormControl>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={5}>
                    <div style={{ width: '100%' }}>
                        {backtestResults && <Button color="red" onClick={() => {
                            const clonedBacktest = cloneDeep(backtestResults);
                            if(clonedBacktest){
                                defaultStoredBacktest(clonedBacktest);
                                dispatch(setLoading(true));
                                updateBacktest(clonedBacktest).finally(() => {
                                    dispatch(setLoading(false));
                                });
                            }
                        }}>Remove results</Button>
                        }
                    </div>
                </Box>
            </Box></>}
        </>
    );
};

export default SummaryComponent;