import { FundMentions } from "../../../database/fundmentions";
import { sendPost } from "../frontend/http-utilities";

export async function getAvanzaFonder(): Promise<FundMentions[]> {
    return sendPost("crawler/get-avanza-fonder", {})
        .then(e => {
            return e.data as FundMentions[];
        })
        .catch(() => {
            return [];
        });
}