import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Button, FormControl, FormLabel, Input, Menu, MenuButton, MenuItem, MenuList, Select, Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react';
import { MathJax } from 'better-react-mathjax';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addHoldValue } from '../../action-creators/criteria-board/criteria-board.actions';
import { createOrEditNewMehtodOrCurrent } from '../../action-creators/methods/methods.actions';
import { setTheoryActiveMain } from '../../action-creators/timeseries/time-series.action';
import { BacktestingSetting, GraphItem } from '../../backtesting-common-frontend';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import { StrategyDTO } from '../../backtesting-common-frontend/strategies/strategy';
import { CriteriaBoardMethod, CriteriaBoardOptionSelect, RelativeCalculate, RelativeMethod, criteriaSign } from '../../backtesting-common-frontend/techniques';
import { TheoryMethodService } from '../../backtesting-common-frontend/techniques/theory-method.service';
import { MethodsResultsManager } from '../../managers/methods/methods-manager';
import { editGraphItemRedux } from '../../store/graphitems/graphitems';
import { AppState } from '../../store/store';
import AddMethodHelper from '../methods/add-method-helper';
import { ExplainerInfo } from '../shared/explainer/info';

export const Relative = () => {
    const dispatch = useDispatch();
    const currentReduxStrategy = useSelector(
        (state: AppState) => state.strategies.strategy
    );
    const currentTab = useSelector((state: AppState) => state.tabs.currentMainTab);
    const graphItems = useSelector((state: AppState) => state.graphItems.graphItems);
    const theoryActiveCtx = useSelector((state: AppState) => state.graphItems.theoryActive);
    const theoryActiveMain = useSelector((state: AppState) => state.graphItems.theoryActiveMain);
    const test = useSelector((state: AppState) => state.tests.test);
    const [ stringValue, setStringValue ] = useState('');
    const [ sliderValue, setSliderValue ] = useState(10);
    const [ direction, setDirection ] = useState<criteriaSign>('up');
    const [ currentStrategy, setCurrentStrategy ] = useState<StrategyDTO | null>(null);
    const [ currentTest, setCurrentTest ] = useState<BacktestingSetting | null>(null);
    const [ theoryMethodService ] = useState<TheoryMethodService>(new TheoryMethodService());
    const [ method, setMethod ] = useState<RelativeMethod>("normalization");
    const [ calculate, setCalculate ] = useState<{categoryTitle: string}>({ categoryTitle: "Dividend" });
    const [ compareTo, setCompareTo ] = useState<string | null>(null);

    useEffect(() => {
        setCurrentStrategy(cloneDeep(currentReduxStrategy));
    }, [ currentReduxStrategy ]);

    useEffect(() => {
        const t = cloneDeep(test);
        if(t){
            setCurrentTest(t);
        }
    }, [ test ]);

    useEffect(() => {
        if(currentTest && currentTab){
            const currentMethod = MethodsResultsManager.getCurrentMethod(currentTest, currentTab);
            if(currentMethod){
                const work = currentMethod.parameters as CriteriaBoardMethod | undefined;
                if(work){
                    setSliderValue(+work.relativeInterval[0]);
                    setDirection(work.relativeQuotaType[0]);
                    setStringValue(work.relativeQuota[0]);
                    setMethod(work.relativeMethod);
                    setCalculate({ categoryTitle: work.relativeCalculate === "dividend" ? "Dividend" : work.relativeCalculate });
                }
            }
        }
    }, [ currentTest, currentTab ]);

    const addOrTestMethod = (name: string, update: boolean, test?: boolean, graphItemsCtx?: GraphItem[], holdValue?: string) => {
        if(!currentTest || !currentTab) return;
        const currentMethod = MethodsResultsManager.getCurrentMethod(currentTest, currentTab);
        let work: CriteriaBoardMethod | undefined = currentMethod?.parameters as CriteriaBoardMethod | undefined;
        const options = new CriteriaBoardOptionSelect();
        options.relativeQuota = [ true ];
        const w = new CriteriaBoardMethod("Theory", options);
        w.relativeInterval = [ sliderValue.toString() ];
        w.relativeQuotaType = [ direction ];
        w.relativeQuota = [ stringValue ];
        w.relativeMethod = method;
        w.relativeCalculate = calculate.categoryTitle.toLocaleLowerCase() as RelativeCalculate;
        addHoldValue(holdValue, w);
        work = w;
        const activeGraphItem = graphItems.filter((e) => theoryActiveCtx.includes(e._id));
        let dtoGraphItem = cloneDeep(activeGraphItem);
        if(test && dtoGraphItem && work){
            // if compareTo is not null, then order the dtoGraphItem so that the first item is the compareTo item
            if(compareTo != null){
                const compareToGraphItem = dtoGraphItem.find(e => e._id === compareTo);
                if(compareToGraphItem){
                    dtoGraphItem = dtoGraphItem.filter(e => e._id !== compareTo);
                    dtoGraphItem.unshift(compareToGraphItem);
                }
            }
            const res = theoryMethodService.addBuyLabels(dtoGraphItem.map(e => e.timeSeries), work);
            dtoGraphItem[0].timeSeries = res[0];
            dtoGraphItem = dtoGraphItem.map((e, i) => {
                if(i === 0){
                    return e;
                }
                e.timeSeries.graphValue = e.timeSeries.graphValue.map(f => {
                    f.buy = false;
                    return f;
                });
                return e;
            });
            dispatch(editGraphItemRedux(dtoGraphItem[0]));
            if(dtoGraphItem.length > 1){
                dispatch(editGraphItemRedux(dtoGraphItem[1]));
            }
        }else if(dtoGraphItem){
            const mainTs = graphItems.find(e => e._id === theoryActiveMain);
            if(!mainTs) return;
            createOrEditNewMehtodOrCurrent(
                dispatch,
                currentTest,
                currentTab,
                new MethodsResultsManager(),
                work,
                name,
                mainTs,
                dtoGraphItem.map(e => e.timeSeries),
                currentStrategy,
                null,
                undefined,
                update === false ? true : false,
                "Theory"
            );
        }

    };

    const handleStringChange = (e) => {
        setStringValue(e.target.value);
    };

    const handleSliderChange = (value) => {
        setSliderValue(value);
    };

    const handleDirectionChange = (e) => {
        setDirection(e.target.value.toLowerCase() as criteriaSign);
    };

    const handleRelativeMethodChange = (e) => {
        setMethod(e.target.value.toLowerCase() as RelativeMethod);
    };

    const activeGraphItem = graphItems.filter((e) => theoryActiveCtx.includes(e._id));

    const calculateOptions = [ {
        categoryTitle: "Growth",
    }, {
        categoryTitle: "Dividend",
    },
    {
        categoryTitle: "Absolute",
    },
    ];

    const handleCategorySelect = (category) => {
        setCalculate(category);
    };

    const handleCompareToSelect = (category) => {
        const g = cloneDeep(graphItems);
        if(g){
            setCompareTo(category._id);
            setTheoryActiveMain(dispatch, g, cloneDeep(category));
        }
    };

    const disabled = !activeGraphItem ||
    compareTo == null ||
    (calculate.categoryTitle === "Dividend" && stringValue === "");

    return (
        <Box p={4}>
            <ExplainerInfo.Information
                title="Cointegration Method"
                text={
                    <MathJax>
                        {`
                The "Relative" or cointegration method determines buy signals based on the relative performance of the asset compared to a benchmark or another asset.
            `}
                    </MathJax>
                }
            />
            <Box width="100%" p={1}>
                <Menu>
                    <MenuButton as={Button} rounded={0} rightIcon={<ChevronDownIcon />} width={'full'}>
                        {compareTo == null ? "Main time series" : graphItems.find(e => e._id === compareTo)?.timeSeries?.display}
                    </MenuButton>
                    <MenuList>
                        {graphItems.map((category) => (
                            <MenuItem key={category?.timeSeries?.transformationKey} minH="48px" onClick={() => handleCompareToSelect(category)}>
                                <span>{category?.timeSeries?.display}</span>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>
            {/* <Box width="100%" p={1}>
                <Menu>
                    <MenuButton as={Button} rounded={0} rightIcon={<ChevronDownIcon />} width={'full'}>
                        {calculate.categoryTitle}
                    </MenuButton>
                    <MenuList>
                        {calculateOptions.map((category) => (
                            <MenuItem key={category.categoryTitle} minH="48px" onClick={() => handleCategorySelect(category)}>
                                <span>{category.categoryTitle}</span>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box> */}
            {calculate.categoryTitle === "Absolute" || calculate.categoryTitle === "Dividend" && <FormControl>
                <FormLabel>Value:</FormLabel>
                <Input type="text" value={stringValue} onChange={handleStringChange} />
            </FormControl>}
            {calculate.categoryTitle !== "Growth" && <FormControl mt={4}>
                <FormLabel>Normalization</FormLabel>
                <Select value={method === "natural logarithm" ? "Natural logarithm" : method === "normalization" ? "Normalization" : "None"} onChange={handleRelativeMethodChange}>
                    <option value="Natural logarithm">Natural logarithm</option>
                    <option value="Normalization">Normalization</option>
                    <option value="None">None</option>
                </Select>
            </FormControl>}
            {calculate.categoryTitle !== "Absolute" && <FormControl mt={4}>
                <FormLabel>Interval: {sliderValue}</FormLabel>
                <Slider
                    value={sliderValue}
                    min={2}
                    max={200}
                    step={1}
                    onChange={(value) => handleSliderChange(value)}
                >
                    <SliderTrack>
                        <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb boxSize={6} />
                </Slider>
            </FormControl>}
            <FormControl mt={4} mb={4}>
                <FormLabel>Comparision</FormLabel>
                <Select value={direction === "up" ? "Up" : "Down"} onChange={handleDirectionChange}>
                    <option value="Up">Up</option>
                    <option value="Down">Down</option>
                </Select>
            </FormControl>
            <AddMethodHelper 
                useHoldValue={true}
                disabledText={'Missing values or time series'} callback={(name, update, holdValue) => {
                    addOrTestMethod(name, update, false, undefined, holdValue);
                }} disabled={disabled} callbackTest={(graphItemsTest, holdValue) => {
                    return new Promise<void>((resolve, _reject) => {
                        setTimeout(() => {
                            addOrTestMethod("", false, true, graphItemsTest, holdValue);
                        }, 500);
                        setTimeout(() => {
                            resolve();
                        }, 1500);
                    });
                }} />
        </Box>
    );
};

