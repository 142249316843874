
import { ImpreemMethodDTO } from "../../../methods";
import { sendPost } from "../frontend/http-utilities";

export async function deleteMethod(testId: string, strategyId: string, method: ImpreemMethodDTO): Promise<void | null> {
    return sendPost("methods/delete", { testId, strategyId, method }).then(e => {
        return null;
    }).catch(() => {
        return null;
    });
}

export async function addMethod(testId: string, method: ImpreemMethodDTO, methodKey: string, to?: string | null): Promise<ImpreemMethodDTO | null> {
    return sendPost("methods/add", { testId, method, to, methodKey }).then((e) => {
        return e.data as ImpreemMethodDTO;
    }).catch(() => {
        return null;
    });
}

export async function editMethod(method: ImpreemMethodDTO, to?: string | null): Promise<ImpreemMethodDTO | null> {
    return sendPost("methods/edit", { method, to }).then((e) => {
        return e.data as ImpreemMethodDTO;
    }).catch(() => {
        return null;
    });
}

export async function getMethod(method: ImpreemMethodDTO): Promise<ImpreemMethodDTO | null> {
    return sendPost("methods/get", method).then((e) => {
        return e.data as ImpreemMethodDTO;
    }).catch(() => {
        return null;
    });
}
