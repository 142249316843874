import mongoose from "mongoose";
import Portfolio from "portfolio-allocation";
import { BacktestingSetting, GraphItem, Series, StatisticalProperties } from "..";
import { GraphItemDocument, GraphItemModel } from "../database/user-DB/backend/timeseries/graphitems";
import { timeseries } from "../database/user-DB/backend/timeseries/timeseries";
import timeserieshelpervalues from "../database/user-DB/backend/timeseries/timeserieshelpervalues";
import { ImpreemDate } from "../date/impreem-date";
import { getDateFromLabel, getLastDate } from "../http-utilities/http-utilities/dates/dates.http.service";
import { TimeSeriesDTO } from "../methods";
import { DatesSettings, DatesSettingsMeta, leftJoinByDate } from "../shared/utilites/dates.utilities";
import { getStandardDeviation, normalizationObjects } from "../shared/utilites/math.utilities";
import { okNumber } from "../shared/utilites/number.utilities";
import { average, transformReturns, transformToPrecent } from "../shared/utilites/transform";
import { CriteriaBoardMethod } from "../techniques";
import { deepClone } from "../worker/transformations/transformation-service";
import { PieChartDTO } from "./models/timeseries.models";
import { TimeSeriesHelperValue } from "./timeseries-models";

export class TimeSeriesService {

    public async getAllGraphItems(clientId: string): Promise<GraphItemDocument[]> {
        // populate timeseries and extras and timeserieshelpervalue
        const graphItems = await GraphItemModel.find({ clientId })
            .select("-groupId -theoryActive -editActive -extras -active -added -clientId -color").exec();
        return graphItems;
    }

    public async saveGraphItem(graphItem: GraphItem, tsId: mongoose.Types.ObjectId) {
        const newGraphItem = new GraphItemModel(graphItem);
        newGraphItem.timeSeries = tsId;
        return await newGraphItem.save();
    }

    public async saveTimeSeries(ts: TimeSeriesDTO) {
        delete ts._id;
        const newTimeSeries = new timeseries(ts);
        const newTs = await newTimeSeries.save();
        return newTs;
    }

    public async saveTimeSeriesHelperValues(ts: TimeSeriesHelperValue[]) {
        // save as bulk timeserieshelpervalues
        const timeSeriesHelperValues = ts.map((tsArray) => {
            delete tsArray._id;
            return new timeserieshelpervalues(tsArray);
        });
    
        const flattenedTimeSeriesHelperValues = timeSeriesHelperValues.flat();
    
        const result = await timeserieshelpervalues.insertMany(flattenedTimeSeriesHelperValues);
        return result;
    }

    public convertTimeSeriesValuesToSeries(timeSeries: TimeSeriesHelperValue[]): Series[] {
        const series: Series[] = [];
        for (const t of timeSeries) {
            const date = new ImpreemDate(-1, t.date);
            const s = new Series(date, t.value);
            s.symbol = t.symbol;
            series.push(s);
        }
        return series;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
    public async getAllocations(
        method: string,
        timeSeries: Series[][],
        timeSeriesNameLabels: string[],
        comparisonSeries: Series[][],
        cb?: (pieChart: PieChartDTO | null, evaluationMetrics: StatisticalProperties[], timeSeries: TimeSeriesDTO[]) => void,
        criteriaBoard?: CriteriaBoardMethod,
        isRelative?: boolean) {
        let historicalR = deepClone(timeSeries);
        let comparisionHistoricalR = deepClone(comparisonSeries);
        historicalR = historicalR.map((s) => normalizationObjects(s, "R"));
        comparisionHistoricalR = comparisionHistoricalR.map((s) => normalizationObjects(s, "R"));
        if(isRelative) {
            historicalR.forEach((series, index) => {
                const comparision = comparisionHistoricalR[index];
                series.forEach((s, i) => {
                    s.R = s.R / comparision[i].R;
                });
            });
        }
        const resultAllocation = this.allocate(historicalR, method, criteriaBoard);
        const allocationComparisionExisits = comparisionHistoricalR.length > 0;
        const resultAllocationComparision = allocationComparisionExisits ? this.allocate(comparisionHistoricalR, method) : { weights: [], returns: [] };
        const weightsForPortfolio = resultAllocation.weights;
        const weightsForPortfolioComparision = resultAllocationComparision.weights;
        const valuesHistorical = resultAllocation.returns;
        const valuesComparision = resultAllocationComparision.returns;
        if (valuesHistorical?.[0] == null) {
            // return empty object
            const objToReturn = {
                pieChartDto: null,
                evaluationMetrics: [  ],
                timeSeries: [  ],
                weightedPortfolio: null,
                symbolsIncluded: [],
            };
            if (cb) {
                cb(null, [], []);
            } else {
                return objToReturn;
            }
        }
        const weightedPortfolioTimeSeries: TimeSeriesHelperValue[] = [];
        for (let i = 0; i < valuesHistorical[0].length; i++) {
            let weightedValues = 0;
            for (let j = 0; j < valuesHistorical.length; j++) {
                weightedValues += valuesHistorical[j][i].value * weightsForPortfolio[j];
            }
            const value = new TimeSeriesHelperValue();
            value.d = new Date(valuesHistorical[0][i].d);
            value.date = valuesHistorical[0][i].date;
            value.value = weightedValues;
            value.transformationKey = "weighted-portfolios";
            weightedPortfolioTimeSeries.push(value);
        }
        const graphValuesComparision: TimeSeriesHelperValue[] = [];
        if (allocationComparisionExisits) {
            for (let i = 0; i < valuesComparision[0].length; i++) {
                let weightedValues = 0;
                for (let j = 0; j < valuesComparision.length; j++) {
                    weightedValues += valuesComparision[j][i].value * weightsForPortfolioComparision[j];
                }
                const value = new TimeSeriesHelperValue();
                value.d = new Date(valuesComparision[0][i].d);
                value.date = valuesComparision[0][i].date;
                value.value = weightedValues;
                value.transformationKey = "weighted-comparision";
                graphValuesComparision.push(value);
            }
        }
        // create two TimeSeriesDTOs
        const timeSeriesDTO1 = new TimeSeriesDTO();
        timeSeriesDTO1.name = "Portfolio";
        timeSeriesDTO1.graphValue = weightedPortfolioTimeSeries;
        timeSeriesDTO1.display = "Portfolio";
        timeSeriesDTO1.transformationKey = "weighted-portfolios";

        const timeSeriesDTO2 = new TimeSeriesDTO();
        timeSeriesDTO2.name = "Comparision";
        timeSeriesDTO2.graphValue = graphValuesComparision;
        timeSeriesDTO2.display = "Comparision";
        timeSeriesDTO2.transformationKey = "weighted-comparision";

        const pieChartDto = new PieChartDTO(weightsForPortfolio, timeSeriesNameLabels);

        const averageMetrics = new StatisticalProperties();
        averageMetrics.display = "Average";
        averageMetrics.value = average(weightedPortfolioTimeSeries.map(t => t.value));
        averageMetrics.id = "average-group";
        const stdMetrics = new StatisticalProperties();
        stdMetrics.display = "Standard deviation";
        stdMetrics.value = getStandardDeviation(weightedPortfolioTimeSeries.map(t => t.value));
        stdMetrics.id = "std-group";
        const totalReturnMetrics = new StatisticalProperties();
        totalReturnMetrics.display = "Total return";
        totalReturnMetrics.id = "total-group";
        const series = transformToPrecent(transformReturns(weightedPortfolioTimeSeries.map(t => t.value)));
        totalReturnMetrics.value = series[series.length - 1];
        const weightedPortfolio = new StatisticalProperties();
        weightedPortfolio.display = "Weighted portfolio";
        weightedPortfolio.value = weightsForPortfolio;
        weightedPortfolio.id = "weights-group";

        // create statistical properties for comparision if it exists
        const averageMetricsComparision = new StatisticalProperties();
        averageMetricsComparision.display = "Average";
        averageMetricsComparision.value = allocationComparisionExisits ? average(graphValuesComparision.map(t => t.value)) : 0;
        const stdMetricsComparision = new StatisticalProperties();
        stdMetricsComparision.display = "Standard deviation";
        stdMetricsComparision.value = allocationComparisionExisits ? getStandardDeviation(graphValuesComparision.map(t => t.value)) : 0;
        const totalReturnMetricsComparision = new StatisticalProperties();
        totalReturnMetricsComparision.display = "Total return";
        const seriesComparision = allocationComparisionExisits ? transformToPrecent(transformReturns(graphValuesComparision.map(t => t.value))) : [];
        totalReturnMetricsComparision.value = allocationComparisionExisits ? seriesComparision[seriesComparision.length - 1] : 0;
        const weightedPortfolioComparision = new StatisticalProperties();
        weightedPortfolioComparision.display = "Weighted portfolio";
        weightedPortfolioComparision.value = allocationComparisionExisits ? weightsForPortfolioComparision : [];

        // create id for each statistical property for comparision with id = "group-comparision"
        averageMetricsComparision.id = "average-compare";
        stdMetricsComparision.id = "std-compare";
        totalReturnMetricsComparision.id = "total-compare";
        weightedPortfolioComparision.id = "weights-compare";

        // add all to array
        const averageMetricsArray = [ averageMetrics, stdMetricsComparision, totalReturnMetricsComparision, weightedPortfolioComparision ];

        if (cb) {
            cb(pieChartDto, [ averageMetrics, stdMetrics, totalReturnMetrics, ...averageMetricsArray ], [ timeSeriesDTO1, timeSeriesDTO2 ]);
        } else {
            // create a object with all the data
            const objToReturn = {
                pieChartDto,
                evaluationMetrics: [ averageMetrics, stdMetrics, totalReturnMetrics, ...averageMetricsArray ],
                timeSeries: [ timeSeriesDTO1, timeSeriesDTO2 ],
                weightedPortfolio,
                symbolsIncluded: resultAllocation.symbolsIncluded,
            };
            return objToReturn;
        }
    }

    public covariance(...arrays: number[][]): number[][] {
        const n = arrays[0].length;
        const m = arrays.length;
        for (let i = 1; i < m; i++) {
            if (arrays[i].length !== n) {
                throw new Error("The length of all arrays should be the same");
            }
        }

        const means = arrays.map((arr) => arr.reduce((sum, val) => sum + val, 0) / n);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        const result = new Array(m).fill(0).map(() => new Array(m).fill(0));

        for (let i = 0; i < m; i++) {
            for (let j = i; j < m; j++) {
                let cov = 0;
                for (let k = 0; k < n; k++) {
                    cov += (arrays[i][k] - means[i]) * (arrays[j][k] - means[j]);
                }
                cov /= n - 1;
                result[i][j] = result[j][i] = cov;
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return result;
    }

    public allocate(series: Series[][], type: string, criteriaBoard?: CriteriaBoardMethod): { weights: number[]; returns: TimeSeriesHelperValue[][]; symbolsIncluded: string[] } {
        if (series.length === 1) {
            return { weights: [ 1 ], returns: [ series[0].map(t => {
                return {
                    transformationKey: "",
                    d: new Date(t.date.date),
                    date: t.date.label,
                    value: t.R,
                };
            }) as TimeSeriesHelperValue[],
            ], symbolsIncluded: [] };
        }
        let val = series.map((s, i) => {
            const n = s.map(t => {
                return {
                    transformationKey: "",
                    d: new Date(t.date.date),
                    date: t.date.label,
                    value: t.R,
                    symbol: t?.symbol,
                };
            });
            return n;
        }) as TimeSeriesHelperValue[][];
        // remove all NAN values
        val = val.map(v => v.filter(t => !isNaN(t.value) && okNumber(t.value)));
        // make all val arrays the same length, cutting from the end and back
        const min = Math.min(...val.map(v => v.length));
        val = val.map(v => v.slice(v.length - min));
        // let every time series in "val" have same dates by overwriting the date from the first time series
        val = val.map((v, i) => v.map((t, j) => {
            return {
                transformationKey: t.transformationKey,
                d: new Date(val[0][j].d),
                date: val[0][j].date,
                value: t.value,
                symbol: t.symbol,
            };
        })) as TimeSeriesHelperValue[][];
        let joined: TimeSeriesHelperValue[][] = [];
        for (let i = 0; i < val.length; i++) {
            if (val[i + 1] == null) {
                break;
            }
            const n = leftJoinByDate(i === 0 ? val[0] : joined[0], val[i + 1], false, 0);
            if (i === 0) {
                joined.push(n[0]);
            }
            joined.push(n[1]);
        }
        // check if joined[0].length is above 21
        if (joined?.[0]?.length == null || joined[0].length < 21) {
            return { weights: [], returns: [], symbolsIncluded: [] };
        }
        // loop each joined, and check if there are more than 21 values, and if so then add more
        let returns = joined.map(s => s.map(t => t.value));

        const absoluteAbove = criteriaBoard?.absolute != null ? +criteriaBoard.absolute : null;
        let avg: number[] = returns.map(x => average(x));
        // remove returns where avg is below absolute, store them into array
        const indexToRemove = [];
        const symbolsIncluded: string[] = [];
        if (absoluteAbove != null) {
            for (let i = 0; i < joined.length; i++) {
                if (avg[i] < absoluteAbove) {
                    indexToRemove.push(i);
                } else {
                    symbolsIncluded.push(joined[i][0]?.symbol);
                }
            }
        }
        returns = returns.filter((_, i) => !indexToRemove.includes(i));
        avg = avg.filter((_, i) => !indexToRemove.includes(i));
        joined = joined.filter((_, i) => !indexToRemove.includes(i));
        
        const covMatrix = this.covariance(...returns);
        const expectedReturns = avg;

        let weights: number[] = [];
        switch (type) {
        case "MVO": {
            weights = new Portfolio.meanVarianceOptimizationWeights(expectedReturns, covMatrix, { constraints: { maxVolatility: 2000, minWeights: expectedReturns.map(() => 0.001) } }) as number[];
            const indexToRemove = [];
            const symbolsIncluded: string[] = [];
            if (absoluteAbove != null) {
                for (let i = 0; i < joined.length; i++) {
                    if (weights[i] <= 0.001) {
                        indexToRemove.push(i);
                    } else {
                        symbolsIncluded.push(joined[i][0]?.symbol);
                    }
                }
            }
            returns = returns.filter((_, i) => !indexToRemove.includes(i));
            avg = avg.filter((_, i) => !indexToRemove.includes(i));
            joined = joined.filter((_, i) => !indexToRemove.includes(i));
            weights = weights.filter((_, i) => !indexToRemove.includes(i));
            return { weights, returns: joined, symbolsIncluded };
        }
        case "GMV": {
            weights = new Portfolio.globalMinimumVarianceWeights(covMatrix);
            break;
        }
        case "SHARPE": {
            weights = new Portfolio.maximumSharpeRatioWeights(expectedReturns, covMatrix, 0);
            break;
        }
        default: {
            return { weights: [], returns: [], symbolsIncluded: [] };
        }
        }
        return { weights, returns: joined, symbolsIncluded };
    }

    public getTimeSeriesUseLatest(test: BacktestingSetting): boolean {
        const n: boolean = test?.endDate?.find(t => t.type === "time-series")?.dates?.find(t => t.name === "to")?.useLatest;
        return n;
    }

    public getMethodTrainToUseLatest(test: BacktestingSetting): boolean {
        const n: boolean = test?.endDate?.find(t => t.type === "method-train")?.dates?.find(t => t.name === "to-train")?.useLatest;
        return n;
    }

    public getMethodTestToUseLatest(test: BacktestingSetting): boolean {
        const n: boolean = test?.endDate?.find(t => t.type === "method-train")?.dates?.find(t => t.name === "to-test")?.useLatest;
        return n;
    }

    public getBacktestToUseLatest(test: BacktestingSetting): boolean {
        const n: boolean = test?.endDate?.find(t => t.type === "backtest")?.dates?.find(t => t.name === "to")?.useLatest;
        return n;
    }

    public getTimeSeriesFrom(test: BacktestingSetting): ImpreemDate {
        const n: ImpreemDate = test?.startDate?.find(t => t.type === "time-series")?.dates?.find(t => t.name === "from")?.date;
        return n;
    }

    public async getTimeSeriesTo(test: BacktestingSetting, setLatestIfPossible?: boolean): Promise<ImpreemDate> {
        let n: ImpreemDate = test?.endDate?.find(t => t.type === "time-series")?.dates?.find(t => t.name === "to")?.date;
        const useLatest = test?.endDate?.find(t => t.type === "time-series")?.dates?.find(t => t.name === "to")?.useLatest;
        if (useLatest && setLatestIfPossible) {
            const lastDate = await getLastDate();
            test.endDate.find(t => t.type === "time-series").dates.find(t => t.name === "to").date = lastDate;
            n = lastDate;
        }
        return n;
    }

    public getMethodTrainFrom(test: BacktestingSetting): ImpreemDate {
        const n: ImpreemDate = test?.startDate?.find(t => t.type === "method-train")?.dates?.find(t => t.name === "from-train")?.date;
        return n;
    }

    public async getMethodTrainTo(test: BacktestingSetting, setLatestIfPossible?: boolean): Promise<ImpreemDate> {
        let n: ImpreemDate = test?.endDate?.find(t => t.type === "method-train")?.dates?.find(t => t.name === "to-train")?.date;
        const useLatest = test?.endDate?.find(t => t.type === "method-train")?.dates?.find(t => t.name === "to-train")?.useLatest;
        if (useLatest && setLatestIfPossible) {
            const lastDate = await getLastDate();
            test.endDate.find(t => t.type === "method-train").dates.find(t => t.name === "to-train").date = lastDate;
            n = lastDate;
        }
        return n;
    }

    public getMethodTestFrom(test: BacktestingSetting): ImpreemDate {
        const n: ImpreemDate = test?.startDate?.find(t => t.type === "method-train")?.dates?.find(t => t.name === "from-test")?.date;
        return n;
    }

    public async getMethodTestTo(test: BacktestingSetting, setLatestIfPossible?: boolean): Promise<ImpreemDate> {
        let n: ImpreemDate = test?.endDate?.find(t => t.type === "method-train")?.dates?.find(t => t.name === "to-test")?.date;
        const useLatest = test?.endDate?.find(t => t.type === "method-train")?.dates?.find(t => t.name === "to-test")?.useLatest;
        if (useLatest && setLatestIfPossible) {
            const lastDate = await getLastDate();
            test.endDate.find(t => t.type === "method-train").dates.find(t => t.name === "to-test").date = lastDate;
            n = lastDate;
        }
        return n;
    }

    public getBacktestFrom(test: BacktestingSetting): ImpreemDate | undefined {
        const n: ImpreemDate | undefined = test?.startDate?.find(t => t.type === "backtest")?.dates?.find(t => t.name === "from")?.date;
        return n;
    }

    public async getBacktestTo(test: BacktestingSetting, setLatestIfPossible?: boolean): Promise<ImpreemDate | undefined> {
        let n: ImpreemDate | undefined = test?.endDate?.find(t => t.type === "backtest")?.dates?.find(t => t.name === "to")?.date;
        const useLatest = test?.endDate?.find(t => t.type === "backtest")?.dates?.find(t => t.name === "to")?.useLatest;
        if (useLatest && setLatestIfPossible) {
            const lastDate = await getLastDate();
            const p = test.endDate.find(t => t.type === "backtest");
            const q = p?.dates.find(t => t.name === "to");
            if (q) {
                q.date = lastDate;
            }
            n = lastDate;
        }
        return n;
    }

    public async updateTimeSeriesFrom(test: BacktestingSetting, date: string): Promise<void> {
        const newDate = await getDateFromLabel(date);
        test.startDate.find(t => t.type === "time-series").dates.find(t => t.name === "from").date = newDate;
    }

    public async updateTimeSeriesTo(test: BacktestingSetting, date: string, useLatest: boolean): Promise<void> {
        const newDate = useLatest ? await getLastDate() : await getDateFromLabel(date);
        test.endDate.find(t => t.type === "time-series").dates.find(t => t.name === "to").date = newDate;
        test.endDate.find(t => t.type === "time-series").dates.find(t => t.name === "to").useLatest = useLatest;
    }

    public async updateMethodTrainFrom(test: BacktestingSetting, date: string): Promise<void> {
        const newDate = await getDateFromLabel(date);
        test.startDate.find(t => t.type === "method-train").dates.find(t => t.name === "from-train").date = newDate;
    }

    public async updateMethodTrainTo(test: BacktestingSetting, date: string, useLatest: boolean): Promise<void> {
        const newDate = useLatest ? await getLastDate() : await getDateFromLabel(date);
        test.endDate.find(t => t.type === "method-train").dates.find(t => t.name === "to-train").date = newDate;
        test.endDate.find(t => t.type === "method-train").dates.find(t => t.name === "to-train").useLatest = useLatest;
    }

    public async updateMethodTestFrom(test: BacktestingSetting, date: string): Promise<void> {
        const newDate = await getDateFromLabel(date);
        test.startDate.find(t => t.type === "method-train").dates.find(t => t.name === "from-test").date = newDate;
    }

    public async updateMethodTestTo(test: BacktestingSetting, date: string, useLatest: boolean): Promise<void> {
        const newDate = useLatest ? await getLastDate() : await getDateFromLabel(date);
        test.endDate.find(t => t.type === "method-train").dates.find(t => t.name === "to-test").date = newDate;
        test.endDate.find(t => t.type === "method-train").dates.find(t => t.name === "to-test").useLatest = useLatest;
    }

    public async updateBacktestFrom(test: BacktestingSetting, date: string): Promise<void> {
        const newDate = await getDateFromLabel(date);
        test.startDate.find(t => t.type === "backtest").dates.find(t => t.name === "from").date = newDate;
    }

    public async updateBacktestTo(test: BacktestingSetting, date: string, useLatest: boolean): Promise<void> {
        const newDate = useLatest ? await getLastDate() : await getDateFromLabel(date);
        test.endDate.find(t => t.type === "backtest").dates.find(t => t.name === "to").date = newDate;
        test.endDate.find(t => t.type === "backtest").dates.find(t => t.name === "to").useLatest = useLatest;
    }

    public async getRecommendedDates(backtestFrom: string = null, setTo: string = null): Promise<DatesSettings[]> {
        const timeSeriesDate = new DatesSettings();
        timeSeriesDate.type = "time-series";
        const getTsFrom = await getDateFromLabel("2000-01-01");
        const getTsTo = await getLastDate();
        const fromMeta = new DatesSettingsMeta();
        fromMeta.name = "from";
        fromMeta.date = getTsFrom;
        const toMeta = new DatesSettingsMeta();
        toMeta.name = "to";
        toMeta.date = getTsTo;
        toMeta.useLatest = true;
        timeSeriesDate.dates = [ fromMeta, toMeta ];
        const methodDates = new DatesSettings();
        methodDates.type = "method-train";
        const getMethodTrainFrom = await getDateFromLabel("2000-01-01");
        const getMethodTrainTo = await getDateFromLabel("2014-12-01");
        const getMethodTestFrom = await getDateFromLabel("2015-01-01");
        const getMethodTestTo = await getDateFromLabel("2016-01-01");
        const methodTrainFromMeta = new DatesSettingsMeta();
        methodTrainFromMeta.name = "from-train";
        methodTrainFromMeta.date = getMethodTrainFrom;
        const methodTrainToMeta = new DatesSettingsMeta();
        methodTrainToMeta.name = "to-train";
        methodTrainToMeta.date = getMethodTrainTo;
        const methodTestFromMeta = new DatesSettingsMeta();
        methodTestFromMeta.name = "from-test";
        methodTestFromMeta.date = getMethodTestFrom;
        const methodTestToMeta = new DatesSettingsMeta();
        methodTestToMeta.name = "to-test";
        methodTestToMeta.date = getMethodTestTo;
        methodDates.dates = [ methodTrainFromMeta, methodTrainToMeta, methodTestFromMeta, methodTestToMeta ];
        const backtestDate = new DatesSettings();
        backtestDate.type = "backtest";
        const getBacktestFrom = await getDateFromLabel( backtestFrom ? backtestFrom : "2015-01-01");
        const getBacktestTo = setTo ? await getDateFromLabel(setTo) : await getLastDate();
        const backtestFromMeta = new DatesSettingsMeta();
        backtestFromMeta.name = "from";
        backtestFromMeta.date = getBacktestFrom;
        const backtestToMeta = new DatesSettingsMeta();
        backtestToMeta.name = "to";
        backtestToMeta.date = getBacktestTo;
        backtestToMeta.useLatest = true;
        backtestDate.dates = [ backtestFromMeta, backtestToMeta ];
        return [ timeSeriesDate, methodDates, backtestDate ];
    }
}
