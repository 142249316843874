
import { SummarySelectionFilter } from "../../../../dtos";
import { Company, CompanyProfile } from "../../../database/R-DB/timeseries/companies";
import { SelectionFilterMenu } from "../../../filters";
import { MenuCategory } from "../../../menu/dtos/menu-dtos";
import { sendPost } from "../frontend/http-utilities";

export class GetSelectionFilterResultDTO {
    public title: string;
    public category: string;
    public marketCap: string;
}

export async function getSymbolsInfo(symbols: string[]): Promise<Company | null> {
    return sendPost("selection-filters/get-symbols-info/", { symbols }).then(e => {
        return e.data as Company;
    }).catch(() => {
        return null;
    });
}

export async function getSymbolInfo(symbol: string): Promise<Company | null> {
    return sendPost("selection-filters/get-symbol-info/", { symbol }).then(e => {
        return e.data as Company;
    }).catch(() => {
        return null;
    });
}

export async function getCompanyProfilesByChatGPT(prompt: string): Promise<CompanyProfile[] | null> {
    return sendPost("selection-filters/get-symbols-chat-gpt/", { prompt }).then(e => {
        return e.data as CompanyProfile[];
    }).catch(() => {
        return [];
    });
}

export async function getCompanyProfiles(selectionFilterMenu: SelectionFilterMenu): Promise<CompanyProfile[]> {
    return sendPost("selection-filters/get-symbols/", { selectionFilterMenu }).then(e => {
        return e.data as CompanyProfile[];
    }).catch(() => {
        return [];
    });
}

export async function getSelectionFilters(): Promise<MenuCategory[]> {
    return sendPost("selection-filters/selection-filters-menu/").then(e => {
        return e.data as MenuCategory[];
    }).catch(() => {
        return [];
    });
}

export async function getSelectionFilterResult(dto: GetSelectionFilterResultDTO): Promise<SummarySelectionFilter> {
    return sendPost("selection-filters/get-selection-filters-result", dto).then(e => {
        const res: SummarySelectionFilter = e.data;
        return res;
    }).catch(() => {
        return null;
    });
}