/* eslint-disable @typescript-eslint/no-empty-function */
import { SunIcon } from '@chakra-ui/icons';
import {
    Avatar,
    Box,
    Button,
    Center,
    Container,
    Divider,
    Flex,
    Grid,
    GridItem,
    HStack,
    Heading,
    Input,
    List,
    ListIcon,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    SkeletonText,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import { MathJax } from 'better-react-mathjax';
import moment from 'moment';
import React, { SyntheticEvent, useCallback, useState } from 'react';
import { FaDotCircle } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import RenderIfVisible from 'react-render-if-visible';
import { BacktestingSetting } from '../../backtesting-common-frontend';
import { FundMentions } from '../../backtesting-common-frontend/database/fundmentions';
import { TrackedNews } from '../../backtesting-common-frontend/database/user-DB/backend/trackednews';
import { createNewBacktest, getBacktests, updateBacktest } from '../../backtesting-common-frontend/http-utilities/http-utilities/backtests/backtests-backend.service';
import { getAvanzaFonder } from '../../backtesting-common-frontend/http-utilities/http-utilities/crawler/crawler.http';
import { Periodicity } from '../../backtesting-common-frontend/shared/periodicity';
import { getPeriodicityTabsOptions } from '../../backtesting-common-frontend/shared/tab.utilities';
import { sortByKey } from '../../backtesting-common-frontend/shared/utilites/array.utilities';
import { StatusDisplayDTO } from '../../backtesting-common-frontend/status/error-handling';
import { SelectBacktests } from '../../components/backtests/select-backtests';
import LazyLoading from '../../components/lazy-loading/lazy-loding';
import { ExplainerInfo } from '../../components/shared/explainer/info';
import { Tab as TabCo } from '../../components/tabs';
import ChakraTagInput from '../../components/tags';
import { setActive, setLoading, updateBacktestsStore, updateMessage } from '../../store/backtests/backtests';
import { AppState } from '../../store/store';

let queue: (() => Promise<void>)[] = [];
let timer: NodeJS.Timeout | null = null;

export default function HomeScreen() {
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const news = useSelector((state: AppState) => state.news.news);
    const newsBacktests = useSelector((state: AppState) => state.backtests.newsBacktests);

    const [ tags, setTags ] = useState<string[]>([  ]);

    const [ periodicityNewBacktest, setPeriodicityNewBacktest ] = useState<Periodicity>('day');
    const [ nameNewBacktest, setNameNewBacktest ] = useState<string | null>(null);
    const [ periodicityOptions ] = React.useState<TabCo[]>(getPeriodicityTabsOptions());
    const [ avanzaFonder, setAvanzaFonder ] = useState<FundMentions[]>([]);

    const handleTagsChange = useCallback((event: SyntheticEvent, tags: string[]) => {
        setTags(tags);
    }, []);

    async function createBacktestHandler(){
        const settings = new BacktestingSetting();
        dispatch(setLoading(true));
        settings.periodicity = periodicityNewBacktest as Periodicity;
        createNewBacktest(settings, periodicityNewBacktest as Periodicity, nameNewBacktest as string, tags).then(() => {
            dispatch(setLoading(false));
            const status = new StatusDisplayDTO("Successfully created backtest", "success");
            dispatch(updateMessage(status));
        }).catch(() => {

        });
    }

    const getAvanzaFonderAndNewsBacktests = React.useCallback(() => {
        getAvanzaFonder().then((data) => {
            setAvanzaFonder(data);
        }).catch(() => {
            // empty
        });
    }, []);

    React.useEffect(() => {
        getAvanzaFonderAndNewsBacktests();
    }, [ getAvanzaFonderAndNewsBacktests ]);

    const result = (title: string, res: string[]) => {
        return (
            <Center py={6}>
                <Box
                    maxW={'full'}
                    w={'full'}
                    bg={'gray.800'}
                    boxShadow={'2xl'}
                    rounded={'md'}
                    overflow={'hidden'}>
                    <Stack
                        textAlign={'center'}
                        p={6}
                        color={'white'}
                        align={'center'}>
                        <Stack direction={'row'} align={'center'} justify={'center'}>
                            <Text fontSize={'2xl'} fontWeight={800}>
                                {title}
                            </Text>
                        </Stack>
                    </Stack>
      
                    <Box bg={'gray.900'} px={6} py={10}>
                        <List spacing={3}>
                            {res.map((r, index) => (
                                <ListItem key={index}>
                                    <HStack>
                                        <ListIcon as={SunIcon} color="blue.400" />
                                        <Text>{r}</Text>
                                    </HStack>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Center>
        );
    };

    return (
        <Container minH={'100vh'} minW={'full'} bg={useColorModeValue('gray.50', 'gray.800')} marginBottom={100}>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create backtest</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack>
                            <Input type="text" placeholder="Name" value={nameNewBacktest ?? ""} onChange={(event) => setNameNewBacktest(event.target.value)} />
                            <Select placeholder="Choose periodicity" onChange={(event) => {
                                // find the periodicity
                                const periodicity = periodicityOptions.find(t => t.Name === event.target.value);
                                setPeriodicityNewBacktest(periodicity?.Id as Periodicity ?? "month");
                            }}>
                                {periodicityOptions.map((option) => (
                                    <option key={option.Id} value={option.Name}>
                                        {option.Name}
                                    </option>
                                ))}
                            </Select>
                            <Container padding={0}>
                                <ChakraTagInput
                                    tags={tags}
                                    placeholder='Add tags (hit enter to add)'
                                    onTagsChange={handleTagsChange}
                                    wrapProps={{ direction: 'column', align: 'start' }}
                                    wrapItemProps={(isInput) => (isInput ? { alignSelf: 'stretch' } as any : undefined)}
                                />
                            </Container>
                            <ExplainerInfo.Information 
                                text={
                                    <p>
                                        <span>
                                            <strong>Periodicity:</strong> The periodicity determines the forecast horizon 
                                            <MathJax>{`\\[ Y_{t+n} \\]`}</MathJax> from the current time 
                                            <MathJax>{`\\[ Y_{t} \\]`}</MathJax>, where 
                                            <MathJax>{`\\[ n \\]`}</MathJax> is the number of periods ahead when the stock market is open. In other words, initially the buying and selling date is (t + n) after the first buying decision at t.
                                        </span>
                                        <br />
                                        <span>
                                            <strong>Tags:</strong> Tags are used to categorize backtests and make them easier to find later on.
                                        </span>
                                    </p>
                                } 
                            />

                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='black' mr={3} onClick={onClose}>
                                Close
                        </Button>
                        <Button colorScheme='green' onClick={() => {
                            createBacktestHandler();
                            onClose();
                        }}>Create</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Container
                marginTop={5}
                minW={'80vh'}
            >
                <Tabs 
                    minW={'full'}
                    isFitted onChange={(index) => {
                        if(index === 2){
                            return;
                        }
                        dispatch(setLoading(true));
                        dispatch(updateBacktestsStore([]));
                        if(index === 1){
                            getBacktests("isNews", true, false, false, false).then((backtests) => {
                                dispatch(setLoading(false));
                                dispatch(updateBacktestsStore(backtests));
                            });
                        }else if(index === 0){
                            getBacktests("", false, false, false, false).then((backtests) => {
                                dispatch(setLoading(false));
                                dispatch(updateBacktestsStore(backtests));
                            });
                        }
                    }}>
                    <TabList mb="1em">
                        <Tab>Backtests</Tab>
                        <Tab>News backtests</Tab>
                        <Tab>Fund holdings</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <LazyLoading>
                                <SelectBacktests
                                    selectedBacktests={[]}
                                    onOpen={onOpen}
                                    onSelect={async(backtest) => {
                                        dispatch(setActive(backtest._id));
                                        dispatch(setLoading(true));
                                        queue.push(async() => {
                                            backtest.activeFrontend = true;
                                            const newLoading = new StatusDisplayDTO("You are currently working on " + backtest?.name, "info");
                                            dispatch(updateMessage(newLoading));
                                            await updateBacktest(backtest, null, true);
                                            getBacktests();
                                        });
                                        if(timer){
                                            clearTimeout(timer);
                                        }
                                        timer = setTimeout(() => {
                                            if(queue.length > 0){
                                                queue[queue.length - 1]();
                                                dispatch(setLoading(false));
                                            }
                                            queue = [];
                                        }, 3000);
                                    }} 
                                />
                            </LazyLoading>
                            <Box bg='tomato' w='100%' p={4} color='white' mt={4}>
                                <strong>PAST PERFORMANCE IS NO GUARANTEE OF FUTURE RESULTS.</strong>
                            </Box>
                        </TabPanel>
                        <TabPanel>
                            <SelectBacktests
                                selectedBacktests={[]}
                                onOpen={onOpen}
                                onSelect={async(backtest) => {
                                    dispatch(setActive(backtest._id));
                                    dispatch(setLoading(true));
                                    queue.push(async() => {
                                        backtest.activeFrontend = true;
                                        const newLoading = new StatusDisplayDTO("You are currently working on " + backtest?.name, "info");
                                        dispatch(updateMessage(newLoading));
                                        await updateBacktest(backtest, null, true);
                                        getBacktests();
                                    });
                                    if(timer){
                                        clearTimeout(timer);
                                    }
                                    timer = setTimeout(() => {
                                        if(queue.length > 0){
                                            queue[queue.length - 1]();
                                            dispatch(setLoading(false));
                                        }
                                        queue = [];
                                    }, 3000);
                                }} 
                            />
                        </TabPanel>
                        <TabPanel>
                            {avanzaFonder?.length > 0 ? <Container p="4" bg={'gray.900'} minW={900}>
                                <h4>Source: Avanza.se</h4>
                                <ExplainerInfo.Information text={<p>
                    The data is collected from Avanza.se and represents the most owned stocks in mutual funds.
                                    <br></br>
                    The data has been compiled by iterating through each mutual fund, examining the fund's holdings, and counting the number of times each stock is owned.
                                    <br></br>
                                    {avanzaFonder?.length > 0 ? "The data is from " + avanzaFonder[avanzaFonder.length - 1].date : "No data available"}
                                </p>} />
                                {result("Most owned Mutual Fund stocks", avanzaFonder.map(x => x.symbol))}
                            </Container> : <SkeletonText mt="4" noOfLines={4} spacing="4" />}
                            <NewsHolder avanzaFonder={avanzaFonder} result={result} news={[]} />
                            {/* <div style={{ width: '100%' }}>
                                {newsBacktests?.length > 0 ? <GridItem p="4" bg={'gray.900'}>
                                    <Heading fontSize={'2xl'} fontFamily={'body'} justifyContent={'center'} alignItems={'center'} display={'flex'} marginTop={5} marginBottom={5}>
                            Recent news subjects & backtests
                                    </Heading>
                                    <ExplainerInfo.Information 
                                        text={
                                            <p>
                                                <span>1. Every day at 17:00 (UTC+1), our AI searches recent news (from today to four days ago) to find topics related to various economic, financial, geopolitical, and environmental crises, including policy measures, market phenomena, natural disasters, and sustainability efforts.</span>
                                                <br />
                                                <span>2. For each subject, we use our sample "description" method to find companies related to that subject.</span>
                                                <br />
                                                <span>3. We create a backtest with the stocks in that group that have the highest beta within the industry to reject the null hypothesis that the portfolio's return is equal to the comparison series (NASDAQ 100).</span>
                                                <br />
                                                <span>The backtests shown here are those where we could reject the null hypothesis.</span>
                                            </p>
                                        } 
                                    />

                                    <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={6}>
                                        {newsBacktests.map((backtest, index) => {
                                            return <GridItem p="4" bg={'gray.900'} key={index}>
                                                <Text color={'gray.500'} fontSize={16}>{backtest.name}</Text>
                                                <Text color={'gray.500'} fontSize={16}>{backtest.periodicity}</Text>
                                                <StatisticalComponent backtest={backtest} onlyTopRelevant={true} evaluation={backtest.statisticsSummary.evaluation} />
                                            </GridItem>;
                                        })}
                                    </Grid>
                                </GridItem> : <SkeletonText mt="4" noOfLines={4} spacing="4" />}

                            </div> */}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Container>
        </Container>
    );

}

const NewsHolderMemo = (props: {avanzaFonder: FundMentions[], result, news: TrackedNews[]}) => {
    const isEmpty = props?.news == null || props?.news?.length === 0;
    if(isEmpty){
        return <Container p="4" bg={'gray.900'} minW={900}>
            <Text color={'gray.500'} fontSize={16}>No news available</Text>
        </Container>;
    }
    return <>
        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={6}>
            {props.news.filter(x => x.isCrisis)?.length > 0 && <GridItem p="4" bg={'gray.900'}>
                <Divider marginTop={5} marginBottom={5} /> <Heading fontSize={'2xl'} fontFamily={'body'} justifyContent={'center'} alignItems={'center'} display={'flex'} marginTop={5} marginBottom={5}>
                    Recent crisis
                </Heading>
                {newsDisplay(sortByKey(props.news.filter(x => x.isCrisis), "mentioned"))}
                <Divider marginTop={5} marginBottom={5} />
            </GridItem>}
            {props.news.filter(x => x.hotterThanGME)?.length > 0 && <GridItem p="4" bg={'gray.900'}>
                <Heading fontSize={'2xl'} fontFamily={'body'} justifyContent={'center'} alignItems={'center'} display={'flex'} marginTop={5} marginBottom={5}>
                    Hot compared to GameStop in 2020
                </Heading>
                <Text color={'gray.500'} fontSize={16} marginBottom={3}>
                    The terms were calculated by summing all mentions in news articles before 16:00 (GMT−5)
                    and pushing the articles occurring after that time to the next day from the New York Times RSS feed.
                    The terms listed below exhibited mentions that exceeded the average (approximately 2.3 mentions per day)
                    from the New York Times RSS feed of the term &quot;GameStop&quot; during the period before it started to trend.
                    This period spans from August 1, 2020, to December 25, 2020.
                </Text>
                {newsDisplay(sortByKey(props.news.filter(x => x.hotterThanGME), "mentioned"), true)}
                <Divider marginTop={5} marginBottom={5} />
            </GridItem>}
            {sortByKey(props.news.filter(x => x.isWord), "mentioned").slice(0, 7)?.length > 0 && <GridItem p="4" bg={'gray.900'}>
                <Divider marginTop={5} marginBottom={5} /> <Heading fontSize={'2xl'} fontFamily={'body'} justifyContent={'center'} alignItems={'center'} display={'flex'} marginTop={5} marginBottom={5}>
                    Top trending news terms
                </Heading>
                {newsDisplay(sortByKey(props.news.filter(x => x.isWord), "mentioned").slice(0, 7))}
                <Divider marginTop={5} marginBottom={5} />
            </GridItem>}
            {sortByKey(props.news.filter(x => x.mentioned != null && !x.isWord && !x.hotterThanGME && !x.isCrisis), "mentioned").slice(0, 10)?.length > 0 && <GridItem p="4" bg={'gray.900'}>
                <Divider marginTop={5} marginBottom={5} />
                <Heading fontSize={'2xl'} fontFamily={'body'} justifyContent={'center'} alignItems={'center'} display={'flex'} marginTop={5} marginBottom={5}>
                    Top recent news mentions
                </Heading>
                {newsDisplay(sortByKey(props.news.filter(x => x.mentioned != null && !x.isWord && !x.hotterThanGME && !x.isCrisis), "mentioned").slice(0, 10))}
                <Divider marginTop={5} marginBottom={5} />
            </GridItem>}
        </Grid>
    </>;
};

const NewsHolder = React.memo(NewsHolderMemo);

function newsDisplay(trackedNews: TrackedNews[], showMentioned?: boolean) {
    return <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={2} minW={'full'} className='news__wrapper'>
        {trackedNews.map((news, index) => {
            return <RenderIfVisible defaultHeight={135} key={index + '' + news?.news}>
                <GridItem w='100%' alignItems={'center'} justifyContent={'center'} display={'flex'} key={'news' + index}>
                    <Box
                        maxW={'full'}
                        w={'full'}
                        bg={useColorModeValue('white', 'gray.900')}
                        boxShadow={'2xl'}
                        rounded={'md'}
                        p={6}
                        overflow={'hidden'}
                        onClick={() => {
                            // setNavigateToTheory(true);
                        }} cursor={'pointer'}
                    >
                        <Stack direction={'row'} spacing={4} align={'center'}>
                            <Avatar icon={<FaDotCircle  size={20} color={'blue.800'} />} bg={'transparent'} />
                            <HStack width={'full'}>
                                <Flex width={'full'}>
                                    <Stack direction={'column'} spacing={0} fontSize={'sm'}>
                                        {news.source && !news.isWord && <Text color={'white.500'} fontSize={26}>{news.source}</Text>}
                                        <Text color={'gray.500'} fontSize={16}>{news.news}</Text>
                                        <Text color={'gray.500'} fontSize={16}>{moment(news.lastSeen).format("YYYY-MM-DD HH:mm:ss")}</Text>
                                    </Stack>
                                </Flex>
                                {showMentioned && <Flex width={'full'}>
                                    <Stack spacing={0} fontSize={'sm'}>
                                        <Text color={'gray.500'} fontSize={16}>Mentions:</Text>
                                        <Text color={'gray.500'} fontSize={16}>{news.mentioned }</Text>
                                    </Stack>
                                </Flex>}
                                {news.oppositeWord && <Flex width={'full'}>
                                    <Stack spacing={0} fontSize={'sm'}>
                                        <Text color={'gray.500'} fontSize={16}>Opposite term:</Text>
                                        <Text color={'gray.500'} fontSize={16}>{news.oppositeWord}</Text>
                                    </Stack>
                                </Flex>}
                            </HStack>
                        </Stack>
                    </Box>
                </GridItem>
            </RenderIfVisible>;
        })}
    </Grid>;
}
