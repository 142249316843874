import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SelectionFilterMenu } from '../../backtesting-common-frontend/filters';

export class SymbolsState {
    selectedSymbols: string[] = [];
    allSymbols: string[] = [];
    selectedIndustry: SelectionFilterMenu[] = [];
}

const initialState: SymbolsState = {
    selectedSymbols: [],
    allSymbols: [],
    selectedIndustry: [],
};

const symbols = createSlice({
    name: 'symbols',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<string[]>) => {
            state.selectedSymbols = action.payload;
        },
        removeAll: (state) => {
            state.selectedSymbols = [];
        },
        addAllSymbols: (state, action: PayloadAction<string[]>) => {
            state.allSymbols = action.payload;
        },
        addIndustry: (state, action: PayloadAction<SelectionFilterMenu[]>) => {
            state.selectedIndustry = action.payload;
        },
    },
});

export const addSymbolsStore = symbols.actions.add;
export const removeAllSymbolsStore = symbols.actions.removeAll;
export const addAllSymbolsStore = symbols.actions.addAllSymbols;
export const addIndustryStore = symbols.actions.addIndustry;
export const symbolsReducer = symbols.reducer;