/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Card, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { FaFlag } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Backtest, BacktestingSetting } from '../../backtesting-common-frontend';
import { updateBacktest } from '../../backtesting-common-frontend/http-utilities/http-utilities/backtests/backtests-backend.service';
import { updateTest } from '../../backtesting-common-frontend/http-utilities/http-utilities/tests/tests.backend.service';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import { StatusDisplayDTO } from '../../backtesting-common-frontend/status/error-handling';
import { TimeSeriesService } from '../../backtesting-common-frontend/timeseries/timeseries.service';
import { setLoading, updateMessage } from '../../store/backtests/backtests';
import { AppState } from '../../store/store';
import DatesController from './date-controller';

let queueTimeSeries: any[] = [];
let queueBacktest: any[] = [];

const queueTimeSeriesTimer: any[] = [];
const queueBacktestTimer: any[] = [];

export default function DateSettings() {
    const dispatch = useDispatch();
    // replace with redux
    const backtest = useSelector(
        (state: AppState) => state.backtests.backtest
    );
    const test = useSelector(
        (state: AppState) => state.tests
    );
    const [ timeSeriesService ] = React.useState<TimeSeriesService>(new TimeSeriesService());
    const [ graphDatesTo, setGraphDatesTo ] = React.useState<string>("");
    const [ backtestTo, setBacktestTo ] = React.useState<string>("");
    const [ methodTo, setMethodTo ] = React.useState<string>("");
    const [ methodTestTo, setMethodTestTo ] = React.useState<string>("");

    // create state for each redux and use deepClone
    const [ testCtx, setTestCtx ] = React.useState<BacktestingSetting | null | undefined>(null);
    const [ backtestCtx, setBacktestCtx ] = React.useState<Backtest | null | undefined>(null);

    React.useEffect(() => {
        setTestCtx(cloneDeep(test.test));
        setBacktestCtx(cloneDeep(backtest));
    }, [ test, backtest ]);

    React.useEffect(() => {
        const m = async() => {
            if(!testCtx) return;
            const graphTo = await timeSeriesService.getTimeSeriesTo(testCtx, true);
            const backtestTo = await timeSeriesService.getBacktestTo(testCtx, true);
            const methodTo = await timeSeriesService.getMethodTrainTo(testCtx, true);
            const methodTestTo = await timeSeriesService.getMethodTestTo(testCtx, true);
            setGraphDatesTo(graphTo?.label);
            setBacktestTo(backtestTo?.label);
            setMethodTo(methodTo?.label);
            setMethodTestTo(methodTestTo?.label);
        };
        m();
    }, [ testCtx, timeSeriesService ]);

    return (
        <div style={{ width: '100%', height: '80vh' }}>
            <Card
                w="17rem"
                p={4}
                border="1px solid"
                borderColor={useColorModeValue('gray.400', 'gray.600')}
                rounded="md"
                margin="0 auto"
            >
                <FaFlag size={20} color={useColorModeValue('black', 'white')} />
                <Heading size="md">Use recommended dates</Heading>
                <Text>Select own from and to dates or use our recommended dates.</Text>
                <Button onClick={async() => {
                    if(!testCtx) return;
                    dispatch(setLoading(true));
                    const dates = await timeSeriesService.getRecommendedDates();
                    testCtx.startDate = dates.filter(p => p.dates.some(t => t.name.includes("from")));
                    testCtx.endDate = dates.filter(p => p.dates.some(t => t.name.includes("to")));
                    await updateTest(testCtx);
                    const status = new StatusDisplayDTO("Dates updated", "success");
                    dispatch(setLoading(false));
                    dispatch(updateMessage(status));
                }}>Use recommended dates</Button>
            </Card>
            {testCtx != null && <>            
                <DatesController title={"Graph dates"}
                    defaultFrom={timeSeriesService.getTimeSeriesFrom(testCtx)?.label ?? ""}
                    defaultTo={graphDatesTo}
                    defaultUseLatest={timeSeriesService.getTimeSeriesUseLatest(testCtx)}
                    onDateChange={async(from: string, to: string, useLatest: boolean) => {
                        dispatch(setLoading(true));
                        const m = async() => {
                            if(!testCtx) return;
                            await timeSeriesService.updateTimeSeriesFrom(testCtx, from);
                            await timeSeriesService.updateTimeSeriesTo(testCtx, to, useLatest);
                            await updateTest(testCtx);
                            const status = new StatusDisplayDTO("Dates updated", "success");
                            dispatch(setLoading(false));
                            dispatch(updateMessage(status));
                        };
                        queueTimeSeries.push(m);
                        // clear all timers
                        queueTimeSeriesTimer.forEach(p => clearTimeout(p));
                        const timer = setTimeout(() => {
                            if (queueTimeSeries.length > 0) {
                                queueTimeSeries[queueTimeSeries.length - 1]();
                                queueTimeSeries = [];
                            }
                        }, 5000);
                        queueTimeSeriesTimer.push(timer);
                    }} />
                <DatesController title={"Backtest dates"}
                    defaultFrom={timeSeriesService.getBacktestFrom(testCtx)?.label ?? ""}
                    defaultTo={backtestTo}
                    defaultUseLatest={timeSeriesService.getBacktestToUseLatest(testCtx)}
                    onDateChange={async(from: string, to: string, useLatest: boolean) => {
                        const f = moment(from);
                        const t = moment(to);
                        const isMoreThanOneYear = t.diff(f, 'years') > 1;
                        if(!isMoreThanOneYear){
                            const status = new StatusDisplayDTO("Backtest dates must be at least one year apart", "error");
                            dispatch(updateMessage(status));
                            return;
                        }
                        dispatch(setLoading(true));
                        const m = async() => {
                            if(!backtestCtx || !testCtx) return;
                            await timeSeriesService.updateBacktestFrom(testCtx, from);
                            await timeSeriesService.updateBacktestTo(testCtx, to, useLatest);
                            const res = testCtx.startDate.find(t => t.type === "backtest")?.dates?.find(p => p.name.includes("from"))?.date.step;
                            if(res){
                                backtestCtx.atStep = res;
                            }
                            await updateTest(testCtx);
                            const status = new StatusDisplayDTO("Dates updated", "success");
                            dispatch(setLoading(false));
                            dispatch(updateMessage(status));
                            setTimeout(() => {
                                updateBacktest(backtestCtx);
                            }, 1000);
                        };
                        queueBacktest.push(m);
                        // clear all timers
                        queueBacktestTimer.forEach(p => clearTimeout(p));
                        const timer = setTimeout(() => {
                            if (queueBacktest.length > 0) {
                                queueBacktest[queueBacktest.length - 1]();
                                queueBacktest = [];
                            }
                        }, 5000);
                        queueBacktestTimer.push(timer);
                    }} />
            </>}
        </ div>
    );
}

