import { FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { SelectionFilterMenu } from '../../backtesting-common-frontend/filters';
import { updateStrategy } from '../../backtesting-common-frontend/http-utilities/http-utilities/strategy/strategy';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import { mergeNestedCapitalLetters, splitByCapitalLetter } from '../../backtesting-common-frontend/shared/utilites/string.utilities';
import { StatusDisplayDTO } from '../../backtesting-common-frontend/status/error-handling';
import { setLoading, updateMessage } from '../../store/backtests/backtests';
import { AppState } from '../../store/store';
import { ColumnDTO, RowTableDTO } from '../table';
import TableComponent from '../table/table';

class SummarySelectionFilterGraphValue {
    public date: Date;
    public returnAvg: number;
    public symbol: string;
}

export class SummarySelectionFilter {
    public graphResult: SummarySelectionFilterGraphValue[] = [];
    public fundamentals: any;
}

export default function SelectionFiltersSummary() {
    const dispatch = useDispatch();
    const currentStrategy = useSelector(
        (state: AppState) => state.strategies.strategy
    );
    const backtest = useSelector(
        (state: AppState) => state.backtests.backtest
    );
    const columns: ColumnDTO[] = [];

    const remove = (item: SelectionFilterMenu) => {
        if(!currentStrategy) return;
        const str = cloneDeep(currentStrategy);
        str.selectionFilters = str.selectionFilters.filter(x => x != null && JSON.stringify(x) !== JSON.stringify(item));
        const statusDisplay = new StatusDisplayDTO("Successfully removed selection filter", "success");
        dispatch(setLoading(true));
        updateStrategy(str).finally(() => {
            dispatch(updateMessage(statusDisplay));
            dispatch(setLoading(false));
        });
    };

    const selectionFilters = currentStrategy?.selectionFilters;

    if(selectionFilters){
        for (let i = 0; i < selectionFilters.length; i++) {
            if(selectionFilters?.[i]?.display == null) continue;
            const fieldName = selectionFilters[i].display;
            columns.push({ title: "Name", value: mergeNestedCapitalLetters(splitByCapitalLetter(fieldName)) });
            columns.push({ title: "Remove", value: <FaTrash onClick={() => remove(selectionFilters[i])} /> });
        }
    }

    const rows: RowTableDTO[] = [];
    for (let i = 0; i < columns.length; i++) {
        if (i % 2 === 0) {
            rows.push({ columns: [ columns[i], columns[i + 1] ] });
        }
    }

    return (
        <TableComponent rows={rows} />
    );
}