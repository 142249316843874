
import axios from "axios";
import { ApiKey, Client, licenseType } from "../../clients/clients";
import { env } from "../../env";
import { sendPost } from "../http-utilities/frontend/http-utilities";

export const isBacktestLicense = (): boolean => {
    const userItem = localStorage.getItem("user");
    if (userItem) {
        const user = JSON.parse(userItem) as AuthResponse;
        return user == null ? false : user.license === "backtester";
    }
    return false;
};

export class AuthResponse {
    public token: string;
    public expiresIn: number;
    public clientId: string;
    public langIndex: string;
    public theme: any;
    public apiKeys: ApiKey[];
    public license: licenseType;
}

export const login = async (email: string, password: string): Promise<AuthResponse | null> => {
    return await axios({
        method: "post",
        url: env.routerUrl + "clients/login",
        data: {
            email,
            password,
        },
    }).then(e => {
        return e.data as AuthResponse;
    }).catch(e => {
        return null;
    });
};

export const signup = async (newClient: Client): Promise<Client | null> => {
    return await axios({
        method: "post",
        url: env.routerUrl + "clients/add",
        data: newClient,
    }).then(e => {
        return e.data as Client;
    }).catch(e => {
        return null;
    });
};

export const generateApiKey = async (): Promise<ApiKey | null> => {
    return sendPost("clients/generate-api-key", {}).then((e) => {
        return e.data as ApiKey;
    }).catch(() => {
        return null;
    });
};
