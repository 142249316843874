import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { BacktestingSetting, GraphItem } from "../../backtesting-common-frontend";
import { ModernPortfolioSelectionSettings } from "../../backtesting-common-frontend/methods";
import { cloneDeep } from "../../backtesting-common-frontend/shared/utilites/object.utilities";
import { CriteriaBoardMethod, CriteriaBoardOptionSelect } from "../../backtesting-common-frontend/techniques";
import { Tab, tabType } from "../../components/tabs";
import { MethodsResultsManager } from "../../managers/methods/methods-manager";
import { TabsManager } from "../../managers/tabs/tabs-manager";
import { TimeSeriesResultsManager } from "../../managers/time-series/time-series-manager";

export function addHoldValue(holdValue: string, w: CriteriaBoardMethod) {
    const holdValueNumber = holdValue != null && typeof (+holdValue) === "number" ? +holdValue : 0;
    if (holdValueNumber > 0) {
        w.holdValue = holdValue;
        w.hold = true;
    }
}

export const getOptions = (graphItems: GraphItem[], tabId: tabType | null, timeSeriesManager: TimeSeriesResultsManager): CriteriaBoardOptionSelect | null => {
    const graphItem = timeSeriesManager.getGraphTheoryItems(graphItems, []);
    switch (tabId) {
    case 'market-conditions':
        return { aboveBelow: true, growth: true, lag: true, forecastPoints: true };
    case 'theory-tab':
        return graphItem?.length > 1 ? { aboveBelow: true, growth: true, lag: true, year: true, month: true, relativeQuota: [ true ], trend: [ true ] } :
            { aboveBelow: true, growth: true, lag: true, year: true, month: true, trend: [ true ] };
    case "modern-portfolio-tab":
        return { absolute: true };
    }
    return null;
};

export function findCriteriaBoardMethodAssumeNoMethodChange(dispatch: Dispatch<AnyAction>, unSavedWorkInProgress: CriteriaBoardMethod | null | undefined, currentTest: BacktestingSetting, currentMainTab: Tab | null | undefined): CriteriaBoardMethod | null | undefined {
    if(currentMainTab && currentTest){
        const findCurrentWork = unSavedWorkInProgress;
        const alternative = MethodsResultsManager.getCurrentMethod(currentTest, currentMainTab);
        if(!alternative){
            return null;
        }
        const methodKey = TabsManager.convertTabIdToMethodKey(currentMainTab.Id);
        // if(findCurrentWork?.methodKey !== methodKey){
        //     dispatch(setOngoingWork(null));
        // }
        if (findCurrentWork?.methodKey === methodKey) {
            return cloneDeep(findCurrentWork);
        } else if(alternative && currentMainTab.Id === "modern-portfolio-tab") {
            return cloneDeep((alternative.parameters as ModernPortfolioSelectionSettings)?.criteriaBoard);
        }else if(alternative){
            return cloneDeep(alternative.parameters as CriteriaBoardMethod);
        }else{
            return null;
        }
    }
    return null;
}

export function getCriteriaBoard(currentTest: BacktestingSetting, currentMainTab: Tab | null | undefined): CriteriaBoardMethod | null | undefined {
    if(currentMainTab && currentTest){
        const alternative = MethodsResultsManager.getCurrentMethod(currentTest, currentMainTab);
        if(!alternative){
            return null;
        }
        if(alternative && currentMainTab.Id === "modern-portfolio-tab") {
            return cloneDeep((alternative.parameters as ModernPortfolioSelectionSettings)?.criteriaBoard);
        }else if(alternative){
            return cloneDeep(alternative.parameters as CriteriaBoardMethod);
        }else{
            return null;
        }
    }
    return null;
}