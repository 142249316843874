import { createSlice } from '@reduxjs/toolkit';
import { TrackedNews } from '../../backtesting-common-frontend/database/user-DB/backend/trackednews';

export class LazyLoadingsResults {
    news: TrackedNews[];
}

const initialState: LazyLoadingsResults = {
    news: [],
};

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        addNews: (state, action) => {
            state.news = action.payload;
        },
    },
});

export const { addNews } = newsSlice.actions;
export const newsReducer = newsSlice.reducer;