import mongoose from "mongoose";
import tests from "./tests";
const Schema = mongoose.Schema;

const strategiesSchema = new Schema({
    returnCalcMethod: String,
    intersection: [{ type: Schema.Types.ObjectId, ref: "methods", default: [] }],
    decisionTree: [{ type: Schema.Types.ObjectId, ref: "methods", default: [] }],
    closeMethods: [{ type: Schema.Types.ObjectId, ref: "methods", default: [] }],
    selectionFilters: [{ type: Schema.Types.Mixed, default: [] }],
    active: Boolean,
    name: String,
    frontendId: String,
    created: { type: Date, default: Date.now },
    clientId: { type: Schema.Types.ObjectId, ref: "clients", required: true }
});

strategiesSchema.pre("findOneAndDelete", async function (next) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const strategyId = this["_conditions"]._id;
    await tests.updateMany({ strategies: strategyId }, { $pull: { strategies: strategyId } });
    next();
});

const strategies = mongoose.model("strategies", strategiesSchema);

export default strategies;
