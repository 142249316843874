export function onlyUnique(value: any, index: number, self: any): boolean {
    return self.indexOf(value) === index;
}

// write a general function that sorts an array of objects by a given key
export const sortByKey = (array: any[], key: string): any[] => {
    return array.sort((a, b) => {
        const x = a[key];
        const y = b[key];
        return ((x < y) ? 1 : ((x > y) ? -1 : 0));
    });
};

export const makeObjectArrayToArrayOfArrayHelper = (a: any): any[] => {
    const array: any[] = [];
    for (const key in a) {
        array.push(a[key]);
    }
    return array;
};

export const objectToArray = (a: object): any[] => {
    const array: any[] = [];
    for (const key in a) {
        array.push({ [key]: a[key], key: key });
    }
    return array;
};

export function shuffle<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [ array[i], array[j] ] = [ array[j], array[i] ];
    }
    return array;
}

export function randomSelect<T>(array: T[], n: number): T[] {
    const shuffled = array.slice();
    shuffle(shuffled);
    return shuffled.slice(0, n);
}

export function groupThreeDimensionalArray<T>(arr: T[], key: string, keySecond: string | number): T[][] {
    const groups = {};
    for (const obj of arr) {
        const keyId = obj?.[key]?.[keySecond];
        if (keyId == null) continue;
        if (!groups[keyId]) {
            groups[keyId] = [ obj ];
        } else {
            groups[keyId].push(obj);
        }
    }
    return Object.values(groups);
}

export function groupArray<T>(arr: T[], key: string): T[][] {
    const groups = {};
    for (const obj of arr) {
        const keyId = obj[key];
        if (!groups[keyId]) {
            groups[keyId] = [ obj ];
        } else {
            groups[keyId].push(obj);
        }
    }
    return Object.values(groups);
}

export function groupArrayIntoGroups<T>(arr: T[], n: number): T[][] {
    const result: T[][] = [];

    for (let i = 0; i < arr.length; i += n) {
        result.push(arr.slice(i, i + n));
    }

    return result;
}

export function removeDuplicates(duplicatesArray: any[], field: string) {
    const values = [];
    const duplicates = [];
    for (let i = 0; i < duplicatesArray.length; i++) {
        if (values.includes(duplicatesArray[i][field])) {
            duplicates.push(...removeDuplicatesHelper(values, duplicatesArray[i][field]));
        }
        values.push(duplicatesArray[i][field]);
    }
    return duplicatesArray.filter((x, i) => !duplicates.includes(i));
}

function removeDuplicatesHelper(values: any[], value: any) {
    const indexes = [];
    for (let i = 0; i < values.length; i++) {
        if (values[i] === value) { indexes.push(i); }
    }
    return indexes;
}

export function tryParseJSONObject(jsonString): boolean {
    try {
        const o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return true;
        }
    }
    catch (e) { // empty
    }

    return false;
}
