import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StrategyDTO } from '../../backtesting-common-frontend/strategies/strategy';

export class StrategyState {
    strategy: StrategyDTO | null = null;
}

const initialState: StrategyState = {
    strategy: null,
};

const strategy = createSlice({
    name: 'strategy',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<StrategyDTO>) => {
            state.strategy = action.payload;
        },
    },
});

export const updateStrategyStore = strategy.actions.add;
export const strategiesReducer = strategy.reducer;