
import { Backtest } from "../../..";
import { SelectionFilterMenu } from "../../../filters";
import { TimeSeriesDTO } from "../../../methods";
import { StrategyDTO } from "../../../strategies/strategy";
import { sendPost } from "../frontend/http-utilities";

export async function updateStrategy(strategy: StrategyDTO): Promise<void> {
    return sendPost("strategies/update", strategy).then(() => {
        // empty
    }).catch(() => {
        // empty
    });
}

export async function updateDummyStrategy(strategy: StrategyDTO): Promise<void> {
    return sendPost("strategies/update-dummy", strategy).then(() => {
        // empty
    }).catch(() => {
        // empty
    });
}

export async function getStrategy(strategy: StrategyDTO): Promise<void> {
    return sendPost("strategies/get", strategy).then(() => {
        // empty
    }).catch(() => {
        // empty
    });
}

export async function getDescription(term: string): Promise<string[] | null> {
    return sendPost("strategies/description", { term }).then(e => {
        return e.data as string[];
    }).catch(() => {
        return null;
    });
}

export async function getIndustries(term: string, impact: string): Promise<(SelectionFilterMenu | TimeSeriesDTO)[] | null> {
    return sendPost("strategies/industries", { term, impact }).then(e => {
        return e.data as (SelectionFilterMenu | TimeSeriesDTO)[];
    }).catch(() => {
        return null;
    });
}

export async function getCryptocurrencies(term: string, impact: string): Promise<(SelectionFilterMenu | TimeSeriesDTO)[] | null> {
    return sendPost("strategies/cryptocurrencies", { term, impact }).then(e => {
        return e.data as (SelectionFilterMenu | TimeSeriesDTO)[];
    }).catch(() => {
        return null;
    });
}

export async function getCommodities(term: string, impact: string): Promise<(SelectionFilterMenu | TimeSeriesDTO)[] | null> {
    return sendPost("strategies/commodities", { term, impact }).then(e => {
        return e.data as (SelectionFilterMenu | TimeSeriesDTO)[];
    }).catch(() => {
        return null;
    });
}

export async function getNewsAboveThreshold(term: string): Promise<{savedTimeSeries: TimeSeriesDTO, timeSeries: TimeSeriesDTO[]} | null> {
    return sendPost("strategies/news-above-threshold", { term }).then(e => {
        return e.data as {savedTimeSeries: TimeSeriesDTO, timeSeries: TimeSeriesDTO[]};
    }).catch(() => {
        return null;
    });
}

export async function getNewsBacktests(): Promise<Backtest[]> {
    return sendPost("strategies/get-news-backtests", { }).then(e => {
        return e.data as Backtest[];
    }).catch(() => {
        return [];
    });
}