/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { TimeSeriesHelperValue } from "../../timeseries/timeseries-models";
import { deepClone } from "../../worker/transformations/transformation-service";
import { okNumber } from "./number.utilities";

export const max = function (numberArray: number[]): number {
    return Math.max.apply(null, numberArray) as number;
};

export const min = function (numberArray: number[]): number {
    return Math.min.apply(null, numberArray) as number;
};

export const scale0To1 = (a: number[]): number[] => {
    const maxNumber = max(a.filter(t => okNumber(t)));
    const minNumber = min(a.filter(t => okNumber(t)));
    return a.map(e => {
        return (e - minNumber) / (maxNumber - minNumber);
    });
};

export const normalizationObjects = <T>(a: T[], field: string, useMax?: number | null): T[] => {
    const maxNumber = useMax == null ? Math.max(...a.filter(t => okNumber(t[field])).map(e => e[field])) : useMax;
    const minNumber = Math.min(...a.filter(t => okNumber(t[field])).map(e => e[field]));
    return a.map(e => {
        e[field] = (e[field] - minNumber) / (maxNumber - minNumber);
        return e;
    });
};

export const normalizationObjectsReverse = (originalSeries: TimeSeriesHelperValue[], a: TimeSeriesHelperValue[], field: string): TimeSeriesHelperValue[] => {
    const maxNumber = max(originalSeries.filter(t => okNumber(t[field])).map(e => e[field]));
    const minNumber = min(originalSeries.filter(t => okNumber(t[field])).map(e => e[field]));
    return a.map(e => {
        e[field] = e[field] * (maxNumber - minNumber) + minNumber;
        return e;
    });
};

export const revertRelativeTimeSeries = (a: number[]): number[] => {
    const maxNumber = max(a.filter(t => okNumber(t)));
    const minNumber = min(a.filter(t => okNumber(t)));
    return a.map(e => {
        return e * (maxNumber - minNumber) + minNumber;
    });
};

export function arraySort(array: TimeSeriesHelperValue[], field: string): TimeSeriesHelperValue[] {
    array.sort((a, b) => a[field] - b[field]);
    return array;
}

export const makeRelativeTimeSeriesObjectsReverse = (original: TimeSeriesHelperValue[], scaledData: TimeSeriesHelperValue[], field: string): TimeSeriesHelperValue[] => {
    const maxNumber = max(original.filter(t => okNumber(t[field])).map(e => e[field]));
    const minNumber = min(original.filter(t => okNumber(t[field])).map(e => e[field]));
    const range = maxNumber - minNumber;
    return scaledData.map(e => {
        e[field] = (e[field] + 1) / 2 * range + minNumber;
        return e;
    });
};

export const makeRelativeTimeSeriesObjects = (a: TimeSeriesHelperValue[], field: string): TimeSeriesHelperValue[] => {
    const maxNumber = max(a.filter(t => okNumber(t[field])).map(e => e[field]));
    const minNumber = min(a.filter(t => okNumber(t[field])).map(e => e[field]));
    const range = maxNumber - minNumber;
    return a.map(e => {
        e[field] = (e[field] - minNumber) / range * 2 - 1;
        return e;
    });
};

export function findClosestValue(arr: number[], target: number): number {
    if (arr.length === 0) return null;

    let closest = arr[0];
    let closestDiff = Math.abs(target - closest);

    for (let i = 1; i < arr.length; i++) {
        const currentDiff = Math.abs(target - arr[i]);

        if (currentDiff < closestDiff) {
            closest = arr[i];
            closestDiff = currentDiff;
        }
    }

    return closest;
}

export function categorize(value: number, p: TimeSeriesHelperValue[], field: string, type: number): number {
    const n = deepClone(p.map(e => e.value)).sort((a, b) => a - b);
    const index = n.findIndex(x => x === value);
    const length = p.length;
    const quantile = index / length;
    if (quantile < 0.25) {
        return type === 1 || type === 2 ? 1 : 0;
    } else if (quantile < 0.5) {
        return type === 2 ? 1 : 0;
    } else if (quantile < 0.75) {
        return type === 3 ? 1 : 0;
    } else {
        return type === 3 || type === 4 ? 1 : 0;
    }
}

export function toScientific(num: number): string {
    if (num === 0) {
        return "0";
    } else if (Math.abs(num) < 1) {
        const exponent = Math.floor(Math.log10(Math.abs(num)));
        const mantissa = num / 10 ** exponent;
        return `${mantissa.toFixed(3)}e${exponent}`;
    } else {
        const exponent = Math.floor(Math.log10(Math.abs(num)));
        const mantissa = num / 10 ** exponent;
        return `${mantissa.toFixed(3)}e${exponent}`;
    }
}

export function round(value: number, decimals: number): number {
    return okNumber(value) ? Number(value.toFixed(decimals)) : 0;
}

export function getStandardDeviation(array: number[]): number {
    const n = array.length;
    let sum = 0;
    for (let i = 0; i < array.length; i++) {
        sum += array[i];
    }
    const mean = sum / n;
    return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
}

export function diff(array: number[]): number[] {
    const a: number[] = [];
    for (let i = 1; i < array.length; i++) {
        a.push(array[i] - array[i - 1]);
    }
    return a;
}

export function growth(array: number[], frequency?: number): number[] {
    const a: number[] = [];
    if (frequency == null) {
        frequency = 1;
    }
    for (let i = array.length - 1; i >= 0; i--) {
        if ((i % frequency === 0 || i == array.length - 1) && i - frequency >= 0) {
            try {
                a.unshift((array[i] - array[i - frequency]) / array[i - frequency]);
            } catch (error) {
                // empty
            }
        }
    }
    return a;
}

export function growthSMA(array: number[], frequency?: number): number[] {
    const a: number[] = [];
    if (frequency == null) {
        frequency = 1;
    }
    for (let i = array.length - 1; i >= 0; i--) {
        try {
            a.unshift((array[i] - array[i - frequency]) / array[i - frequency]);
        } catch (error) {
            // empty
        }
    }
    return a;
}

export function reverseGrowth(originalSeries: number[], transformedSeries: number[]): number[] {
    const reversed: number[] = [];
    for (let i = transformedSeries.length - 1; i >= 0; i--) {
        try {
            const prevValue = transformedSeries[i] * originalSeries[i] + originalSeries[i];
            reversed.unshift(prevValue);
        } catch (error) {
            // empty
        }
    }
    return reversed;
}

export function growthObject(array: TimeSeriesHelperValue[], field: string, frequency?: number): number[] {
    const a: number[] = [];
    if (frequency == null) {
        frequency = 1;
    }
    for (let i = array.length - 1; i >= 0; i--) {
        if (i % frequency === 0 || i == array.length - 1) {
            try {
                a.unshift((array[i][field] - array[i - frequency][field]) / array[i - frequency][field]);
            } catch (error) {
                // empty
            }
        }
    }
    return a;
}

export function seq_along(i: number): number[] {
    return Array.from(Array(i).keys());
}

export function linearRegression(x: number[], y: number[]): number[] {
    const n = x.length;
    let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0;

    for (let i = 0; i < n; i++) {
        sumX += x[i];
        sumY += y[i];
        sumXY += x[i] * y[i];
        sumX2 += x[i] * x[i];
    }

    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;

    return [ slope, intercept ];
}

export interface LinearRegressionResult {
    slope: number;
    intercept: number;
    pValueIntercept: number;
    pValueSlope: number; // Added p-value for the slope
    rSquared: number;
    stdErrorIntercept: number; // Added standard error for the intercept
    stdErrorSlope: number; // Added standard error for the slope
}

export function linearRegressionWithPValue(x: number[], y: number[]): LinearRegressionResult {
    const n = x.length;
  
    const xMean = x.reduce((sum, xi) => sum + xi, 0) / n;
    const yMean = y.reduce((sum, yi) => sum + yi, 0) / n;
  
    let numerator = 0;
    let denominator = 0;
  
    for (let i = 0; i < n; i++) {
        numerator += (x[i] - xMean) * (y[i] - yMean);
        denominator += (x[i] - xMean) ** 2;
    }
  
    const slope = numerator / denominator;
    const intercept = yMean - slope * xMean;

    const residuals = y.map((yi, index) => yi - (slope * x[index] + intercept));

    const df = n - 2; 

    const residualStandardError = Math.sqrt(
        residuals.reduce((sum, residual) => sum + Math.pow(residual, 2), 0) / df
    );
  
    // Standard Error of the Slope (stdErrorSlope)
    const stdErrorSlope = residualStandardError / Math.sqrt(denominator);

    // Standard Error of the Intercept (stdErrorIntercept)
    const sumXsquared = x.reduce((sum, xi) => sum + xi ** 2, 0);
    const stdErrorIntercept = residualStandardError * Math.sqrt(sumXsquared / (n * denominator));

    // T-statistics for slope and intercept
    const tStatisticSlope = slope / stdErrorSlope;
    const tStatisticIntercept = intercept / stdErrorIntercept;

    // Assuming tDistributionCDF is defined elsewhere to calculate the CDF for the t-distribution
    const pValueSlope = 2 * (1 - tDistributionCDF(Math.abs(tStatisticSlope), df));
    const pValueIntercept = 2 * (1 - tDistributionCDF(Math.abs(tStatisticIntercept), df));

    const tss = y.reduce((acc, cur) => acc + Math.pow(cur - yMean, 2), 0);
    const rss = residuals.reduce((acc, cur) => acc + Math.pow(cur, 2), 0);

    const rSquared = 1 - rss / tss;

    return {
        slope,
        intercept,
        pValueIntercept,
        pValueSlope,
        rSquared,
        stdErrorIntercept,
        stdErrorSlope,
    };
}
  
// Cumulative distribution function for the t-distribution
function tDistributionCDF(t: number, df: number): number {
    const x = t * t / (t * t + df);
    let a = x;
    let s = x;
    for (let i = 1; i < df / 2; ++i) {
        a *= x;
        s += a;
    }
    return 1 - 0.5 * s;
}

// write median function
export function median(values: number[]): number {
    values.sort((a, b) => a - b);
    const half = Math.floor(values.length / 2);
    if (values.length % 2) {
        return values[half];
    }
    return (values[half - 1] + values[half]) / 2.0;
}
