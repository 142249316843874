import mongoose from "mongoose";
const Schema = mongoose.Schema;

const newssentimentsSchema = new Schema({
    symbol: String,
    sentiment: Number,
    finacialSentiment: Number,
    date: String,
});

export const newssentiments = mongoose.model("newssentiments", newssentimentsSchema);

// do class model
export class NewsSentiment {
    symbol: string;
    sentiment: number;
    date: string;
}

export class FinancialSentiment {
    symbol: string;
    finacialSentiment: number;
    date: string;
}
