import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Button, Container, Divider, Flex, Grid, HStack, Heading, Menu, MenuButton, MenuItem, MenuList, Textarea, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { MathJax } from 'better-react-mathjax';
import { MultiSelect, useMultiSelect } from 'chakra-multiselect';
import { Select } from 'chakra-react-select';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addSelectionFilterAction } from '../../action-creators/selection-filters/selection-filters.action';
import { Backtest } from '../../backtesting-common-frontend';
import { CompanyProfile } from '../../backtesting-common-frontend/database/R-DB/timeseries/companies';
import { SelectionFilterMenu, SelectionFilterParameter, SelectionFiltersSummaryDTO } from '../../backtesting-common-frontend/filters';
import { updateBacktest } from '../../backtesting-common-frontend/http-utilities/http-utilities/backtests/backtests-backend.service';
import { getCompanyProfiles, getCompanyProfilesByChatGPT } from '../../backtesting-common-frontend/http-utilities/http-utilities/selection-filters/selection-filters-backend.service';
import { updateStrategy } from '../../backtesting-common-frontend/http-utilities/http-utilities/strategy/strategy';
import { MenuCategory } from '../../backtesting-common-frontend/menu/dtos/menu-dtos';
import { TimeSeriesDTO } from '../../backtesting-common-frontend/methods';
import { randomSelect } from '../../backtesting-common-frontend/shared/utilites/array.utilities';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import { StatusDisplayDTO } from '../../backtesting-common-frontend/status/error-handling';
import { StrategyDTO } from '../../backtesting-common-frontend/strategies/strategy';
import ChakraTagInput from '../../components/tags';
import { TimeSeriesResultsManager } from '../../managers/time-series/time-series-manager';
import { addCompanyProfile, setLoading, updateCompanyProfiles, updateMessage } from '../../store/backtests/backtests';
import { AppState } from '../../store/store';
import { updateStrategyStore } from '../../store/strategies/strategy';
import { updateCurrentSettingsTab } from '../../store/tabs/tabs';
import { strategyTab } from '../../store/tabs/tabs-init';
import LazyLoading from '../lazy-loading/lazy-loding';
import InfoTooltip from '../shared/explainer/explainer';
import { ExplainerInfo } from '../shared/explainer/info';
import SelectionFiltersSummary from './selection-filters-summary';

export type MenuType = "method" | "selection-filters";

export class MenuComponentDTO {
    public type: MenuType;
    public menu: MenuCategory[];
}

export class SelectionFilterBackendResult {
    public summarySelectionFilter: SelectionFiltersSummaryDTO;
    public selectionFilterGraph: TimeSeriesDTO;
}

export default function SelectionFilters({ timeSeriesMenu, handleScreeningResults, handleSelectedSelectionFilter }: 
    { timeSeriesMenu: MenuCategory[], handleScreeningResults?: (cp: CompanyProfile[], add: boolean) => void, handleSelectedSelectionFilter?: (selectionFilter: SelectionFilterMenu) => void }) {
    const dispatch = useDispatch();
    const currentReduxStrategy = useSelector(
        (state: AppState) => state.strategies.strategy
    );
    const currentBacktest = useSelector(
        (state: AppState) => state.backtests.backtest
    );
    const backtests = useSelector(
        (state: AppState) => state.backtests.backtests
    );
    const [ selectionFilters, setSelectionFilters ] = useState<MenuCategory[]>([]);
    const [ sampleFilters, setSampleFilters ] = useState<SelectionFilterMenu[]>([]);
    const [ selectedSample, setSelectedSample ] = useState<SelectionFilterMenu | null>(null);
    const [ optionsSelectionFilter, setOptionsSelectionFilter ] = useState<{label: string, value: (SelectionFilterMenu)}[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ selectedCategory, setSelectedCategory ] = useState<MenuCategory | null>(null);
    const [ selectedSelectionFilter, setSelectedSelectionFilter ] = useState<SelectionFilterMenu | null>(null);
    const [ strategy, setStrategy ] = useState<StrategyDTO | undefined>(undefined);
    const [ allIndustries, setUseAllIndustries ] = useState<boolean>(false);
    const [ isCrypto, setIsCrypto ] = useState<boolean>(false);
    const [ backtest, setBacktest ] = useState<Backtest | null>(null);
    const [ allBacktests, setAllBacktests ] = useState<Backtest[]>([]);
    const [ chatGPTPrompt, setChatGPTPrompt ] = useState<string>('');
    const [ tags, setTags ] = useState<string[]>([  ]);
    const { value, options, onChange } = useMultiSelect({
        value: [],
        options: backtests.filter(b => b._id !== currentBacktest?._id && b.computeReturns?.length === 0).map((b) => ({ value: b._id, label: b.name })),
    });

    const handleTagsChange = useCallback((event: SyntheticEvent, tags: string[]) => {
        setTags(tags);
    }, []);

    useEffect(() => {
        setAllBacktests(cloneDeep(backtests));
    }, [ backtests ]);

    useEffect(() => {
        if(currentReduxStrategy) {
            setStrategy(cloneDeep(currentReduxStrategy));
        }
    }, [ currentReduxStrategy ]);

    useEffect(() => {
        if(currentBacktest) {
            setBacktest(cloneDeep(currentBacktest));
        }
    }, [ currentBacktest ]);

    useEffect(() => {
        const validSelectionFilters = cloneDeep(timeSeriesMenu).filter(e => e.category === "industry" || 
        e.category === "symbols" && !handleSelectedSelectionFilter || 
        e.category === "commodities-time-series" && !handleScreeningResults && !handleSelectedSelectionFilter || 
        e.category === "cryptocurrencies-time-series" && !handleScreeningResults && !handleSelectedSelectionFilter ||
        e.category === "indexes-time-series" && !handleScreeningResults && !handleSelectedSelectionFilter ||
        e.category === "ETF");
        const extraSelectionFilter = new SelectionFilterMenu();
        extraSelectionFilter.ID = "description";
        extraSelectionFilter.display = "Description";
        extraSelectionFilter.category = "description";
        extraSelectionFilter.title = "Description";
        const extraMenuCategory = new MenuCategory();
        extraMenuCategory.category = "description";
        extraMenuCategory.categoryTitle = "Description";

        // make a selectionFilter for small, mid and large cap
        const marketCapSelectionFilter = new SelectionFilterMenu();
        marketCapSelectionFilter.ID = "small";
        marketCapSelectionFilter.display = "Small Cap";
        marketCapSelectionFilter.category = "sample";
        marketCapSelectionFilter.title = "Market Cap";
        marketCapSelectionFilter.extras = "small";
        const midCap = new SelectionFilterMenu();
        midCap.ID = "mid";
        midCap.display = "Mid Cap";
        midCap.category = "sample";
        midCap.title = "Market Cap";
        midCap.extras = "mid";
        const largeCap = new SelectionFilterMenu();
        largeCap.ID = "large";
        largeCap.display = "Large Cap";
        largeCap.category = "sample";
        largeCap.title = "Market Cap";
        largeCap.extras = "large";
        const veryLargeCap = new SelectionFilterMenu();
        veryLargeCap.ID = "very large";
        veryLargeCap.display = "Very Large Cap";
        veryLargeCap.category = "sample";
        veryLargeCap.title = "Market Cap";
        veryLargeCap.extras = "very large";
        // For later
        setSampleFilters([ marketCapSelectionFilter, midCap, largeCap, veryLargeCap ]);

        extraMenuCategory.items = [ extraSelectionFilter ];
        validSelectionFilters.push(extraMenuCategory);
        setSelectionFilters(validSelectionFilters);
        if(handleSelectedSelectionFilter != null){
            setSelectedCategory(validSelectionFilters[0]);
        }
    }, [ timeSeriesMenu, handleScreeningResults, handleSelectedSelectionFilter ]);

    useEffect(() => {
        if (selectedCategory && selectedCategory.items.length > 0) {
            const current = selectedCategory;
            const useOptions = current?.items ? current?.items : selectionFilters[0].items;
            if(isCrypto) {
                setOptionsSelectionFilter([]);
            }else if (useOptions && current?.category !== "symbols" && current?.category !== "ETF") {
                const options = useOptions.map((e: SelectionFilterMenu) => {
                    return { label: e.display, value: e };
                });
                setOptionsSelectionFilter(options);
            } else if (current?.category === "symbols") {
                const fake = new SelectionFilterMenu();
                fake.ID = "search-symbols";
                const staticOption = { label: 'Search for a symbol', value: fake };
                setOptionsSelectionFilter([ staticOption ]);
            }else if(current?.category === "ETF"){
                const fake = new SelectionFilterMenu();
                fake.ID = "search-etf";
                const staticOption = { label: 'Search for an ETF', value: fake };
                setOptionsSelectionFilter([ staticOption ]);
            }
        }
    }, [ isCrypto, selectedCategory, selectionFilters ]);

    const addSelectionFilter = useCallback((subCategory: TimeSeriesDTO | SelectionFilterMenu, removeComputeReturn: string[]) => {
        if (!strategy) return;
        return addSelectionFilterAction(dispatch, strategy, subCategory, removeComputeReturn);
    }, [ dispatch, strategy ]);

    const remove = useCallback(async(item: SelectionFilterMenu) => {
        if(!strategy) return;
        const str = strategy;
        str.selectionFilters = str.selectionFilters.filter(x => x != null && JSON.stringify(x) !== JSON.stringify(item));
        const statusDisplay = new StatusDisplayDTO("Successfully removed selection filter", "success");
        dispatch(setLoading(true));
        await updateStrategy(str).finally(() => {
            dispatch(updateMessage(statusDisplay));
            dispatch(setLoading(false));
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addBacktestsSelectionFilter = useCallback(async(par: {value: string, label: string}[]) => {
        const v = par;
        if(allBacktests.length > 0 && backtest != null && strategy != null && strategy.selectionFilters.length > 0){
            for(let i = 0; i < strategy.selectionFilters.length; i++){
                const exists = v.find(e => e.value === strategy.selectionFilters[i].ID);
                if(exists) continue;
                await remove(strategy.selectionFilters[i]);
            }
        }
        if(v.length > 0 && allBacktests.length > 0 && backtest != null){
            const allIds = v.map(e => e.value);
            const b = allBacktests.filter(x => allIds.includes(x._id));
            const s = b.map(e => {
                return createBacktestSelectionFilter(e);
            });
            s.map(e => addSelectionFilter(e, backtest.computeReturns.map(e => e.transformationKey)));
        }
    }, [ addSelectionFilter, allBacktests, backtest, remove, strategy ]);

    useEffect(() => {
        addBacktestsSelectionFilter(value as unknown as {value: string, label: string}[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ value ]);

    const add = async(newItem?: SelectionFilterMenu | undefined | null) => {
        const computeReturnsAlreadyExists = backtest.computeReturns.length > 0;
        if(computeReturnsAlreadyExists){
            const status = new StatusDisplayDTO("Cannot add selection filter, already exists", "error");
            dispatch(updateMessage(status));
            return;
        }
        const useNewItem = newItem || selectedSelectionFilter;
        let computeReturnsTransformationKeys: string[] = [];
        if(selectedCategory.category === "description") {
            const selectionFilter = new SelectionFilterMenu();
            selectionFilter.ID = "description";
            selectionFilter.display = "Description: " + tags.join(', ');
            selectionFilter.category = "description";
            selectionFilter.title = "Description";
            selectionFilter.extras = tags.join(',');
            selectionFilter.isCategorySelectionFilter = true;
            addSelectionFilter(selectionFilter, computeReturnsTransformationKeys);
            const status = new StatusDisplayDTO("Searching for companies...", "info");
            dispatch(updateMessage(status));
            getCompanyProfiles(selectionFilter).then(e => {
                if(handleScreeningResults != null){
                    handleScreeningResults(e, false);
                }
                dispatch(updateCompanyProfiles(e));
            });
            return;
        }else if(useNewItem){
            if(backtest && TimeSeriesResultsManager.isTimeSeries(useNewItem) && (selectedCategory?.category === "commodities-time-series" || selectedCategory?.category === "cryptocurrencies-time-series" || selectedCategory.category === "indexes-time-series")){
                const selectionFilterAlreadyExists = strategy?.selectionFilters?.length > 0;
                if(selectionFilterAlreadyExists){
                    const status = new StatusDisplayDTO("Cannot add selection filter, already exists", "error");
                    dispatch(updateMessage(status));
                    return;
                }
                // check if not duplicate by transformationKey
                const notDuplicate = !backtest.computeReturns.some(x => x.transformationKey === useNewItem.transformationKey);
                if(notDuplicate){
                    backtest.computeReturns = [ ...backtest.computeReturns, useNewItem ];
                    dispatch(setLoading(true));
                    await updateBacktest(backtest);
                    dispatch(setLoading(false));
                }
            }else{
                computeReturnsTransformationKeys = backtest?.computeReturns.map(e => e.transformationKey) || [];
                backtest.computeReturns = [];
                dispatch(setLoading(true));
                await updateBacktest(backtest);
                dispatch(setLoading(false));
            }
            if(handleScreeningResults != null && newItem != null){
                const status = new StatusDisplayDTO("Searching for companies...", "info");
                dispatch(updateMessage(status));
                getCompanyProfiles(useNewItem).then(e => {
                    // check if option symbol is selected
                    if(selectedCategory.category === "symbols" || selectedCategory.category === "ETF") {
                        handleScreeningResults(e, true);
                        dispatch(addCompanyProfile(e[0]));
                    }else{
                        handleScreeningResults(e, false);
                        dispatch(updateCompanyProfiles(e));
                    }
                });
                return;
            }
            // check if duplicate
            if(strategy?.selectionFilters.find(x => x.title === useNewItem.title)) {
                const status = new StatusDisplayDTO("Cannot add selection filter, already exists", "error");
                dispatch(updateMessage(status));
                return;
            }
            addSelectionFilter(useNewItem, computeReturnsTransformationKeys);
        }else{
            const status = new StatusDisplayDTO("Cannot add selection filter, missing values", "error");
            dispatch(updateMessage(status));
        }
    };

    const randomSelect7Industries = async() => {
        if(selectedCategory?.category === "industry"){
            const random = randomSelect(selectedCategory.items, 7);
            const status = new StatusDisplayDTO("Adding 7 random industries...", "info");
            dispatch(updateMessage(status));
            strategy.selectionFilters = random as SelectionFilterMenu[];
            dispatch(updateStrategyStore(strategy));
            dispatch(updateCurrentSettingsTab(strategyTab));
            await updateStrategy(strategy);
        }
    };

    const isOnScreening = handleSelectedSelectionFilter == null && handleScreeningResults != null;

    return (
        <LazyLoading>
            <Container
                minW={'100%'}
                bg={useColorModeValue('white', 'whiteAlpha.100')}
                boxShadow={'xl'}
                rounded={'lg'}
                p={6}>
                {handleSelectedSelectionFilter == null && 
                <ExplainerInfo.Information 
                    title="What is a sample?"
                    text={
                        <div>
                            <p>This is typically an industry, commodity, or index, filtered by your screening methods.</p>
                            <MathJax>
                                {`
                            \\[
                                Y_{t+n}
                            \\]
                            `}
                            </MathJax> 
                            <p>
                            where 
                                <MathJax>
                                    {`
                                \\[
                                    Y
                                \\]
                                `}
                                </MathJax> 
                            is the return of the portfolio. The sample you select will be candidates to be included in the portfolio, where
                                <MathJax>
                                    {`
                                \\[
                                    t
                                \\]
                                `}
                                </MathJax> 
                            is the current time, and
                                <MathJax>
                                    {`
                                \\[
                                    n
                                \\]
                                `}
                                </MathJax> 
                            is the time in the future (the periodicity).
                            </p>
                        </div>
                    }
                />
                }
                <Grid templateColumns={handleSelectedSelectionFilter == null && handleScreeningResults != null ? "repeat(2, 1fr)" : "repeat(1, 1fr)"} gap={6}>
                    {handleSelectedSelectionFilter == null && handleScreeningResults != null && <Box w="100%">
                        <Heading size="md">Search companies</Heading>
                        Using Chat-GPT
                        <HStack spacing={4} mb={3} mt={3}>
                            <Textarea placeholder="Name top 5 biggest companies in NYSE and NASDAQ..." value={chatGPTPrompt} onChange={(e) => {
                                setChatGPTPrompt(e.target.value);
                            }} />
                        </HStack>
                        <Button colorScheme="blue" size="sm" ml="auto" onClick={async() => {
                            if(!chatGPTPrompt) return;
                            const status = new StatusDisplayDTO("Searching for companies...", "info");
                            dispatch(updateMessage(status));
                            dispatch(setLoading(true));
                            setTimeout(() => {
                                getCompanyProfilesByChatGPT(chatGPTPrompt).then(e => {
                                    if(e == null) return;
                                    handleScreeningResults(e, false);
                                    dispatch(updateCompanyProfiles(e));
                                }).finally(() => {
                                    dispatch(setLoading(false));
                                });
                            }, 1000);
                        }}>Search</Button>
                    </Box>}
                    {!isOnScreening && false && <Box width="100%" p={3}>
                        <Box w="100%">
                            <Menu>
                                <Tooltip
                                    label="May run slower if you have a lot of samples."
                                >
                                    <MenuButton as={Button} rounded={0} rightIcon={<ChevronDownIcon />} width={'full'}>
                                        {selectedSample ? selectedSample.display : 'Choose a sample'}
                                    </MenuButton>
                                </Tooltip>
                                <MenuList>
                                    {sampleFilters && sampleFilters.length > 0 && sampleFilters.map((category: SelectionFilterMenu) => {
                                        return <MenuItem key={category.ID} minH='48px' onClick={async() => {
                                            setSelectedSample(category);
                                            // remove old sample
                                            try {
                                                dispatch(setLoading(true));
                                                if(strategy != null){
                                                    strategy.selectionFilters = strategy.selectionFilters.filter(e => e.category !== "sample");
                                                    await updateStrategy(strategy);
                                                }
                                                addSelectionFilter(category, []);
                                            } catch (error) {
                                                // empty
                                            }finally{
                                                dispatch(setLoading(false));
                                            }
                                        }}>
                                            <span>{category.display}</span>
                                        </MenuItem>;
                                    })}
                                </MenuList>
                            </Menu>
                        </Box>
                        <Divider />
                    </Box>
                    }
                    {!isOnScreening && selectedCategory?.category === "industry" && <Box width="100%" p={3}>
                        <Button colorScheme="blue" size="sm" ml="auto" onClick={randomSelect7Industries}>{ 'Add 7 random industries'}</Button>
                    </Box>}
                    <Box w="100%">
                        {handleSelectedSelectionFilter == null && <Box width="100%" p={3}>
                            <Menu>
                                <MenuButton as={Button} rounded={0} rightIcon={<ChevronDownIcon />} width={'full'}>
                                    {selectedCategory ? selectedCategory.categoryTitle : 'Choose category'}
                                </MenuButton>
                                <MenuList>
                                    {selectionFilters && selectionFilters.length > 0 && selectionFilters.map((category: MenuCategory) => {
                                        return <MenuItem key={category.categoryTitle} minH='48px' onClick={() => {
                                            setSelectedCategory(category);
                                            if(category.category === "cryptocurrencies-time-series") {
                                                setIsCrypto(true);
                                            }else{
                                                setIsCrypto(false);
                                            }
                                        }}>
                                            <span>{category.categoryTitle}</span>
                                        </MenuItem>;
                                    })}
                                </MenuList>
                            </Menu>
                        </Box>}
                        {selectedCategory?.category === "description" && <Box width="100%" p={3}>
                            <ChakraTagInput
                                tags={tags}
                                placeholder='Add tags (hit enter to add)'
                                onTagsChange={handleTagsChange}
                                wrapProps={{ direction: 'column', align: 'start' }}
                                wrapItemProps={(isInput) => (isInput ? { alignSelf: 'stretch' } as any : undefined)}
                            />
                        </Box>}
                        {selectedCategory?.category !== "description" && <Box width="100%" p={3}>
                            <Select variant="filled" 
                                isDisabled={allIndustries}
                                onInputChange={(value) => {
                                    if((selectedCategory?.category === "symbols" || isCrypto || selectedCategory?.category === "ETF") && value.length >= 2) {
                                        const symbols = selectedCategory.items.filter(e => isCrypto ? e.display.toLowerCase().includes(value.toLowerCase()) : e.ID != null && e?.ID.toLowerCase().includes(value.toLowerCase()));
                                        const options = symbols.map((e: SelectionFilterMenu) => {
                                            return { label: e.display, value: e };
                                        });
                                        setOptionsSelectionFilter(options);
                                    }
                                }}
                                options={optionsSelectionFilter} placeholder='Choose a Sample' onChange={(value) => {
                                    setSelectedSelectionFilter((value as any).value);
                                    if(handleSelectedSelectionFilter){
                                        if(strategy?.selectionFilters.find(x => x.ID === (value as any).value.ID)) {
                                            const status = new StatusDisplayDTO("Cannot add selection filter, already exists", "error");
                                            dispatch(updateMessage(status));
                                            return;
                                        }
                                        addSelectionFilter((value as any).value, []);
                                    }else{
                                        add((value as any).value as SelectionFilterMenu);
                                    }
                                }} />
                        </Box>}
                        {handleSelectedSelectionFilter == null && (handleScreeningResults != null || selectedCategory?.category === "description") && <Box width="100%"  p={3}>
                            <Flex>
                                <Button colorScheme="green" size="sm" ml="auto" onClick={() => add(null)}>{ 'Add & view sample'}</Button>
                            </Flex>
                        </Box>}
                        {!isOnScreening && <Box width="100%" p={3}>
                            <InfoTooltip text={'It will follow the same buying strategy as the backtests you have selected. The selected backtests provide samples of what was purchased for every time unit, which are intersected by the samples from the new backtest.'} />
                            <Heading size="md">Backtests:</Heading>
                            <p>Same sample per time unit</p>
                            <MultiSelect
                                options={options}
                                value={value}
                                label='Choose sample from backtest'
                                onChange={onChange}
                                create
                            />
                        </Box>}
                        <Divider />
                        <Box width="100%" p={3}>
                            <SelectionFiltersSummary />
                        </Box>
                    </Box>
                </Grid>
            </Container>
        </LazyLoading>
    );
}

export function createBacktestSelectionFilter(e: Backtest) {
    const selectionFilter = new SelectionFilterMenu();
    selectionFilter.ID = e._id;
    selectionFilter.display = e.name;
    selectionFilter.category = "backtest";
    selectionFilter.title = e.name;
    selectionFilter.extras = e._id;
    const parameter = new SelectionFilterParameter();
    parameter.historicalPortfolios = e.historicalPortfolios;
    selectionFilter.parameters = parameter;
    return selectionFilter;
}

