const theBlueColor = '#3F8BD6';
const theGreenColor = '#2C974B';
const theRedColor = '#8f0a29';
const theGrayBackgroundColor = 'white';

export class ColorsLight {
    public main = 'white';
    public bg1: string = theGrayBackgroundColor;
    public bg2 = '#F0F2F4';
    public blue: string = theBlueColor;
    public darkBlue = '#26447a';
    public green: string = theGreenColor;
    public red: string = theRedColor;
    public gray = '#adbac7';
    public topTabHeaderBg = '#3c4147';
    public white = '#cdd9e5';
    public realWhite = 'white';
    public borderColor = 'rgba(84,174,255,0.4)';
    public blueLight = '#DDF4FF';
    public borderGray = '#d0d7de';
    public grayLight = '#F6F8FA';
    public black = '#24292f';
    public secondGray = '#f6f8fa';
    public borderGraySecond = 'rgba(27,31,36,0.15)';
    public heavyGray = '#2a2e32';
    public subBlue = '#0349B4';
    public yellow = 'rgb(224, 191, 130)';
    public iconGray = '#707070';
    public simpleGray = '#F2F2F2';
}

export class ColorsDark {
    public main = '#22272E';
    public bg1 = '#30353D';
    public bg2 = '#363b42';
    public blue = '#539BF5';
    public darkBlue = '#26447a';
    public green: string = theGreenColor;
    public red: string = theRedColor;
    public gray = '#adbac7';
    public topTabHeaderBg = '#3c4147';
    public white = '#cdd9e5';
    public realWhite = 'white';
    public borderColor = 'rgba(84,174,255,0.4)';
    public blueLight = '#DDF4FF';
    public borderGray = '#d0d7de';
    public grayLight = '#F6F8FA';
    public black = '#24292f';
    public secondGray = '#f6f8fa';
    public borderGraySecond = 'rgba(27,31,36,0.15)';
    public heavyGray = '#2a2e32';
    public subBlue = '#0349B4';
    public yellow = 'rgb(224, 191, 130)';
    public iconGray = '#707070';
    public simpleGray = '#F2F2F2';
}

export type ThemeType = 'dark' | 'light';