import { ChakraProvider, ColorModeProvider, extendTheme } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { MathJaxContext } from 'better-react-mathjax';
import { MultiSelectTheme } from 'chakra-multiselect';
import { Provider } from 'react-redux';
import Navigation from './navigation';
import store from './store/store';

const theme = extendTheme({
    components: {
        MultiSelect: MultiSelectTheme,
    },
    breakpoints: {
        sm: '320px',
        md: '760px',
        lg: '766px',
        xl: '800px',
        '2xl': '826px',
    },
    
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <MathJaxContext>
        <ChakraProvider theme={theme}>
            <ColorModeProvider 
                options={{
                    initialColorMode: 'dark',
                }}>
                <Provider store={store}>
                    <React.StrictMode>
                        <Navigation />
                    </React.StrictMode>
                </Provider>
            </ColorModeProvider>
        </ChakraProvider>
    </MathJaxContext>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
