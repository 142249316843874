export class Tab {
    public Name: string | undefined;
    public Id: tabType | undefined;
    public isActive: boolean | undefined;
    public Image: string | undefined;
    public Icon: JSX.Element | undefined;
    public meta: any;

    constructor(name?: string, id?: tabType, isActive?: boolean, image?: string, icon?: JSX.Element) {
        this.Name = name;
        this.Id = id;
        this.isActive = isActive;
        this.Image = image;
        this.Icon = icon;
    }
}

export type tabType = 'selected-time-series' | 'selection-filters' | 'theory-tab' | 'bivariate-tab' |
    'forecast-tab' | 'ml-tab' | 'close-position-tab' | 'play-mode' | 'edit-method' |
    'market-conditions' | 'probabilty-model' | 'settings-tab' | 'my-time-series' | 'risk-tab'
    | 'my-methods' | 'select-time-series' | 'strategy-tab' | 'portfolio-tab' | 'probabilty-model'
    | 'main-model' | 'probability-models' | 'market-conditions' | 'forecast-betas' | 'theories' | 'univariate-forecasts'
    | 'previous-value' | 'average' | number | 'week' | 'month' | 'day' | 'google-trends' | 'upload-tab'
    | 'date-settings' | 'api-methods' | 'preview' | 'distribution' | 'visualisation'
    | 'modern-portfolio-tab' | 'transformations-tab' | 'screening';