import mongoose from "mongoose";
const Schema = mongoose.Schema;

const datesSchema = new Schema({
    step: Number,
    last: Boolean,
    label: String
});

const dates = mongoose.model("dates", datesSchema);

export default dates;
