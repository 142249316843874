import { io } from "socket.io-client";
import { env } from '../backtesting-common-frontend/env';

export class GlobalSocket {
    public socket: any = null;

    public renderSocket = async(clientId: string) => {
        this.socket = io(env.socketUrl, { query: { "clientId": clientId } });
    };
}

export const socket = new GlobalSocket();