import { Box, List, ListItem, Text } from "@chakra-ui/react";
import { MathJax } from 'better-react-mathjax';
import React from "react";
import { useSelector } from "react-redux";
import { ImpreemMethodDTO } from "../../backtesting-common-frontend/methods";
import { AppState } from "../../store/store";

export const ModelTable = () => {
    const strategy = useSelector((state: AppState) => state.strategies.strategy);
    const [  y, setY ] = React.useState<string>("");
    const [ x, setX ] = React.useState<string>("");
    const [ xCoefficients, setXCoefficients ] = React.useState<ImpreemMethodDTO[]>([]);

    React.useEffect(() => {
        if(strategy){
            const methods = strategy.intersection.filter(x => x.methodKey !== "SelectionCriteria").filter(x => x.active);
            setXCoefficients(methods);
            const selectionFilters = strategy.selectionFilters;
            const selectionCriteria = strategy.intersection.filter(x => x.methodKey === "SelectionCriteria");
            const Y = selectionFilters.filter(x => x.active).map(s => s.display).concat(selectionCriteria.filter(x => x.active).map(s => s.display)).join(", ");
            const x = methods.map((m, i) => "D" + (i + 1) + " (" + m.display + ")").join(" + ");
            setX(x);
            setY(Y);
        }
    }, [ strategy ]);

    function removeComma(str: string) {
        if (str.endsWith(', ')) {
            return str.slice(0, -2);
        }
        return str;
    }

    return (
        <>
            <Box bg="white.500" p={5} borderRadius="md" boxShadow="sm">
                <Box p={5} borderWidth="1px" borderRadius="lg" mb={4}>
                    <Text fontSize="xl" mb={2}>
            Regression Model
                    </Text>
                    <MathJax>
                        {`
                \\[
                    R^{a_i}_{\\scriptsize\\substack{t+n}} = (D1_t*D2_t...) * \\beta_t
                \\]
            `}
                    </MathJax>
                    <Text mt={4} mb={2} fontSize="lg">
            Where:
                    </Text>
                    <List spacing={2}>
                        <ListItem>
                            <Text>
                                <MathJax inline>{"\\(R^{a_i}_{\\scriptsize\\substack{t+n}}\\)"}</MathJax>: The future return of asset <MathJax inline>{"\\(a\\)"}</MathJax> at time <MathJax inline>{"\\(t+n\\)"}</MathJax>.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <MathJax inline>{"\\(D_1, D_2, D_3\\)"}</MathJax>: Binary dummy variables (0 or 1) representing different categorical influences.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <MathJax inline>{"\\(\\beta_t\\)"}</MathJax>: Coefficient at time <MathJax inline>{"\\(t\\)"}</MathJax> quantifying the influence of the variables.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                    The portfolio return is the average of each asset's return:
                            </Text>
                            <MathJax>
                                {`
                        \\[
                            P_{t+n} = \\frac{1}{N} \\sum_{a=1}^{N} R^{a_i}_{\\scriptsize\\substack{t+n}}
                        \\]
                    `}
                            </MathJax>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <MathJax inline>{"\\(P_{t+n}\\)"}</MathJax>: The portfolio return at time <MathJax inline>{"\\(t+n\\)"}</MathJax>, which is the average return of all assets in the portfolio.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <MathJax inline>{"\\(n\\)"}</MathJax>: The periodicity, representing the future time period (e.g., days, weeks, months).
                            </Text>
                        </ListItem>
                    </List>
                </Box>
                <Box p={5} borderWidth="1px" borderRadius="lg" mb={4}>
                    <Text fontSize="xl" mb={2}>
            Explanatory Variables
                    </Text>
                    <Text>
            Your methods are explanatory variables, which are dummy variables that either occurred or did not occur, represented as 1 or 0. These explanatory variables are applied to all assets' regression models to calculate their future returns.
                    </Text>
                    <MathJax>
                        {`
                \\[
                    D_{it}
                \\]
            `}
                    </MathJax>
                    <Text mt={4} mb={2} fontSize="lg">
            Where:
                    </Text>
                    <List spacing={2}>
                        <ListItem>
                            <Text>
                                <MathJax inline>{"\\(D_{it}\\)"}</MathJax>: Represents the binary dummy variables (0 or 1) indicating different categorical influences.
                                {xCoefficients.length > 0 && (
                                    xCoefficients.map((m, i) => (
                                        <Text key={i}>
                                            <MathJax inline>{"\\(D" + (i + 1) + "\\)"}</MathJax>: {m.display}
                                        </Text>
                                    ))
                                )}
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <MathJax inline>{"\\(t\\)"}</MathJax>: A time index representing the day, week, or month when the variable is measured.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                    Essentially, <MathJax inline>{"\\(t+1\\)"}</MathJax> refers to the next step (day, week, or month) when the selling decision was executed and the next buying decision is made.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                    When displaying the values in the graph, we only use the observations when all explanatory variables are 1. You could potentially add the intercept.
                            </Text>
                        </ListItem>
                    </List>
                </Box>
                <Box p={5} borderWidth="1px" borderRadius="lg" mb={4}>
                    <Text fontSize="xl" mb={2}>
            Strategy Simulation
                    </Text>
                    <Text>
            Using the historical data, you apply your strategy to calculate the portfolio returns at each time step. The backtest calculates returns based on the defined sample and explanatory variables, and evaluates the performance metrics such as return, risk, Sharpe ratio, etc.
                    </Text>
                </Box>
            </Box>

        </>
    );
};