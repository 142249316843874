import axios from "axios";
import { env } from "../../../env";
import { AuthResponse } from "../../auth/auth-http-utilities";

export async function sendInsightsData(id: string | number, client: AuthResponse): Promise<void> {
    try {
    // Sending a POST request to the specified URL with the data
        await axios.post(env.homePage +  'api/insights', { id, location: "app", clientId: client.clientId });
    // No return statement needed, function returns void
    } catch (error) {
    // Handle any errors here
        console.error('Error sending insights data:', error);
    }
}