import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false,
});

const middleware = [ ...getDefaultMiddleware(), ...customizedMiddleware, thunk ];

const store = configureStore({
    reducer: rootReducer,
    middleware,
});

export type AppState = ReturnType<typeof rootReducer>;

export default store;