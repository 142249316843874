/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Divider, Grid, GridItem, Heading, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { isBacktestLicense } from "../../backtesting-common-frontend/http-utilities/auth/auth-http-utilities";
import { TimeSeriesDTO } from "../../backtesting-common-frontend/methods";
import LineChartPretty from "../../components/graphs/line-chart-pretty";
import { NavItem } from "../../components/shared/sidebar/sidebar";
import SelectedTimeSeries from "../../components/time-series/selected-time-series";
import HandleTransformationSettingsComponent from "../../components/transformations/handle-transformation-settings";
import { TimeSeriesResultsManager } from "../../managers/time-series/time-series-manager";
import { AppState } from "../../store/store";
import { updateNavigationTo } from "../../store/tabs/tabs";

export default function TransformationScreen() {
    const dispatch = useDispatch();
    const timeSeriesMenu = useSelector(
        (state: AppState) => state.backtests.menuCategory
    );
    const currentBacktestCtx = useSelector(
        (state: AppState) => state.backtests.backtest
    );
    const graphItemsCtx = useSelector(
        (state: AppState) => state.graphItems?.graphItems
    );
    const editActive = useSelector(
        (state: AppState) => state.graphItems.editActive
    );
    const [ currentEditTimeSeries, setCurrentEditTimeSeries ] = React.useState<TimeSeriesDTO[] | null>([]);
    const [ currentName, setCurrentName ] = useState<string | null>(null);
    const [ timeSeriesManager ] = React.useState<TimeSeriesResultsManager>(new TimeSeriesResultsManager());

    useEffect(() => {
        if (currentBacktestCtx) {
            const activeGraphItem = timeSeriesManager.getEditGraphItems(graphItemsCtx, editActive);
            if (activeGraphItem) {
                const toAdd = [ activeGraphItem.timeSeries ];
                if (activeGraphItem?.extras?.length > 0) {
                    toAdd.push(activeGraphItem.extras[0]);
                }
                const name = "Edit: " + toAdd[0].display;
                setCurrentName(name);
                setCurrentEditTimeSeries(toAdd);
            } else {
                setCurrentEditTimeSeries(null);
            }
        }
    }, [ currentBacktestCtx, editActive, graphItemsCtx, timeSeriesManager ]);

    return (
        <>
            <Grid
                templateColumns={{ base: '1fr', md: '7% 20% 53% 20%' }}
                height={'100vh'}
            >
                <GridItem colSpan={1} bg={useColorModeValue('gray.300', '#292C31')} zIndex={3}>
                    <Box
                        justifyContent={'center'} alignItems={'center'}
                        transition="3s ease"
                        bg={useColorModeValue('gray.300', '#292C31')}
                        borderRight="1px"
                        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
                        w={{ base: 'full', md: 'full' }}
                        h="full">
                        <NavItem icon={<FaArrowLeft size={35} />} width={200} preventHover={false} onClick={() => {
                            if(!isBacktestLicense()){
                                return dispatch(updateNavigationTo("/"));
                            }
                            dispatch(updateNavigationTo("/backtesting"));
                        }}>
                            <Heading size="md" ml="2" justifyContent={'center'} alignItems={'center'} paddingLeft={5}>
                                        Back
                            </Heading>
                        </NavItem>
                        <Divider />
                    </Box>
                </GridItem>
                <GridItem colSpan={1} bg={useColorModeValue('gray.300', '#292C31')} zIndex={2} borderRight="1px"
                    borderRightColor={useColorModeValue('gray.200', 'gray.700')}>
                    <HandleTransformationSettingsComponent />
                </GridItem>
                <GridItem colSpan={1} bg={useColorModeValue('white', '#171719')} zIndex={1}>
                    {currentEditTimeSeries && <Grid
                        templateColumns={{ base: '1fr', md: currentEditTimeSeries?.length > 1 ? '50% 50%' : '100%' }}
                        height={'100vh'}
                    >
                        {currentEditTimeSeries && currentEditTimeSeries.map((e, i) => {
                            return <GridItem key={i + "edit"}>
                                <Heading size="md" ml="2" justifyContent={'center'} alignItems={'center'} paddingTop={5}>
                                    {i === 0 ? currentEditTimeSeries.length > 1 ?  "Transformation" : "Original data" : "Original data"}
                                    <br></br>
                                    {currentEditTimeSeries[0].display}
                                </Heading>
                                <LineChartPretty TimeSeriesDTOs={[ e ]} index={i} shouldDisplayPreviewMethod={false} />
                            </GridItem>;
                        })}
                    </Grid>}
                </GridItem>
                <GridItem colSpan={1} bg={useColorModeValue('gray.300', '#292C31')} borderLeft="1px"
                    borderLeftColor={useColorModeValue('gray.200', 'gray.700')}>
                    <Heading size="md" ml="2" justifyContent={'center'} alignItems={'center'} paddingTop={5}>
                        Selected time series
                    </Heading>
                    <SelectedTimeSeries />
                </GridItem>
            </Grid>
        </>
    );
}