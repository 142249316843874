import mongoose from "mongoose";
const Schema = mongoose.Schema;

const newsmentionsSchema = new Schema({
    symbol: String,
    mentions: Number,
    date: String,
    step: Number,
});

export const newsmentions = mongoose.model("newsmentions", newsmentionsSchema);

// do class model
export class NewsMentions {
    symbol: string;
    mentions: number;
    date: string;
    step: number;
}
