import moment from "moment";
import { TimeSeriesDTO } from "../methods";
import { tryParseJSONObject } from "../shared/utilites/array.utilities";
import { TimeSeriesHelperValue } from "../timeseries/timeseries-models";
import googleTrends from "./google-trends-api/google-trends-api/src/index";
import GoogleTrendsMapper from "./google-trends.mapper";
import Categories from "./modules/entities/categories.json";
import Countries from "./modules/entities/countries.json";
import { CategoryDTO, CountryDTO, GoogleTrendsDTO } from "./modules/entities/google-trends-dto";

export class GoogleTrendsReader {

    private _Mapper: GoogleTrendsMapper = new GoogleTrendsMapper();

    private key = "googletrends-";

    private prefixTransformationKey = "none-" + this.key;

    public async categories(autoComplete: string = null) {
        const res = await this.loopCategories(autoComplete, Categories.children);
        const all = new CategoryDTO();
        all.id = 0;
        all.name = "All";
        res.unshift(all);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return res;
    }

    public countries() {
        try {
            const companies = new Array<CountryDTO>();
            for (let i = 0; i < Countries.length; i++) {
                const company = new CountryDTO();
                company.name = Countries[i].name;
                company.code = Countries[i].code;
                companies.push(company);
            }
            const worldWide = new CountryDTO;
            worldWide.name = "Worldwide";
            worldWide.code = "";
            companies.unshift(worldWide);
            return companies;
        } catch (error) {
            return null;
        }
    }

    public async interestOverTimeWeekly(dto: GoogleTrendsDTO) {
        const endDates = this.getThreeDates();
        const startDates = this.getThreeDates(new Date(endDates[2].getTime() - 5 * 365 * 24 * 60 * 60 * 1000));
        const firstPeriod: GoogleTrendsDTO = { ...dto, startTime: startDates[0], endTime: endDates[0] };
        const secondPeriod: GoogleTrendsDTO = { ...dto, startTime: startDates[1], endTime: endDates[1] };
        const thirdPeriod: GoogleTrendsDTO = { ...dto, startTime: startDates[2], endTime: endDates[2] };
        const promise1 = this.interestOverTimeCompareRaw(firstPeriod);
        const promise2 = this.interestOverTimeCompareRaw(secondPeriod);
        const promise3 = this.interestOverTimeCompareRaw(thirdPeriod);
        if (dto.startTime) {
            dto.startTime = new Date(dto.startTime);
        }
        dto.endTime = new Date();
        const promise4 = this.interestOverTimeCompareRaw(dto);
        const n = await Promise.all([promise1, promise2, promise3, promise4]);
        const ts1 = this._Mapper.MapperTimeSeriesDTOWeeklyCompare(n[0]);
        const ts2 = this._Mapper.MapperTimeSeriesDTOWeeklyCompare(n[1]);
        const ts3 = this._Mapper.MapperTimeSeriesDTOWeeklyCompare(n[2]);
        const total = this._Mapper.MapperTimeSeriesDTOCompare(n[3]);

        const tsDTO = this.convertToTimeSeriesDTO(ts1, dto.keyword[0], dto.keyword[1]);
        const tsDTO2 = this.convertToTimeSeriesDTO(ts2, dto.keyword[0], dto.keyword[1]);
        const tsDTO3 = this.convertToTimeSeriesDTO(ts3, dto.keyword[0], dto.keyword[1]);
        const totalDTO = this.convertToTimeSeriesDTO(total, dto.keyword[0], dto.keyword[1]);
        const maxFirstPeriod = [0, 0];
        const maxSecondPeriod = [0, 0];
        const maxThirdPeriod = [0, 0];
        const firstPeriodStartTimeDate = tsDTO[0].graphValue[0].d;
        const firstPeriodEndTimeDate = tsDTO[0].graphValue[tsDTO[0].graphValue.length - 1].d;
        const secondPeriodStartTimeDate = tsDTO2[0].graphValue[0].d;
        const secondPeriodEndTimeDate = tsDTO2[0].graphValue[tsDTO2[0].graphValue.length - 1].d;
        const thirdPeriodStartTimeDate = tsDTO3[0].graphValue[0].d;
        const thirdPeriodEndTimeDate = tsDTO3[0].graphValue[tsDTO3[0].graphValue.length - 1].d;
        totalDTO.forEach((t, i) => {
            t.graphValue.forEach(g => {
                if (g.d.getTime() >= firstPeriodStartTimeDate.getTime() && g.d.getTime() <= firstPeriodEndTimeDate.getTime()) {
                    maxFirstPeriod[i] = Math.max(maxFirstPeriod[i], g.value);
                } else if (g.d.getTime() >= secondPeriodStartTimeDate.getTime() && g.d.getTime() <= secondPeriodEndTimeDate.getTime()) {
                    maxSecondPeriod[i] = Math.max(maxSecondPeriod[i], g.value);
                } else if (g.d.getTime() >= thirdPeriodStartTimeDate.getTime()) {
                    maxThirdPeriod[i] = Math.max(maxThirdPeriod[i], g.value);
                }
            });
        });

        const tsDTOJoined = this.joinTimeSeriesDTO(tsDTO[0], tsDTO2[0], tsDTO3[0]);
        this.convertDataPointsToOriginalScale(tsDTOJoined, maxFirstPeriod[0], maxSecondPeriod[0], maxThirdPeriod[0], firstPeriodEndTimeDate, thirdPeriodStartTimeDate);
        let tsDTOJoined2: TimeSeriesDTO = null;
        if (tsDTO.length > 1) {
            tsDTOJoined2 = this.joinTimeSeriesDTO(tsDTO[1], tsDTO2[1], tsDTO3[1]);
            this.convertDataPointsToOriginalScale(tsDTOJoined2, maxFirstPeriod[1], maxSecondPeriod[1], maxThirdPeriod[1], firstPeriodEndTimeDate, thirdPeriodStartTimeDate);
        }
        return [tsDTOJoined, tsDTOJoined2];
    }

    public interestOverTime(dto: GoogleTrendsDTO) {
        return new Promise<any>((resolve, reject) => {
            googleTrends.interestOverTime(dto)
                .then((results: string) => {
                    try {
                        const json = JSON.parse(results);
                        resolve(json);
                    } catch (e) {
                        reject(e);
                    }
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
    }

    public interestOverTimeCompareRaw(dto: GoogleTrendsDTO) {
        return new Promise<TimeSeriesDTO[]>((resolve, reject) => {
            googleTrends.interestOverTime(dto)
                .then((results: string) => {
                    try {
                        const json = JSON.parse(results);
                        resolve(json);
                    } catch (e) {
                        reject(e);
                    }
                })
                .catch((err: any) => {
                    reject(err);

                });
        });
    }

    public interestOverTimeCompare(dto: GoogleTrendsDTO): Promise<TimeSeriesDTO[] | null> {
        return new Promise<TimeSeriesDTO[] | null>((resolve, reject) => {
            try {
                googleTrends.interestOverTime(dto)
                    .then((results: string) => {
                        try {
                            if (results != null && tryParseJSONObject(results)) {
                                const mapped = this._Mapper.MapperTimeSeriesDTOCompare(JSON.parse(results));
                                const tsDTO = this.convertToTimeSeriesDTO(mapped, dto.keyword[0], dto.keyword[1]);
                                resolve(tsDTO);
                            } else {
                                resolve(null);
                            }
                        } catch (e) {
                            resolve(null);
                        }
                    })
                    .catch((err: any) => {
                        resolve(null);

                    });
            } catch (e) {
                resolve(null);
            }
        });
    }

    public autoComplete(dto: GoogleTrendsDTO) {
        return new Promise<any>((resolve, reject) => {
            googleTrends.autoComplete({ keyword: dto.keyword })
                .then((results: string) => {
                    try {
                        const json = JSON.parse(results);
                        resolve(json);
                    } catch (e) {
                        reject(e);
                    }
                })
                .catch((err: any) => {
                    reject(err);

                });
        });
    }

    public interestByRegion(dto: GoogleTrendsDTO) {
        return new Promise<any>((resolve, reject) => {
            googleTrends.interestByRegion(dto)
                .then((results: string) => {
                    try {
                        const json = JSON.parse(results);
                        resolve(json);
                    } catch (e) {
                        reject(e);
                    }
                })
                .catch((err: any) => {
                    reject(err);

                });
        });
    }

    public relatedQueries(dto: GoogleTrendsDTO) {
        return new Promise<any>((resolve, reject) => {
            googleTrends.relatedQueries(dto)
                .then((results: string) => {
                    try {
                        const json = JSON.parse(results);
                        resolve(json);
                    } catch (e) {
                        reject(e);
                    }
                })
                .catch((err: any) => {

                    reject(err);
                });
        });
    }

    public relatedTopics(dto: GoogleTrendsDTO) {
        return new Promise<any>((resolve, reject) => {
            googleTrends.relatedTopics(dto)
                .then((results: string) => {
                    try {
                        const json = JSON.parse(results);
                        resolve(json);
                    } catch (e) {
                        reject(e);
                    }
                })
                .catch((err: any) => {
                    reject(err);

                });
        });
    }

    /*
     * Helpers
     */
    private async loopCategories(keyword: string, children) {
        try {
            const a = new Array<CategoryDTO>();
            for (let i = 0; i < children.length; i++) {
                if (typeof children[i].children !== "undefined") {
                    const res = await this.loopCategories(keyword, children[i].children);
                    a.push(...res);
                }
                const category = new CategoryDTO();
                category.name = children[i].name;
                category.id = children[i].id;
                a.push(category);
            }
            return a;
        } catch (error) {
            return null;
        }
    }

    private getEndTime(dto: GoogleTrendsDTO) {
        const currentDate = moment(new Date()).startOf("M");
        currentDate.add(-1, "d");
        dto.endTime = currentDate.toDate();
    }

    private getThreeDates(curr: Date = null) {
        const today = curr != null ? curr : new Date();
        const fiveYears = 5 * 365 * 24 * 60 * 60 * 1000; // milliseconds in 5 years
        const moment1 = new Date(today.getTime() - 2 * fiveYears);
        const moment2 = new Date(today.getTime() - fiveYears);
        const moment3 = today;
        return [moment1, moment2, moment3];
    }

    private convertToTimeSeriesDTO(results: any, searchTerm: string, searchTerm1: string) {
        const ts: TimeSeriesDTO[] = [];
        const a: TimeSeriesHelperValue[][] = [];
        if (Array.isArray(results[0])) {
            results.forEach(p => {
                p.forEach((x, i) => {
                    const exists = a.findIndex(f => f[0].transformationKey === this.prefixTransformationKey + searchTerm && i === 0 || f[0].transformationKey === this.prefixTransformationKey + searchTerm1 && i === 1);
                    const graphValue = new TimeSeriesHelperValue();
                    graphValue.date = x.date;
                    graphValue.value = x.value;
                    graphValue.transformationKey = i === 0 ? this.prefixTransformationKey + searchTerm : this.prefixTransformationKey + searchTerm1;
                    graphValue.timeSeriesKey = i === 0 ? this.key + searchTerm : this.key + searchTerm1;
                    graphValue.d = new Date(x.date);
                    graphValue.buy = false;
                    if (exists === -1) {
                        a.push([graphValue]);
                    } else {
                        a[exists].push(graphValue);
                    }
                });
            });
        } else {
            results.forEach((x) => {
                const exists = 0;
                const graphValue = new TimeSeriesHelperValue();
                graphValue.date = x.date;
                graphValue.value = x.value;
                graphValue.transformationKey = this.prefixTransformationKey + searchTerm;
                graphValue.timeSeriesKey = this.key + searchTerm;
                graphValue.d = new Date(x.date);
                graphValue.buy = false;
                if (a.length === 0) {
                    a.push([graphValue]);
                } else {
                    a[exists].push(graphValue);
                }
            });
        }
        a.forEach(t => {
            const timeSeriesDto = new TimeSeriesDTO();
            timeSeriesDto.display = t[0].transformationKey;
            timeSeriesDto.name = t[0].transformationKey;
            timeSeriesDto.graphValue = t;
            ts.push(timeSeriesDto);
        });
        return ts;
    }

    private joinTimeSeriesDTO(ts1: TimeSeriesDTO, ts2: TimeSeriesDTO, ts3: TimeSeriesDTO) {
        const graphValue1 = ts1.graphValue;
        const graphValue2 = ts2.graphValue;
        const graphValue3 = ts3.graphValue;
        const graphValue = [...graphValue1, ...graphValue2, ...graphValue3];
        graphValue.sort((a, b) => a.d.getTime() - b.d.getTime());
        const timeSeriesDto = new TimeSeriesDTO();
        timeSeriesDto.display = ts1.display;
        timeSeriesDto.name = ts1.name;
        timeSeriesDto.graphValue = graphValue;
        return timeSeriesDto;
    }

    private convertDataPointsToOriginalScale(ts: TimeSeriesDTO,
        firstPeriodMax: number,
        secondPeriodMax: number,
        thirdPeriodMax: number,
        firstPeriodEndTimeDate: Date,
        thirdPeriodStartTimeDate: Date) {
        for (let i = 0; i < ts.graphValue.length; i++) {
            const value = ts.graphValue[i];
            if (value.d.getTime() <= firstPeriodEndTimeDate.getTime()) {
                value.value = value.value * (firstPeriodMax / 100);
            } else if (value.d.getTime() >= firstPeriodEndTimeDate.getTime() && value.d.getTime() <= thirdPeriodStartTimeDate.getTime()) {
                value.value = value.value * (secondPeriodMax / 100);
            } else if (value.d.getTime() >= thirdPeriodStartTimeDate.getTime()) {
                value.value = value.value * (thirdPeriodMax / 100);
            }
        }
    }

}
