import mongoose from "mongoose";
import { timeseries } from "../backend/timeseries/timeseries";
import strategies from "./strategies";
import tests from "./tests";
const Schema = mongoose.Schema;

const methodsSchema = new Schema({
    isMultipleSeriesMethod: Boolean,
    frontendId: String,
    timeSeriesGraphResults: Schema.Types.Mixed,
    previewBacktestIds: [ String ],
    comparisionKey: String,
    selectionFilters: [ Schema.Types.Mixed ],
    mlType: String,
    mlMethodType: String,
    methodKey: String,
    id: String,
    key: String,
    name: String,
    display: String,
    parameters: Schema.Types.Mixed,
    category: String,
    subcategoy: String,
    methods: [ { type: Schema.Types.ObjectId, ref: "methods", default: [] } ],
    methodPath: String,
    timeseries: [ { type: Schema.Types.ObjectId, ref: "timeseries", default: [] } ],
    shouldValidate: Boolean,
    active: Boolean,
    editActive: Boolean,
    firstModel: Schema.Types.Mixed,
    secondModel: Schema.Types.Mixed,
    all: String,
    results: Schema.Types.Mixed,
    methodType: String,
    firstModelh5Url: [ String ],
    secondModelh5Url: [ String ],
    date: Date,
    clientId: { type: Schema.Types.ObjectId, ref: "clients", required: true },
    testId: { type: Schema.Types.ObjectId, ref: "tests", required: true },
    inProgress: Boolean,
    apiMethodSettings: Schema.Types.Mixed,
    screeningResults: [ { type: Schema.Types.Mixed, default: [] } ],
});

methodsSchema.pre("findOneAndDelete", async function (next) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const methodId = this["_conditions"]._id;
    await methods.updateMany({ methods: methodId }, { $pull: { methods: methodId } });
    await tests.updateMany({ methods: methodId }, { $pull: { methods: methodId } });
    await strategies.updateMany({ intersection: methodId }, { $pull: { intersection: methodId } });

    const method = await methods.findById(methodId);

    if (!method) {
        // Handle the case where the method document is not found
        return next();
    }

    const timeSeriesIds = method.timeseries;
    await timeseries.findOneAndDelete({ _id: { $in: timeSeriesIds } });
    next();
});

const methods = mongoose.model("methods", methodsSchema);

export default methods;
