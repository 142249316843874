export class NewsWordDTO {
    public keywords: string | string[];
}

export class NewsWord {
    publishedDate: string;
    title: string;
    text: string;
    symbols?: string;
}
