export class ColumnDTO {
    public title: string | JSX.Element | null;
    public value: string | number | JSX.Element | null;
}

export class RowTableDTO {
    public columns: (ColumnDTO | null | undefined)[] = [];
}

export class NonePaddingDTO{
    public rowIndex: number;
    public columnIndex: number;

    // create a constructor
    constructor(rowIndex: number, columnIndex: number) {
        this.rowIndex = rowIndex;
        this.columnIndex = columnIndex;
    }
}