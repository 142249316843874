import { InfoIcon } from '@chakra-ui/icons';
import {
    Box,
    Tooltip,
    useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';

interface InfoTooltipProps {
  text: string;
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({ text }) => {
    return (
        <Tooltip label={text} fontSize="md">
            <Box as="span" ml={2} display="inline-block">
                <InfoIcon color={useColorModeValue('black', 'white')} w={6} h={6} />
            </Box>
        </Tooltip>
    );
};

export default InfoTooltip;
