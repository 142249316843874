// SupportChat.js
import { useState } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import './support.css';

const SupportChat = () => {
    const [ isOpen, setIsOpen ] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="support-chat-container">
            <div className="icon-container" onClick={handleClick}>
                <FaQuestionCircle size={30} />
            </div>
            {isOpen && (
                <div className="message-container">
          Have any question? Contact us at edvardwo.com@gmail.com
                </div>
            )}
        </div>
    );
};

export default SupportChat;
