/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
    Box, Button, ButtonGroup, Container, Divider, Flex,
    Grid, GridItem, HStack, Heading, Input, Menu, MenuButton, MenuItem, MenuList, Popover,
    PopoverBody, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Tab, TabList, TabPanel, TabPanels, Tabs, Tooltip, VStack, useColorModeValue, useDisclosure,
} from "@chakra-ui/react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { Select } from "chakra-react-select";
import moment from "moment";
import React, { SyntheticEvent, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTimeSeries } from "../../action-creators/timeseries/time-series.action";
import { Backtest, GraphItem } from "../../backtesting-common-frontend";
import { SelectionFilterMenu } from "../../backtesting-common-frontend/filters";
import { isBacktestLicense } from "../../backtesting-common-frontend/http-utilities/auth/auth-http-utilities";
import { updateBacktest } from "../../backtesting-common-frontend/http-utilities/http-utilities/backtests/backtests-backend.service";
import { MenuCategory } from "../../backtesting-common-frontend/menu/dtos/menu-dtos";
import { ImpreemMethodDTO, TimeSeriesDTO } from "../../backtesting-common-frontend/methods";
import { downloadExcel } from "../../backtesting-common-frontend/shared/excel/excel-helper";
import { getStandardDeviation, growth, median, round } from "../../backtesting-common-frontend/shared/utilites/math.utilities";
import { cloneDeep } from "../../backtesting-common-frontend/shared/utilites/object.utilities";
import { capitalizeFirstLetter } from "../../backtesting-common-frontend/shared/utilites/string.utilities";
import { average } from "../../backtesting-common-frontend/shared/utilites/transform";
import { StatusDisplayDTO } from "../../backtesting-common-frontend/status/error-handling";
import { NewsWordDTO } from "../../backtesting-common-frontend/timeseries/news-words/news-words";
import { RunBacktestButton } from "../../components/backtests/run-backtest-button";
import { DisplayGraphItems } from "../../components/graphs/graph-item";
import LazyLoading from "../../components/lazy-loading/lazy-loding";
import { ModelTable } from "../../components/regression/model";
import { ExplainerInfo } from "../../components/shared/explainer/info";
import { RenderSidebarContent, SidebarMenu, SidebarType } from "../../components/shared/sidebar/sidebar";
import StrategyTable from "../../components/strategies/strategies";
import SelectIndustry from "../../components/symbols/select-industry";
import SelectSymbols from "../../components/symbols/select-symbols";
import ChakraTagInput from '../../components/tags';
import SelectedTimeSeries from "../../components/time-series/selected-time-series";
import { TimeSeriesResultsManager } from "../../managers/time-series/time-series-manager";
import { setLoading, updateMessage } from "../../store/backtests/backtests";
import { setEditActive } from "../../store/graphitems/graphitems";
import { AppState } from "../../store/store";
import { updateNavigationTo } from "../../store/tabs/tabs";

type metrics = "average" | "median" | "standard-deviation" | "return";

export default function TheoryScreen() {
    const dispatch = useDispatch();
    const timeSeriesMenu = useSelector(
        (state: AppState) => state.backtests.menuCategory
    );
    const currentBacktestCtx = useSelector(
        (state: AppState) => state.backtests.backtest
    );
    const currentBacktestsCtx = useSelector(
        (state: AppState) => state.backtests.backtests
    );
    const graphItemsCtx = useSelector(
        (state: AppState) => state.graphItems?.graphItems
    );
    const theoryActive = useSelector(
        (state: AppState) => state.graphItems.theoryActive
    );
    const selectedSymbols = useSelector(
        (state: AppState) => state.symbols.selectedSymbols
    );
    const selectedIndustry = useSelector(
        (state: AppState) => state.symbols.selectedIndustry
    );
    const { onOpen, onClose, isOpen } = useDisclosure();
    const { onOpen: onOpenAvg, onClose: onCloseAvg, isOpen: isOpenAvg } = useDisclosure();
    const { onOpen: onOpenMedian, onClose: onCloseMedian, isOpen: isOpenMedian } = useDisclosure();
    const { onOpen: onOpenStd, onClose: onCloseStd, isOpen: isOpenStd } = useDisclosure();
    const { onOpen: onOpenReturn, onClose: onCloseReturn, isOpen: isOpenReturn } = useDisclosure();
    const [ selectedCategoryTitle, setSelectedCategoryTitle ] = useState<string | null>(null);
    const [ menu, setMenu ] = useState<MenuCategory[]>([]);
    const [ options, setOptions ] = useState<{label: string, value: (SelectionFilterMenu | TimeSeriesDTO)}[]>([]);
    const [ backtest, setBacktest ] = useState<Backtest | null>(null);
    const [ graphItems, setGraphItems ] = useState<GraphItem[]>([]);
    const [ timeSeriesResultsManager ] = useState<TimeSeriesResultsManager>(new TimeSeriesResultsManager());
    const [ isNYTTimeSeries, setIsNYTTimeSeries ] = useState<boolean>(false);
    const [ searchValue, setSearchValue ] = useState<string>('');
    const [ tags, setTags ] = useState<string[]>([  ]);
    const [ showSidebar, setShowSidebar ] = useState<SidebarType | null>(isBacktestLicense() ? "Sample" : "Growth");
    const [ selectedGraphItemHelper, setSelectedGraphItemHelper ] = useState<GraphItem | null>(null);
    const [ selectedDates, setSelectedDates ] = useState<Date[]>([ new Date(), new Date() ]);
    const [ selectedMetrics, setSelectedMetrics ] = useState<metrics | null>(null);
    const [ metricValue, setMetricValue ] = useState<number | null>(null);
    const [ isCrypto, setIsCrypto ] = useState<boolean>(false);

    const [ isAvgFinancialRatiosRatiosQuarter, setIsAvgFinancialRatiosRatiosQuarter ] = useState<boolean>(false);
    const [ isAvgFinancialRatiosIncomeStatementQuarter, setIsAvgFinancialRatiosIncomeStatementQuarter ] = useState<boolean>(false);

    const handleSidebarClick = (type: SidebarType) => {
        if(type === "AI news searcher"){
            dispatch(updateNavigationTo("/news"));
            return;
        }
        setShowSidebar(type);
    };

    const handleTagsChange = useCallback((event: SyntheticEvent, tags: string[]) => {
        if(!backtest) return;
        setTags(tags);
        backtest.tags = tags;
        dispatch(setLoading(true));
        updateBacktest(backtest)
            .then(() => {
                const status = new StatusDisplayDTO("Tags updated", "success");
                dispatch(updateMessage(status));
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    }, [ backtest, dispatch ]);

    React.useEffect(() => {
        let m = cloneDeep(timeSeriesMenu);
        if(m){
            const NYTMenuCategory = new MenuCategory();
            NYTMenuCategory.categoryTitle = 'News searchterms';
            const fakeTs = new TimeSeriesDTO();
            fakeTs.display = 'News searchterms';
            NYTMenuCategory.items = [ fakeTs ];
            m.unshift(NYTMenuCategory);
            // remove results where categoryTitle are "Sectors", "Industries", "Symbols", "Beta" or "Uploaded"
            const removeCategories = [ "Sectors", "Industries", "Symbols", "Beta", "Uploaded", "ETF" ];
            // do a filter
            m = m.filter((category) => !removeCategories.includes(category.categoryTitle));
            // find m.display === "News" and move it to the top
            const index = m.findIndex((category) => category.categoryTitle === "News");
            if(index !== -1){
                const news = m.splice(index, 1);
                m.unshift(news[0]);
            }
            setMenu(m ?? []);
        }
    }, [ timeSeriesMenu ]);

    React.useEffect(() => {
        if (currentBacktestCtx) {
            const m = cloneDeep(currentBacktestCtx);
            if(m){
                setBacktest(m);
                setTags(m.tags);
            }
        }
    }, [ currentBacktestCtx ]);
    
    React.useEffect(() => {
        if (graphItemsCtx) {
            setGraphItems(graphItemsCtx);
        }
    }, [ graphItemsCtx ]);

    React.useEffect(() => {
        if (selectedCategoryTitle && menu && menu.length > 0) {
            const category = menu.find((category) => category.categoryTitle === selectedCategoryTitle);
            if (category) {
                let isAvgFinancialRatios = false;
                let isAvgFinancialRatiosIncomeStatement = false;
                let crypto = false;
                let isNYT = false;
                if(category.category === "avg-financial-ratios-IncomeStatementQuarter"){
                    isAvgFinancialRatiosIncomeStatement = true;
                }else if(category.category === "avg-financial-ratios-RatiosQuarter"){
                    setIsAvgFinancialRatiosRatiosQuarter(true);
                    isAvgFinancialRatios = true;
                }
                
                if(category.categoryTitle === "Cryptocurrencies"){
                    crypto = true;
                    setOptions([]);
                }else if(category.categoryTitle === 'News searchterms'){
                    isNYT = true;
                }else{
                    setOptions(category.items.map((timeSeries: (SelectionFilterMenu | TimeSeriesDTO)) => {
                        return {
                            label: timeSeries.display,
                            value: timeSeries,
                        };
                    }));
                }

                setIsNYTTimeSeries(isNYT);
                setIsCrypto(crypto);
                setIsAvgFinancialRatiosIncomeStatementQuarter(isAvgFinancialRatiosIncomeStatement);
                setIsAvgFinancialRatiosRatiosQuarter(isAvgFinancialRatios);
            }
        }
    }, [ menu, selectedCategoryTitle ]);

    React.useEffect(() => {
        setSelectedGraphItemHelper(null);
    }, [ theoryActive ]);

    async function handleSearchNYT() {
        if(searchValue && searchValue !== ''){
            const searchTerm = searchValue;
            const dto = new NewsWordDTO();
            dto.keywords = [ searchTerm ];
            
            const item = new TimeSeriesDTO();
            item.parameters = dto;
            item.name = "Article mentions: " + searchTerm;
            item.periodicity = 'day';
            item.collectionInDb = 'newsword';
            item.transformationKey = "none-newsword-" + searchTerm;
            item.timeSeriesKey = "newsword-" + searchTerm;
            item.display = "Article mentions: " + searchTerm;

            getTimeSeries(selectedSymbols, item, timeSeriesResultsManager, dispatch, graphItems, backtest, "theory");
        }
    }

    return (
        <>
            <Grid
                templateColumns={{ base: '1fr', md: '7% 20% 53% 20%' }}
                height={'100vh'}
            >
                <GridItem colSpan={1} bg={useColorModeValue('gray.300', '#292C31')} zIndex={3}>
                    <SidebarMenu handleSidebarClick={handleSidebarClick} showSidebar={showSidebar} />
                </GridItem>
                <GridItem colSpan={1} bg={useColorModeValue('gray.300', '#292C31')} zIndex={2} borderRight="1px"
                    borderRightColor={useColorModeValue('gray.200', 'gray.700')}>
                    <RenderSidebarContent showSidebar={showSidebar} timeSeriesMenu={timeSeriesMenu} />
                </GridItem>
                <GridItem colSpan={1} bg={useColorModeValue('white', '#171719')} zIndex={1}>
                    {renderSelectTimeSeries()}
                    <Container m={4} p={10} justifyContent={'center'} alignItems={'center'} minW={'full'} position={'relative'}>
                        <LazyLoading>
                            <Box>
                                <Tabs isFitted>
                                    <TabList mb="1em">
                                        <Tab>Graph</Tab>
                                        {backtest?.test?.strategies?.[0]?.intersection?.some(x => x.methodKey === "Theory") && <Tab>Explanation</Tab>}
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            <HStack>
                                                {currentBacktestsCtx?.length === 0 && isBacktestLicense() && <Heading size="md" ml="2" justifyContent={'center'} alignItems={'center'} marginTop={5}>
                                    Please create a backtest to start
                                                </Heading>}
                                                <DisplayGraphItems />
                                                {graphRightMenu()}
                                            </HStack>
                                        </TabPanel>
                                        <TabPanel>
                                            <ModelTable />
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Box>
                        </LazyLoading>
                        <Container position={'absolute'} bottom={-10} right={0} left={0} p={3}>
                            <HStack>
                                <RunBacktestButton />
                                <Button  colorScheme="blue" width={'full'} onClick={async( ) => {
                                    dispatch(updateNavigationTo("/screening"));
                                }}>
                                Screening
                                </Button>
                            </HStack>
                        </Container>
                    </Container>
                </GridItem>
                <GridItem colSpan={1} bg={useColorModeValue('gray.300', '#292C31')} borderLeft="1px"
                    borderLeftColor={useColorModeValue('gray.200', 'gray.700')}>
                    <LazyLoading>
                        <>
                            <Container>
                                <HStack  alignItems={"center"} marginTop={5}>
                                    <Heading size="md" ml="2" justifyContent={'center'} alignItems={'center'} marginTop={5}>
                        Selected time series
                                    </Heading>
                                    <Flex  alignItems={"center"} style={{ display: "block" }}>
                                        <Tooltip label="Will download all selected time series" fontSize="md" placement="top">
                                            <Button
                                                size="xs"
                                                colorScheme="teal"
                                                disabled={(theoryActive == null || theoryActive?.length === 0)}
                                                onClick={() => {
                                                    // get all theoryActive
                                                    if(graphItems.length > 0){
                                                        const ts = graphItems.map(e => e.timeSeries);
                                                        ts.forEach(e => {
                                                            downloadExcel(e, e.graphValue);
                                                        });
                                                        const status = new StatusDisplayDTO("Downloading time series", "info");
                                                        dispatch(updateMessage(status));
                                                    }
                                                }}>
            Download all Excel
                                            </Button>
                                        </Tooltip>
                                        <Button 
                                            ml={2}
                                            size="xs"
                                            colorScheme="red" onClick={() => {
                                                // remove all
                                                graphItems.forEach((e) => {
                                                    timeSeriesResultsManager.removeGraphItem(graphItems, e.timeSeries, backtest, false, dispatch);
                                                });
                                            }}>
                            Remove all time series
                                        </Button>
                                    </Flex>
                                </HStack>
                                {graphItems.some(g => TimeSeriesResultsManager.isCompanyTimeSeries(g.timeSeries)) && <ExplainerInfo.Information
                                    title="What is *sample?"
                                    text="*sample, the symbol representing the sample. Methods applied to this time series will add to all asset's model."
                                />
                                }
                            </Container>
                            <SelectedTimeSeries isOnTheory={true} />
                            <StrategyTable />
                            {isBacktestLicense() && <>
                                <Heading size="md" ml="2" justifyContent={'center'} alignItems={'center'} paddingBottom={5}>
                        Add tags to your backtest
                                </Heading>
                                <ChakraTagInput
                                    tags={tags}
                                    placeholder='Add tags (hit enter to add)'
                                    onTagsChange={handleTagsChange}
                                    wrapProps={{ direction: 'column', align: 'start' }}
                                    wrapItemProps={(isInput) => (isInput ? { alignSelf: 'stretch' } as any : undefined)} />
                                <Divider marginTop={5} marginBottom={5} />
                            </>
                            }
                        </>
                    </LazyLoading>
                </GridItem>
            </Grid>
        </>
    );

    function graphRightMenu() {
        const disabled = theoryActive == null || theoryActive?.length === 0;
        return <VStack>
            {theoryActive?.length > 1 ? <Popover
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                placement='right'
                closeOnBlur={false}
            >
                <PopoverTrigger>
                    <Button disabled={disabled} colorScheme={'gray'} size="sm" width={'full'}>Transform</Button>
                </PopoverTrigger>
                <PopoverContent p={5}>
                    <PopoverHeader fontWeight='semibold'>Choose time series</PopoverHeader>
                    <PopoverBody>
                        {rightMenuChooseTimeSeries()}
                    </PopoverBody>
                    <PopoverFooter display='flex' justifyContent='flex-end'>
                        <ButtonGroup size='sm'>
                            <Button variant='outline' onClick={onClose}>Cancel</Button>
                            <Button colorScheme='blue'  onClick={() => {
                                if(selectedGraphItemHelper == null) return;
                                dispatch(setEditActive(selectedGraphItemHelper?._id));
                                dispatch(updateNavigationTo("/transformations"));
                            }}>Choose</Button>
                        </ButtonGroup>
                    </PopoverFooter>
                </PopoverContent>
            </Popover>
                : <Button disabled={disabled} colorScheme={'gray'} size="sm" width={'full'} onClick={() => {
                    if(theoryActive != null && theoryActive.length > 0){
                        dispatch(setEditActive(theoryActive[0]));
                        dispatch(updateNavigationTo("/transformations"));
                    }
                }}>Transform</Button>}
            <Popover
                isOpen={isOpenAvg}
                onOpen={() => {
                    setSelectedMetrics("average");
                    onOpenAvg();
                }}
                onClose={onCloseAvg}
                placement='right'
                closeOnBlur={false}
            >
                <PopoverTrigger>
                    <Button disabled={disabled} colorScheme={'gray'} size="sm" width={'full'}>Average</Button>
                </PopoverTrigger>
                <PopoverContent p={5}>
                    {renderMetricsPopover()}
                </PopoverContent>
            </Popover>
            <Popover
                isOpen={isOpenMedian}
                onOpen={() => {
                    setSelectedMetrics("median");
                    onOpenMedian();
                }}
                onClose={onCloseMedian}
                placement='right'
                closeOnBlur={false}
            >
                <PopoverTrigger>
                    <Button disabled={disabled} colorScheme={'gray'} size="sm" width={'full'} onClick={() => {
                    } }>Median</Button>
                </PopoverTrigger>
                <PopoverContent p={5}>
                    {renderMetricsPopover()}
                </PopoverContent>
            </Popover>
            <Popover
                isOpen={isOpenStd}
                onOpen={() => {
                    setSelectedMetrics("standard-deviation");
                    onOpenStd();
                }}
                onClose={onCloseStd}
                placement='right'
                closeOnBlur={false}
            >
                <PopoverTrigger>
                    <Button disabled={disabled} colorScheme={'gray'} size="sm" width={'full'} onClick={() => {
                    } }>Standard deviation</Button>
                </PopoverTrigger>
                <PopoverContent p={5}>
                    {renderMetricsPopover()}
                </PopoverContent>
            </Popover>
            <Popover
                isOpen={isOpenReturn}
                onOpen={() => {
                    setSelectedMetrics("return");
                    onOpenReturn();
                }}
                onClose={onCloseReturn}
                placement='right'
                closeOnBlur={false}
            >
                <PopoverTrigger>
                    <Button disabled={disabled} colorScheme={'gray'} size="sm" width={'full'}>Average return</Button>
                </PopoverTrigger>
                <PopoverContent p={5}>
                    {renderMetricsPopover()}
                </PopoverContent>
            </Popover>
        </VStack>;
    }

    function renderMetricsPopover() {
        return <>
            <PopoverHeader fontWeight='semibold'>Choose from and to date</PopoverHeader>
            <PopoverBody>
                <RangeDatepicker
                    selectedDates={selectedDates}
                    onDateChange={setSelectedDates} />
                {theoryActive?.length > 1 ? rightMenuChooseTimeSeries() : <><span>Selected: {selectedGraphItemHelper?.timeSeries?.display ?? timeSeriesResultsManager.getGraphTheoryItems(graphItems, theoryActive)?.[0]?.timeSeries?.display}</span></>}
                <Select value={selectedMetrics ? { label: capitalizeFirstLetter(selectedMetrics), value: selectedMetrics as string } : undefined} options={[ "average", "median", "standard-deviation", "return" ].map(e => {
                    return { label: capitalizeFirstLetter(e), value: e };
                })} placeholder='Choose a metric' onChange={(value) => {
                    setSelectedMetrics((value as any).value as metrics);
                } } />
                <Heading size="md" ml="2" justifyContent={'center'} alignItems={'center'} paddingTop={5}>
                    {metricValue != null ? 'Result: ' + round(metricValue, 4) : null}
                </Heading>
            </PopoverBody>
            <PopoverFooter display='flex' justifyContent='flex-end'>
                <ButtonGroup size='sm'>
                    <Button variant='outline' onClick={() => {
                        if(isOpenAvg){
                            onCloseAvg();
                        }
                        if(isOpenMedian){
                            onCloseMedian();
                        }
                        if(isOpenStd){
                            onCloseStd();
                        }
                        if(isOpenReturn){
                            onCloseReturn();
                        }
                    }}>Cancel</Button>
                    <Button colorScheme='blue' onClick={() => {
                        if (selectedMetrics != null) {
                            const ts = selectedGraphItemHelper?.timeSeries?.graphValue ?? timeSeriesResultsManager.getGraphTheoryItems(graphItems, theoryActive)?.[0]?.timeSeries?.graphValue;
                            if (ts == null) return;
                            const datesValues = ts.filter(e => new Date(e.date).getTime() >= selectedDates[0].getTime() && new Date(e.date).getTime() <= selectedDates[1].getTime());
                            if(datesValues.length === 0) {
                                const status = new StatusDisplayDTO("No data in selected date range", "error");
                                dispatch(updateMessage(status));
                                return;
                            }
                            switch (selectedMetrics) {
                            case "average": {
                                const avg = average(datesValues.map(e => e.value));
                                setMetricValue(avg);
                                break;
                            }
                            case "median": {
                                const m = median(datesValues.map(e => e.value));
                                setMetricValue(m);
                                break;
                            }
                            case "standard-deviation": {
                                const std = getStandardDeviation(datesValues.map(e => e.value));
                                setMetricValue(std);
                                break;
                            }
                            case "return": {
                                const R = growth(datesValues.filter(e => moment(e.date).day() !== 0 && moment(e.date).day() !== 6).map(e => e.value));
                                setMetricValue(average(R));
                                break;
                            }
                            default: {
                                break;
                            }
                            }
                        }
                    } }>Apply</Button>
                </ButtonGroup>
            </PopoverFooter>
        </>;
    }

    function rightMenuChooseTimeSeries() {
        return <Menu>
            <MenuButton as={Button} rounded={0} rightIcon={<ChevronDownIcon />} width={'full'}>
                {selectedGraphItemHelper ? selectedGraphItemHelper.timeSeries.display.substring(0, 16) : 'Choose time series'}
            </MenuButton>
            <MenuList>
                {graphItems && graphItems.length > 0 && graphItems.map((category: GraphItem) => {
                    return <MenuItem key={category.timeSeries.display} minH='48px' onClick={() => {
                        setSelectedGraphItemHelper(category);
                    } }>
                        <span>{category.timeSeries.display}</span>
                    </MenuItem>;
                })}
            </MenuList>
        </Menu>;
    }

    function onCategorySelected(category: MenuCategory) {
        setSelectedCategoryTitle(category.categoryTitle);
    }

    function extractTransformationKey(key: string): string {
        const parts = key.split("-");
        return parts[parts.length - 2];
    }

    function renderSelectTimeSeries() {
        return <Container
            maxW={'lg'}
            minW={800}
            bg={'whiteAlpha.100'}
            boxShadow={'xl'}
            rounded={'lg'}
            zIndex={2}
            p={6}>
            <Flex zIndex={2}>
                <Box width="50%">
                    <Menu>
                        <MenuButton as={Button} rounded={0} rightIcon={<ChevronDownIcon />} width={'full'}>
                            {selectedCategoryTitle ? selectedCategoryTitle.substring(0, 16) : 'Choose category'}
                        </MenuButton>
                        <MenuList>
                            {menu && menu.length > 0 && menu.map((category: MenuCategory) => {
                                return <MenuItem key={category.categoryTitle} minH='48px' onClick={() => {
                                    onCategorySelected(category);
                                } }>
                                    <span>{category.categoryTitle}</span>
                                </MenuItem>;
                            })}
                        </MenuList>
                    </Menu>
                </Box>
                <Box width="50%">
                    {isNYTTimeSeries &&
                        <Flex>
                            <Box width="100%">
                                <Input bg={'gray.600'}
                                    type="text"
                                    width={'full'}
                                    placeholder='Search for trends...'
                                    borderColor={'gray.700'}
                                    value={searchValue}
                                    onChange={(event) => {
                                        setSearchValue(event.target.value);
                                    } } />
                            </Box>
                            <Box>
                                <Button
                                    colorScheme={'blue'}
                                    w="100%"
                                    rounded={4}
                                    type={'button'}
                                    onClick={() => {
                                        handleSearchNYT();
                                    } }>
                                    {'Search'}
                                </Button>
                            </Box>
                        </Flex>}
                    {!isNYTTimeSeries && <Select options={options} 
                        onInputChange={(value) => {
                            if(isCrypto && value.length > 3){
                                const category = menu.find((category) => category.categoryTitle === selectedCategoryTitle);
                                setOptions(category?.items.filter((item) => {
                                    return item.display.toLowerCase().includes(value.toLowerCase());
                                }).map((timeSeries: (SelectionFilterMenu | TimeSeriesDTO)) => {
                                    return {
                                        label: timeSeries.display,
                                        value: timeSeries,
                                    };
                                }));
                            }
                        }}
                        placeholder='Choose a time series' onChange={(value) => {
                            const theTs = cloneDeep((value as any).value) as TimeSeriesDTO;
                            const industryMenu = timeSeriesMenu.find((x) => x.category === "industry");
                            if(isAvgFinancialRatiosIncomeStatementQuarter || isAvgFinancialRatiosRatiosQuarter){
                                theTs.ID = (selectedIndustry?.[0]?.title ?? industryMenu.items[0].title) + "-" + extractTransformationKey(theTs.transformationKey);
                                theTs.collectionInDb = "avg-financial-ratios-" + selectedCategoryTitle.replace("Industry", "").replace(/\s/g, '');
                                theTs.transformationKey = "none-" + theTs.collectionInDb + "-" + theTs.ID;
                                theTs.timeSeriesKey = theTs.collectionInDb + "-" + theTs.ID;
                                theTs.display = theTs.display + " " + (selectedIndustry?.[0]?.title ?? industryMenu.items[0].title);
                            }
                            getTimeSeries(selectedSymbols, theTs, timeSeriesResultsManager, dispatch, graphItems, backtest, "theory");
                        } } />}
                </Box>
            </Flex>
            {isNYTTimeSeries &&
                    <>
                        <ExplainerInfo.Information  title="What is news search?" text={<p>
                        Choose a search term, and we will fetch the number of articles mentioning the term from the New York Times from 2010-01-01 to today.
                        </p>} />
                        <ExplainerInfo.Information  title="Valid search terms" text={<p>
                        Companies, company symbols, topics related to various economic, financial, geopolitical, and environmental crises, including policy measures, market phenomena, natural disasters, and sustainability efforts.
                        </p>} />
                    </>}
            {!isAvgFinancialRatiosIncomeStatementQuarter && !isAvgFinancialRatiosRatiosQuarter ? 
                (TimeSeriesResultsManager.isCompanyTimeSeries(menu?.find((category) => category.categoryTitle === selectedCategoryTitle)?.items?.[0] ?? new ImpreemMethodDTO()) ?
                    <SelectSymbols /> : null) : <SelectIndustry />}
        </Container>;
    }
}

