/* eslint-disable @typescript-eslint/no-loop-func */

import { tagsBacktest } from "../../..";
import { sendPost } from "../frontend/http-utilities";

export async function getAllTags(): Promise<tagsBacktest[] | null> {
    return sendPost("tags/get-tags", {}).then(e => {
        return e.data as tagsBacktest[];
    }).catch(() => {
        return null;
    });
}