import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthResponse } from '../../backtesting-common-frontend/http-utilities/auth/auth-http-utilities';

class ClientResults {
    client: AuthResponse | null = null;
    shouldCheckUptade = 0;
}

const initialState: ClientResults = {
    client: null,
    shouldCheckUptade: 0,
};

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        addOrUpdateClient: (state, action: PayloadAction<AuthResponse>) => {
            state.client = action.payload;
        },
        removeClient: (state, _) => {
            state.client = null;
        },
        shouldCheck: (state, action: PayloadAction<number>) => {
            state.shouldCheckUptade = action.payload;
        },
    },
});

export const addOrUpdateClient = clientSlice.actions.addOrUpdateClient;
export const removeClient = clientSlice.actions.removeClient;
export const shouldCheckState = clientSlice.actions.shouldCheck;
export const clientReducer = clientSlice.reducer;