import { SelectionFilterMenu } from "../../backtesting-common-frontend/filters";
import { updateStrategy } from "../../backtesting-common-frontend/http-utilities/http-utilities/strategy/strategy";
import { TimeSeriesDTO } from "../../backtesting-common-frontend/methods";
import { StatusDisplayDTO } from "../../backtesting-common-frontend/status/error-handling";
import { StrategyDTO } from "../../backtesting-common-frontend/strategies/strategy";
import { updateMessage } from "../../store/backtests/backtests";
import { updateStrategyStore } from "../../store/strategies/strategy";
import { updateCurrentSettingsTab } from "../../store/tabs/tabs";
import { strategyTab } from "../../store/tabs/tabs-init";

export async function addSelectionFilterAction(dispatch, strategy: StrategyDTO, subCategory: SelectionFilterMenu | TimeSeriesDTO, removeComputeReturn: string[]) {
    const status = new StatusDisplayDTO("Successfully added selection filter", "success");
    dispatch(updateMessage(status));
    strategy.selectionFilters.push(subCategory as SelectionFilterMenu);
    if (removeComputeReturn.length > 0) {
        strategy.selectionFilters = strategy.selectionFilters.filter(e => !removeComputeReturn.includes(e.transformationKey));
    }
    dispatch(updateStrategyStore(strategy));
    dispatch(updateCurrentSettingsTab(strategyTab));
    await updateStrategy(strategy);
}