import { DeleteIcon } from '@chakra-ui/icons';
import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box,
    Button, Center,
    Container,
    Flex, Grid, GridItem, HStack,
    Input, ListItem, Modal,
    ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    UnorderedList, VStack,
    useDisclosure,
} from '@chakra-ui/react';
import React, { SyntheticEvent, useCallback, useState } from 'react';
import { FaEdit, FaFlag } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, Dispatch } from 'redux'; // Assuming you are using Redux
import { BacktestActions } from '../../action-creators/backtests/backtests.actions';
import { Backtest } from '../../backtesting-common-frontend';
import { deleteBacktest, deleteBelowTtestThreshold, getBacktests, updateBacktest } from '../../backtesting-common-frontend/http-utilities/http-utilities/backtests/backtests-backend.service';
import { round } from '../../backtesting-common-frontend/shared/utilites/math.utilities';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import { StatusDisplayDTO } from '../../backtesting-common-frontend/status/error-handling';
import ChakraTagInput from '../../components/tags';
import { addBacktestQueue, setLoading, updateBacktestsStore, updateMessage } from '../../store/backtests/backtests';
import { AppState } from '../../store/store';
import { ExplainerInfo } from '../shared/explainer/info';
import StatisticalComponent from './statistics-summary/statistical-summary.component';

interface SelectBacktestsProps {
  onOpen?: () => void | undefined;
    onSelect: (backtest: Backtest) => void;
    useNewsBacktests?: boolean;
    selectedBacktests: Backtest[];
    useNiceBacktestDisplay?: boolean;
}

export const SelectBacktests: React.FC<SelectBacktestsProps> = ({
    onOpen,
    onSelect,
    useNewsBacktests,
    selectedBacktests,
    useNiceBacktestDisplay,
}) => {
    const dispatch = useDispatch();
    const backtestCtx = useSelector((state: AppState) => state.backtests.backtest);
    const backtests = useSelector((state: AppState) => state.backtests.backtests);
    const backtestQueue = useSelector((state: AppState) => state.backtests.backtestsQueue);
    const newsBacktests = useSelector((state: AppState) => state.backtests.newsBacktests);
    const [ allBacktests, setAllBacktests ] = useState<Backtest[]>([]);
    const [ currentBacktest, setCurrentBacktest ] = useState<Backtest | null>(null);
    const tagsFromBacktests = useSelector((state: AppState) => state.backtests.tags);
    const { isOpen: isOpenTags, onOpen: onOpenTags, onClose: onCloseTags } = useDisclosure();
    const [ searchValue, setSearchValue ] = useState<string>('');
    const [ searchForTags, setSearchForTags ] = useState<boolean>(true);
    const [ searchForName, setSearchForName ] = useState<boolean>(false);
    const [ searchForDate, setSearchForDate ] = useState<boolean>(false);
    const [ searchForTTest, setSearchForTTest ] = useState<boolean>(false);

    const getSelectedOption = () => {
        if (searchForTags) return 'tags';
        if (searchForName) return 'name';
        if (searchForDate) return 'date';
        if (searchForTTest) return 'tTest';
        return '';
    };

    const [ selectedOption, setSelectedOption ] = useState(getSelectedOption);

    const addToQueue = (backtest: Backtest) => {
        const m = cloneDeep(backtestQueue);
        if(m){
            m.push(backtest);
            dispatch(setLoading(true));
            dispatch(addBacktestQueue(m));
            dispatch(setLoading(false));
        }
    };

    const handleNameChange = (backtest: Backtest) => {
        if(backtest){
            const name = prompt('Please enter a new name for the backtest', backtest.name);
            if(name){
                const n = backtest;
                if(n){
                    n.name = name;
                    dispatch(setLoading(true));
                    updateBacktest(n).then(() => {
                        dispatch(setLoading(false));
                        const status = new StatusDisplayDTO("Successfully updated backtest", "success");
                        dispatch(updateMessage(status));
                    });
                }
            }
        }
    };

    React.useEffect(() => {
        if(backtestCtx){
            setCurrentBacktest(cloneDeep(backtestCtx));
        }
    
    }, [ backtestCtx ]);

    React.useEffect(() => {
        switch (selectedOption) {
        case 'tags':
            setSearchForTags(true);
            setSearchForName(false);
            setSearchForDate(false);
            setSearchForTTest(false);
            break;
        case 'name':
            setSearchForTags(false);
            setSearchForName(true);
            setSearchForDate(false);
            setSearchForTTest(false);
            break;
        case 'date':
            setSearchForTags(false);
            setSearchForName(false);
            setSearchForDate(true);
            setSearchForTTest(false);
            break;
        case 'tTest':
            setSearchForTags(false);
            setSearchForName(false);
            setSearchForDate(false);
            setSearchForTTest(true);
            break;
        default:
            setSearchForTags(false);
            setSearchForName(false);
            setSearchForDate(false);
            setSearchForTTest(false);
            break;
        }
    }, [ selectedOption ]);

    React.useEffect(() => {
        const useB = useNewsBacktests ? newsBacktests : backtests;
        if(useB){
            const n = cloneDeep(useB);
            if(n && currentBacktest) {
                setAllBacktests(n);
            }
        }
    }, [ backtests, currentBacktest, newsBacktests, useNewsBacktests ]);
    
    return <>
        <Modal isOpen={isOpenTags} onClose={onCloseTags}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Tags</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack>
                        <UnorderedList>
                            {tagsFromBacktests.map((value, index) => {
                                return <ListItem cursor={'pointer'} key={'tag' + value.tag + index} onClick={() => {
                                    setSearchValue(value.tag);
                                    setSearchForTags(true);
                                    setSearchForName(false);
                                    setSearchForDate(false);
                                    onCloseTags();
                                }}>{value.tag}</ListItem>;
                            })}
                        </UnorderedList>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='black' mr={3} onClick={onCloseTags}>
                                Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
        {!useNewsBacktests &&  <Container>
            <Flex
                align={'center'}
                justify={'center'}
                bg={'gray.800'}>
                <Container
                    maxW={'lg'}
                    bg={'whiteAlpha.100'}
                    boxShadow={'xl'}
                    rounded={'lg'}
                    p={6}>
                    <Text mt={2} textAlign={'center'} color={'gray.500'} marginBottom={3}>
                        Search for your backtest
                    </Text>
                    <Flex>
                        <Box width="100%">
                            <Input bg={'gray.600'}
                                type="text"
                                width={'full'}
                                placeholder='Search...'
                                borderColor={'gray.700'}
                                value={searchValue}
                                onChange={(event) => {
                                    setSearchValue(event.target.value);
                                } } />
                        </Box>
                        <Box>
                            <Button
                                colorScheme={'blue'}
                                w="100%"
                                rounded={4}
                                type={'button'}
                                onClick={() => {
                                    dispatch(setLoading(true));
                                    getBacktests(searchValue, searchForTags, searchForName, searchForDate, searchForTTest).then((backtests) => {
                                        dispatch(setLoading(false));
                                        dispatch(updateBacktestsStore(backtests));
                                        setAllBacktests(backtests);
                                    });
                                } }>
                                {'Search'}
                            </Button>
                        </Box>
                    </Flex>
                    <HStack marginTop={2}>
                        <Stack spacing={5} direction='row'>
                            <Text textAlign={'center'} color={'gray.500'}>
                                By:
                            </Text>
                            <RadioGroup 
                                onChange={setSelectedOption} 
                                value={selectedOption} 
                                colorScheme='blue'
                            >
                                <Stack direction='row'>
                                    <Radio value='tags'>Tag</Radio>
                                    <Radio value='name'>Name</Radio>
                                </Stack>
                            </RadioGroup>
                        </Stack>
                    </HStack>
                </Container>
            </Flex>
        </Container>}

        <Center>
            <Box>
                <Stack
                    direction={{ base: 'column', md: 'row' }}
                    spacing={{ base: 4, lg: 10 }}>
                    <Box position="relative">
                        {onOpen != null &&  <>
                            <Flex
                                align={'center'}
                                justify={'center'}>
                                <Container
                                    alignItems={'center'} justifyContent={'center'}
                                    p={6}>
                                    <VStack>
                                        <Box w={'80%'}>
                                            <Button w="full" colorScheme="blue" onClick={() => {
                                                onOpenTags();
                                            } }>
                                            All tags
                                            </Button>
                                        </Box>
                                    </VStack>
                                </Container>
                            </Flex>
                            <Button
                                alignItems={'center'}
                                justifyContent={'center'}
                                mt={10}
                                bg={'green.400'}
                                color={'white'}
                                rounded={'xl'}
                                _hover={{
                                    bg: 'green.500',
                                }}
                                _focus={{
                                    bg: 'green.500',
                                }}
                                onClick={() => {
                                    onOpen();
                                } }
                            >
                            Create new backtest
                            </Button>
                            <Button
                                ml={4}
                                alignItems={'center'}
                                justifyContent={'center'}
                                mt={10}
                                bg={'red.400'}
                                color={'white'}
                                rounded={'xl'}
                                _hover={{
                                    bg: 'red.500',
                                }}
                                _focus={{
                                    bg: 'red.500',
                                }}
                                onClick={() => {
                                    dispatch(setLoading(true));
                                    deleteBelowTtestThreshold().finally(() => {
                                        dispatch(setLoading(false));
                                    });
                                } }
                            >
                             Delete all backtests below 0.1 (t-test)
                            </Button>
                            <Button
                                ml={4}
                                alignItems={'center'}
                                justifyContent={'center'}
                                mt={10}
                                bg={'blue.400'}
                                color={'white'}
                                rounded={'xl'}
                                _hover={{
                                    bg: 'blue.500',
                                }}
                                _focus={{
                                    bg: 'blue.500',
                                }}
                                onClick={() => {
                                    BacktestActions.runAll(dispatch, backtests);
                                } }
                            >
                                Run all backtests
                            </Button>
                            <HStack>
                                <ExplainerInfo.Information
                                    title='What is delete below t-test threshold?'
                                    text={'This will delete all your backtests that have a t-test below 0.1. This is useful to delete backtests that are not statistically significant.'}
                                />
                                <ExplainerInfo.Information
                                    title='What is run all?'
                                    text={"This will run all backtests, adjusting the 'from' date to cover the last six months up to today. This adjustment is useful for identifying backtests that are currently active and for checking if any backtests have a buying signal today. Note that this change affects the 'from' date in the backtest runner, not the settings of the individual backtests."}
                                />
                            </HStack>
                        </>}
                        {currentBacktest && !useNiceBacktestDisplay && <BacktestSectionMemo addToQueue={addToQueue} 
                            allBacktests={allBacktests.filter(b => !selectedBacktests.map(c => c._id).includes(b._id))} 
                            dispatch={dispatch} 
                            backtestCtx={currentBacktest} 
                            onSelect={onSelect} 
                            handleNameChange={handleNameChange} />}
                        {currentBacktest && useNiceBacktestDisplay && <NiceBacktestSectionMemo addToQueue={addToQueue}
                            allBacktests={allBacktests.filter(b => !selectedBacktests.map(c => c._id).includes(b._id))} 
                            dispatch={dispatch} 
                            backtestCtx={currentBacktest} 
                            onSelect={onSelect} 
                            handleNameChange={handleNameChange} />}
                    </Box>
                </Stack>
            </Box>
        </Center>
    </>;
};

function BacktestSection({ addToQueue, allBacktests, dispatch, backtestCtx, onSelect, handleNameChange }:
    { addToQueue: (backtest: Backtest) => void, allBacktests: Backtest[], dispatch: Dispatch<AnyAction>, backtestCtx?: Backtest, onSelect: (backtest: Backtest) => void, handleNameChange: (backtest: Backtest) => void }) {

    const [ backtestsTags, setBacktestsTags ] = useState<string[][]>([]);

    React.useEffect(() => {
        if(allBacktests){
            setBacktestsTags(allBacktests.map((backtest) => backtest.tags));
        }
    }, [ allBacktests ]);

    const handleTagsChange = useCallback((event: SyntheticEvent, tags: string[], backtest: Backtest) => {
        backtest.tags = tags;
        dispatch(setLoading(true));
        updateBacktest(backtest)
            .then(() => {
                const status = new StatusDisplayDTO("Tags updated", "success");
                dispatch(updateMessage(status));
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    }, [ dispatch ]);

    const addToQueueComponent = (b: Backtest) => {
        return <Button
            ml={4}
            flex={1}
            fontSize={'sm'}
            rounded={'full'}
            onClick={() => {
                if (b) {
                    addToQueue(b);
                }
            }}>
            Add to queue
        </Button>;
    };

    return (
        <Table variant="simple" minW={'full'} className='root' mt={5}>
            <Thead>
                <Tr>
                    <Th borderTop="1px" borderLeft={'1px'} borderBottom={'1px'} borderColor="gray.200">Name</Th>
                    <Th borderTop={'1px'} borderColor={'gray.200'}></Th>
                    <Th border="1px" borderColor="gray.200">Periodicity</Th>
                    <Th border="1px" borderColor="gray.200" bg={'blue.900'}>T-test</Th>
                    <Th border="1px" borderColor="gray.200">T-test (less than)</Th>
                    <Th border="1px" borderColor="gray.200">Returns</Th>
                    <Th border="1px" borderColor="gray.200">Comparison Returns</Th>
                    <Th border="1px" borderColor="gray.200">Observation Count</Th>
                    <Th border="1px" borderColor="gray.200">Latest Backtest Date</Th>
                    <Th borderTop="1px"  borderBottom={'1px'}>Actions</Th>
                    <Th borderTop="1px"  borderBottom={'1px'}></Th>
                    <Th borderTop="1px"  borderBottom={'1px'} borderRight={'1px'}></Th>
                    <Th border="1px" borderColor="gray.200">Tags</Th>
                </Tr>
            </Thead>
            <Tbody>
                {allBacktests != null && allBacktests.length !== 0 ?
                    allBacktests.map((backtest, i) => {
                        if (backtest == null || backtest?.name == null) {
                            return null;
                        }

                        const evaluation = backtest.statisticsSummary?.evaluation;
                        const tTest = evaluation?.find(x => x.display === 'T-test')?.value as number | null;
                        const ttestLessthan = evaluation?.find(x => x.display === 'T-test (less than)')?.value as number | null;
                        const returns = backtest != null && backtest?.summary?.portfolioReturn != null ? backtest.summary.portfolioReturn : 0;
                        const comparisionReturns = backtest != null && backtest?.summary?.comparisonReturn ? backtest.summary.comparisonReturn : 0;
                        const observationCount = backtest?.summary?.observationCount != null ? backtest.summary.observationCount : 0;
                        const latestBacktestDate = backtest?.summary?.latestBacktestDate ? backtest.summary.latestBacktestDate.label : "N/A";

                        return (
                            <Tr key={"backtest-tag-row" + i}>
                                <Td borderTop="1px" borderLeft={'1px'} borderBottom={'1px'} borderColor="gray.200">
                                    <Stack direction="row" alignItems="center">
                                        <Text fontWeight="semibold">{backtest.name}</Text>
                                    </Stack>
                                </Td>
                                <Td borderTop="1px"  borderBottom={'1px'} borderRight={'1px'}>
                                    <Stack direction="row" alignItems="center">
                                        <Button onClick={() => handleNameChange(backtest)} size="sm" leftIcon={<FaEdit />}>
                                            Change Name
                                        </Button>
                                        <FaFlag />
                                    </Stack>
                                </Td>
                                <Td border="1px" borderColor="gray.200">{backtest.periodicity}</Td>
                                <Td border="1px" borderColor="gray.200" bg={'blue.900'}>{round(tTest, 3)}</Td>
                                <Td border="1px" borderColor="gray.200">{round(ttestLessthan, 3)}</Td>
                                <Td border="1px" borderColor="gray.200">{returns}%</Td>
                                <Td border="1px" borderColor="gray.200">{comparisionReturns}%</Td>
                                <Td border="1px" borderColor="gray.200">{observationCount}</Td>
                                <Td border="1px" borderColor="gray.200">{latestBacktestDate}
                                </Td>
                                <Td borderTop="1px"  borderBottom={'1px'} borderColor="gray.200">
                                    <Stack direction="row">
                                        <Button variant={backtest.activeFrontend ? undefined : 'outline'} colorScheme={'green'} onClick={() => onSelect(backtest)}>
                                            {backtest.activeFrontend ? 'Current active' : 'Select'}
                                        </Button>
                                    </Stack>
                                </Td>
                                <Td borderTop="1px"  borderBottom={'1px'}> 
                                    {addToQueueComponent(backtest)}
                                </Td>
                                <Td borderTop="1px"  borderBottom={'1px'}>
                                    <Button
                                        flex={1}
                                        fontSize={'sm'}
                                        rounded={'full'}
                                        onClick={() => {
                                            dispatch(setLoading(true));
                                            deleteBacktest(backtest).finally(() => {
                                                dispatch(setLoading(false));
                                                const status = new StatusDisplayDTO("Successfully deleted backtest", "success");
                                                dispatch(updateMessage(status));
                                            });
                                        }}>
                                        <DeleteIcon color={'red.300'} />
                                    </Button>
                                </Td>
                                <Td border="1px" borderColor="gray.200">
                                    <div style={{ width: 200 }}>
                                        <ChakraTagInput
                                            tags={backtestsTags[i]}
                                            placeholder='Add tags (hit enter to add)'
                                            onTagsChange={(event, tags) => handleTagsChange(event, tags, backtest)}
                                            wrapProps={{ direction: 'column', align: 'start' }}
                                            wrapItemProps={(isInput) => (isInput ? { alignSelf: 'stretch' } as any : undefined)} />
                                    </div>
                                </Td>
                            </Tr>
                        );
                    })
                    : null}
            </Tbody>
        </Table>
    );
}

function NiceBacktestSection({ addToQueue, allBacktests, dispatch, backtestCtx, onSelect, handleNameChange }
    :
    {addToQueue: (backtest: Backtest) =>void, allBacktests: Backtest[], dispatch: Dispatch<AnyAction>, backtestCtx?: Backtest, onSelect: (backtest: Backtest) => void, handleNameChange: (backtest: Backtest) => void}) {
    const addToQueueComponent = (b: Backtest) => {
        return <Button
            ml={4}
            marginTop={5}
            flex={1}
            fontSize={'sm'}
            rounded={'full'}
            onClick={() => {
                if(b){
                    addToQueue(b);
                }
            }}>
            Add to queue
        </Button>;
    };
    return <Grid templateColumns={{ base: '1fr', md: '1fr' }} gap={2} minW={'full'} className='root'>
        {allBacktests != null && allBacktests.length !== 0 ?
            <>
                {allBacktests.map((backtest, i) => {
                    if(backtest == null || backtest?.name == null){
                        return null;
                    }
                    return (
                        <GridItem w='100%' key={"backtest-tag-grid" + i}>
                            <Stack p="4" boxShadow="lg" m="4" borderRadius="sm" w='100%' padding={20} paddingBottom={5}>
                                <Stack direction="row" alignItems="center">
                                    <Text fontWeight="semibold">{backtest.name}</Text>
                                    <Button onClick={() => handleNameChange(backtest)} size="sm" leftIcon={<FaEdit />}>
                                                Change Name
                                    </Button>
                                    <FaFlag />
                                </Stack>

                                <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
                                    <Text fontSize={{ base: 'sm' }} textAlign={'left'} maxW={'4xl'}>
                                        <HStack marginTop={2}>
                                            <Stack spacing={5} direction='row'>
                                                <Text color={'gray.500'} mb={4}>
                                                        Periodicity: {backtest.periodicity}
                                                </Text>
                                            </Stack>
                                        </HStack>
                                    </Text>
                                    <Stack direction={{ base: 'column', md: 'row' }}>
                                        <Button variant={backtest._id === backtestCtx?._id ? undefined : 'outline'} colorScheme={'green'} onClick={() => {
                                            onSelect(backtest);
                                        } }>{backtest._id === backtestCtx?._id ? 'Current active' : 'Select'}</Button>
                                    </Stack>
                                </Stack>
                                <Container>
                                    <Flex
                                        align={'center'}
                                        justify={'center'}>
                                        <Container
                                            maxW={'lg'}
                                            bg={'whiteAlpha.100'}
                                            boxShadow={'xl'}
                                            rounded={'lg'}
                                            p={6}>
                                            <StatisticalComponent onlyTopRelevant={true} backtest={backtest} evaluation={backtest.statisticsSummary?.evaluation} />
                                            <Accordion key={"backtest-tag" + i} allowToggle marginTop={5} marginBottom={5}>
                                                <AccordionItem key={"backtest-tag" + i + 1}>
                                                    <h2>
                                                        <AccordionButton>
                                                            <Box as="span" flex='1' textAlign='left'>
                                                                    Tags
                                                            </Box>
                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                    </h2>
                                                    {backtest.tags.map((tag, index) => {
                                                        return (
                                                            <AccordionPanel pb={4} key={"tags" + index}>
                                                                <Text borderRadius="full" px="2" colorScheme="teal">
                                                                    {tag}
                                                                </Text>
                                                            </AccordionPanel>);
                                                    })}
                                                </AccordionItem>
                                            </Accordion>
                                        </Container>
                                    </Flex>
                                    <Button
                                        marginTop={5}
                                        flex={1}
                                        fontSize={'sm'}
                                        rounded={'full'}

                                        onClick={() => {
                                            dispatch(setLoading(true));
                                            deleteBacktest(backtest).finally(() => {
                                                dispatch(setLoading(false));
                                                const status = new StatusDisplayDTO("Successfully deleted backtest", "success");
                                                dispatch(updateMessage(status));
                                            });
                                        } }>
                                        <DeleteIcon />
                                    </Button>
                                    {addToQueueComponent(backtest)}
                                </Container>
                            </Stack>
                        </GridItem>
                    );
                })}
            </>
            : null}
    </Grid>;
}

export const BacktestSectionMemo = React.memo(BacktestSection);
export const NiceBacktestSectionMemo = React.memo(NiceBacktestSection);