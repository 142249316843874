import { SelectionFilterMenu } from "../../filters";
import { TimeSeriesDTO } from "../../methods";

export class MenuCategory {
    public categoryTitle: string;
    public items: SelectionFilterMenu[] | TimeSeriesDTO[] = [];
    public isActive = false;
    public logo: string;
    public display = "";
    public category: string;
}