import { Box, Container, Spinner } from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createId } from "../../backtesting-common-frontend/shared/utilites/string.utilities";
import { addToLazyLoadingArray, removeFromLazyLoadingArray } from "../../store/lazy-loadings/lazy-loadings";
import { AppState } from "../../store/store";

// get children from props
export default function LazyLoading({ children, forceHide, isLoadingGlobal, full }: { children: JSX.Element, forceHide?: boolean, isLoadingGlobal?: boolean, full?:boolean }) {
    const dispatch = useDispatch();
    const lazyLoadingArray = useSelector(
        (state: AppState) => state.lazyLoadings.queue
    );
    const [ hide, setHide ] = useState<boolean>(true);
    const [ id ] = useState<string>(createId());

    React.useEffect(() => {
        if (lazyLoadingArray[0] === id) {
            setTimeout(() => {
                setHide(false);
                dispatch(removeFromLazyLoadingArray(id));
            }, forceHide ? 1000 : 300);
        }
    }, [ lazyLoadingArray ]);

    React.useEffect(() => {
        dispatch(addToLazyLoadingArray(id));
        return () => {
            dispatch(removeFromLazyLoadingArray(id));
        };
    }, []);

    return (
        <>
            {hide || lazyLoadingArray.includes(id) || forceHide || isLoadingGlobal ? 
                <Container width={'full'} height={full ? '100vh' : 'full'} alignItems={'center'}
                    display={'flex'}
                    justifyContent={'center'}>
                    <Box alignItems={'center'}
                        justifyContent={'center'}>
                        <Spinner  /> 
                    </Box>
                </Container>
                : children}
        </>
    );
}
