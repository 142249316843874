/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useColorMode } from '@chakra-ui/react';
import { createChart } from 'lightweight-charts';
import React, { useEffect } from 'react';
import { TimeSeriesDTO } from '../../backtesting-common-frontend/methods';

const LineChartMemo = ({ TimeSeriesDTOs, colors }: { TimeSeriesDTOs: TimeSeriesDTO[]; colors: string[] }) => {
    const { colorMode } = useColorMode();

    // create 10 hex colors, nice hex colors
    const colorsBackup: { light: string[]; dark: string[] } = {
        light: [ '#ff0000', '#ff8000', '#ffff00', '#80ff00', '#00ff00', '#00ff80', '#00ffff', '#0080ff', '#0000ff', '#8000ff' ],
        dark: [ '#ff6666', '#ffaa80', '#ffff99', '#b3ff66', '#66ff66', '#66ffb3', '#66ffff', '#66a3ff', '#6666ff', '#a366ff' ],
    };

    useEffect(() => {
        const values = TimeSeriesDTOs.map((dto) => {
            return dto.graphValue.map((value) => {
                return { time: value.date, value: value.value ?? undefined };
            });
        });
        const element = document.getElementById('chart-container');
        const chart = createChart(element ?? '', {
            autoSize: true,
            width: 600,
            height: 600,
            timeScale: {
                barSpacing: 0.001,
            },
            layout: {
                background: { color: colorMode === 'dark' ? '#171719' : '#ffffff' },
                textColor: colorMode === 'dark' ? '#DDD' : '#333',
            },
            grid: {
                vertLines: { color: colorMode === 'dark' ? '#444' : '#ddd' },
                horzLines: { color: colorMode === 'dark' ? '#444' : '#ddd' },
            },
        });

        const lineSeriesArray: any[] = [];
        values.forEach((value, index) => {
            const lineSeries = chart.addLineSeries({
                color: colors?.[index] ?? colorsBackup[colorMode][index],
            });
            lineSeries.setData(value);
            lineSeriesArray.push(lineSeries);
        });

        const markers = TimeSeriesDTOs?.map((dto) => {
            const buying = dto.graphValue.filter((value) => value.buy && value.value != null);
            return buying.map((value) => {
                const date = new Date(value.date);
                // check if date is valid
                if (isNaN(date.getTime())) {
                    return;
                }
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                // check if year, month, and day are valid
                if (isNaN(year) || isNaN(month) || isNaN(day)) {
                    return;
                }
                return { time: { year: year, month: month, day: day }, position: 'aboveBar', color: '#e3f8ff', shape: 'circle', text: '' };
            });
        });

        lineSeriesArray?.[0]?.setMarkers(markers[0]);
        if (markers[1] != null) {
            lineSeriesArray?.[1]?.setMarkers(markers[1]);
        }

        return () => {
            // Cleanup when the component unmounts
            chart.remove();
        };
    }, [ TimeSeriesDTOs, colors, colorMode ]);

    return <div id="chart-container" style={{ width: '100%', zIndex: 0 }}></div>;
};

export const LineChart = React.memo(LineChartMemo);
