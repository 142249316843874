import {
    Box,
    Container,
    FormLabel,
    Input,
} from '@chakra-ui/react';
import { MathJax } from 'better-react-mathjax';
import { Select } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addHoldValue } from '../../action-creators/criteria-board/criteria-board.actions';
import { createOrEditNewMehtodOrCurrent } from '../../action-creators/methods/methods.actions';
import { BacktestingSetting, GraphItem } from '../../backtesting-common-frontend';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import { StrategyDTO } from '../../backtesting-common-frontend/strategies/strategy';
import { CriteriaBoardMethod, CriteriaBoardOptionSelect, criteriaSign } from '../../backtesting-common-frontend/techniques';
import { TheoryMethodService } from '../../backtesting-common-frontend/techniques/theory-method.service';
import { MethodsResultsManager } from '../../managers/methods/methods-manager';
import { editGraphItemRedux } from '../../store/graphitems/graphitems';
import { AppState } from '../../store/store';
import AddMethodHelper from '../methods/add-method-helper';
import InfoTooltip from '../shared/explainer/explainer';
import { ExplainerInfo } from '../shared/explainer/info';

export default function Absolute() {
    const dispatch = useDispatch();
    const currentReduxStrategy = useSelector(
        (state: AppState) => state.strategies.strategy
    );
    const theoryActiveCtx = useSelector((state: AppState) => state.graphItems.theoryActive);
    const graphItems = useSelector((state: AppState) => state.graphItems.graphItems);
    const test = useSelector((state: AppState) => state.tests.test);
    const currentTab = useSelector((state: AppState) => state.tabs.currentMainTab);
    const [ absoluteValue, setAbsoluteValue ] = useState<string>("10");
    const [ absoluteType, setAbsoluteType ] = useState<criteriaSign>("up");
    const [ currentStrategy, setCurrentStrategy ] = useState<StrategyDTO | null>(null);
    const [ currentTest, setCurrentTest ] = useState<BacktestingSetting | null>(null);
    const [ theoryMethodService ] = useState<TheoryMethodService>(new TheoryMethodService());

    useEffect(() => {
        setCurrentStrategy(cloneDeep(currentReduxStrategy));
    }, [ currentReduxStrategy ]);

    useEffect(() => {
        const t = cloneDeep(test);
        if(t){
            setCurrentTest(t);
        }
    }, [ test ]);

    const addMethod = (name: string, update: boolean, test?: boolean, graphItemsTest?: GraphItem[], holdValue?: string) => {
        if(!currentTest || !currentTab) return;
        const currentMethod = MethodsResultsManager.getCurrentMethod(currentTest, currentTab);
        let work: CriteriaBoardMethod | undefined = currentMethod?.parameters as CriteriaBoardMethod | undefined;
        const options = new CriteriaBoardOptionSelect();
        options.absolute = true;
        const w = new CriteriaBoardMethod("Theory", options);
        w.absolute = absoluteValue.toString();
        w.absoluteType = absoluteType;
        addHoldValue(holdValue, w);
        work = w;
        const activeGraphItem = graphItemsTest?.[0] ?? graphItems.find((e) => theoryActiveCtx.includes(e._id));
        const dtoGraphItem = cloneDeep(activeGraphItem);
        if(test && dtoGraphItem && work){
            const res = theoryMethodService.addBuyLabels([ dtoGraphItem?.timeSeries ], work);
            dtoGraphItem.timeSeries = res[0];
            dispatch(editGraphItemRedux(dtoGraphItem));
        }else if(dtoGraphItem){
            createOrEditNewMehtodOrCurrent(
                dispatch,
                currentTest,
                currentTab,
                new MethodsResultsManager(),
                work,
                name,
                dtoGraphItem,
                [ dtoGraphItem.timeSeries ],
                currentStrategy,
                null,
                undefined,
                update === false ? true : false,
                "Theory"
            );
        }

    };

    const activeGraphItem = graphItems.find((e) => theoryActiveCtx.includes(e._id));
    const disabled = !activeGraphItem || !absoluteValue || !absoluteType;

    return (
        <Container width={'full'} p={3}>
            <InfoTooltip text="This method will buy when the time series is above or below a certain value." />
            <ExplainerInfo.Information
                title="Absolute Method"
                text={
                    <MathJax>
                        {`
                The "Absolute" method involves setting buy signals based on fixed threshold values. If the value of the asset reaches or exceeds the threshold, a buy signal is generated.

                **Algorithm:**
                1. Define a threshold value \\( T \\).
                2. For each data point \\( x_i \\) in the time series:
                   \\[
                   \\text{if } x_i \\geq T \\text{ then generate a buy signal}
                   \\]
            `}
                    </MathJax>
                }
            />
            <Input type="string" value={absoluteValue} margin={2} onChange={(e) => {
                const inputValue = e.target.value;
                // Use regex to allow positive and negative numbers with optional decimal places
                if (/^-?\d*\.?\d*$/.test(inputValue)) {
                    setAbsoluteValue(inputValue);
                }
            }} />
            <Box margin={2} width={'full'}>
                <FormLabel>Comparision</FormLabel>
                <Select options={[ { label: "Up", value: "up" }, { label: "Down", value: "down" } ]} onChange={(e) => setAbsoluteType((e as any).value as criteriaSign)} />
            </Box>
            <Box margin={2} width={'full'}>
                <AddMethodHelper 
                    useHoldValue={true}
                    disabled={disabled} testDisabled={activeGraphItem != null} callback={(name, update, holdValue) => {
                        addMethod(name, update, false, undefined, holdValue);
                    }} callbackTest={(graphItemsTest, holdValue) => {
                        return new Promise<void>((resolve, _reject) => {
                            setTimeout(() => {
                                addMethod("", false, true, graphItemsTest, holdValue);
                            }, 500);
                            setTimeout(() => {
                                resolve();
                            }, 1500);
                        });
                    }} />
            </Box>
        </Container>
    );
}
