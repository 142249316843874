import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { MethodAddUpdateExtras } from "../../action-creators/methods/methods.actions";
import { BacktestingSetting } from "../../backtesting-common-frontend";
import { addMethod, editMethod } from "../../backtesting-common-frontend/http-utilities/http-utilities/methods/methods-backend.service";
import { getTest } from "../../backtesting-common-frontend/http-utilities/http-utilities/tests/tests.backend.service";
import { ImpreemMethodDTO, TimeSeriesDTO, methodKey } from "../../backtesting-common-frontend/methods";
import { cloneDeep } from "../../backtesting-common-frontend/shared/utilites/object.utilities";
import { createId } from "../../backtesting-common-frontend/shared/utilites/string.utilities";
import { CriteriaBoardMethod } from "../../backtesting-common-frontend/techniques";
import { Tab } from "../../components/tabs";
import { setLoading } from "../../store/backtests/backtests";
import { TabsManager } from "../tabs/tabs-manager";
import { TimeSeriesResultsManager } from "../time-series/time-series-manager";

export class MethodsResultsManager {

    //#region utilities
    // check if the ImpreemMethodDTO is type ImpreemMethodDTO
    public static isMethod = (x: unknown): x is ImpreemMethodDTO => {
        return x != null && x["key"] != null && x["parameters"] != null && x["methodKey"] != null;
    };
    //#endregion

    public static allowedTargetVariables = (item: TimeSeriesDTO, currentTest: BacktestingSetting | null | undefined, currentMainTab: Tab | null): boolean | null => {
        if (currentTest == null || currentMainTab == null) return null;
        const isOnMarketConditionsTab = currentTest && currentMainTab.Id === "market-conditions";
        const isOnUnivariateTab = currentTest && currentMainTab.Id === "forecast-tab";

        const okMarketCondition = isOnMarketConditionsTab && TimeSeriesResultsManager.isTimeSeries(item) &&
            (item.collectionInDb.toLowerCase().includes("indexes") ||
                item.collectionInDb.toLowerCase().includes("commodities"));

        const okUnivariate = isOnUnivariateTab && TimeSeriesResultsManager.isTimeSeries(item);

        return okMarketCondition || okUnivariate;
    };

    public static getCurrentMethod = (currentTest: BacktestingSetting, currentMainTab: Tab): ImpreemMethodDTO | null => {
        if (currentTest == null || currentMainTab == null) return null;
        const isOnMainModelTab = currentTest && currentMainTab.Id === "ml-tab";
        const isOnProbabilityModelTab = currentTest && currentMainTab.Id === "probabilty-model";
        const isOnMarketConditionsTab = currentTest && currentMainTab.Id === "market-conditions";
        const isOnUnivariateTab = currentTest && currentMainTab.Id === "forecast-tab";
        const isOnTheoryTab = currentTest && currentMainTab.Id === "theory-tab";
        const isOnForecastBeta = currentTest && currentMainTab.Id === "risk-tab";
        const isOnSelectionCriteria = currentTest && currentMainTab.Id === "selection-filters";
        const isApiMethod = currentTest && currentMainTab.Id === "api-methods";
        const isModernPortfolioTheory = currentTest && currentMainTab.Id === "modern-portfolio-tab";
        return currentTest.methods.map(t => {
            if (isOnMainModelTab && t.methodKey === "SharpeRatioModel" ||
                isOnMarketConditionsTab && t.methodKey === "MarketCondition" ||
                isOnProbabilityModelTab && t.methodKey === "ProbabilityModel" ||
                isOnUnivariateTab && t.methodKey === "UnivariateForecast" ||
                isOnTheoryTab && t.methodKey === "Theory" ||
                isOnForecastBeta && t.methodKey === "ForecastBeta" ||
                isOnSelectionCriteria && t.methodKey === "SelectionCriteria" ||
                isApiMethod && t.methodKey === "API-methods" || 
                isModernPortfolioTheory && t.methodKey === "ModernPortfolioTheory"
            ) {
                return t.active ? t : null;
            }
            return null;
        }).filter(t => t != null)?.[0];
    };

    public static isMlMethod = (currentMainTab: Tab): boolean => {
        if (currentMainTab.Id === "ml-tab" || currentMainTab.Id === "probability-models" || currentMainTab.Id === "market-conditions") return true;
        return false;
    };

    public createAndAdd = async(
        dispatch:  Dispatch<AnyAction>,
        item: CriteriaBoardMethod | null, 
        currentMethod: ImpreemMethodDTO | null,
        currentTest: BacktestingSetting | null | undefined,
        currentMainTab: Tab,
        name: string, 
        addToMethodKey?: string) => {
        if (!currentTest) return null;
        dispatch(setLoading(true));
        let newMethod: ImpreemMethodDTO | null = null;
        try {
            let addToId: string | null = null;
            if (addToMethodKey) {
                const method = currentTest.methods.find(t => t.methodKey === addToMethodKey);
                if (method) {
                    addToId = method._id;
                }
            }
            const method: ImpreemMethodDTO | null = this.create(item, currentMethod, name, currentMainTab);
            if (method) {
                if (!currentMethod) {
                    newMethod = await addMethod(currentTest._id, method, addToId);
                } else {
                    newMethod = await editMethod(method, addToId);
                }
                getTest(currentTest);
            }
        } catch (error) {
            // empty
        } finally {
            dispatch(setLoading(false));
        }
        return newMethod;
    };

    public add = async(method: ImpreemMethodDTO, 
        currentMethod: ImpreemMethodDTO | null, 
        test: BacktestingSetting,
        methodKey: string,
        addToMethodKey?: string | null) => {
        if (!test) return null;
        let newMethod: ImpreemMethodDTO | null = null;
        try {
            let addToId: string | null = null;
            if (addToMethodKey) {
                const method = test.methods.find(t => t.methodKey === addToMethodKey);
                if (method) {
                    addToId = method._id;
                }
            }
            if (!currentMethod) {
                newMethod = await addMethod(test._id, method, methodKey, addToId);
            } else {
                newMethod = await editMethod(method, addToId);
            }
        } catch (error) {
            console.log(error);
        }
        return newMethod;
    };

    public create(item: CriteriaBoardMethod | null | undefined, 
        currentMethod: ImpreemMethodDTO | null, 
        name: string, 
        currentTab: Tab, 
        extras?: MethodAddUpdateExtras,
        overwriteMethodKey?: methodKey | null) {
        const methodKey = overwriteMethodKey ?? TabsManager.convertTabIdToMethodKey(currentTab.Id);
        if (!methodKey) return null;
        const paramsToUser = !item ? currentMethod?.parameters : cloneDeep(item) ?? {} as CriteriaBoardMethod;
        const method: ImpreemMethodDTO = currentMethod ? cloneDeep(currentMethod) : new ImpreemMethodDTO();
        if(method.parameters == null){
            method.parameters = {} as CriteriaBoardMethod;
        }
        if(paramsToUser){
            method.parameters = paramsToUser;
        }
        if(extras?.aiNews != null){
            method.parameters = extras.aiNews;
        }
        method.display = name && name.length !== 0 ? name : methodKey;
        method.name = name && name.length !== 0 ? name : methodKey;
        method.timeseries = currentMethod ? currentMethod.timeseries : [];
        method.methodKey = methodKey;
        method.frontendId = createId();
        method.date = new Date();
        method.key = methodKey;
        if(extras?.apiMethodSettings){
            method.apiMethodSettings = extras.apiMethodSettings;
        }
        return method;
    }
}