import https from "axios";
import querystring from "querystring";
//import tr from "tor-request";

// tr.TorControlPort = {
//     port: 9050,
//     password: "",
//     host: "127.0.0.1"
// };

// cache of the cookie - avoid re-requesting on subsequent requests.
let cookieVal;

export default function request({ method, host, path, qs, agent }) {
    const options = {
        host,
        method,
        path: `${path}?${querystring.stringify(qs)}`,
        agent: {},
        headers: {},
    };

    if (agent) options.agent = agent;
    // will use cached cookieVal if set on 429 error
    if (cookieVal) options.headers = { "cookie": cookieVal };

    const tries = 0;
    return new Promise((resolve, reject) => {
        const req = sendTorRequest(options, reject, resolve, tries);
        // tr.newTorSession(() => {
        //     const req = sendTorRequest(options, reject, resolve, tries);
        // });
    });
}
function sendTorRequest(options: { host: any; method: any; path: string, headers: object }, reject: (reason?: any) => void, resolve: (value: unknown) => void, tries: number): any {
    tries++;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    //tr.request({ uri: "https://" + options.host + options.path, headers: options.headers }, function (err, res, body) {
    return https({
        method: "get",
        url: "https://" + options.host + options.path,
        headers: options.headers,
    }).then(res => {
        const body = res.data;
        if (body.toString("utf8").includes("Too Many Requests") || body.toString("utf8").includes("Bad Request")) {
            // Fix for the "too many requests" issue
            // Look for the set-cookie header and re-request
            cookieVal = res.headers["set-cookie"]?.[0]?.split(";")?.[0];
            if (cookieVal) {
                options.headers = { "cookie": cookieVal };
            }
            if (tries % 3 === 0) {
                resolve(null);
            } else {
                sendTorRequest(options, reject, resolve, tries);
            }
        } else {
            cookieVal = res.headers["set-cookie"]?.[0]?.split(";")?.[0];
            resolve(body.toString("utf8"));
        }
    }).catch((err) => {
        console.log(err.response.data);
        cookieVal = err.response.headers["set-cookie"]?.[0]?.split(";")?.[0];
        if (cookieVal) {
            options.headers = { "cookie": cookieVal };
        }
        if (tries % 3 === 0) {
            resolve(null);
        } else {
            sendTorRequest(options, reject, resolve, tries);
        }
    });
}

// function sendTorRequest(options: { host: any; method: any; path: string }, reject: (reason?: any) => void, resolve: (value: unknown) => void, tries: number): any {
//     // eslint-disable-next-line @typescript-eslint/no-unsafe-return
//     return tr.request({ uri: "https://" + options.host + options.path, headers: options.headers }, function (err, res, body) {
//         tries++;
//         if (body.toString("utf8").includes("Too Many Requests") || body.toString("utf8").includes("Bad Request")) {
//             if (tries < 3) {
//                 // Fix for the "too many requests" issue
//                 // Look for the set-cookie header and re-request
//                 try {
//                     options.headers = { "cookie": res.headers["set-cookie"][0].split(";")[0] };
//                     sendTorRequest(options, reject, resolve, tries);
//                 } catch (e) {
//                     again(options, reject, resolve, tries);
//                 }
//             } else {
//                 tries = 0;
//                 again(options, reject, resolve, tries);
//             }
//         } else {
//             cookieVal = res.headers["set-cookie"][0].split(";")[0];
//             resolve(body.toString("utf8"));
//         }
//     });
// }
// function again(options: { host: any; method: any; path: string }, reject: (reason?: any) => void, resolve: (value: unknown) => void, tries: number) {
//     tr.newTorSession(() => {
//         cookieVal = null;
//         delete options.headers;
//         sendTorRequest(options, reject, resolve, tries);
//     });
// }
