/* eslint-disable @typescript-eslint/no-explicit-any */
import { Company } from "..";
import { ApiKey } from "../clients/clients";

export class TimeSeriesHelperValue {

    public _id?: string;

    public value: number;

    public date: string;

    public symbol: string;

    public timeSeriesKey: string;

    public transformationKey: string;

    public companies: Company[] = [];

    public buy: boolean | null = false;

    public isPastForecast = false;

    public parameters: any;

    public d: Date;

    // helper
    public price1: number;
    public shouldCategorized?: boolean;

    // ref
    public meta: any;
}

export class Meta {
    public name = "some name";
}

export class Values {
    public date: Date = new Date();
    public value = 100;
}

export class Upload {
    public meta: Meta = new Meta();
    public values: Values[] = [new Values()];
    public apiKey: ApiKey = new ApiKey();
}
