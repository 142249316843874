import { tabType } from "../../components/tabs";

export class TabsManager {

    public static validPickMainTimeSeries = (id: tabType | null | undefined) => {
        switch (id) {
        case "selection-filters":
            return false;
        case "play-mode":
            return false;
        case "api-methods":
            return false;
        }
        return true;
    };

    public static convertTabIdToMethodKey = (id: tabType | null | undefined) => {
        switch (id) {
        case "risk-tab":
            return "ForecastBeta";
        case "market-conditions":
            return "MarketCondition";
        case "probabilty-model":
            return "ProbabilityModel";
        case "ml-tab":
            return "SharpeRatioModel";
        case "theory-tab":
            return "Theory";
        case "forecast-tab":
            return "UnivariateForecast";
        case "selection-filters":
            return "SelectionCriteria";
        case "api-methods":
            return "API-methods";
        case "modern-portfolio-tab":
            return "ModernPortfolioTheory";
        }
        return null;
    };

    public static validTimeseriesTechniquesScreen = (id: tabType | null | undefined) => {
        switch (id) {
        case "theory-tab":
            return true;
        }
        return false;
    };

    public static notValidVariableScreen = (id: tabType | null | undefined) => {
        switch (id) {
        case "theory-tab":
            return false;
        }
        return true;
    };

    public static validParameterScreen = (id: tabType | null | undefined) => {
        switch (id) {
        case "theory-tab":
            return true;
        case "forecast-tab":
            return true;
        case "market-conditions":
            return true;
        case "selection-filters":
            return true;
        case "modern-portfolio-tab":
            return true;
        case "api-methods":
            return true;
        }
        return false;
    };

    public static validResultsScreen = (id: tabType | null | undefined) => {
        switch (id) {
        case "probabilty-model":
            return true;
        case "ml-tab":
            return true;
        case "market-conditions":
            return true;
        case "selection-filters":
            return true;
        case "modern-portfolio-tab":
            return true;
        }
        return false;
    };

    public static validCriteriaBoard = (id: tabType | null | undefined) => {
        switch (id) {
        case "theory-tab":
            return true;
        case "market-conditions":
            return true;
        case "modern-portfolio-tab":
            return true;
        }
        return false;
    };

    public static validGoogleTrends = (id: tabType | null | undefined) => {
        switch (id) {
        case "selection-filters":
            return false;
        }
        return true;
    };

    public static validSelectTimeseriesForParameter = (id: tabType | null | undefined) => {
        switch (id) {
        case "market-conditions":
            return true;
        }
        return false;
    };

}