/* eslint-disable @typescript-eslint/no-empty-function */
import { AddIcon } from "@chakra-ui/icons";
import { Button, Container, Grid, GridItem, HStack, Heading, IconButton, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOrEditNewMehtodOrCurrent } from "../../action-creators/methods/methods.actions";
import { addSelectionFilterAction } from "../../action-creators/selection-filters/selection-filters.action";
import { BacktestingSetting } from "../../backtesting-common-frontend";
import { CompanyProfile } from "../../backtesting-common-frontend/database/R-DB/timeseries/companies";
import { getPreviewTransformations } from "../../backtesting-common-frontend/http-utilities/http-utilities/transformations/transformations-backend.service";
import { MenuCategory } from "../../backtesting-common-frontend/menu/dtos/menu-dtos";
import { CategorizeType, GeneralCategorize, TimeSeriesDTO } from "../../backtesting-common-frontend/methods";
import { groupArray } from "../../backtesting-common-frontend/shared/utilites/array.utilities";
import { cloneDeep } from "../../backtesting-common-frontend/shared/utilites/object.utilities";
import { StrategyDTO } from "../../backtesting-common-frontend/strategies/strategy";
import { RunBacktestButton } from "../../components/backtests/run-backtest-button";
import LazyLoading from "../../components/lazy-loading/lazy-loding";
import SelectionFilters from "../../components/selection-filters/selection-filters";
import StrategyTable from "../../components/strategies/strategies";
import { SelectTimeSeries } from "../../components/time-series/time-series-selector";
import { MethodsResultsManager } from "../../managers/methods/methods-manager";
import { TimeSeriesResultsManager } from "../../managers/time-series/time-series-manager";
import { setLoading, setSelectedSymbol, updateCompanyProfiles } from "../../store/backtests/backtests";
import { AppState } from "../../store/store";
import { updateNavigationTo } from "../../store/tabs/tabs";

export default function ScreeningScreen() {
    const dispatch = useDispatch();
    const timeSeriesMenu = useSelector(
        (state: AppState) => state.backtests.menuCategory
    );
    const companyProfilesRedux = useSelector(
        (state: AppState) => state.backtests.companyProfiles
    );
    const test = useSelector((state: AppState) => state.tests.test);
    const currentTab = useSelector((state: AppState) => state.tabs.currentMainTab);
    const backtestCtx = useSelector((state: AppState) => state.backtests.backtest);
    const strategyCtx = useSelector((state: AppState) => state.strategies.strategy);
    const [ companyProfiles, setCompanyProfiles ] = useState<CompanyProfile[]>([]);
    const [ strategy, setStrategy ] = useState<StrategyDTO | null>(null);
    const [ allValuesTimeSeries, setAllValuesTimeSeries ] = useState<TimeSeriesDTO[]>([]);
    const [ timeSeries, setTimeSeries ] = useState<MenuCategory[]>([]);
    const [ allSelectedTimeSeries, setAllSelectedTimeSeries ] = useState<TimeSeriesDTO[]>([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [ modalContent, setModalContent ] = useState<TimeSeriesDTO | null>(null);
    const [ lowerBound, setLowerBound ] = useState('');
    const [ upperBound, setUpperBound ] = useState('');
    const [ currentTest, setCurrentTest ] = useState<BacktestingSetting | null>(null);

    useEffect(() => {
        setStrategy(cloneDeep(strategyCtx));
    }, [ strategyCtx ]);

    useEffect(() => {
        const t = cloneDeep(test);
        if(t){
            setCurrentTest(t);
        }
    }, [ test ]);

    const handleScreeningResults = (cp: CompanyProfile[], add: boolean) => {
        if(add){
            setCompanyProfiles(prev => [ ...prev, ...cp ]);
        }else{
            setCompanyProfiles(cp);
        }
    };

    const getTimeSeries = useCallback(async(selectedTimeSeries: TimeSeriesDTO, symbol: string) => {
        if(selectedTimeSeries){
            getPreviewTransformations([ symbol ], backtestCtx, selectedTimeSeries, "http", undefined, "selection-criteria" )
                .then(data => {
                    const values = data[0].timeSeries;
                    values.ID = symbol;
                    // replace the old time series with the new one
                    setAllValuesTimeSeries(prev => {
                        const exists = prev.findIndex(ts => ts.transformationKey === selectedTimeSeries.transformationKey && ts.ID === symbol);
                        if(exists > -1){
                            prev[exists] = values;
                        } else {
                            prev.push(values);
                        }
                        return [ ...prev ];
                    });
                }).catch(() => {});
        }
    }, [ backtestCtx ]);

    useEffect(() => {
        if(companyProfiles.length > 0){
            const top5 = companyProfiles.slice(0, 20);
            top5.forEach(co => {
                if(allSelectedTimeSeries.length > 0){
                    const allTransformionKeys = allSelectedTimeSeries.map(ts => ts.transformationKey);
    
                    const requiredTimeSeries = allValuesTimeSeries.filter(ts => 
                        allTransformionKeys.includes(ts.transformationKey) && ts.ID === co.symbol
                    );
                
                    const missingTimeSeries = allSelectedTimeSeries.filter(ts => 
                        !requiredTimeSeries.find(rts => rts.transformationKey === ts.transformationKey && rts.ID === co.symbol)
                    );
                
                    if (missingTimeSeries.length > 0) {
                        dispatch(setLoading(true));
                        const promises: Promise<void>[] = [];
                        missingTimeSeries.forEach(ts => {
                            promises.push(getTimeSeries(ts, co.symbol));
                        });
                        Promise.all(promises).then(() => {
                            dispatch(setLoading(false));
                        });
                    }
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ allSelectedTimeSeries, companyProfiles, getTimeSeries, allValuesTimeSeries ]);

    useEffect(() => {
        if(companyProfilesRedux?.length > 0) {
            setCompanyProfiles(companyProfilesRedux);
        }
    }, [ companyProfilesRedux ]);

    useEffect(() => {
        if(timeSeriesMenu){
            const validTimeSeries = cloneDeep(timeSeriesMenu).filter(e => e.category === "fundamentals" ||
            e.category === "company-time-series" || e.category === "beta");
            setTimeSeries(validTimeSeries);
        }
    }, [ timeSeriesMenu ]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent minW={'700px'}>
                    <ModalHeader>Set Threshold Criteria</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <HStack spacing={4} align="center">
                            <Input
                                value={lowerBound}
                                onChange={(e) => setLowerBound(e.target.value)}
                                placeholder="< Lower threshold"
                            />
                            <span>{modalContent?.display}</span>
                            <Input
                                value={upperBound}
                                onChange={(e) => setUpperBound(e.target.value)}
                                placeholder="> Upper threshold"
                            />
                        </HStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
                        </Button>
                        <Button variant="ghost" onClick={() => {
                            // check if lowerBound and upperBound are numbers
                            createThresholdMethods(modalContent);

                        }}>Apply</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Grid
                templateColumns={{ base: '1fr', md: '15% 65% 20%' }}
                height={'100vh'}
            >
                <GridItem colSpan={1} bg={useColorModeValue('gray.300', '#292C31')} zIndex={3}>
                    <Heading as="h2" size="lg" p={4}>Choose financial ratios</Heading>
                    <span>Showing values for the first 20 companies.</span>
                    <span>Add threshold by the "+" and run the screening analysis by clicking on the "run backtest"-button.</span>
                    <SelectTimeSeries 
                        onHandleSelectionChange={(value) => {
                            if(TimeSeriesResultsManager.isTimeSeries(value)) {
                                setAllSelectedTimeSeries(prev => [ ...prev, value ]);
                            }
                        }}
                        data={timeSeries}>
                        <>
                        
                        </>
                    </SelectTimeSeries>
                    {/* Generate a list of allSelectedTimeSeries display .display and add a remove button */}
                    {allSelectedTimeSeries.map((e, i) => (
                        <HStack key={i}>
                            <Button onClick={() => {
                                setAllSelectedTimeSeries(prev => prev.filter(x => e.transformationKey !== x.transformationKey));
                                setAllValuesTimeSeries(prev => [ ...prev.filter(x => e.transformationKey !== x.transformationKey) ]);
                            }}>Remove</Button>
                            <span>{e.display}</span>
                        </HStack>
                    ))}
                </GridItem>
                <GridItem colSpan={1} bg={useColorModeValue('white', '#171719')} zIndex={1} >
                    <Container m={4} p={10} justifyContent={'center'} alignItems={'center'} minW={'full'} position={'relative'}>
                        <LazyLoading>
                            <>
                                <HStack>
                                    <SelectionFilters timeSeriesMenu={timeSeriesMenu} handleScreeningResults={handleScreeningResults} />
                                </HStack>
                                <Button bg={'red.300'} _hover={
                                    { bg: 'red.500' }
                                } onClick={() => {
                                    setCompanyProfiles([]);
                                    dispatch(updateCompanyProfiles([]));
                                }}>Clear</Button>
                                <TableContainer >
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                <Th>View</Th>
                                                <Th>Add</Th>
                                                <Th>Symbol</Th>
                                                <Th>Image</Th>
                                                {groupArray(allValuesTimeSeries, "transformationKey").map((ts, index) => (
                                                    <Th key={index + "headers"}>
                                                        <HStack spacing="3">
                                                            <span>{ts[0].display}</span>
                                                            <Tooltip label="Applying threshold criteria" hasArrow>
                                                                <IconButton
                                                                    aria-label="Applying threshold criteria"
                                                                    icon={<AddIcon />}
                                                                    onClick={() => {
                                                                        setModalContent(ts[0]);
                                                                        onOpen();
                                                                    }}
                                                                    variant="outline"
                                                                    size="sm"
                                                                />
                                                            </Tooltip>
                                                        </HStack>
                                                    </Th>
                                                ))}
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {companyProfiles.map((co, index) => (
                                                <Tr key={index + "rows"}>
                                                    <Td><Button onClick={() => {
                                                        dispatch(setSelectedSymbol(companyProfiles[index].symbol));
                                                        dispatch(updateNavigationTo("profile"));
                                                    }}>View</Button></Td>
                                                    <Td>
                                                        <Button onClick={() => {
                                                            const theSymbol = timeSeriesMenu.find((e) => e.category === "symbols")?.items.find((e) => e.ID === co.symbol);
                                                            if(theSymbol && strategy){
                                                                addSelectionFilterAction(dispatch, strategy, theSymbol, []);
                                                            }
                                                        }}>Add</Button>
                                                    </Td>
                                                    <Td>{co.symbol}</Td>
                                                    <Td><Image src={co.image} alt={co.companyName} boxSize="40px" objectFit="cover" /></Td>
                                                    {allValuesTimeSeries.filter(ts => ts.ID === co.symbol).map((ts, idx) => (
                                                        <Td key={idx}>{ts.graphValue[0]?.value ?? "N/A"}</Td> // Assuming there's at least one graphValue per time series.
                                                    ))}
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </>
                        </LazyLoading>
                    </Container>
                </GridItem>
                <GridItem colSpan={1} bg={useColorModeValue('gray.300', '#292C31')} borderLeft="1px"
                    borderLeftColor={useColorModeValue('gray.200', 'gray.700')}>
                    <RunBacktestButton />
                    <LazyLoading>
                        <>
                            <StrategyTable />
                        </>
                    </LazyLoading>
                </GridItem>
            </Grid>
        </>
    );

    function createThresholdMethods(ts: TimeSeriesDTO) {
        setModalContent(null);

        if(lowerBound.length > 0 && !isNaN(Number(lowerBound))){
            const copyTs = cloneDeep(ts);
            const betaCategorize = new GeneralCategorize();
            betaCategorize.key = "absolute";
            betaCategorize.interval = [];
            betaCategorize.categorizeType = [ "up", lowerBound ];
            const categorizeType = new CategorizeType();
            categorizeType.key = "beta";
            categorizeType.meta = betaCategorize;
            copyTs.categorizeType = categorizeType;
    
            copyTs.categorizeType.isOnFly = true;
    
            createOrEditNewMehtodOrCurrent(
                dispatch,
                currentTest,
                currentTab,
                new MethodsResultsManager(),
                null,
                "Lowerbound: " + lowerBound + " " + modalContent?.display,
                null,
                [ copyTs ],
                strategy,
                null,
                undefined,
                true,
                "SelectionCriteria"
            );
        }

        if(upperBound.length > 0 && !isNaN(Number(upperBound))){
            const copyTs2 = cloneDeep(ts);
            const betaCategorizeUpper = new GeneralCategorize();
            betaCategorizeUpper.key = "absolute";
            betaCategorizeUpper.interval = [];
            betaCategorizeUpper.categorizeType = [ "down", upperBound ];
            const categorizeTypeUpper = new CategorizeType();
            categorizeTypeUpper.key = "beta";
            categorizeTypeUpper.meta = betaCategorizeUpper;
            copyTs2.categorizeType = categorizeTypeUpper;
    
            copyTs2.categorizeType.isOnFly = true;
    
            createOrEditNewMehtodOrCurrent(
                dispatch,
                currentTest,
                currentTab,
                new MethodsResultsManager(),
                null,
                "Upperbound: " + upperBound + " " + modalContent?.display,
                null,
                [ copyTs2 ],
                strategy,
                null,
                undefined,
                true,
                "SelectionCriteria"
            );
        }

        setLowerBound('');
        setUpperBound('');
        onClose();
    }
}