/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Container, Divider, Grid, GridItem, Heading, Image, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Company } from "../../backtesting-common-frontend/database/R-DB/timeseries/companies";
import { getSymbolInfo } from "../../backtesting-common-frontend/http-utilities/http-utilities/selection-filters/selection-filters-backend.service";
import LazyLoading from "../../components/lazy-loading/lazy-loding";
import { NavItem } from "../../components/shared/sidebar/sidebar";
import { setLoading } from "../../store/backtests/backtests";
import { AppState } from "../../store/store";
import { updateNavigationTo } from "../../store/tabs/tabs";

export default function ProfileScreen() {
    const dispatch = useDispatch();
    const symbolStore = useSelector(
        (state: AppState) => state.backtests.selectedSymbol
    );
    const [ company, setCompany ] = useState<Company>(new Company({}));

    const getCompany = useCallback(async() => {
        if(symbolStore){
            dispatch(setLoading(true));
            const c = await getSymbolInfo(symbolStore);
            dispatch(setLoading(false));
            setCompany(c);
        }
    }, [ dispatch, symbolStore ]);
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    React.useEffect(() => {
        if(symbolStore){
            getCompany();
        }
    }, [ symbolStore, getCompany ]);

    const {
        symbol,
        beta,
        volAvg,
        mktCap,
        companyName,
        exchange,
        exchangeShortName,
        industry,
        website,
        description,
        ceo,
        sector,
        country,
        fullTimeEmployees,
        phone,
        address,
        city,
        state,
        zip,
        dcfDiff,
        dcf,
        image,
        isEtf,
        'income-statement-quarter': incomeStatementQuarter,
        'ratios-quarter': ratiosQuarter,
    } = company;

    const incomeMetrics = {
        revenue: 'Revenue',
        grossProfit: 'Gross Profit',
        operatingIncome: 'Operating Income',
        netIncome: 'Net Income',
        eps: 'Earnings Per Share (EPS)',
        ebitda: 'EBITDA',
        grossProfitRatio: 'Gross Profit Ratio',
        operatingIncomeRatio: 'Operating Income Ratio',
        netIncomeRatio: 'Net Income Ratio',
        epsdiluted: 'EPS Diluted',
    };

    const ratiosMetrics = {
        currentRatio: 'Current Ratio',
        quickRatio: 'Quick Ratio',
        cashRatio: 'Cash Ratio',
        daysOfSalesOutstanding: 'Days of Sales Outstanding',
        daysOfInventoryOutstanding: 'Days of Inventory Outstanding',
        operatingCycle: 'Operating Cycle',
        daysOfPayablesOutstanding: 'Days of Payables Outstanding',
        cashConversionCycle: 'Cash Conversion Cycle',
        grossProfitMargin: 'Gross Profit Margin',
        operatingProfitMargin: 'Operating Profit Margin',
        pretaxProfitMargin: 'Pretax Profit Margin',
        netProfitMargin: 'Net Profit Margin',
        effectiveTaxRate: 'Effective Tax Rate',
        returnOnAssets: 'Return on Assets',
        returnOnEquity: 'Return on Equity',
        returnOnCapitalEmployed: 'Return on Capital Employed',
        receivablesTurnover: 'Receivables Turnover',
        payablesTurnover: 'Payables Turnover',
        fixedAssetTurnover: 'Fixed Asset Turnover',
        assetTurnover: 'Asset Turnover',
        operatingCashFlowPerShare: 'Operating Cash Flow per Share',
        freeCashFlowPerShare: 'Free Cash Flow per Share',
        cashPerShare: 'Cash per Share',
        payoutRatio: 'Payout Ratio',
        operatingCashFlowSalesRatio: 'Operating Cash Flow to Sales Ratio',
        freeCashFlowOperatingCashFlowRatio: 'Free Cash Flow to Operating Cash Flow Ratio',
        priceBookValueRatio: 'Price to Book Value Ratio',
        priceToBookRatio: 'Price to Book Ratio',
        priceToSalesRatio: 'Price to Sales Ratio',
        priceEarningsRatio: 'Price Earnings Ratio',
        priceToFreeCashFlowsRatio: 'Price to Free Cash Flows Ratio',
        priceToOperatingCashFlowsRatio: 'Price to Operating Cash Flows Ratio',
        priceCashFlowRatio: 'Price Cash Flow Ratio',
        priceEarningsToGrowthRatio: 'Price Earnings to Growth Ratio',
        priceSalesRatio: 'Price Sales Ratio',
        enterpriseValueMultiple: 'Enterprise Value Multiple',
        priceFairValue: 'Price Fair Value',
    };

    return (
        <>
            <Grid
                templateColumns={{ base: '1fr', md: '7% 20% 53% 20%' }}
                height={'100vh'}
            >
                <GridItem >
                    <Box
                        justifyContent={'center'} alignItems={'center'}
                        transition="3s ease"
                        bg={useColorModeValue('gray.300', '#292C31')}
                        borderRight="1px"
                        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
                        w={{ base: 'full', md: 'full' }}
                        h="full">
                        <NavItem icon={<FaArrowLeft size={35} />} width={200} preventHover={false} onClick={() => {
                            dispatch(updateNavigationTo("/screening"));
                        }}>
                            <Heading size="md" ml="2" justifyContent={'center'} alignItems={'center'} paddingLeft={5}>
                                        Back
                            </Heading>
                        </NavItem>
                        <Divider />
                    </Box>
                </GridItem>
                <GridItem>
                </GridItem>
                <GridItem colSpan={1} bg={useColorModeValue('white', '#171719')} zIndex={1}>
                    {symbol != null && <Container m={4} p={10} justifyContent={'center'} alignItems={'center'} minW={'full'} position={'relative'}>
                        <LazyLoading>
                            <>
                                <Box p={4} borderWidth="1px" borderRadius="lg">
                                    <Image src={image} alt={companyName} mb={4} />

                                    <Table variant="striped" colorScheme="black" size="sm">
                                        <Thead>
                                            <Tr>
                                                <Th>Property</Th>
                                                <Th>Value</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td>Company name</Td>
                                                <Td>{companyName}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Symbol</Td>
                                                <Td>{symbol}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Beta</Td>
                                                <Td>{beta}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>VolAvg</Td>
                                                <Td>{volAvg}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>MktCap</Td>
                                                <Td>{mktCap}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Industry</Td>
                                                <Td>{industry}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Sector</Td>
                                                <Td>{sector}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Full time employees</Td>
                                                <Td>{fullTimeEmployees}</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>

                                    <Box mt={4}>
                                        <Box fontWeight="bold" mb={2}>
          Ratios (Quarter), date: {ratiosQuarter[0].date}
                                        </Box>
                                        <Table variant="striped" colorScheme="black" size="sm">
                                            <Thead>
                                                <Tr>
                                                    <Th>Metric</Th>
                                                    <Th>Value</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {Object.entries(ratiosMetrics).map(([ metricKey, metricLabel ]) => (
                                                    <Tr key={metricKey}>
                                                        <Td>{metricLabel}</Td>
                                                        <Td>{ratiosQuarter[0][metricKey]}</Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </Box>

                                    <Box mt={4}>
                                        <Box fontWeight="bold" mb={2}>
          Income Statement (Quarter), date: {incomeStatementQuarter[0].date}
                                        </Box>
                                        <Table variant="striped" colorScheme="black" size="sm">
                                            <Thead>
                                                <Tr>
                                                    <Th>Metric</Th>
                                                    <Th>Value</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {Object.entries(incomeMetrics).map(([ metricKey, metricLabel ]) => (
                                                    <Tr key={metricKey}>
                                                        <Td>{metricLabel}</Td>
                                                        <Td>{incomeStatementQuarter[0][metricKey]}</Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </Box>

                                    <Box mt={4}>
                                        <Box fontWeight="bold" mb={2}>
          Company information
                                        </Box>
                                        <Box>{description}</Box>
                                    </Box>

                                    <Box mt={4}>
                                        <Box fontWeight="bold" mb={2}>
          Additional Information
                                        </Box>
                                        <Box>
                                            <strong>CEO:</strong> {ceo}
                                        </Box>
                                        <Box>
                                            <strong>Sector:</strong> {sector}
                                        </Box>
                                        {/* Add other information here */}
                                    </Box>

                                    <Box mt={4}>
                                        <Box fontWeight="bold" mb={2}>
          Contact Information
                                        </Box>
                                        <Box>
                                            <strong>Phone:</strong> {phone}
                                        </Box>
                                        <Box>
                                            <strong>Address:</strong> {`${address}, ${city}, ${state}, ${zip}, ${country}`}
                                        </Box>
                                    </Box>

                                    <Box mt={4}>
                                        <Box fontWeight="bold" mb={2}>
          Discounted Cash Flow
                                        </Box>
                                        <Box>
                                            <strong>DCF Difference:</strong> {dcfDiff}
                                        </Box>
                                        <Box>
                                            <strong>DCF:</strong> {dcf}
                                        </Box>
                                    </Box>

                                    <Box mt={4}>
                                        <Box fontWeight="bold" mb={2}>
          ETF Information
                                        </Box>
                                        <Box>
                                            <strong>Is ETF:</strong> {isEtf ? 'Yes' : 'No'}
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        </LazyLoading>
                    </Container>}
                </GridItem>
                <GridItem >

                </GridItem>
            </Grid>
        </>
    );
}

