import { methodKey } from "../methods";

export type criteriaSign = "up" | "down";

export class CriteriaBoardOptionSelect {
    public aboveBelow?: boolean = false;
    public growth?: boolean = false;
    public lag?: boolean = false;
    public forecastPoints?: boolean = false;
    public year?: boolean = false;
    public month?: boolean = false;
    public dates?: boolean = false;
    public relativeQuota?: boolean[] = [ false ];
    public trend?: boolean[] = [ false ];
    public absolute?: boolean = false;
    public acceleration?: boolean = false;
    public outlier?: boolean = false;
}

export class CriteriaDateDTO {
    constructor(public year: number | null, public months: {label: string; value: number | string}[], public weeks: {label: string; value: number | string}[] = []) {
        this.year = year;
        this.months = months;
        this.weeks = weeks;
    }
}

export type RelativeMethod = "normalization" | "natural logarithm" | "none";

export type RelativeCalculate = "growth" | "absolute" | "dividend";

export class Criteria {
    public type: criteriaSign = "up";
    public horizon = "30";
    public lag = "0";
    public added = false;
    public growthValue = "0.3";
    public growthType: criteriaSign = "up";
    public dates: CriteriaDateDTO[] = [];
    public year: number[] = [];
    public yearExclude = false;
    public month: number[] = [];
    public monthExclude = false;
    public accelerationStep = "10";
    public relativeQuota: string[] = [ "2" ];
    public outlierInterval = "30";
    public outlierType: criteriaSign = "up";
    public outlierValue = "0.05";
    public relativeQuotaType: criteriaSign[] = [ "up" ];
    public relativeInterval: string[] = [ "30" ];
    public relativeIntervalMax: boolean[] = [ false ];
    public relativeComparisonMax: boolean[] = [ false ];
    public relativeMethod: RelativeMethod = "normalization";
    public relativeCalculate: RelativeCalculate = "dividend";
    public hold = false;
    public holdValue = "0";

    public trend: string[] = [ "0.001" ];
    public trendType: criteriaSign[] = [ "up" ];
    public trendInterval: string[] = [ "30" ];

    // absolute
    public absolute = "1";
    public absoluteType: criteriaSign = "up";

    // forecast
    public forecastPoints = "1";
    public forecastType: criteriaSign = "up";
    public model = "auto-arima";
}

export class CriteriaBoardSetting extends Criteria {
    public name = "Add a time series";
    public frequency = "1";
    public transformationKey: string | null = null;

    // multiple series
    public method = "diff";
    public absoluteValue = "0.05";
}

export type CriteriaBoardLoadingStatus = "loading" | "unsaved" | "saved" | "saving";

export class CriteriaBoardMethod extends CriteriaBoardSetting {
    public methodKey: methodKey | null = null;
    public optionsSelect: CriteriaBoardOptionSelect | null = null;
    public main: string | null = null;
    public unsaved: CriteriaBoardLoadingStatus = "saved";

    constructor(methodKey: methodKey | null, optionsSelect: CriteriaBoardOptionSelect | null) {
        super();
        this.methodKey = methodKey;
        this.optionsSelect = optionsSelect;
    }
}
