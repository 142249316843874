/* eslint-disable @typescript-eslint/dot-notation */
import mongoose, { Schema } from "mongoose";
import { timeseries } from "./timeseries";
import timeserieshelpervalues from "./timeserieshelpervalues";

const graphItemSchema = new Schema({
    tab: Schema.Types.Mixed,
    timeSeries: { type: Schema.Types.ObjectId, ref: "timeseries" }, 
    groupId: String,
    theoryActive: Boolean,
    theoryActiveMain: Boolean,
    editActive: Boolean,
    extras: [{ type: Schema.Types.ObjectId, ref: "timeseries" }], 
    active: Boolean,
    added: Date,
    clientId: { type: Schema.Types.ObjectId, ref: "clients" },
    color: String,
});

graphItemSchema.pre("findOneAndDelete", async function (next) {
    const graphItemId = this["_conditions"]._id;
    const graphItem = await GraphItemModel.findById(graphItemId);
    if (!graphItem) return next();
    const timeseriesIds = graphItem.timeSeries;
    const extrasIds = graphItem.extras;
    // find time series
    const ts = await timeseries.find({ _id: { $in: [timeseriesIds].concat(extrasIds) } });
    const timeserieshelpervaluesId = ts.map((t) => t.graphValue);
    await timeseries.deleteMany({ _id: { $in: [timeseriesIds].concat(extrasIds) } });
    await timeserieshelpervalues.deleteMany({ _id: { $in: timeserieshelpervaluesId.flat() } });
    next();
});
// Create and export the Mongoose model for GraphItem
export const GraphItemModel = mongoose.model<GraphItemDocument>("graphitems", graphItemSchema);

export interface GraphItemDocument extends Document {
    // write the id
    _id: mongoose.Types.ObjectId;
    tab: any;
    timeSeries: mongoose.Types.ObjectId; // Reference to the "timeseries" collection
    groupId: string;
    theoryActive: boolean;
    theoryActiveMain: boolean;
    editActive: boolean;
    extras: mongoose.Types.ObjectId[]; // Reference to the "timeseries" collection for 'extras'
    active: boolean;
    added: Date;
    clientId: mongoose.Types.ObjectId; // Reference to the "clients" collection
    color: string;
}
