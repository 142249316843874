/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep, toNumber } from "lodash";
import moment from "moment";
import { CriteriaBoardMethod } from ".";
import { ImpreemDate } from "../date/impreem-date";
import { getDatesFromLabel } from "../http-utilities/http-utilities/dates/dates.http.service";
import { ImpreemMethodDTO, TimeSeriesDTO } from "../methods";
import { MonthIndexArray, cleanDateArray, getDatesFromLabelUtilities, leftJoinByDate, sortByDate } from "../shared/utilites/dates.utilities";
import { findClosestValue, linearRegression, normalizationObjects, seq_along } from "../shared/utilites/math.utilities";
import { StrategyDTO } from "../strategies/strategy";
import { TimeSeriesHelperValue } from "../timeseries/timeseries-models";
import { deepClone } from "../worker/transformations/transformation-service";

class TheoryHelperDate {
    public date: string;

    public d: Date;
}

export class TheoryMethodService {

    public addBuy(theMatrix: TimeSeriesHelperValue[][], theoryMethods: ImpreemMethodDTO[], Z: number, symbols: string[][]) {
        
        const getValue = (curr: number, idx: number) => theMatrix[curr]?.[idx]?.value;
        const getValues = (curr: number, idx: number, lag: number) => theMatrix[curr]?.slice(idx - lag, idx).map(e => e.value);
        const currentDate = theMatrix?.[0]?.[Z]?.d;

        if (!currentDate) {
            return;
        }
        
        let holdValue = null;
        for (let curr = 0; curr < theMatrix.length; curr++) {
            let buy = true;
            for (let i = 0; i < theoryMethods.length; i++) {
                const criteriaSettings = theoryMethods[i].parameters as CriteriaBoardMethod;
                const theTs = (theoryMethods[i].parameters as any)?.main;
                const comparisonTransformatioKey = theoryMethods[i].timeseries.map(e => e.transformationKey).filter(e => e !== theTs);
                const comparisonSeries = theMatrix.find(e => e[0].transformationKey === comparisonTransformatioKey[0]);
                if (theMatrix[curr][0].transformationKey !== theTs) {
                    continue;
                }
                
                const keys = theoryMethods[i].timeseries.map(e => e.transformationKey);
                const symbol = theMatrix[curr][0].symbol;
                const timeSeries: TimeSeriesHelperValue[][] = [];
                for (let j = 0; j < keys.length; j++) {
                    const ts = theMatrix.find(e => e[0].transformationKey === keys[j] && (symbol ? e[0].symbol === symbol : true));
                    if (ts) {
                        timeSeries.push(ts);
                    }
                }

                if (criteriaSettings.optionsSelect?.relativeQuota?.some(x => x)) {
                    const methodValues = criteriaSettings.relativeQuota.map(x => toNumber(x));
                    const interval = criteriaSettings.relativeInterval.map(x => toNumber(x));
                    const types = criteriaSettings.relativeQuotaType;

                    let series = theMatrix[curr].slice(Z - interval[0] + 1, Z + 1);
                    let seriesC = comparisonSeries.slice(Z - interval[0] + 1, Z + 1);
                    if (series.length > 5 && seriesC.length > 5) {
                        if (criteriaSettings.relativeMethod === "normalization") {
                            series = normalizationObjects(cloneDeep(series), "value");
                            seriesC = normalizationObjects(cloneDeep(seriesC), "value");
                        } else if (criteriaSettings.relativeMethod === "natural logarithm") {
                            series = series.map(x => {
                                x.value = Math.log(x.value);
                                return x;
                            });
                            seriesC = seriesC.map(x => {
                                x.value = Math.log(x.value);
                                return x;
                            });
                        }
                        const compareValue = series[series.length - 1 ].value / seriesC[seriesC.length - 1].value;
                        const mValue = +methodValues[0];

                        if (!(types[0] === "up" && compareValue > mValue || types[0] === "down" && compareValue < mValue)) {
                            buy = false;
                        }
                    }
                }

                if (criteriaSettings.optionsSelect?.outlier) {
                    const interval = +criteriaSettings.outlierInterval;
                    const outlierValue = +criteriaSettings.outlierValue;
                    const outlierType = criteriaSettings.outlierType;
                    const values = getValues(curr, Z, interval);
                    const value = getValue(curr, Z);
                    const series = values.sort((a, b) => a - b);
                    let theIndex = series.findIndex(e => value === e);
                    if (theIndex === -1) {
                        const closestValue = findClosestValue(series, value);
                        if (closestValue != null) {
                            theIndex = series.findIndex(e => e === closestValue);
                        }
                    }
                    const quantile = theIndex / series.length;
    
                    if (!(outlierType === "up" && quantile >= outlierValue || outlierType === "down" && quantile <= outlierValue)) {
                        buy = false;   
                    }
                }

                // Growth condition
                if (criteriaSettings.optionsSelect?.growth) {
                    const growthValue = Number(criteriaSettings.growthValue);
                    const valCurrent = getValue(curr, Z);
                    const valPrevious = getValue(curr, Z - 1);
                    const growth = (valCurrent - valPrevious) / valPrevious;
            
                    if (criteriaSettings.growthType === "up") {
                        if (!(growth > growthValue)) {
                            buy = false;
                        }
                    } else if (criteriaSettings.growthType === "down") {
                        if (!(growth < growthValue)) {
                            buy = false;
                        }
                    }
                }
            
                // Absolute condition
                if (criteriaSettings.optionsSelect?.absolute) {
                    const absoluteValue = Number(criteriaSettings.absolute);
                    const val = getValue(curr, Z);
            
                    if (criteriaSettings.absoluteType === "up") {
                        if (!(val >= absoluteValue)) {
                            buy = false;
                        }
                    } else if (criteriaSettings.absoluteType === "down") {
                        if (!(val <= absoluteValue)) {
                            buy = false;
                        }
                    }
                }
            
                // Relative Quota condition (if comparisonSeries is provided)
                if (comparisonSeries && criteriaSettings.optionsSelect?.relativeQuota?.some(x => x) && timeSeries.length > 1) {
                    const methodValues = criteriaSettings.relativeQuota.map(x => Number(x));
                    const interval = criteriaSettings.relativeInterval.map(x => Number(x));
            
                    methodValues.forEach((methodValue, q) => {
                        const comparisonValues = comparisonSeries.slice(Z - interval[q] + 1, Z + 1);
                        const valuesSlice = timeSeries[0].slice(Z - interval[q] + 1, Z + 1);
            
                        // Example logic for comparison, specifics depend on criteriaSettings.relativeCalculate etc.
                        // This is a placeholder for actual comparison logic.
                        if (comparisonValues.length > 0 && valuesSlice.length > 0) {
                            // Implement specific comparison logic here
                            // Placeholder logic:
                            const compareValue = valuesSlice[valuesSlice.length - 1]?.value;
                            if (!(compareValue > methodValue)) {
                                buy = false;
                            }
                        }
                    });
                }
            
                // Date condition
                if (criteriaSettings.optionsSelect?.dates) {
                    const date = currentDate;
                    const theYear = moment(date).year();
                    const theMonth = moment(date).month();
                    const datesToCompare = criteriaSettings.dates;
                    datesToCompare.forEach(dateToCompare => {
                        const year = dateToCompare.year;
                        const months = dateToCompare.months.map(month => MonthIndexArray.find(x => x.name === month.label)?.index);
                        if (year !== -1 && theYear !== year) {
                            buy = false;
                        }
                        if (months.length > 0 && !months.includes(theMonth)) {
                            buy = false;
                        }
                    });
                }
                const anyHold = +criteriaSettings.holdValue;
                if (criteriaSettings.hold && buy && holdValue == null) {
                    holdValue = anyHold;
                }
            }
        
            if (buy) {
                symbols[symbols.length - 1].push(theMatrix[curr][0].symbol);
                if (holdValue) {
                    // do a reverse loop of symbols
                    let index = 0;
                    for (let i = symbols.length; i >= 0; i--) {
                        if (holdValue > index && symbols[i - 1] != null) {
                            symbols[i - 1].push(theMatrix[curr][0].symbol);
                            index++;
                        }
                    }
                }
            }
        }
    
    }

    public async getTheorySteps(
        testStrategies: StrategyDTO[],
        theMatrix: TimeSeriesHelperValue[][],
        symbol?: string): Promise<TimeSeriesHelperValue[] | null> {
        const allTheories = testStrategies.map(t => t.intersection.filter(p => p.methodKey === "Theory")).flat();
        const stepsFromTheories = await this.getStepsFromTheory(allTheories, theMatrix, true, symbol) as TimeSeriesHelperValue[];
        if (stepsFromTheories.length > 0) {
            return stepsFromTheories;
        }
        return [];
    }

    public addBuyLabels(Timeseries: TimeSeriesDTO[], criteriaSettings: CriteriaBoardMethod, alignTimeSeries?: boolean): TimeSeriesDTO[] {
        if (!Timeseries || Timeseries.length === 0 || !Timeseries[0].graphValue || Timeseries[0].graphValue.length === 0) {
            return Timeseries;
        }

        let TimeSeriesToReturn = deepClone(Timeseries);
        TimeSeriesToReturn = TimeSeriesToReturn.map(e => {
            e.graphValue = e.graphValue.map(t => {
                t.buy = null;
                return t;
            });
            return e;
        });

        let originalData = deepClone(Timeseries);

        if (!originalData || originalData.length === 0) {
            originalData = Timeseries;
        }

        let lag = toNumber(criteriaSettings?.lag ?? 0) ?? 0;
        lag = Math.sign(lag) === -1 ? -1 * lag : lag;

        let timeseries1 = originalData[0].graphValue;
        let timeseries2 = originalData?.[1]?.graphValue != null ? originalData[1].graphValue : null;

        // set all buys to null
        timeseries1 = timeseries1.map(e => {
            e.buy = null;
            e.transformationKey = "1-1";
            return e;
        });
        if (timeseries2) {
            timeseries2 = timeseries2?.map(e => {
                e.buy = null;
                e.transformationKey = "2-2";
                return e;
            });
        }

        if (alignTimeSeries && timeseries2 != null) {
            const leftJoined = leftJoinByDate(timeseries1.map(e => {
                e.d = new Date(e.date);
                return e;
            }), timeseries2.map(e => {
                e.d = new Date(e.date);
                return e;
            }));
            timeseries1 = leftJoined[0];
            timeseries2 = leftJoined[1];
        }

        // make timeseries1 and timeseries2 same length, starting from the newest date
        if (timeseries2 != null && timeseries2.length > 0) {
            if (timeseries1.length > timeseries2.length) {
                timeseries1 = timeseries1.slice(timeseries1.length - timeseries2.length);
            } else if (timeseries1.length < timeseries2.length) {
                timeseries2 = timeseries2.slice(timeseries2.length - timeseries1.length);
            }
        }

        const allDates = Timeseries[0].graphValue.map(e => {
            return {
                date: e.date,
                d: new Date(e.date),
            } as TheoryHelperDate;
        });
        // order dates, so that the first date is the oldest
        const sortedDates = sortByDate(cleanDateArray(allDates, "date"), "date");

        let countBuy = 0;
        let after = 0;
        let holdStarted = false;
        let hold = 0;
        for (let i = sortedDates.length - 1; i >= 0; i--) {
            const index = i;
            const val = timeseries1[index]?.value;
            const val2 = timeseries2 != null ? timeseries2[index]?.value : null;
            const valPrevious = timeseries1[index - 1]?.value ?? 0;

            if (val == null) {
                continue;
            }

            if (criteriaSettings.optionsSelect?.outlier) {
                const interval = +criteriaSettings.outlierInterval;
                const outlierValue = +criteriaSettings.outlierValue;
                const outlierType = criteriaSettings.outlierType;
                const values = timeseries1.slice(index - interval + 1, index + 1);
                const value = val;
                const series = values.map(e => e.value).sort((a, b) => a - b);
                let theIndex = series.findIndex(e => e === value);
                if (theIndex === -1) {
                    const closestValue = findClosestValue(series, value);
                    if (closestValue != null) {
                        theIndex = series.findIndex(e => e === closestValue);
                    }
                }
                const quantile = theIndex / series.length;

                if (outlierType === "up" && quantile >= outlierValue || outlierType === "down" && quantile <= outlierValue) {
                    TimeSeriesToReturn[0].graphValue[index - lag].buy = TimeSeriesToReturn[0].graphValue[index - lag].buy == null ? true : TimeSeriesToReturn[0].graphValue[index - lag].buy;
                } else {
                    TimeSeriesToReturn[0].graphValue[index - lag].buy = false;
                }
            }

            if (criteriaSettings.optionsSelect?.relativeQuota?.some(x => x) && originalData.length > 1) {
                const methodValues = criteriaSettings.relativeQuota.map(x => toNumber(x));
                const interval = criteriaSettings.relativeInterval.map(x => toNumber(x));
                const types = criteriaSettings.relativeQuotaType;

                if (val != null && val2 != null) {
                    methodValues.forEach((methodValue, q) => {
                        if (timeseries2 == null) {
                            return;
                        }

                        let comparisonValues = timeseries2.slice(index - interval[q] + 1, index + 1);
                        let values = timeseries1.slice(index - interval[q] + 1, index + 1);
                        let value1 = 0;
                        let value2 = 0;
                        // Transform the data
                        if (criteriaSettings.relativeCalculate !== "growth") {
                            if (criteriaSettings.relativeMethod === "normalization") {
                                comparisonValues = normalizationObjects(cloneDeep(comparisonValues), "value");
                                values = normalizationObjects(cloneDeep(values), "value");
                            } else if (criteriaSettings.relativeMethod === "natural logarithm") {
                                comparisonValues = comparisonValues.map(x => {
                                    x.value = Math.log(x.value);
                                    return x;
                                });
                                values = values.map(x => {
                                    x.value = Math.log(x.value);
                                    return x;
                                });
                            }
                        }
                        if (values[values.length - 1] == null || comparisonValues[comparisonValues.length - 1] == null) {
                            return;
                        }
                        let compareValue = 0;
                        let mValue = methodValue;
                        if (criteriaSettings.relativeCalculate === "growth") {
                            const lastValueComparison = comparisonValues[comparisonValues.length - 1].value;
                            const firstValueComparison = comparisonValues[0].value;
                            const lastValue = values[values.length - 1].value;
                            const firstValue = values[0].value;
                            const growthComparison = (lastValueComparison - firstValueComparison) / firstValueComparison;
                            const growth = (lastValue - firstValue) / firstValue;
                            value1 = growth;
                            value2 = growthComparison;
                            compareValue = growth;
                            mValue = growthComparison;
                            // replace compareValue and mValue if infinity then set 1 or if -infinity then set -1, if the difference between last and first value is not equal to 0
                            if (lastValue - firstValue !== 0) {
                                if (compareValue === Infinity) {
                                    compareValue = 1;
                                } else if (compareValue === -Infinity) {
                                    compareValue = -1;
                                }
                            }
                            if (lastValueComparison - firstValueComparison !== 0) {
                                if (mValue === Infinity) {
                                    mValue = 1;
                                } else if (mValue === -Infinity) {
                                    mValue = -1;
                                }
                            }
                        } else if (criteriaSettings.relativeCalculate === "dividend") {
                            value1 = values[values.length - 1].value;
                            value2 = comparisonValues[comparisonValues.length - 1].value;
                            compareValue = value1 / value2;
                            mValue = methodValue;
                        } else {
                            value1 = values[values.length - 1].value;
                            value2 = comparisonValues[comparisonValues.length - 1].value;
                            compareValue = value1;
                            mValue = value2;
                        }

                        if (types[q] === "up") {
                            if (compareValue > mValue && criteriaSettings.relativeCalculate !== "growth" ||
                            (compareValue >= mValue || !isFinite(mValue) && isFinite(compareValue) && values[values.length - 1].value > comparisonValues[comparisonValues.length - 1].value) && criteriaSettings.relativeCalculate === "growth") {
                                TimeSeriesToReturn[0].graphValue[index - lag].buy = TimeSeriesToReturn[0].graphValue[index - lag].buy == null ? true : TimeSeriesToReturn[0].graphValue[index - lag].buy;
                            } else {
                                TimeSeriesToReturn[0].graphValue[index - lag].buy = false;
                            }
                        } else {
                            if (compareValue < mValue && criteriaSettings.relativeCalculate !== "growth" ||
                            (compareValue <= mValue || !isFinite(mValue) && isFinite(compareValue) && values[values.length - 1].value < comparisonValues[comparisonValues.length - 1].value) && criteriaSettings.relativeCalculate === "growth") {
                                TimeSeriesToReturn[0].graphValue[index - lag].buy = TimeSeriesToReturn[0].graphValue[index - lag].buy == null ? true : TimeSeriesToReturn[0].graphValue[index - lag].buy;
                            } else {
                                TimeSeriesToReturn[0].graphValue[index - lag].buy = false;
                            }
                        }
                        if (TimeSeriesToReturn[0].graphValue[index - lag].buy) {
                            countBuy++;
                        }

                    });
                }
            }

            const falseForNow = false;
            if (criteriaSettings.optionsSelect?.trend?.some(x => x) && falseForNow) {
                const methodValues = criteriaSettings.trend.map(x => toNumber(x));
                const types = criteriaSettings.trendType;
                const interval = criteriaSettings.trendInterval.map(x => toNumber(x));

                if (timeseries1 && timeseries1.length > 0) {
                    methodValues.forEach((methodValue, q) => {
                        const series = [ ...timeseries1.slice(index - interval[q] + 1, index + 1).map(e => e.value) ];
                        const time = seq_along(series.length);
                        const regression = linearRegression(time, series);
                        const beta = regression[0];

                        if (criteriaSettings.optionsSelect?.trend == null || !criteriaSettings.optionsSelect.trend[q]) {
                            return;
                        }
                        if (series.length === 0) {
                            return;
                        }

                        const canSetTrue = TimeSeriesToReturn[0].graphValue[index - lag].buy == null || TimeSeriesToReturn[0].graphValue[index - lag].buy;

                        if (types[q] === "up") {
                            if (beta > methodValue) {
                                TimeSeriesToReturn[0].graphValue[index - lag].buy = canSetTrue ? true : TimeSeriesToReturn[0].graphValue[index - lag].buy;
                            } else {
                                TimeSeriesToReturn[0].graphValue[index - lag].buy = false;
                            }
                        } else {
                            if (beta < methodValue) {
                                TimeSeriesToReturn[0].graphValue[index - lag].buy = canSetTrue ? true : TimeSeriesToReturn[0].graphValue[index - lag].buy;
                            } else {
                                TimeSeriesToReturn[0].graphValue[index - lag].buy = false;
                            }
                        }
                    });
                }
            }

            if (criteriaSettings.optionsSelect?.growth) {
                const growthValue = toNumber(criteriaSettings.growthValue);
                const value = (val - valPrevious) / valPrevious;
                const isDebug = false;
                if (isDebug) {
                    console.log(true);
                }
                if (typeof value === "number" && !isNaN(value)) {
                    const canSetTrue = TimeSeriesToReturn[0].graphValue[index - lag].buy == null || TimeSeriesToReturn[0].graphValue[index - lag].buy;
                    if (isDebug) {
                        console.log(canSetTrue);
                    }
                    if (criteriaSettings.growthType === "up") {
                        if (value > growthValue && canSetTrue) {
                            countBuy++;
                            TimeSeriesToReturn[0].graphValue[index - lag].buy = true;
                            if (isDebug) {
                                console.log(sortedDates[index].date);
                            }
                        } else {
                            TimeSeriesToReturn[0].graphValue[index - lag].buy = false;
                        }
                    } else if (criteriaSettings.growthType === "down" && canSetTrue) {
                        if (isDebug) {
                            console.log("Yes?");
                        }
                        if (value < growthValue) {
                            countBuy++;
                            if (isDebug) {
                                console.log(sortedDates[index].date);
                            }
                            TimeSeriesToReturn[0].graphValue[index - lag].buy = true;
                        } else {
                            TimeSeriesToReturn[0].graphValue[index - lag].buy = false;
                        }
                    }
                    if (TimeSeriesToReturn[0].graphValue[index - lag].buy) {
                        after++;
                    }
                }
            }

            if (criteriaSettings.optionsSelect?.absolute) {
                const absoluteValue = toNumber(criteriaSettings.absolute);
                const value = +val;
                if (typeof value === "number" && !isNaN(value)) {
                    const canSetTrue = TimeSeriesToReturn[0].graphValue[index - lag].buy == null || TimeSeriesToReturn[0].graphValue[index - lag].buy;
                    if (criteriaSettings.absoluteType === "up") {
                        if (value >= absoluteValue && canSetTrue) {
                            countBuy++;
                            TimeSeriesToReturn[0].graphValue[index - lag].buy = true;
                        } else {
                            TimeSeriesToReturn[0].graphValue[index - lag].buy = false;
                        }
                    } else if (criteriaSettings.absoluteType === "down" && canSetTrue) {
                        if (value <= absoluteValue) {
                            countBuy++;
                            TimeSeriesToReturn[0].graphValue[index - lag].buy = true;
                        } else {
                            TimeSeriesToReturn[0].graphValue[index - lag].buy = false;
                        }
                    }
                }
            }

            // check if there is any buy -30 days before
            if (criteriaSettings.hold && TimeSeriesToReturn[0].graphValue[index - lag].buy) {
                const holdValue = +criteriaSettings.holdValue;
                for (let j = 1; j <= holdValue; j++) {
                    // check if TimeSeriesToReturn[0].graphValue[index - lag + j] is not null
                    if (TimeSeriesToReturn[0].graphValue?.[index - lag + j] != null) {
                        TimeSeriesToReturn[0].graphValue[index - lag + j].buy = true;
                    }
                }
            }
        }

        if (criteriaSettings.optionsSelect?.dates) {
            holdStarted = false;
            hold = 0;
            const yearsAndMonths = criteriaSettings.dates;
            // check if any year is -1
            if (yearsAndMonths && yearsAndMonths.length > 0) {

                /*
                 * Create a moment array of all years in year and set to year and last day of the year
                 * set buy to true if current date have same year as in moments
                 */
                TimeSeriesToReturn[0].graphValue.forEach((value, index) => {
                    if (value == null) {
                        return;
                    }
                    const date = moment(value.date);
                    let canSetTrue = TimeSeriesToReturn[0].graphValue.some((x: TimeSeriesHelperValue) => typeof x.buy === "boolean") ? TimeSeriesToReturn[0].graphValue[index].buy : TimeSeriesToReturn[0].graphValue[index].buy == null;
                    canSetTrue = canSetTrue == null ? true : canSetTrue;

                    // find year in yearsAndMonths
                    const theYear = yearsAndMonths.find(e => e.year != null && date.isSame(moment(e.year, "YYYY"), "year"));
                    let canSetMonth = yearsAndMonths.every(x => x.year == null);
                    let canSetWeek = yearsAndMonths.every(x => x.year == null && x.months.length === 0);
                    let year: number | null = null;
                    if (theYear != null) {
                        year = theYear.year;
                        canSetMonth = year == null;
                        const isSameYear = !canSetMonth ? date.isSame(moment(year, "YYYY"), "year") : false;

                        if (criteriaSettings.yearExclude) {
                            if (isSameYear) {
                                TimeSeriesToReturn[0].graphValue[index].buy = false;
                            } else if (canSetTrue) {
                                TimeSeriesToReturn[0].graphValue[index].buy = true;
                                canSetMonth = true;
                                canSetWeek = yearsAndMonths.every(x => x.months.length === 0);
                            }
                        } else {
                            if (isSameYear && canSetTrue) {
                                TimeSeriesToReturn[0].graphValue[index].buy = true;
                                canSetMonth = true;
                                canSetWeek = yearsAndMonths.every(x => x.months.length === 0);
                            } else {
                                TimeSeriesToReturn[0].graphValue[index].buy = false;
                            }
                        }
                    }

                    const months = theYear?.months ?? yearsAndMonths.map(e => e.months).flat().filter(e => e != null);
                    const monthsIndex = MonthIndexArray.filter(e => months.some(x => x.label === e.name)).map(x => x.index);
                    const isSameMonth = monthsIndex.some(e => e === date.month());

                    if (canSetMonth && months.length > 0) {
                        if (criteriaSettings.monthExclude) {
                            if (isSameMonth) {
                                TimeSeriesToReturn[0].graphValue[index].buy = false;
                            } else if (canSetTrue) {
                                TimeSeriesToReturn[0].graphValue[index].buy = true;
                                canSetWeek = true;
                            }
                        } else {
                            if (isSameMonth && canSetTrue) {
                                countBuy++;
                                TimeSeriesToReturn[0].graphValue[index].buy = true;
                                canSetWeek = true;
                            } else {
                                TimeSeriesToReturn[0].graphValue[index].buy = false;
                            }
                        }
                    }

                    const dayOfMonth = date.date();
                    const weekOfMonth = Math.ceil(dayOfMonth / 7);
                    const weeks = yearsAndMonths.map(e => e.weeks).flat().filter(e => e != null);
                    const isSameWeek = weeks.some(e => +e.value === weekOfMonth);
                    if (isSameWeek && canSetWeek && canSetTrue && weeks.length > 0) {
                        TimeSeriesToReturn[0].graphValue[index].buy = true;
                    } else if (!isSameWeek && canSetWeek && canSetTrue && weeks.length > 0) {
                        TimeSeriesToReturn[0].graphValue[index].buy = false;
                    }

                    if (criteriaSettings.hold) {
                        if (holdStarted) {
                            if (hold < +criteriaSettings.holdValue) {
                                TimeSeriesToReturn[0].graphValue[index - lag].buy = true;
                            } else if (hold >= +criteriaSettings.holdValue) {
                                hold = 0;
                                holdStarted = false;
                            }
                            hold++;
                        } else {
                            if (TimeSeriesToReturn[0].graphValue[index - lag].buy) {
                                holdStarted = true;
                            }
                        }
                    }
                });
            }
        }

        const clonedTs = cloneDeep(Timeseries);
        clonedTs[0].graphValue = TimeSeriesToReturn[0].graphValue.map(e => {
            e.buy = e.buy == null ? false : e.buy;
            return e;
        });
        return clonedTs;
    }

    public async getStepsFromTheory(
        allTheories: ImpreemMethodDTO[],
        theMatrix: TimeSeriesHelperValue[][],
        returnGraphValues?: boolean,
        symbol?: string): Promise<ImpreemDate[] | TimeSeriesHelperValue[]> {
        const tsHelper: TimeSeriesHelperValue[][] = [];
        // find all time series for each method in allTheories
        const theoryDtoHelper: {
            method: ImpreemMethodDTO;
            timeSeries: TimeSeriesHelperValue[][];
            isDateMethodHelper: boolean;
        }[] = [];
        for (let i = 0; i < allTheories.length; i++) {
            const keys = allTheories[i].timeseries.map(e => e.transformationKey);
            const n: TimeSeriesHelperValue[][] = [];
            for (let j = 0; j < keys.length; j++) {
                const ts = theMatrix.find(e => e[0].transformationKey === keys[j] && (symbol ? e[0].symbol === symbol : true));
                if (ts) {
                    n.push(ts);
                }
            }
            theoryDtoHelper.push({
                method: allTheories[i],
                timeSeries: n,
                isDateMethodHelper: (allTheories[i].parameters as CriteriaBoardMethod).dates.length > 0,
            });
        }
        const stepsResults: ImpreemDate[] = [];
        try {
            // foreach allTimeSeriesForAllTheories use techniqueService
            for (let i = 0; i < theoryDtoHelper.length; i++) {
                const groupFakeTs: TimeSeriesDTO[] = [];
                const p = theoryDtoHelper[i].timeSeries;
                p.forEach(t => {
                    const g = new TimeSeriesDTO();
                    g.graphValue = t;
                    g.transformationKey = t[0].transformationKey;
                    groupFakeTs.push(g);
                });
                const first = (theoryDtoHelper[i].method.parameters as any)?.main;
                // put first in the first position
                const index = groupFakeTs.findIndex(t => t.transformationKey === first);
                if (index > -1) {
                    const n = groupFakeTs[index];
                    groupFakeTs.splice(index, 1);
                    groupFakeTs.unshift(n);
                }
                // eslint-disable-next-line @typescript-eslint/require-await
                const m = this.addBuyLabels(groupFakeTs, theoryDtoHelper[i].method.parameters as CriteriaBoardMethod);
                tsHelper.push(m[0].graphValue.map(v => {
                    v.meta = theoryDtoHelper[i].isDateMethodHelper;
                    return v;
                }));
            }
        } catch (e) {
            // empty
            console.log(e);
        }

        let shortestTimeSeries: TimeSeriesHelperValue[] | null = null;
        for (let i = 0; i < tsHelper.length; i++) {
            if (shortestTimeSeries == null || tsHelper[i].length < shortestTimeSeries.length) {
                shortestTimeSeries = tsHelper[i];
            }
        }
        const cuttedValues = tsHelper.map(e => e.slice(e.length - (shortestTimeSeries.length ?? 0), e.length));
        const results: TimeSeriesHelperValue[] = [];

        const alignedValues = cuttedValues;

        for (let i = alignedValues[0].length - 1; i >= 0; i--) {
            const values = alignedValues.map(e => e[i]);
            const anyDateMethod = values.filter(e => typeof e.meta === "boolean" && e.meta);
            const noneDateMethod = values.filter(e => !e.meta);
            const buy = (noneDateMethod.every(e => e.buy) || noneDateMethod.length === 0) && (anyDateMethod.some(e => e.buy) || anyDateMethod.length === 0);
            results.unshift({ buy: buy, date: alignedValues[0][i].date, d: new Date(alignedValues[0][i].d), symbol: symbol } as TimeSeriesHelperValue);
        }

        if (returnGraphValues) {
            return results;
        }

        if (results.length > 0) {
            const datesAddPosition = results.filter(x => x.buy).map(e => e.date);
            // get all steps from datesAddPosition
            const allRelevantSteps = typeof window !== "undefined" ? await getDatesFromLabel(datesAddPosition) : await getDatesFromLabelUtilities(datesAddPosition);
            if (allRelevantSteps == null) {
                return [];
            }
            // Sort date ImpreemDate by date
            allRelevantSteps.sort((a, b) => a.date.getTime() - b.date.getTime());
            stepsResults.push(...allRelevantSteps);
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        testDates.push(stepsResults);
        // check if all testDates is same
        return stepsResults;
    }

}

const testDates: ImpreemDate[][] = [];
