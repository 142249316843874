import mongoose from "mongoose";
const Schema = mongoose.Schema;

const swedishnewsSchema = new Schema({
    symbol: String,
    news: Schema.Types.Mixed,
    newsCount: Schema.Types.Mixed,
    sentimentScore: Schema.Types.Mixed
});

const swedishnews = mongoose.model("swedishnews", swedishnewsSchema);

export default swedishnews;
