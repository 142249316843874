import moment from "moment";
import { GeoValuesDTO, RelatedQueriesDTO, RelatedTopicsDTO, TimeSeriesValue } from "./modules/entities/google-trends-dto";

export function removeDuplicates(duplicatesArray: any[], field: string) {
    const values = [];
    const duplicates = [];
    for (let i = 0; i < duplicatesArray.length; i++) {
        if (values.includes(duplicatesArray[i][field])) {
            duplicates.push(...removeDuplicatesHelper(values, duplicatesArray[i][field]));
        }
        values.push(duplicatesArray[i][field]);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return duplicatesArray.filter((x, i) => !duplicates.includes(i));
}

function removeDuplicatesHelper(values: any[], value: any) {
    const indexes = [];
    for (let i = 0; i < values.length; i++) {
        if (values[i] === value) { indexes.push(i); }
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return indexes;
}

export const Months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const MonthsPrefix = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export class GoogleTrendsMapper {

    public MapperTimeSeriesDTO(results: any) {
        try {
            const a = results.default.timelineData;
            const timeSeries = new Array<TimeSeriesValue>();
            for (let i = 0; i < a.length; i++) {
                const value = new TimeSeriesValue();
                value.value = a[i].value[0];
                const newDate = new Date((+a[i].time) * 1000);
                newDate.setDate(2);
                value.date = newDate;
                timeSeries.push(value);
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return removeDuplicates(timeSeries, "date")
                .sort((y: TimeSeriesValue, x: TimeSeriesValue) => y.date.getTime() - x.date.getTime());
        } catch (error) {
            return null;
        }
    }

    public MapperTimeSeriesDTOWeekly(results: any) {
        try {
            const a = results.default.timelineData;
            const timeSeries = new Array<TimeSeriesValue>();
            for (let i = 0; i < a.length; i++) {
                const value = new TimeSeriesValue();
                value.value = a[i].value[0];
                const newDate = this.getLastDateInRange(a[i].formattedTime);
                value.date = newDate;
                timeSeries.push(value);
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return removeDuplicates(timeSeries, "date")
                .sort((y: TimeSeriesValue, x: TimeSeriesValue) => y.date.getTime() - x.date.getTime());
        } catch (error) {
            return null;
        }
    }

    public getLastDateInRange(dateStr) {
        const dateParts = dateStr.split("–"); // Split the string by "-"

        // Get the end date part of the string and remove any leading or trailing whitespace
        let endDateStr = dateParts[1].trim();

        // If the end date string doesn't contain any alphabetic characters, add the first word from the start date string
        if (!/[a-zA-Z]/.test(endDateStr)) {
            const firstWord = dateParts[0].split(" ")[0];
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            endDateStr = firstWord + " " + endDateStr;
        }

        // Use the Date constructor to create a new Date object from the end date string
        const endDate = new Date(endDateStr);

        return endDate;
    }

    public MapperTimeSeriesDTOWeeklyCompare(results: any) {
        try {
            const a = results.default.timelineData;
            const timeSeries = new Array<TimeSeriesValue[]>();
            for (let i = 0; i < a.length; i++) {

                for (let p = 0; p < a[i].value.length; p++) {
                    const value = new TimeSeriesValue();
                    value.value = a[i].value[p];
                    const newDate = this.getLastDateInRange(a[i].formattedTime);
                    value.date = newDate;
                    if (typeof timeSeries[i] === "undefined") {
                        timeSeries[i] = new Array<TimeSeriesValue>();
                    }
                    timeSeries[i].push(value);
                }

            }
            return timeSeries;
        } catch (error) {
            return null;
        }
    }

    public MapperTimeSeriesDTOCompareSerpapi(timelineData: { date: string; timestamp: string; values: { query: string; value: string; extracted_value: number }[] }[]) {
        try {
            const a = timelineData;
            const timeSeries = new Array<TimeSeriesValue[]>();
            for (let i = 0; i < a.length; i++) {

                for (let p = 0; p < a[i].values.length; p++) {
                    const value = new TimeSeriesValue();
                    value.value = a[i].values[p].extracted_value;
                    const newDate = new Date();
                    newDate.setMonth(this.findMonth(a[i].date.replace(/[0-9]/g, "").toLowerCase()));
                    newDate.setFullYear(+a[i].date.replace(/\D/g, ""));

                    const momentDate = moment(newDate).startOf("month").add(10, "days").format("YYYY-MM-DD");

                    value.date = new Date(momentDate);
                    if (typeof timeSeries[i] === "undefined") {
                        timeSeries[i] = new Array<TimeSeriesValue>();
                    }
                    timeSeries[i].push(value);
                }

            }
            return timeSeries;
        } catch (error) {
            return null;
        }
    }

    public MapperTimeSeriesDTOCompare(results: any) {
        try {
            const a = results.default.timelineData;
            const timeSeries = new Array<TimeSeriesValue[]>();
            for (let i = 0; i < a.length; i++) {

                for (let p = 0; p < a[i].value.length; p++) {
                    const value = new TimeSeriesValue();
                    value.value = a[i].value[p];
                    const newDate = new Date();
                    newDate.setMonth(this.findMonth(a[i].formattedTime.replace(/[0-9]/g, "").toLowerCase()));
                    newDate.setFullYear(a[i].formattedTime.replace(/\D/g, ""));

                    const momentDate = moment(newDate).startOf("month").add(10, "days").format("YYYY-MM-DD");

                    value.date = new Date(momentDate);
                    if (typeof timeSeries[i] === "undefined") {
                        timeSeries[i] = new Array<TimeSeriesValue>();
                    }
                    timeSeries[i].push(value);
                }

            }
            return timeSeries;
        } catch (error) {
            return null;
        }
    }

    public MapperGeoValuesDTO(results: any) {
        try {
            const a = results.default.geoMapData;
            const timeSeries = new Array<GeoValuesDTO>();
            for (let i = 0; i < a.length; i++) {
                const value = new GeoValuesDTO();
                value.values = a[i].value[0];
                value.geoCode = a[i].geoCode;
                value.geoName = a[i].geoName;
                timeSeries.push(value);
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return timeSeries;
        } catch (error) {
            return null;
        }
    }

    public MapperRelatedQueriesDTO(results: any) {
        try {
            const a = results.default.rankedList[0].rankedKeyword;
            const dto = new Array<RelatedQueriesDTO>();
            for (let i = 0; i < a.length; i++) {
                const value = new RelatedQueriesDTO();
                value.hasData = a[i].hasData;
                value.query = a[i].query;
                value.value = a[i].value;
                dto.push(value);
            }
            return dto;
        } catch (error) {
            return null;
        }
    }

    public MapperRelatedTopicsDTO(results: any) {
        try {
            const a = results.default.rankedList[0].rankedKeyword;
            const dto = new Array<RelatedTopicsDTO>();
            for (let i = 0; i < a.length; i++) {
                const value = new RelatedTopicsDTO();
                value.hasData = a[i].hasData;
                value.value = a[i].value;
                value.title = a[i].topic.title;
                value.type = a[i].topic.type;
                value.mid = a[i].topic.mid;
                dto.push(value);
            }
            return dto;
        } catch (error) {
            return null;
        }
    }

    private findMonth(date: string) {
        for (let i = 0; i < MonthsPrefix.length; i++) {
            if (MonthsPrefix[i].toLowerCase().includes(date.replace(" ", ""))) {
                return i;
            }
        }
        return null;
    }

}

export default GoogleTrendsMapper;
