import mongoose from "mongoose";
const Schema = mongoose.Schema;

const newsSchema = new Schema({
    publishedDate: String,
    text: String,
    title: String,
    symbols: String,
});

const news = mongoose.model("news", newsSchema);

export default news;
