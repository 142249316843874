import { CiCalendarDate, CiGlobe, CiWarning } from "react-icons/ci";
import { FaBook, FaChartLine, FaPlay, FaRegChartBar, FaRobot, FaShoppingBag, FaUpload } from "react-icons/fa";
import { HiMiniUsers } from "react-icons/hi2";
import { MdOutlineShowChart, MdOutlineWeb } from "react-icons/md";
import { PiCursor, PiStrategy } from "react-icons/pi";

import { Tab } from "../../components/tabs";

// marketCondition.Icon = <MaterialIcons name="batch-prediction" size={20} color="black" />;
export const selectionFiltersTab = new Tab();
selectionFiltersTab.Id = "selection-filters";
selectionFiltersTab.Name = "Selection critiera";
selectionFiltersTab.isActive = false;
selectionFiltersTab.Icon = <HiMiniUsers size={20} color="black" />;
export const modernPortfolioTab = new Tab();
modernPortfolioTab.Id = "modern-portfolio-tab";
modernPortfolioTab.Name = "Modern portfolio theory";
modernPortfolioTab.isActive = false;
modernPortfolioTab.Icon = <HiMiniUsers size={20}  color="black" />;
export const transformationTab = new Tab();
transformationTab.Id = "transformations-tab";
transformationTab.Name = "Transformations";
transformationTab.isActive = false;
transformationTab.Icon = <FaChartLine size={20} color={'black'} />;
export const theoryTab = new Tab();
theoryTab.Id = "theory-tab";
theoryTab.Name = "Theory";
theoryTab.isActive = true;
theoryTab.Icon = <FaBook size={20} color="black" />;
const forecastTab = new Tab();
forecastTab.Id = "forecast-tab";
forecastTab.Name = "Univariate forecasts";
forecastTab.isActive = false;
forecastTab.Icon = <FaChartLine  size={20} color="black" />;
export const backtestScreenTab = new Tab();
backtestScreenTab.Id = "play-mode";
backtestScreenTab.Name = "Backtest screen";
backtestScreenTab.isActive = false;
backtestScreenTab.Icon = <FaPlay width={'100%'} color="black" />;
const machineLearningTab = new Tab();
machineLearningTab.Id = "ml-tab";
machineLearningTab.Name = "Stocks ML models";
machineLearningTab.isActive = false;
machineLearningTab.Icon = <FaRobot  size={20} color="black" />;
const riskModels = new Tab();
riskModels.Id = "risk-tab";
riskModels.Name = "Forecast beta";
riskModels.isActive = false;
riskModels.Icon = <CiWarning size={20} color="black" />;
const probabilityTab = new Tab();
probabilityTab.Id = "probabilty-model";
probabilityTab.Name = "Probability models";
probabilityTab.isActive = false;
probabilityTab.Icon = <FaRegChartBar  size={20} color="black" />;
export const apiMethodsTab = new Tab();
apiMethodsTab.Id = "api-methods";
apiMethodsTab.Name = "API Methods";
apiMethodsTab.isActive = false;
apiMethodsTab.Icon = <MdOutlineWeb size={20} color="black" />;

const myTimeSeriesTab = new Tab();
myTimeSeriesTab.Id = "my-time-series";
myTimeSeriesTab.Name = "Selected time series";
myTimeSeriesTab.isActive = false;
myTimeSeriesTab.Icon = <MdOutlineShowChart  size={20} color="black" />;
const myMethodsTab = new Tab();
myMethodsTab.Id = "my-methods";
myMethodsTab.Name = "Models and methods";
myMethodsTab.isActive = false;
myMethodsTab.Icon = <CiGlobe  size={20} color="black" />;
const selectTimeseriesTab = new Tab();
selectTimeseriesTab.Id = "select-time-series";
selectTimeseriesTab.Name = "Browse time series";
selectTimeseriesTab.isActive = false;
selectTimeseriesTab.Icon = <PiCursor  size={20} color="black" />;
export const strategyTab = new Tab();
strategyTab.Id = "strategy-tab";
strategyTab.Name = "Strategy";
strategyTab.isActive = false;
strategyTab.Icon = <PiStrategy  size={20} color="black" />;
const portfolioTab = new Tab();
portfolioTab.Id = "portfolio-tab";
portfolioTab.Name = "Portfolio settings";
portfolioTab.isActive = false;
portfolioTab.Icon = <FaShoppingBag  size={20} color="black" />;
const uploadTab = new Tab();
uploadTab.Id = "upload-tab";
uploadTab.Name = "Upload time series";
uploadTab.isActive = false;
uploadTab.Icon = <FaUpload  size={20} color="black" />;
const dateSettings = new Tab();
dateSettings.Id = "date-settings";
dateSettings.Name = "Date settings";
dateSettings.isActive = false;
dateSettings.Icon = <CiCalendarDate  size={20} color="black" />;
const screeningTab = new Tab();
screeningTab.Id = "screening";
screeningTab.Name = "Screening";
screeningTab.isActive = false;

export const initMainTabs = JSON.parse(JSON.stringify([
    selectionFiltersTab, theoryTab, machineLearningTab, apiMethodsTab, modernPortfolioTab,
]));

export const settingsTabs = JSON.parse(JSON.stringify([ strategyTab, uploadTab, selectTimeseriesTab, myTimeSeriesTab, myMethodsTab, portfolioTab, dateSettings, screeningTab ]));