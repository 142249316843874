/* eslint-disable @typescript-eslint/no-namespace */
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import moment from "moment";
import { Backtest, BacktestingMetaDTO, BacktestingSetting } from "../../backtesting-common-frontend";
import { SelectionFilterMenu } from "../../backtesting-common-frontend/filters";
import { runBacktest } from "../../backtesting-common-frontend/http-utilities/http-utilities/backtests/backtests-backend.service";
import { getDateFromLabel } from "../../backtesting-common-frontend/http-utilities/http-utilities/dates/dates.http.service";
import { ImpreemMethodDTO } from "../../backtesting-common-frontend/methods";
import { cloneDeep } from "../../backtesting-common-frontend/shared/utilites/object.utilities";
import { StatusDisplayDTO } from "../../backtesting-common-frontend/status/error-handling";
import { StrategyDTO } from "../../backtesting-common-frontend/strategies/strategy";
import { TimeSeriesService } from "../../backtesting-common-frontend/timeseries/timeseries.service";
import { updateMessage } from "../../store/backtests/backtests";
import { priceTimeseries } from "../timeseries/time-series.action";

export async function runAnyBacktest(
    currentBacktest: Backtest, 
    test: BacktestingSetting, 
    strategy: StrategyDTO, 
    timeSeriesService: TimeSeriesService,
    meta: BacktestingMetaDTO,
    methods?: ImpreemMethodDTO[],
    selectionFilters?: SelectionFilterMenu[]) {
    const cloneCurrentBacktest = cloneDeep(currentBacktest);
    const cloneCurrentTest = cloneDeep(test);
    const cloneCurrentStrategy = cloneDeep(strategy);
    if(cloneCurrentBacktest && cloneCurrentTest && cloneCurrentStrategy){
    // create a YYYY-MM-DD date 6 months ago
        const fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 6);
        const from = await getDateFromLabel(moment(fromDate).format("YYYY-MM-DD"));
        const toDate = new Date();
        const to = await getDateFromLabel(moment(toDate).format("YYYY-MM-DD"));
        if (from && to) {
            timeSeriesService.updateBacktestFrom(cloneCurrentTest, from?.label);
            timeSeriesService.updateBacktestTo(cloneCurrentTest, to?.label, true);
        }

        const extraMethod = new ImpreemMethodDTO();
        if(!methods || methods?.length === 0){
            extraMethod.timeseries.push(priceTimeseries);
        }

        cloneCurrentStrategy.intersection = methods != null && methods?.length > 0 ? methods : [ extraMethod ];
        cloneCurrentStrategy.selectionFilters = selectionFilters ?? [];

        cloneCurrentTest.strategies = [ cloneCurrentStrategy ];
        cloneCurrentBacktest.test = cloneCurrentTest;

        const res = await runBacktest(cloneCurrentBacktest._id, meta, false, true);
        return res;
    }
    return null;
}

export namespace BacktestActions {
    export async function runAll(
        dispatch: Dispatch<AnyAction>,
        backtests: Backtest[]
    ){
        const meta = new BacktestingMetaDTO();
        meta.calculateReturnsLast = true;
        meta.returnPortfolio = false;
        meta.takeFirstValue = true;
        meta.takeTestFromBacktest = false;
        meta.useCachedTimeSeries = [];
        const status = new StatusDisplayDTO("Queue started", "success");
        dispatch(updateMessage(status));
    
        const promises = backtests.map((backtest) => {
            return runBacktest(backtest._id, meta, true, false);
        });
        await Promise.all(promises);
    }
    export function hasBuyingSignal(backtest?: Backtest | undefined | null){
        return backtest != null && backtest?.historicalPortfolios?.length > 0 ? 
            backtest.historicalPortfolios[backtest.historicalPortfolios.length - 1].companies.length > 0 && 
        moment(backtest.historicalPortfolios[backtest.historicalPortfolios.length - 1].date.label).isSame(moment(), 'day') 
            : false;
    }
}