
import { useColorMode } from "@chakra-ui/react";
import Chart from "react-apexcharts";
import { PieChartDTO } from "../../backtesting-common-frontend/timeseries/models/timeseries.models";

export const pieChartColors = [
    "#FF6F61", // Light Coral
    "#85C1E9", // Light Sky Blue
    "#FFD700", // Light Gold
    "#D0B3E6", // Light Purple
    "#A9DFBF", // Light Green
    "#FF9AA2", // Light Pink
    "#76D7C4", // Light Teal
    "#FFD966", // Light Amber
    "#A6C8FF", // Light Azure Blue
    "#FF7F7F", // Light Red
    "#85C1B9", // Light Teal Green
    "#FFB266", // Light Orange
    "#9ACD9A", // Light Forest Green
    "#C39BD3", // Light Lavender
    "#7FDBFF", // Aqua
    "#FF6F61", // Light Coral Red
    "#D3D3D3", // Light Gray
    "#A9A9A9", // Dark Gray
    "#B0B0B0", // Medium Gray
    "#A9DFBF", // Light Bright Green
    "#FF9AA2", // Light Vibrant Pink
    "#D0B3E6", // Light Vivid Purple
    "#FFD966", // Light Bold Amber
    "#85C1E9", // Light Electric Blue
    "#76D7C4", // Light Teal
    "#FF7F7F", // Light Crimson
    "#A6C8FF", // Light Bright Blue
    "#FF6F61", // Light Bold Orange
    "#FFD700", // Light Vivid Yellow
    "#B0B0B0", // Medium Light Gray
    "#A9A9A9", // Medium Deep Gray
    "#D3D3D3", // Medium Light Gray
    "#C39BD3", // Light Royal Purple
    "#9ACD9A", // Light Fresh Green
    "#FF9AA2", // Light Electric Pink
    "#D0B3E6", // Light Intense Purple
    "#A6C8FF", // Light Brilliant Blue
];

export function PieChart({
    pieChart,
}: {
  pieChart: PieChartDTO;
}) {
    const { colorMode } = useColorMode();
    const chartOptions = {
        labels: pieChart.labels,
        tooltip: {
            theme: colorMode === "dark" ? "dark" : "light", // Set tooltip theme based on colorMode
        },
        theme: {
            mode: colorMode,
        },
        colors: pieChartColors,
    };

    const chart = (
        <Chart
            options={chartOptions}
            series={pieChart.values}
            type="pie"
            width="100%"
        />
    );

    return <div style={{ flex: 1, width: "100%" }}>{chart}</div>;
}
