import * as express from "express";
import { createId } from "../shared/utilites/string.utilities";

export type messageType = "error" | "info" | "success" | "warning";

export class StatusDisplayDTO {
    public message: string = null;

    public loading = false;

    public type: messageType = "success";

    public frontendId: string = createId();

    public task: string = null;

    public show = true;

    public meta: string = null;

    constructor(message: string, type: messageType, loading?: boolean, task?: string, show?: boolean, meta?: string) {
        this.message = message;
        this.type = type;
        this.loading = loading;
        this.task = task;
        this.show = show;
        this.meta = meta;
    }
}

export const informUser = (req: express.Request, statusDisplay: StatusDisplayDTO): void => {
    req.app.get("io").to(req.body.RequestClientId).emit("status", statusDisplay);
};
