import mongoose from "mongoose";
const Schema = mongoose.Schema;

const inprogressSchema = new Schema({
    backtest: { type: Schema.Types.ObjectId, ref: "backtest" },
    atStep: Number,
    totalSteps: Number,
    ended: Boolean,
    created: Date,
    name: String,
    client: { type: Schema.Types.ObjectId, ref: "clients" },
    description: String,
    type: String,
});

const inprogress = mongoose.model("inprogresses", inprogressSchema);

export default inprogress;
