import mongoose from "mongoose";
const Schema = mongoose.Schema;

const indexesSchema = new Schema({
    symbol: String,
    price: Schema.Types.Mixed,
    name: String
}, { strict: false });

const indexes = mongoose.model("indexes", indexesSchema);

export default indexes;
