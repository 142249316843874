/* eslint-disable @typescript-eslint/no-namespace */
import { InfoIcon } from '@chakra-ui/icons';
import {
    Box,
    Divider,
    HStack,
    Heading,
} from '@chakra-ui/react';
import React from 'react';

export namespace ExplainerInfo{
    interface InfoProps {
        text: string | JSX.Element;
        title?: string;
      }
      
      export const Information: React.FC<InfoProps> = ({ text, title }) => {
          const [ show, setShow ] = React.useState(false);
          return (
              <Box as="span" ml={2} display="inline-block" p="4" bg={'gray.900'}>
                  <HStack>
                      <InfoIcon color={'#FDF8AF'} w={25} h={25} style={{ cursor: "pointer" }} onClick={() => {
                          setShow(!show);
                      }} />
                      {title && <Heading size="sm" color={'white'}>{title}</Heading>}
                  </HStack>
                  {show && <>
                      <Divider mt={4} mb={4} />
                      {text}
                  </>}
              </Box>
          );
      };
}

