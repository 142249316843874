
import { Backtest, GraphItem } from "../../..";
import { TimeSeriesDTO } from "../../../methods";
import { graphActive } from "../../../timeseries/display/display.service";
import { TransformationDTO } from "../../../transformations/transformations-dtos";
import { sendPost } from "../frontend/http-utilities";

export async function getTransformations(): Promise<TransformationDTO[]> {
    return sendPost("transformations/get-all-transformations").then(e => {
        return e.data as TransformationDTO[];
    }).catch(() => {
        return [];
    });
}

export async function getPreviewTransformations(symbols: string[],
    backtest: Backtest,
    timeSeriesDTO: TimeSeriesDTO,
    returnType: string | null | undefined,
    groupId: string | null = null,
    displayType: graphActive | null | undefined = null): Promise<GraphItem[] | null> {
    const data = {
        symbols,
        backtest,
        timeSeriesDTO,
        returnType,
        groupId,
        displayType,

    };
    return sendPost("transformations/get-preview-transformation", data)
        .then(e => {
            return e.data as GraphItem[];
        })
        .catch(() => {
            return null;
        });
}
