import { Progress } from "@chakra-ui/react";
import { Backtest } from "../../backtesting-common-frontend";

export default function ProcessDisplay({ backtestResults }: { backtestResults: Backtest }) {

    if (backtestResults == null) {
        return null;
    }

    const progressBarProcentage = backtestResults.summary?.timeUnitsLeft != null
        && backtestResults.numberOfStepsTotal != null ? (backtestResults.numberOfStepsTotal - backtestResults.summary?.timeUnitsLeft) / backtestResults.numberOfStepsTotal : 0;

    return (
        <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '100%' }}>
                <Progress value={progressBarProcentage * 100} />
            </div>
        </div>
    );
}