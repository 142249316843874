/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useColorMode } from "@chakra-ui/react";
import { min } from "lodash";
import moment from "moment";
import React from "react";
import Chart from "react-apexcharts";
import { TimeSeriesDTO } from "../../backtesting-common-frontend/methods";
import { max, round, seq_along, toScientific } from "../../backtesting-common-frontend/shared/utilites/math.utilities";
import { cloneDeep } from "../../backtesting-common-frontend/shared/utilites/object.utilities";
import { transformReturns, transformToPrecent } from "../../backtesting-common-frontend/shared/utilites/transform";
import { TimeSeriesHelperValue } from "../../backtesting-common-frontend/timeseries/timeseries-models";
import { ColorsDark } from "../shared/constants/Colors";

export class AnnotationsDTO {
    public x: number | Date;
    public y: number;
    public image?: any;
    public label?: any;

    constructor(xValue: number, yValue: number, imageValue: any) {
        this.x = xValue;
        this.y = yValue;
        this.image = imageValue;
    }
}

function GraphComponent({ TimeSeriesDTOs, shouldDisplayPreviewMethod, index, transform, xaxisType }:
    {
        TimeSeriesDTOs: TimeSeriesDTO[],
        shouldDisplayPreviewMethod: boolean,
        index?: number,
        transform?: boolean,
        xaxisType?: 'datetime' | 'numeric',
    }) {
    const { colorMode } = useColorMode();
    const [ colors ] = React.useState(new ColorsDark());
    const anyPastForecast = TimeSeriesDTOs.some(x => x?.graphValue?.some(y => y?.isPastForecast));

    const seriesPreviewSeries = TimeSeriesDTOs.filter(e => e.graphValue != null).map(e => {
        if (transform) {
            const n = cloneDeep(e.graphValue) as TimeSeriesHelperValue[];
            const values = transformToPrecent(transformReturns(n.map(x => x.value)));
            return values.map((t, i) => {
                return {
                    value: t,
                    date: n[i].date,
                };
            });
        } else {
            return e.graphValue.filter(t => t.value != null);
        }
    });

    // check if any seriesPreviewSeries is small numbers series between -1 to 1
    const isSmallNumbers = seriesPreviewSeries.find(e => e.find(t => t.value > 1 || t.value < -1) == null) != null;

    const timeSeriesToShowInGraph: any = [];
    seriesPreviewSeries.forEach((t, index) => {
        const previewSeries = {
            name: TimeSeriesDTOs[index].display,
            data: t.map((e, i) => {
                const timestamp = Date.parse(e.date);
                let d: Date | null = null;
                if (isNaN(timestamp) == false) {
                    d = new Date(timestamp);
                }
                if (d == null) {
                    return;
                }
                return {
                    x: xaxisType === "numeric" ? i : d,
                    y: toScientific(e.value),
                };
            }),
        };
        timeSeriesToShowInGraph.push(previewSeries);
    });

    const annotationsDTO: any[] = [];
    let graphDataPreview = [];

    if (shouldDisplayPreviewMethod && TimeSeriesDTOs.find(e => e.graphValue.find(x => x.buy) != null) != null) {

        const timeSeriesUsed = TimeSeriesDTOs[0].graphValue;
        let numberOfTrueGroup = 0;
        let prevent = 0;
        for (let i = 0; i < timeSeriesUsed.length; i++) {
            if (timeSeriesUsed[i].buy && prevent === 0) {
                numberOfTrueGroup++;
                prevent = 1;
            } else if (timeSeriesUsed[i].buy === false && prevent === 1) {
                prevent = 0;
            }
        }

        const totalArrayCount = seq_along(numberOfTrueGroup).map(e => timeSeriesUsed.map((t, i) => {
            return { x: new Date(t.date), y: null } as unknown as { x: Date, y: number };
        }));

        let index = 0;
        let preventDup = 0;
        let first = false;
        for (let p = 0; p < timeSeriesUsed.length; p++) {
            if (timeSeriesUsed[p].buy && (index) < totalArrayCount.length) {
                preventDup = 0;
                const maxValue = max(timeSeriesUsed.map(e => e.value)) as number;
                const minValue = min(timeSeriesUsed.map(e => e.value)) as number;
                totalArrayCount[index][p].y = (timeSeriesUsed[p].value - minValue) / (maxValue - minValue);
                annotationsDTO.push({
                    x: totalArrayCount[index][p].x.getTime(),
                    y: (timeSeriesUsed[p].value),
                    // image: {
                    //     path: "https://assetsonlinevan1.nyc3.cdn.digitaloceanspaces.com/impreem/cross.png",
                    //     width: 20,
                    //     height: 20,
                    // },
                });
                first = true;
            } else if (preventDup === 0 && first) {
                preventDup = 1;
                if (totalArrayCount[index] != null && totalArrayCount[index][p] != null) {
                    const maxValue = max(timeSeriesUsed.map(e => e.value));
                    const minValue = min(timeSeriesUsed.map(e => e.value)) as number;
                    totalArrayCount[index][p].y = (timeSeriesUsed[p].value - minValue) / (maxValue - minValue);
                }
                index++;
            }
        }

        graphDataPreview = totalArrayCount.map((e, i) => {
            return {
                name: "" + i,
                data: e,
                pointRadius: 0,
            };
        }) as any;
    }

    let chart: JSX.Element | null = null;

    const pastForecastGraphColors = [
        "#FF6633", "#FFB399", "#FF33FF", "#FFFF99", "#00B3E6",
        "#E6B333", "#3366E6", "#999966", "#99FF99", "#B34D4D",
        "#80B300", "#809900", "#E6B3B3", "#6680B3", "#66991A",
        "#FF99E6", "#CCFF1A", "#FF1A66", "#E6331A", "#33FFCC",
        "#66994D", "#B366CC", "#4D8000", "#B33300", "#CC80CC",
        "#66664D", "#991AFF", "#E666FF", "#4DB3FF", "#1AB399",
        "#E666B3", "#33991A", "#CC9999", "#B3B31A", "#00E680",
        "#4D8066", "#809980", "#E6FF80", "#1AFF33", "#999933",
    ];
    const graphColors = !anyPastForecast ? [ colors.blue, colorMode === "light" ? colors.heavyGray : colors.realWhite ] : [ colors.blue, ...pastForecastGraphColors ];

    let xAxis = {};
    if(xaxisType != null && xaxisType === "numeric"){
        xAxis = {
            labels: {
                formatter: function(val: number) {
                    return val.toString();
                },
            },
        };
    }else if (!xaxisType) {
        xAxis = {
            labels: {
                datetimeFormatter: {
                    month: 'MMM \'yy',
                },
            },
            type: 'datetime',
        };
    } else {
        xAxis = {
            labels: {
                formatter: function(val) {
                    return moment(val).format("YYYY-MM-DD");
                },
            },
        };
    }

    chart = (<Chart
        options={{
            yaxis: {
                labels: {
                    formatter: function(val) {
                        return round(val, isSmallNumbers ? 100 : 5).toString() + (transform ? '%' : '');
                    },
                    style: {
                        colors: colorMode === 'dark' ? '#ffffff' : '#333', // Adjust label text color for dark mode
                    },
                },
                tickAmount: 10,
            },
            xaxis: {
                tickAmount: 10,
                ...xAxis,
                labels: {
                    style: {
                        colors: colorMode === 'dark' ? '#ffffff' : '#333', // Adjust x-axis label text color for dark mode
                    },
                },
            },
            annotations: {
                points: annotationsDTO as any,
            },
            colors: graphColors,
            tooltip: {
                theme: colorMode === 'dark' ? 'dark' : 'light', // Set tooltip theme based on colorMode
            },
        }}
        series={[
            ...timeSeriesToShowInGraph,
            // ...graphDataPreview
        ]}
        type="line"
        width="100%"
            
    />);

    return (
        <div style={{ flex: 1, width: '100%' }}>
            {chart}
        </div>
    );
}

const LineChartPretty = React.memo(GraphComponent);

export default LineChartPretty;