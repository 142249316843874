/* eslint-disable @typescript-eslint/no-inferrable-types */
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { Backtest, BacktestingSetting, GraphItem, Series } from "../../backtesting-common-frontend";
import { ImpreemDate } from "../../backtesting-common-frontend/date/impreem-date";
import { SelectionFilterMenu } from "../../backtesting-common-frontend/filters";
import { TimeSeriesDTO } from "../../backtesting-common-frontend/methods";
import { StrategyDTO } from "../../backtesting-common-frontend/strategies/strategy";
import { CriteriaBoardMethod } from "../../backtesting-common-frontend/techniques";
import { graphActive } from "../../backtesting-common-frontend/timeseries/display/display.service";
import { deepClone } from "../../backtesting-common-frontend/worker/transformations/transformation-service";
import { Tab } from "../../components/tabs";
import { MethodsResultsManager } from "../../managers/methods/methods-manager";
import { TimeSeriesResultsManager } from "../../managers/time-series/time-series-manager";
import { isSameTimeSeries } from "../../managers/time-series/timeseries-helper";
import { setLoading } from "../../store/backtests/backtests";
import { bulkAddAndOverwriteGraphItemRedux, setEditActive, setTheoryActive, setTheoryActiveMainRedux } from "../../store/graphitems/graphitems";
import { findCriteriaBoardMethodAssumeNoMethodChange } from "../criteria-board/criteria-board.actions";
import { setActiveGraphProperties } from "../graph-items/graph-items";
import { createOrEditNewMehtodOrCurrent } from "../methods/methods.actions";

export function convertTimeSeriesDTOtoSeries(ts: TimeSeriesDTO[]){
    const series: Series[][] = [];
    ts.forEach(p => {
        const seriesHelper: Series[] = [];
        p.graphValue.forEach(t => {
            const date = new ImpreemDate(-1, t.date);
            const s = new Series(date, t.value);
            seriesHelper.push(s);
        });
        series.push(seriesHelper);
    });
    return series;
}

export async function getOriginalTimeSeries(
    dispatch:  Dispatch<AnyAction>,
    currentGraphItem: GraphItem,
    currentBacktestCtx: Backtest,
    graphItems: GraphItem[],
    currentTimeseries: TimeSeriesDTO,
    timeSeriesResultsManager: TimeSeriesResultsManager,
    shouldRemoveScale?: boolean,
    addToGraphItems: boolean = true,
    useSameTransformation: boolean = false) {
    const transformationKey = currentGraphItem.timeSeries.transformationKey;
    if (transformationKey == null || currentBacktestCtx == null) return;
    const parts = transformationKey.split("-");
    const keyRelevant = "none-" + parts.slice(1).join("-");
    let originalSeriesFromGraph = timeseriesItemsExistsReturnOriginal("none", useSameTransformation ? transformationKey : keyRelevant, graphItems, shouldRemoveScale != null && shouldRemoveScale ? false : true) as (TimeSeriesDTO | null | undefined);

    dispatch(setLoading(true));
    if (originalSeriesFromGraph == null || originalSeriesFromGraph.categorizeType != null
        || originalSeriesFromGraph.normalization !== "none") {
        let thinkIsOriginal = deepClone(currentTimeseries);
        if (shouldRemoveScale) {
            thinkIsOriginal.normalization = "none";
        }
        thinkIsOriginal = timeSeriesResultsManager.setDefaultTimeSeries(thinkIsOriginal, useSameTransformation);
        originalSeriesFromGraph = await getGraphItemHelper(dispatch, currentGraphItem, thinkIsOriginal, currentTimeseries, timeSeriesResultsManager, currentBacktestCtx, graphItems, originalSeriesFromGraph ?? null, addToGraphItems);
    }
    dispatch(setLoading(false));
    return originalSeriesFromGraph;
}

export async function getTimeSeriesWithNormalizationNone(
    dispatch:  Dispatch<AnyAction>,
    currentGraphItem: GraphItem,
    currentBacktest: Backtest,
    graphItems: GraphItem[],
    currentTimeseries: TimeSeriesDTO,
    timeSeriesResultsManager: TimeSeriesResultsManager,
    shouldRemoveScale?: boolean,
    addToGraphItems: boolean = true) {
    if (graphItems == null) return null;
    const transformationKey = currentGraphItem.timeSeries.transformationKey;
    if (transformationKey == null) return;
    let originalSeriesFromGraph = timeseriesItemsExistsReturnOriginal("none", transformationKey, graphItems) as (TimeSeriesDTO | null | undefined);

    dispatch(setLoading(true));
    if (originalSeriesFromGraph == null || originalSeriesFromGraph.categorizeType != null
        || originalSeriesFromGraph.normalization !== "none") {
        const thinkIsOriginal = deepClone(currentTimeseries);
        if (shouldRemoveScale) {
            thinkIsOriginal.normalization = "none";
        }
        originalSeriesFromGraph = await getGraphItemHelper(dispatch, currentGraphItem, thinkIsOriginal, currentTimeseries, timeSeriesResultsManager, currentBacktest, graphItems, originalSeriesFromGraph, addToGraphItems);
    }
    dispatch(setLoading(false));
    return originalSeriesFromGraph;
}

export const addAndDispatchOrNull = (item: GraphItem, graphItems: GraphItem[], dispatch: Dispatch<AnyAction>, unshift?: boolean) => {
    if (graphItems == null) return null;
    const ts = item.timeSeries;
    const normalizationCriteria = ts.normalization;
    const transformationKey = ts.transformationKey;
    const exists = graphItemsExists(normalizationCriteria, transformationKey, graphItems, ts.parameters, ts.transformations);
    if (exists == null) {
        if (unshift) {
            graphItems.unshift(item);
        } else {
            graphItems.push(item);
        }
        dispatch(bulkAddAndOverwriteGraphItemRedux(graphItems));
        setActiveGraphProperties(graphItems, dispatch);
    }
};

export function timeseriesItemsExistsReturnOriginal(normalization: string | null | undefined, transformationKey: string, graphItems: GraphItem[], ignoreNormalization: boolean = false) {
    // check if any of the items is already in the graph
    const exists = graphItems.find(x => x.timeSeries.transformationKey === transformationKey && (ignoreNormalization || x.timeSeries.normalization === normalization));
    const childInExists = graphItems.map(x => x.extras.find(p => p.transformationKey === transformationKey && (ignoreNormalization || p.normalization === normalization)));
    return exists?.timeSeries ?? childInExists?.filter(t => t != null)?.[0];
}

export function graphItemsExists(normalization: string | undefined, transformationKey: string, graphProperties: GraphItem[], parameters?: object | null, transformations?: string[]) {
    // check if any of the items is already in the graph
    const exists = graphProperties.find(x => 
        JSON.stringify(x.timeSeries.transformations) === JSON.stringify(transformations) &&
        x.timeSeries.transformationKey === transformationKey && x.timeSeries.normalization === normalization && (parameters ? JSON.stringify(x.timeSeries.parameters) === JSON.stringify(parameters) : true));
    return exists;
}

export function setTheoryItemGraphActive(item: GraphItem, graphItems: GraphItem[], dispatch:  Dispatch<AnyAction>) {
    if (graphItems == null) return null;
    const ids: string[] = [];
    graphItems.forEach(e => {
        if (isSameTimeSeries(e.timeSeries, item.timeSeries)) {
            e.theoryActive = !e.theoryActive;
        }
        if(e.theoryActive){
            ids.push(e._id);
        }
        return e;
    });
    dispatch(setTheoryActive(ids));
}

export function setTheoryActiveMain(dispatch: Dispatch<AnyAction>, graphItems: GraphItem[], ts: GraphItem) {
    if (graphItems == null) return null;
    let id: string | null = null;
    graphItems.forEach(e => {
        if (e.timeSeries.transformationKey === ts.timeSeries.transformationKey) {
            e.theoryActiveMain = true;
            e.theoryActive = true;
        } else {
            e.theoryActiveMain = false;
        }
        if(e.theoryActiveMain){
            id = e._id;
        }
        return e;
    });
    dispatch(setTheoryActiveMainRedux(id));
}

export function setEditItemGraphActive(item: GraphItem, graphItems: GraphItem[], dispatch: Dispatch<AnyAction>) {
    if (graphItems == null) return null;
    let id: string | null = null;
    graphItems.forEach(e => {
        if (isSameTimeSeries(e.timeSeries, item.timeSeries)) {
            e.editActive = !e.editActive;
        } else {
            e.editActive = false;
        }
        if(e.editActive){
            id = e._id;
        }
        return e;
    });
    console.log(id);
    dispatch(setEditActive(id));
}

async function getGraphItemHelper(
    dispatch:  Dispatch<AnyAction>,
    currentGraphItem: GraphItem,
    thinkIsOriginal: TimeSeriesDTO,
    currentTimeseries: TimeSeriesDTO,
    timeSeriesResultsManager: TimeSeriesResultsManager,
    Backtest: Backtest,
    graphItems: GraphItem[],
    originalSeriesFromGraph: TimeSeriesDTO | null | undefined,
    addToGraphItems: boolean): Promise<TimeSeriesDTO | null | undefined> {
    if (graphItems == null) return;
    thinkIsOriginal.parameters = currentTimeseries.parameters;
    const data = await timeSeriesResultsManager.getGraphHelper(dispatch, thinkIsOriginal, Backtest, graphItems, 'http');
    if (data != null) {
        const entity = data.find(t => t.timeSeries.transformationKey === thinkIsOriginal.transformationKey);
        originalSeriesFromGraph = entity?.timeSeries;
        if (addToGraphItems && entity) {
            currentGraphItem.extras.push(entity?.timeSeries);
        }
    }
    return originalSeriesFromGraph;
}

export function addTimeSeriesToMethod(dispatch:  Dispatch<AnyAction>, 
    criteriaBoardsUnSavedCtx: CriteriaBoardMethod | null | undefined, 
    test: BacktestingSetting | null | undefined, 
    currentTab: Tab | null | undefined, 
    item: GraphItem | TimeSeriesDTO, 
    currentStrategy: StrategyDTO | null) {
    if(!currentTab  || !test) return;
    const board = findCriteriaBoardMethodAssumeNoMethodChange(dispatch, criteriaBoardsUnSavedCtx, test, currentTab);
    const currentMethod = MethodsResultsManager.getCurrentMethod(test, currentTab);
    const ts: TimeSeriesDTO[] = [];
    if (currentMethod) {
        currentMethod.timeseries.forEach(t => {
            ts.push(t);
        });
    }
    if (item && TimeSeriesResultsManager.isTimeSeries(item)){
        ts.push(item);
    }else if(!TimeSeriesResultsManager.isTimeSeries(item) && item?.timeSeries) {
        ts.push(item.timeSeries);
    }
    createOrEditNewMehtodOrCurrent(
        dispatch,
        test,
        currentTab,
        new MethodsResultsManager(),
        board,
        currentMethod?.name ?? "Add a note to this method",
        null,
        ts,
        currentStrategy,
        null,
        undefined
    );
}

export async function getTimeSeries(
    symbols: string[],
    item: TimeSeriesDTO | SelectionFilterMenu, 
    timeSeriesResultsManager: TimeSeriesResultsManager,
    dispatch: Dispatch<AnyAction>,
    graphItems: GraphItem[],
    backtest: Backtest | null | undefined,
    graphType?: graphActive | null, 
    saveAnyWay?: boolean) {
    if (TimeSeriesResultsManager.isTimeSeries(item) && backtest) {
        dispatch(setLoading(true));
        let res: GraphItem[] | null | undefined = null;
        try {
            res = await timeSeriesResultsManager.addGraphItem(symbols, dispatch, item, backtest, graphItems, (() => {
                // empty
            }), undefined, undefined, undefined, graphType, saveAnyWay ?? TimeSeriesResultsManager.isCompanyTimeSeries(item) ? true : undefined);
            if(res){
                dispatch(setTheoryActive([ res[0]._id ]));
            }
        } catch (error) {
            // empty
        } finally {
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 1000);
        }
        return res;
    }
}

export const createFakeGraphItem = (timeSeries: TimeSeriesDTO) => {
    const graphItem = new GraphItem();
    graphItem.timeSeries = timeSeries;
    return graphItem;
};

export const goldTimeSeries = {
    "categorizeType" : null,
    "normalization" : "none",
    "disaggregationMethod" : "previous-value",
    "stationary" : false,
    "multiply" : null,
    "transformationKey" : "none-commodities-GCUSD-adjClose-date",
    "timeSeriesKey" : "commodities-GCUSD-adjClose-date",
    "display" : "",
    "category" : "Commodities",
    "title" : "Commodities",
    "collectionInDb" : "commodities",
    "field" : "adjClose",
    "column" : "adjClose",
    "transformationId" : "63273a03ca1c000034001cc5",
    "active" : true,
    "date" : "date",
    "periodicity" : "day",
    "main" : true,
    "ID" : "GCUSD",
} as TimeSeriesDTO;

export const priceTimeseries = {
    transformationKey: "none-companies-price_x-adjClose-date",
    timeSeriesKey: "companies-price_x-adjClose-date",
    display: "Price",
    category: "Price",
    title: "Price",
    collectionInDb: "companies",
    field: "price_x",
    column: "adjClose",
    transformationId: "63273a03ca1c000034001cc5",
    graphValue: [
        {
            value: 0,
            date: "",
        },
    ],
    active: false,
    date: "date",
    periodicity: "day",
} as TimeSeriesDTO;
