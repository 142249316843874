import axios from "axios";
import inprogress from "../../../database/user-DB/backend/inprogress";
import { env } from "../../../env";
import { sleep } from "../../../shared/utilites/utilities";
import { InProgressDTO } from "../../../status/inprogress-dto";

export async function createProgress(progressDTO: InProgressDTO, userId: string): Promise<InProgressDTO> {
    await sleep(5000);
    return axios({
        method: "post",
        url: env.routerUrl + "progress/create",
        data: { ...progressDTO, client: userId.toString(), userId: userId.toString() },
        headers: {
            authorization: "bearer superGoodPassword",
        },

    }).then(e => {
        return e.data as InProgressDTO;
    }).catch(() => {
        return null;
    });
}

export async function sendProgress(progressDTO?: InProgressDTO | null, userId?: string): Promise<any> {
    if (progressDTO == null) return;
    await inprogress.updateOne({ _id: progressDTO._id }, progressDTO).exec();
    return axios({
        method: "post",
        url: env.routerUrl + "progress/send-progress",
        data: { ...progressDTO, userId: userId.toString() },
        headers: {
            authorization: "bearer superGoodPassword",
        },

    }).then(e => {
        return null;
    }).catch(() => {
        return null;
    });
}

export async function deleteProgress(progressDTO: InProgressDTO, userId: string): Promise<any> {
    await sleep(5000);
    return axios({
        method: "post",
        url: env.routerUrl + "progress/delete",
        data: { inProgressId: progressDTO._id.toString(), userId: userId.toString() },
        headers: {
            authorization: "bearer superGoodPassword",
        },

    }).then(e => {
        return null;
    }).catch(() => {
        return null;
    });
}
