/* eslint-disable @typescript-eslint/no-explicit-any */

import { Container, Tab, TabList, Tabs, useColorModeValue } from "@chakra-ui/react";
import { FaHome, FaPlay } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdPieChart, MdSettings } from "react-icons/md";
import { RiProfileFill, RiStockLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { isBacktestLicense } from "../../../backtesting-common-frontend/http-utilities/auth/auth-http-utilities";
import { AppState } from "../../../store/store";
import { updateCurrentMainTab, updateNavigationTo } from "../../../store/tabs/tabs";
import { initMainTabs, settingsTabs } from "../../../store/tabs/tabs-init";
import { Tab as TabEdvardWo } from "../../tabs";

export default function HomeMenu() {
    const dispatch = useDispatch();
    const navigateTo = useSelector((state: AppState) => state.tabs.navigationTo);

    const homeTab = new TabEdvardWo();
    homeTab.Id = "" as any;
    homeTab.Name = "Home";
    homeTab.isActive = false;
    homeTab.Icon = <FaHome  size={20} color={useColorModeValue('black', 'white')} />;

    const backtestingTab = new TabEdvardWo();
    backtestingTab.Id = isBacktestLicense() ? "backtesting" : "" as any;
    backtestingTab.Name = "Backtesting";
    backtestingTab.isActive = false;
    backtestingTab.Icon = <RiStockLine size={20} color={useColorModeValue('black', 'white')} />;
    backtestingTab.meta = initMainTabs.find(x => x.Id === "theory-tab");

    const runBacktest = new TabEdvardWo();
    runBacktest.Id = "run-backtest" as any;
    runBacktest.Name = "Run backtest";
    runBacktest.isActive = false;
    runBacktest.Icon = <FaPlay size={20} color={useColorModeValue('black', 'white')} />;
    runBacktest.meta = initMainTabs.find(x => x.Id === "play-mode");

    const portfolioAllocation = new TabEdvardWo();
    portfolioAllocation.Id = "portfolio-allocation" as any;
    portfolioAllocation.Name = "Portfolio allocation";
    portfolioAllocation.isActive = false;
    portfolioAllocation.Icon = <MdPieChart size={20} color={useColorModeValue('black', 'white')} />;
    portfolioAllocation.meta = initMainTabs.find(x => x.Id === "modern-portfolio-tab");

    const settings = new TabEdvardWo();
    settings.Id = "settings" as any;
    settings.Name = "Settings";
    settings.isActive = false;
    settings.Icon = <MdSettings size={20} color={useColorModeValue('black', 'white')} />;
    settings.meta = settingsTabs.find(x => x.Id === "date-settings");

    const screening = new TabEdvardWo();
    screening.Id = "screening" as any;
    screening.Name = "Screening";
    screening.isActive = false;
    screening.Icon = <RiProfileFill size={20} color={useColorModeValue('black', 'white')} />;
    screening.meta = settingsTabs.find(x => x.Id === "screening");

    const logout = new TabEdvardWo();
    logout.Id = "logout" as any;
    logout.Name = "Logout";
    logout.isActive = false;
    logout.Icon = <GiHamburgerMenu size={20} color={useColorModeValue('black', 'white')} />;
    logout.meta = settingsTabs.find(x => x.Id === "screening");

    // const profile = new TabEdvardWo();
    // profile.Id = "profile" as any;
    // profile.Name = "Profile";
    // profile.isActive = false;
    // profile.Icon = <RiProfileFill size={20} color={useColorModeValue('black', 'white')} />;
    // profile.meta = settingsTabs.find(x => x.Id === "screening");

    const currentTabCtx = [ ];

    if(isBacktestLicense()){
        currentTabCtx.push(...[ homeTab, backtestingTab, screening, runBacktest, portfolioAllocation, settings, logout ]);
    }

    if(!isBacktestLicense()){
        currentTabCtx.push(...[ backtestingTab, screening, logout ]);
    }

    return (
        <Container minW={'full'} flex={1}>
            {navigateTo && navigateTo !== "" && <Navigate to={navigateTo} replace={true} />}
            <Tabs>
                <TabList bg={useColorModeValue('white', '#1A202C')}>
                    {currentTabCtx.map((tab: TabEdvardWo) => (
                        <Tab key={tab.Id} cursor={'pointer'} width={'full'} onClick={() => {
                            dispatch(updateNavigationTo("/" + tab.Id));
                            if(tab.meta){
                                dispatch(updateCurrentMainTab(tab.meta));
                            }
                        }}>
                            {tab.Icon}
                        </Tab>
                    ))}
                </TabList>
            </Tabs>
        </Container>
    );
}
