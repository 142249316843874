import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Checkbox,
    Container,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { SelectionFilterMenu } from '../../backtesting-common-frontend/filters';
import { TimeSeriesDTO } from '../../backtesting-common-frontend/methods';

export class CategoryItem {
    categoryTitle: string;
    items: (SelectionFilterMenu | TimeSeriesDTO)[];
    category: string;
}

interface SelectTimeSeriesProps<T extends CategoryItem> {
  data: T[];
  add?: (selectedItem: (SelectionFilterMenu | TimeSeriesDTO)) => void;
  onAddAll?: () => void;
  children?: ReactNode;
  onHandleSelectionChange? : (value: (SelectionFilterMenu | TimeSeriesDTO)) => void;
}

export const SelectTimeSeries = <T extends CategoryItem>({ data, add, onAddAll, onHandleSelectionChange, children, ...props }: SelectTimeSeriesProps<T>) => {

    const [ selectedCategory, setSelectedCategory ] = useState<T | null>(null);
    const [ selectedSelectionFilter, setSelectedSelectionFilter ] = useState<(SelectionFilterMenu | TimeSeriesDTO | null)>(null);
    const [ options, setOptions ] = useState<{label: string, value: SelectionFilterMenu | TimeSeriesDTO}[]>([]);

    const handleCategorySelect = useCallback((category: T) => {
        setSelectedCategory(category);
    }, []);

    const handleInputChange = useCallback((value) => {
        if (selectedCategory?.category === 'symbols' && value.length >= 2) {
            const symbols = selectedCategory.items.filter(
                (e) => e.ID != null && e?.ID.toLowerCase().includes(value.toLowerCase())
            );
            const newOptions = symbols.map((e) => ({ label: e.display, value: e }));
            setOptions(newOptions);
        }
    }, [ selectedCategory ]);

    const handleSelectionChange = useCallback((value) => {
        setSelectedSelectionFilter(value.value);
        if(onHandleSelectionChange){
            onHandleSelectionChange(value.value);
        }
    }, []);

    useEffect(() => {
        if (selectedCategory && selectedCategory.items.length > 0) {
            const current = selectedCategory;
            const useOptions = current?.items ? current?.items : data[0].items;
            if (useOptions &&  current?.category !== "symbols") {
                const options = useOptions.map((e) => {
                    return { label: e.display, value: e };
                });
                setOptions(options);
            } else if (current?.category === "symbols") {
                const fake = new SelectionFilterMenu();
                fake.ID = "search-symbols";
                const staticOption = { label: 'Search for a symbol', value: fake };
                setOptions([ staticOption ]);
            }
        }
    }, [ selectedCategory, data ]);

    return (
        <Container minW={'full'} bg={useColorModeValue('white', 'whiteAlpha.100')} boxShadow={'xl'} rounded={'lg'} p={2} {...props}>
            <Box width="100%" p={1}>
                <Menu>
                    <MenuButton as={Button} rounded={0} rightIcon={<ChevronDownIcon />} width={'full'}>
                        {selectedCategory ? selectedCategory.categoryTitle : 'Choose category'}
                    </MenuButton>
                    <MenuList>
                        {data &&
              data.length > 0 &&
              data.map((category) => (
                  <MenuItem key={category.categoryTitle} minH="48px" onClick={() => handleCategorySelect(category)}>
                      <span>{category.categoryTitle}</span>
                  </MenuItem>
              ))}
                    </MenuList>
                </Menu>
            </Box>
            <Box width="100%" p={1}>
                <Select
                    variant="filled"
                    onInputChange={handleInputChange}
                    options={options}
                    placeholder="Choose a Sample"
                    onChange={handleSelectionChange}
                />
            </Box>
            <Box width="100%" p={1}>
                <Flex>
                    {selectedCategory && selectedCategory.category !== 'symbols' && onAddAll != null && (
                        <>
                            <Text fontSize="md" mr={2} mt={1}>
                Add all {selectedCategory.categoryTitle}
                            </Text>
                            <Checkbox size="lg" colorScheme="green" />
                        </>
                    )}
                    {add && selectedSelectionFilter && <Button colorScheme="green" size="sm" ml="auto" onClick={() => add(selectedSelectionFilter)}>
            Add
                    </Button>}
                </Flex>
            </Box>
            <Box width="100%" p={3}>
                {children}
            </Box>
        </Container>
    );
};
