import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { GraphItem } from "../../backtesting-common-frontend";
import { setEditActive, setTheoryActive, setTheoryActiveMainRedux } from "../../store/graphitems/graphitems";

export function setActiveGraphProperties(graphItems: GraphItem[], dispatch: Dispatch<AnyAction>) {
    const editActive = graphItems.find((graphItem) => graphItem.editActive);
    const theoryActive = graphItems.filter((graphItem) => graphItem.theoryActive);
    const theoryActiveMain = graphItems.find((graphItem) => graphItem.theoryActiveMain);
    dispatch(setEditActive(editActive?._id));
    dispatch(setTheoryActiveMainRedux(theoryActiveMain?._id));
    dispatch(setTheoryActive(theoryActive.map((graphItem) => graphItem._id)));
}