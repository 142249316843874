import { BacktestingSetting } from "../../..";
import { sendPost } from "../frontend/http-utilities";


export async function updateTest(test: BacktestingSetting): Promise<any> {
    return sendPost("tests/update", test).catch((e) => {
        return null;
    });
}

export async function getTest(test: BacktestingSetting): Promise<any> {
    return sendPost("tests/get", test).catch((e) => {
        return null;
    });
}