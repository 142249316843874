import { Heading } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "../../backtesting-common-frontend/shared/utilites/object.utilities";
import { AppState } from "../../store/store";
import { addSymbolsStore } from "../../store/symbols/symbols";

export default function SelectSymbols() {
    const dispatch = useDispatch();
    const timeSeriesMenu = useSelector(
        (state: AppState) => state.backtests.menuCategory
    );
    const [ allSymbols, setAllSymbols ] = React.useState<string[]>([]);
    const [ options, setOptions ] = React.useState<{label: string, value: number}[]>([]);

    const handleInputChange = (value: {label: string, value: number}) => {
        dispatch(addSymbolsStore([ value.label ]));
    };

    React.useEffect(() => {
        const m = cloneDeep(timeSeriesMenu);
        if(m){
            const symbolsMenu = m.find((x) => x.category === "symbols");
            if(symbolsMenu){
                const allSymbols = symbolsMenu.items.map((x) => x.display) as string[];
                setAllSymbols(allSymbols);
                setOptions([ {
                    label: "Search for a symbol",
                    value: -1,
                } ]);
            }
        }
    }, [ timeSeriesMenu ]);

    return(
        <>
            <Heading as="h5" size="md" mt={5}>Select a symbol</Heading>
            <Select variant="filled" 
                isDisabled={allSymbols.length === 0}
                onChange={handleInputChange}
                options={options} placeholder='Choose a symbol' onInputChange={(value) => {
                    if(value.length >= 2){
                        setOptions(allSymbols.filter((x) => x.includes(value)).map((x, i) => {
                            return {
                                label: x,
                                value: i,
                            };
                        }));
                    }else{
                        setOptions([ {
                            label: "Search for a symbol",
                            value: -1,
                        } ]);
                    }
                }} />
        </>
    );
}