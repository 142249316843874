import { Box } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { SampleType } from "../../backtesting-common-frontend/database/samples";
import { TimeSeriesService } from "../../backtesting-common-frontend/timeseries/timeseries.service";
import { AppState } from "../../store/store";

class Warning{
    message: string;
}

export const Warnings = () => {
    const backtest = useSelector((state: AppState) => state.backtests.backtest);
    const testCtx = useSelector((state: AppState) => state.tests.test);
    const strategyCtx = useSelector((state: AppState) => state.strategies.strategy);
    const [ timeSeriesService ] = React.useState<TimeSeriesService>(new TimeSeriesService());
    const [ warnings, setWarnings ] = React.useState<Warning[]>([]);

    React.useEffect(() => {
        if(backtest && testCtx && strategyCtx){
            const allNewsMessages: Warning[] = [];
            const from = timeSeriesService.getBacktestFrom(testCtx);
            timeSeriesService.getBacktestTo(testCtx).then(to => {
                const fromMoment = moment(from.label);
                const toMoment = moment(to.label);
                const isMoreThanOneYear = toMoment.diff(fromMoment, 'years') > 1;
                if(!isMoreThanOneYear){
                    const w = new Warning();
                    w.message = "Backtest period is less than 1 year";
                    allNewsMessages.push(w);
                }
                const isMoreThan4Years = toMoment.diff(fromMoment, 'years') > 4;
                if(isMoreThan4Years){
                    const w = new Warning();
                    w.message = "Backtest period is more than 4 years. This may cause a backtest to run for 4-7 minutes";
                    allNewsMessages.push(w);
                }
                const hasOnlySampleSelectionFilter = strategyCtx.selectionFilters.every(x => {
                    const sample = x.ID as SampleType;
                    return sample === "small" || sample === "medium" || sample === "large" || sample === "very large";
                });
                if(hasOnlySampleSelectionFilter){
                    const w = new Warning();
                    w.message = `When using only small, mid or large caps as sample, may cause a backtest to run for ${isMoreThanOneYear && !isMoreThan4Years ? '2-3' : '4-7'} minutes`;
                    allNewsMessages.push(w);
                }
                const noResultsHeadsUp = new Warning();
                noResultsHeadsUp.message = "If no results are shown, it means that no trades were made during the backtest period. This can be due to overfitting.";
                allNewsMessages.push(noResultsHeadsUp);
                setWarnings(allNewsMessages);
            });
        }
    }, [ backtest, strategyCtx, testCtx, timeSeriesService ]);

    return (
        <>
            {warnings.map((w, i) => {
                return (
                    <Box bg='tomato' w='100%' p={4} color='white'>
                        <p>{w.message}</p>
                    </Box>
                );
            })}
        </>
    );
};