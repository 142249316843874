import { createSlice } from '@reduxjs/toolkit';
import { TransformationDTO } from '../../backtesting-common-frontend/transformations/transformations-dtos';

export class TransformationResults {
    transformations: TransformationDTO[];
}

const initialState: TransformationResults = {
    transformations: [],
};

const transformations = createSlice({
    name: 'transformations',
    initialState,
    reducers: {
        updateTransformations: (state, action) => {
            state.transformations = action.payload;
        },
    },
});

export const { updateTransformations } = transformations.actions;
export const transformationsReducer = transformations.reducer;