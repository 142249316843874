import { Box, Button, Flex, FormLabel, Input, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { MathJax } from 'better-react-mathjax';
import { Select } from 'chakra-react-select';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { runAnyBacktest } from '../../action-creators/backtests/backtests.actions';
import { createOrEditNewMehtodOrCurrent } from '../../action-creators/methods/methods.actions';
import { Backtest, BacktestingMetaDTO, BacktestingSetting } from '../../backtesting-common-frontend';
import { CompanyProfile } from '../../backtesting-common-frontend/database/R-DB/timeseries/companies';
import { getDateFromLabel, getLastDate } from '../../backtesting-common-frontend/http-utilities/http-utilities/dates/dates.http.service';
import { deleteMethod } from '../../backtesting-common-frontend/http-utilities/http-utilities/methods/methods-backend.service';
import { MenuCategory } from '../../backtesting-common-frontend/menu/dtos/menu-dtos';
import { CategorizeType, GeneralCategorize, TimeSeriesDTO } from '../../backtesting-common-frontend/methods';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import { StatusDisplayDTO } from '../../backtesting-common-frontend/status/error-handling';
import { StrategyDTO } from '../../backtesting-common-frontend/strategies/strategy';
import { criteriaSign } from '../../backtesting-common-frontend/techniques';
import { TimeSeriesService } from '../../backtesting-common-frontend/timeseries/timeseries.service';
import { MethodsResultsManager } from '../../managers/methods/methods-manager';
import { TimeSeriesResultsManager } from '../../managers/time-series/time-series-manager';
import { setLoading, updateCompanyProfiles, updateMessage } from '../../store/backtests/backtests';
import { AppState } from '../../store/store';
import LazyLoading from '../lazy-loading/lazy-loding';
import AddMethodHelper from '../methods/add-method-helper';
import { ExplainerInfo } from '../shared/explainer/info';
import { SelectTimeSeries } from '../time-series/time-series-selector';

export default function ToppLists({ timeSeriesMenu, isScreening }: { timeSeriesMenu: MenuCategory[], isScreening?: boolean }) {
    const dispatch = useDispatch();
    const currentReduxStrategy = useSelector(
        (state: AppState) => state.strategies.strategy
    );
    const backtestCtx = useSelector((state: AppState) => state.backtests.backtest);
    const test = useSelector((state: AppState) => state.tests.test);
    const currentTab = useSelector((state: AppState) => state.tabs.currentMainTab);
    const graphValuesCtx = useSelector((state: AppState) => state.graphItems.graphItems);
    const theoryActiveCtx = useSelector((state: AppState) => state.graphItems.theoryActive);
    const [ timeSeries, setTimeSeries ] = useState<MenuCategory[]>([]);
    const [ selectedTimeSeries, setSelectedTimeSeries ] = useState<TimeSeriesDTO | null>(null);
    const [ selectedOption, setSelectedOption ] = useState<string | null>(null);
    const [ selectedOptionNumber, setSelectedOptionNumber ] = useState<number | null>(null);
    // create state for currentReduxStrategy, test
    const [ currentStrategy, setCurrentStrategy ] = useState<StrategyDTO | null>(null);
    const [ currentTest, setCurrentTest ] = useState<BacktestingSetting | null>(null);
    const [ backtest, setBacktest ] = useState<Backtest | null>(null);
    const [ timeSeriesService ] = useState(new TimeSeriesService());
    const [ tabIndex, setTabIndex ] = useState<number>(0);
    const [ absoluteComparison, setAbsoluteComparison ] = useState<boolean>(false);
    const [ intervalComparison, setIntervalComparison ] = useState<boolean>(false);
    const [ interval, setInterval ] = useState<number[]>([ 0, 1 ]);
    const [ type, setType ] = useState<criteriaSign>("up");
    const [ sublistOption ] = useState<{label: string, value: string}[]>([ {
        label: "Highest values (at time t)",
        value: "end",
    }, 
    {
        label: "Lowest values (at time t)",
        value: "start",
    },
    // {
    //     label: "Select from middle",
    //     value: "middle",
    // },
    ]);

    // create useEffect and cloneDeep for currentReduxStrategy, test
    useEffect(() => {
        setCurrentStrategy(cloneDeep(currentReduxStrategy));
    }, [ currentReduxStrategy ]);

    useEffect(() => {
        const t = cloneDeep(test);
        if(t){
            setCurrentTest(t);
        }
    }, [ test ]);

    useEffect(() => {
        if(backtestCtx){
            setBacktest(backtestCtx);
        }
    }, [ backtestCtx ]);

    useEffect(() => {
        // const validTimeSeries = cloneDeep(timeSeriesMenu).filter(e => e.category === "fundamentals" || e.category === "selection-filters" ||
        // e.category === "company-time-series" || e.category === "beta" || e.category === "news");
        const fakeTsMenu = new MenuCategory();
        fakeTsMenu.category = "graph";
        fakeTsMenu.display = "Graph";
        fakeTsMenu.categoryTitle = "Graph values";
        const fakeTs = new TimeSeriesDTO();
        fakeTs.display = "Category: the time series from the graph";
        fakeTs.timeSeriesKey = "fake";
        fakeTsMenu.items = [ fakeTs ];
        // validTimeSeries.unshift(fakeTsMenu);
        setTimeSeries([ fakeTsMenu ]);
    }, [ timeSeriesMenu ]);

    const addToppListMethod = async(name: string, update: boolean, runBacktest?: boolean) => {
        if(!currentTab || !currentTest || !currentStrategy) return;
        if((!selectedTimeSeries || selectedOptionNumber == null) && !intervalComparison) {
            const status = new StatusDisplayDTO("Cannot save, missing values", "error");
            dispatch(updateMessage(status));
            return;
        }
        const activeGraphItem = graphValuesCtx.find((e) => theoryActiveCtx.includes(e._id));
        const currentTimeSeries = selectedTimeSeries.timeSeriesKey === "fake" ? cloneDeep(activeGraphItem.timeSeries) : cloneDeep(selectedTimeSeries);
        const betaCategorize = new GeneralCategorize();
        betaCategorize.key = intervalComparison ? "interval" :  absoluteComparison ? "absolute" : "sublist";
        betaCategorize.interval = interval;
        betaCategorize.categorizeType = intervalComparison ? [ selectedOption, selectedOptionNumber ] : absoluteComparison ? [ type, selectedOptionNumber ] : [ selectedOption, selectedOptionNumber ];
        const categorizeType = new CategorizeType();
        categorizeType.key = "beta";
        categorizeType.meta = betaCategorize;
        currentTimeSeries.categorizeType = categorizeType;

        if(selectedTimeSeries.timeSeriesKey === "fake"){
            currentTimeSeries.categorizeType.isOnFly = true;
        }

        const newMethod = await createOrEditNewMehtodOrCurrent(
            dispatch,
            currentTest,
            currentTab,
            new MethodsResultsManager(),
            null,
            name,
            null,
            [ currentTimeSeries ],
            currentStrategy,
            null,
            undefined,
            update === false ? true : false,
            "SelectionCriteria"
        );

        if(runBacktest){
            if(currentStrategy?.selectionFilters?.length === 0){
                const status = new StatusDisplayDTO("Please select a selection category", "error");
                dispatch(updateMessage(status));
                return;
            }
            const status = new StatusDisplayDTO("Triggered", "success");
            dispatch(updateMessage(status));
            dispatch(setLoading(true));
            const meta = new BacktestingMetaDTO();
            meta.calculateReturnsLast = true;
            meta.useCachedTimeSeries = [];
            meta.lastDate = await getLastDate();
            meta.firstDate = await getDateFromLabel(moment().add((250*2)*-1, "days").format("YYYY-MM-DD"));
            meta.returnPortfolio = true;
            meta.takeTestFromBacktest = true;
            meta.takeFirstValue = true;
            newMethod.timeseries = [ currentTimeSeries ];
            const screenResults = await runAnyBacktest(
                backtest,
                currentTest,
                currentStrategy,
                timeSeriesService,
                meta,
                [ newMethod ],
                currentStrategy.selectionFilters
            );
            dispatch(setLoading(false));
            if(screenResults){
                const res = screenResults.historicalPortfolio[screenResults.historicalPortfolio.length - 1].companies.map(e => {
                    const c = new CompanyProfile({ symbol: e.symbol, companyName: e.fullName, image: e.image });
                    return c;
                });
                dispatch(updateCompanyProfiles(res));
            }
            deleteMethod(currentTest._id, currentStrategy._id, newMethod);
            return;
        }
    };

    const disabled = absoluteComparison ? false : intervalComparison ? false : (!selectedTimeSeries || !selectedOption || !selectedOptionNumber);

    return (
        <LazyLoading>
            <>
                <ExplainerInfo.Information 
                    title="What is screening?"
                    text={
                        <div>
                            <p>All assets in our sample are candidates for our portfolio, but you might find yourself with a large sample. When picking relevant stocks, you may want to consider choosing stocks that have certain financial ratios.</p>
                            <br />
                            <p>When screening is applied, it may shrink the sample to a more narrowly niched selection of stocks.</p>
                            <br />
                            <p>The relative screening method will sort the financial ratios at current time (t) and select N number of stocks with the highest or lowest finacial ratio.</p>
                            <MathJax>
                                {`
                                \\[
                                    t
                                \\]
                                `}
                            </MathJax> 
                            is the current time, and
                            <MathJax>
                                {`
                                \\[
                                    N
                                \\]
                                `}
                            </MathJax> is the number of stocks to select.
                        </div>
                    }
                />
                <SelectTimeSeries 
                    onHandleSelectionChange={(value) => {
                        if(TimeSeriesResultsManager.isTimeSeries(value) || value['timeSeriesKey'] === "fake") {
                            setSelectedTimeSeries(value);
                        }
                    }}
                    data={timeSeries}>
                    <>
                        <p>Drawn for each time unit (t)</p>
                        <Tabs index={tabIndex} onChange={(index) => {
                            setTabIndex(index);
                            if(index === 1){
                                setAbsoluteComparison(true);
                                setIntervalComparison(false);
                            } else if(index === 2){
                                setAbsoluteComparison(false);
                                setIntervalComparison(true);
                            } else {
                                setAbsoluteComparison(false);
                                setIntervalComparison(false);
                            }
                        }}>
                            <TabList>
                                <Tab>Relative</Tab>
                                <Tab>Absolute</Tab>
                                <Tab>Interval</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    {selectedTimeSeries && !absoluteComparison && <>
                                        <Box width="100%" p={1}>
                                            <Select
                                                options={sublistOption}
                                                placeholder="Select sublist option"
                                                onChange={(value) => {
                                                    setSelectedOption((value as any).value);
                                                }}
                                            />
                                        </Box>
                                        <Box width={'100%'} p={1}>
                                            <Input
                                                type="number"
                                                placeholder="Select how many"
                                                onChange={(e) => {
                                                    setSelectedOptionNumber(Number(e.target.value));
                                                }}
                                            />
                                        </Box>
                                    </>}
                                </TabPanel>
                                <TabPanel>
                                    {selectedTimeSeries && absoluteComparison && <Box width={'100%'} p={1}>
                                        <Box width={'100%'} p={1}>
                                            <Input
                                                type="number"
                                                placeholder="Select value"
                                                onChange={(e) => {
                                                    setSelectedOptionNumber(Number(e.target.value));
                                                }}
                                            />
                                            <FormLabel>Comparision</FormLabel>
                                            <Select options={[ { label: "Up", value: "up" }, { label: "Down", value: "down" } ]} onChange={(e) => setType((e as any).value as criteriaSign)} />
                                        </Box>
                                    </Box>}
                                </TabPanel>
                                <TabPanel>
                                    <Box width={'100%'} p={1}>
                                        <Input 
                                            type="number"
                                            placeholder="Select index"
                                            onChange={(e) => {
                                                const start = Number(e.target.value);
                                                const end = interval[1];
                                                setInterval([ start, end ]);
                                            }}
                                        />
                                        <Input 
                                            type="number"
                                            placeholder="Select index"
                                            onChange={(e) => {
                                                const start = interval[0];
                                                const end = Number(e.target.value);
                                                setInterval([ start, end ]);
                                            }}
                                        />
                                        <Box width="100%" p={1}>
                                            <Select
                                                options={sublistOption}
                                                placeholder="Select sublist option"
                                                onChange={(value) => {
                                                    setSelectedOption((value as any).value);
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                        <Box width={'100%'} p={1}>
                            <Flex>
                                {!isScreening && <AddMethodHelper 
                                    removeViewBuySignal={true}
                                    useHoldValue={false}
                                    callbackTest={() => {
                                        return new Promise<void>((resolve, _reject) => {
                                            resolve();
                                        });
                                    }} disabledText={'Cannot save, missing values'} callback={(name, update) => {
                                        addToppListMethod(name, update);
                                    }} disabled={disabled} />}
                                {isScreening && <Button colorScheme="green" size="sm" ml="auto" onClick={async() => {
                                    addToppListMethod("Screening: " + selectedTimeSeries.display, false, true);
                                }} disabled={disabled}>Screen</Button>}
                            </Flex>
                        </Box>
                    </>
                </SelectTimeSeries>
            </>
        </LazyLoading>
    );
}
