/* eslint-disable no-useless-escape */
// In handle-transformation-settings.tsx or a separate file
import { MathJax } from 'better-react-mathjax';
import { TransformationDTO, TransformationTypeKey } from '../../backtesting-common-frontend/transformations/transformations-dtos';
import { ExplainerInfo } from '../shared/explainer/info';

const TransformationExplainer = ({ currentTransformation }: {currentTransformation: TransformationDTO}) => {
    const getTransformationInfo = (key: TransformationTypeKey) => {
        switch (key) {
        case "sma":
            return {
                title: "Simple Moving Average (SMA)",
                text: `
            The Simple Moving Average (SMA) calculates the average of the last \( n \) data points.

            **Formula:**
            \\[
            \\text{SMA}_n = \\frac{1}{n} \\sum_{i=0}^{n-1} x_{t-i}
            \\]
          `,
            };
        case "ema":
            return {
                title: "Exponential Moving Average (EMA)",
                text: `
            The Exponential Moving Average (EMA) gives more weight to recent prices, making it more responsive to new information.

            **Formula:**
            \\[
            \\text{EMA}_t = \\alpha x_t + (1 - \\alpha) \\text{EMA}_{t-1}
            \\]
            where \\( \\alpha = \\frac{2}{n+1} \\)
          `,
            };
        case "growth":
            return {
                title: "Growth",
                text: `
            The Growth transformation calculates the rate of change over a specified period.

            **Formula:**
            \\[
            \\text{Growth}_t = \\frac{x_t - x_{t-P}}{x_{t-P}}
            \\]
          `,
            };
        case "growth1years":
            return {
                title: "Growth (1 year)",
                text: `
            Calculates the growth rate over one year.

            **Formula:**
            \\[
            \\text{Growth}_{1\\text{year}} = \\frac{x_t - x_{t-1\\text{year}}}{x_{t-1\\text{year}}}
            \\]
          `,
            };
        case "growth2years":
            return {
                title: "Growth (2 years)",
                text: `
            Calculates the growth rate over two years.

            **Formula:**
            \\[
            \\text{Growth}_{2\\text{years}} = \\frac{x_t - x_{t-2\\text{years}}}{x_{t-2\\text{years}}}
            \\]
          `,
            };
        case "growth3years":
            return {
                title: "Growth (3 years)",
                text: `
            Calculates the growth rate over three years.

            **Formula:**
            \\[
            \\text{Growth}_{3\\text{years}} = \\frac{x_t - x_{t-3\\text{years}}}{x_{t-3\\text{years}}}
            \\]
          `,
            };
        case "diff":
            return {
                title: "Difference",
                text: `
            The Difference transformation calculates the difference between consecutive data points.

            **Formula:**
            \\[
            \\text{Difference}_t = x_t - x_{t-1}
            \\]
          `,
            };
        case "none":
            return {
                title: "No transformation",
                text: "No changes are applied to the time series data.",
            };
        case "wma":
            return {
                title: "Weighted Moving Average (WMA)",
                text: `
            The Weighted Moving Average (WMA) assigns a weight to each data point, with more recent points given higher weights.

            **Formula:**
            \\[
            \\text{WMA}_n = \\frac{\\sum_{i=1}^{n} w_i x_{t-i+1}}{\\sum_{i=1}^{n} w_i}
            \\]
            where \\( w_i = i \\)
          `,
            };
        case "kama":
            return {
                title: "Kaufman's Adaptive Moving Average (KAMA)",
                text: `
            KAMA adjusts the moving average based on market volatility.

            **Formula:**
            \\[
            \\text{KAMA}_t = \\text{KAMA}_{t-1} + \\beta (x_t - \\text{KAMA}_{t-1})
            \\]
            where \\( \\beta \\) is an efficiency ratio adjusted smoothing constant.
          `,
            };
        case "vwap":
            return {
                title: "Volume Weighted Average Price (VWAP)",
                text: `
            VWAP calculates the average price weighted by volume.

            **Formula:**
            \\[
            \\text{VWAP} = \\frac{\\sum_{i=1}^{n} P_i V_i}{\\sum_{i=1}^{n} V_i}
            \\]
            where \\( P_i \\) is the price and \\( V_i \\) is the volume.
          `,
            };
        case "macd":
            return {
                title: "Moving Average Convergence Divergence (MACD)",
                text: `
            MACD is the difference between a fast EMA and a slow EMA.

            **Formula:**
            \\[
            \\text{MACD}_t = \\text{EMA}_{\\text{fast},t} - \\text{EMA}_{\\text{slow},t}
            \\]
          `,
            };
        case "rsi":
            return {
                title: "Relative Strength Index (RSI)",
                text: `
            RSI measures the magnitude of recent price changes to evaluate overbought or oversold conditions.

            **Formula:**
            \\[
            \\text{RSI} = 100 - \\frac{100}{1 + \\frac{\\text{average gain}}{\\text{average loss}}}
            \\]
          `,
            };
        case "ticks":
            return {
                title: "Tick bars",
                text: "Tick bars group price data by a fixed number of ticks (trades).",
            };
        case "fractional":
            return {
                title: "Fractional Differentiation",
                text: `
            Fractional differentiation applies a non-integer differentiation to the time series, preserving memory in the data.

            **Formula:**
            \\[
            x_t^d = \\sum_{k=0}^{\\infty} \\frac{\\Gamma(d+1)}{\\Gamma(k+1)\\Gamma(d-k+1)} x_{t-k}
            \\]
            where \\( \\Gamma \\) is the Gamma function and \\( d \\) is the differentiation order.
          `,
            };
        case "beta":
            return {
                title: "Beta",
                text: `
            Beta measures the volatility of an asset relative to the market.

            **Formula:**
            \\[
            \\beta = \\frac{\\text{Cov}(R_i, R_m)}{\\text{Var}(R_m)}
            \\]
            where \\( R_i \\) is the return of the asset and \\( R_m \\) is the return of the market.
          `,
            };
        case "std":
            return {
                title: "Standard Deviation (STD)",
                text: `
            STD measures the dispersion of data points from their mean.

            **Formula:**
            \\[
            \\sigma = \\sqrt{\\frac{1}{n-1} \\sum_{i=1}^{n} (x_i - \\bar{x})^2}
            \\]
            where \\( \\bar{x} \\) is the mean of the data points.
          `,
            };
        default:
            return null;
        }
    };

    const info = currentTransformation != null ? getTransformationInfo(currentTransformation.key) : null;

    return (
        info && (
            <ExplainerInfo.Information
                title={info.title}
                text={<MathJax>{info.text}</MathJax>}
            />
        )
    );
};

export default TransformationExplainer;
