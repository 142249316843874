import moment from "moment";

export const UTCnow = (st: string): Date => {
    const utc = moment(st).utc();
    const date = utc.toDate();
    return date;
};

export class ImpreemDate {
    public step: number;

    public date: Date;

    public label: string;

    constructor(step: number, label: string) {
        this.step = step;
        this.label = label;
        this.date = UTCnow(label);
    }
}
