import { Heading } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectionFilterMenu } from '../../backtesting-common-frontend/filters';
import { cloneDeep } from "../../backtesting-common-frontend/shared/utilites/object.utilities";
import { AppState } from "../../store/store";
import { addIndustryStore } from "../../store/symbols/symbols";

export default function SelectIndustry() {
    const dispatch = useDispatch();
    const timeSeriesMenu = useSelector(
        (state: AppState) => state.backtests.menuCategory
    );
    const [ allIndustries, setAllIndustries ] = React.useState<SelectionFilterMenu[]>([]);
    const [ options, setOptions ] = React.useState<{label: string, value: SelectionFilterMenu}[]>([]);

    const handleInputChange = (value: {
        label: string;
        value: SelectionFilterMenu;
    }) => {
        dispatch(addIndustryStore([ value.value ]));
    };

    React.useEffect(() => {
        const m = cloneDeep(timeSeriesMenu);
        if(m){
            const industryMenu = m.find((x) => x.category === "industry");
            if(industryMenu){
                const a = industryMenu.items as SelectionFilterMenu[];
                setAllIndustries(a);
                setOptions(a.map((x, i) => {
                    return {
                        label: x.display,
                        value: x,
                    };
                }));
            }
        }
    }, [ timeSeriesMenu ]);

    return(
        <>
            <Heading as="h5" size="md" mt={5}>Select a industry</Heading>
            <Select variant="filled" 
                isDisabled={allIndustries.length === 0}
                onChange={handleInputChange}
                options={options} placeholder='Choose a industry' />
        </>
    );
}