import { createSlice } from '@reduxjs/toolkit';

export class LazyLoadingsResults {
    queue: string[];
}

const initialState: LazyLoadingsResults = {
    queue: [],
};

const lazyloadings = createSlice({
    name: 'lazy-loadings',
    initialState,
    reducers: {
        addToLazyLoadingArray: (state, action) => {
            state.queue.push(action.payload);
        },
        // remove
        removeFromLazyLoadingArray: (state, action) => {
            state.queue = state.queue.filter((item) => item !== action.payload);
        },
    },
});

export const { addToLazyLoadingArray, removeFromLazyLoadingArray } = lazyloadings.actions;
export const lazyLoadingsReducer = lazyloadings.reducer;