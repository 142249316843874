import moment from 'moment';
import * as XLSX from 'xlsx';
import { TimeSeriesDTO } from '../../methods';
import { TimeSeriesHelperValue } from "../../timeseries/timeseries-models";
import { createId } from '../utilites/string.utilities';

export function downloadExcel(ts: TimeSeriesDTO, timeSeriesData: TimeSeriesHelperValue[]) {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert your data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(timeSeriesData.map(data => ({
        Value: data.value,
        Date: data.date,
    })));

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Time Series');

    // Generate an XLSX file
    XLSX.writeFile(workbook, ts.display + createId().substring(0, 2) + moment().format("YYYY-MM-DD") + '.xlsx');
}