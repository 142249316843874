import { Box, Heading, Input, Switch, Textarea } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MethodAddUpdateExtras, createOrEditNewMehtodOrCurrent } from "../../action-creators/methods/methods.actions";
import { BacktestingSetting } from "../../backtesting-common-frontend";
import { AiNews } from "../../backtesting-common-frontend/ai-news/ai-news-model";
import { cloneDeep } from "../../backtesting-common-frontend/shared/utilites/object.utilities";
import { StatusDisplayDTO } from "../../backtesting-common-frontend/status/error-handling";
import { StrategyDTO } from "../../backtesting-common-frontend/strategies/strategy";
import { MethodsResultsManager } from "../../managers/methods/methods-manager";
import { updateMessage } from "../../store/backtests/backtests";
import { AppState } from "../../store/store";
import AddMethodHelper from "../methods/add-method-helper";

export default function AiNewsMethod() {
    const dispatch = useDispatch();
    const currentReduxStrategy = useSelector(
        (state: AppState) => state.strategies.strategy
    );
    const test = useSelector((state: AppState) => state.tests.test);
    const currentTab = useSelector((state: AppState) => state.tabs.currentMainTab);
    // Create Three states and inputs from Chakra UI: weeks, prompt, and expected
    const [ weeks, setWeeks ] = useState<number>(0);
    const [ prompt, setPrompt ] = useState<string>('Based on these articles, are there any news that have negative impact on A? If you agree in general then write at the end "agree: 1" or if you do not agree write "agree: 0"');
    const [ expected, setExpected ] = useState<string>("agree: 1");
    const [ currentStrategy, setCurrentStrategy ] = useState<StrategyDTO | null>(null);
    const [ currentTest, setCurrentTest ] = useState<BacktestingSetting | null>(null);
    const [ perCompany, setPerCompany ] = useState<boolean>(false);

    useEffect(() => {
        setCurrentStrategy(cloneDeep(currentReduxStrategy));
    }, [ currentReduxStrategy ]);

    useEffect(() => {
        const t = cloneDeep(test);
        if(t){
            setCurrentTest(t);
        }
    }, [ test ]);

    const handleAddMethod = async(name: string, update: boolean, holdValue?: string) => {
        if(!currentTab || !currentTest || !currentStrategy) return;
        // check if weeks are between 1 and 7
        if(weeks < 1 || weeks > 7) {
            const status = new StatusDisplayDTO("Weeks must be between 1 and 7", "error");
            dispatch(updateMessage(status));
            return;
        }
        const extras = new MethodAddUpdateExtras();
        extras.aiNews = new AiNews();
        extras.aiNews.weeks = weeks;
        extras.aiNews.prompt = prompt;
        extras.aiNews.expected = expected;
        extras.aiNews.isCompany = perCompany;
        await createOrEditNewMehtodOrCurrent(
            dispatch,
            currentTest,
            currentTab,
            new MethodsResultsManager(),
            null,
            name,
            null,
            [  ],
            currentStrategy,
            null,
            extras,
            true,
            "AiNews"
        );
    };

    const toggleHandler = () => {
        setPerCompany(!perCompany);
    };

    // chakra ui
    return (
        <Box>
            <Heading as="h5" size="md">Per Company</Heading>
            <p>If toggled, then this method will be applied to each company and if returned true then we buy into that company, 
                and if not toggled then this method will set buying date on a broader scope. 
                Meaning that if this method returns true, then do the other methods and eventually buy.</p>
            {!perCompany && <p>
                    You can use our variables to reference to the backtest settings.
                <p>
                    <ul>
                        <li>Selection filter = A</li>
                    </ul>
                </p>
            </p>}
            <span>Per company: </span>
            <Switch id="is-short-toggle" colorScheme="blue" onChange={toggleHandler} isChecked={perCompany} />
            <Heading as="h5" size="md">Week back</Heading>
            <Input
                placeholder="Weeks"
                value={weeks}
                onChange={(e) => setWeeks(+e.target.value)}
            />
            <Heading as="h5" size="md">Prompt</Heading>
            <Textarea
                placeholder="Prompt"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
            />
            <Heading as="h5" size="md">Expected</Heading>
            <Input
                placeholder="Expected"
                value={expected}
                onChange={(e) => setExpected(e.target.value)}
            />
            <AddMethodHelper 
                useHoldValue={false}
                callback={handleAddMethod}
                disabled={weeks === 0 || prompt === "" || expected === ""}
                disabledText="Please fill out all fields"
                testDisabled={true}
            />
        </Box>
    );
}