export type Periodicity = "day" | "month" | "week" | "quarter" | "year";

export function sleep(ms): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function convertPeriodictyToStep(periodicity: Periodicity): number {
    switch (periodicity) {
        case "day":
            return 1;
        case "week":
            return 7;
        case "month":
            return 30;
        default:
            return 30;
    }
}

export function periodictyToYear(periodicity: Periodicity): number {
    switch (periodicity) {
        case "day":
            return 250;
        case "week":
            return 250 / 7;
        case "month":
            return 250 / 30;
        default:
            return 250;
    }
}

export function convertPeriodictyToPlural(periodicity: Periodicity): string {
    switch (periodicity) {
        case "day":
            return "days";
        case "week":
            return "weeks";
        case "month":
            return "months";
        default:
            return "months";
    }
}

export class DataPoint {
    date: Date;

    value: number;

    // write a constructor that takes in a date and a value
    constructor(date: Date, value: number) {
        this.date = date;
        this.value = value;
    }
}

export function determinePeriodicity(data: DataPoint[]): string {
    const dateDifferences: number[] = [];

    for (let i = 1; i < data.length; i++) {
        const timeDiff = data[i].date.getTime() - data[i - 1].date.getTime();
        dateDifferences.push(timeDiff);
    }

    const medianDateDifference = findMedian(dateDifferences);

    if (medianDateDifference < 2 * 24 * 60 * 60 * 1000) {
        return "day";
    } else if (medianDateDifference < 7 * 24 * 60 * 60 * 1000) {
        return "week";
    } else if (medianDateDifference < 31 * 24 * 60 * 60 * 1000) {
        return "month";
    } else if (medianDateDifference < 92 * 24 * 60 * 60 * 1000) {
        return "quarter";
    } else {
        return "year";
    }
}

function findMedian(values: number[]): number {
    const sortedValues = values.sort();
    const mid = Math.floor(sortedValues.length / 2);

    if (sortedValues.length % 2 === 0) {
        return (sortedValues[mid - 1] + sortedValues[mid]) / 2;
    } else {
        return sortedValues[mid];
    }
}
