import { ImpreemMethodDTO, MethodDTO, TimeSeriesDTO } from "../../backtesting-common-frontend/methods";
import { TimeSeriesResultsManager } from "../../managers/time-series/time-series-manager";

export const isSameTimeSeries = (x: (TimeSeriesDTO | ImpreemMethodDTO | MethodDTO), item: (TimeSeriesDTO | ImpreemMethodDTO)) => {
    if (TimeSeriesResultsManager.isTimeSeries(x) && TimeSeriesResultsManager.isTimeSeries(item)) {
        return x != null && item != null && x.categorizeType === item.categorizeType && (x.transformationKey === item.transformationKey && JSON.stringify(x.parameters) === JSON.stringify(item.parameters)
            || x.transformationKey === item.transformationKey && x.parameters == item.parameters)
            && x.normalization === item.normalization;
    }
    return false;
};
