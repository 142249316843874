/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Image,
    Input,
    Stack,
    useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../../backtesting-common-frontend/http-utilities/auth/auth-http-utilities';
import { StatusDisplayDTO } from '../../backtesting-common-frontend/status/error-handling';
import { addOrUpdateClient, shouldCheckState } from '../../store/auth/client';
import { updateMessage } from '../../store/backtests/backtests';

let uptade = 0;

export default function LoginScreen() {
    const dispatch = useDispatch();
    const [ email, setEmail ] = useState<string | null>(null);
    const [ password, setPassword ] = useState<string | null>(null);

    async function loginHandle() {
        if(email == null || password == null) return;
        const status = new StatusDisplayDTO("About to login", "info");
        dispatch(updateMessage(status));
        const auth = await login(email, password);
        dispatch(updateMessage(status));
        localStorage.setItem('user', JSON.stringify(auth));
        if (auth != null) {
            const statusLogin = new StatusDisplayDTO("Successfully login", "success");
            dispatch(updateMessage(statusLogin));
            dispatch(addOrUpdateClient(auth));
            dispatch(shouldCheckState(uptade++));
            // reload website
            window.location.reload();
        }
    }

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                loginHandle();
            }
        };
    
        window.addEventListener('keydown', handleKeyPress);

        if (!localStorage.getItem("chakra-ui-color-mode")) {
            localStorage.setItem("chakra-ui-color-mode", "dark");
        }
    
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Image src={'https://edvardwo.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo-bg1.b28ba767.png&w=96&q=75'} />
                    <Heading fontSize={'4xl'}>Sign in to your account</Heading>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={4}>
                        <FormControl id="email">
                            <FormLabel>Email address</FormLabel>
                            <Input type="email" onChange={(event) => {
                                setEmail(event.target.value);
                            }} />
                        </FormControl>
                        <FormControl id="password">
                            <FormLabel>Password</FormLabel>
                            <Input type="password" onChange={(event) => {
                                setPassword(event.target.value);
                            }}/>
                        </FormControl>
                        <Stack spacing={10}>
                            <Stack
                                direction={{ base: 'column', sm: 'row' }}
                                align={'start'}
                                justify={'space-between'}>
                                <Checkbox>Remember me</Checkbox>
                            </Stack>
                            <Button
                                bg={'blue.400'}
                                color={'white'}
                                _hover={{
                                    bg: 'blue.500',
                                }}
                                onClick={loginHandle}
                            >
                Sign in
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );
}

