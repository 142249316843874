import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { RowTableDTO } from ".";

// get children from props
export default function TableComponent({ rows }: { rows: RowTableDTO[] }) {
    return (
        <>
            <TableContainer>
                <Table>
                    {rows.map((row, index) => {
                        return (
                            <>
                                <Thead key={index}>
                                    <Tr>
                                        {row.columns.map((column, index) => {
                                            return column?.title ? (
                                                <Th key={index}>{column.title}</Th>
                                            ) : null;
                                        })}
                                    </Tr>
                                </Thead>
                                <Tbody key={index + "body"}>
                                    <Tr>
                                        {row.columns.map((column, index) => {
                                            return (
                                                column ?
                                                    <Td key={index}>{column.value}</Td> : null
                                            );
                                        })}
                                    </Tr>
                                </Tbody>
                            </>
                        );
                    })}
                </Table>
            </TableContainer>
        </>
    );
}
