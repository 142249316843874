/* eslint-disable @typescript-eslint/no-explicit-any */
import { ArrowUpIcon, SearchIcon, SunIcon } from "@chakra-ui/icons";
import { Box, Button, Center, Divider, Grid, GridItem, HStack, Heading, Input, List, ListIcon, ListItem, Skeleton, SkeletonText, Stack, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { SyntheticEvent, useCallback, useState } from "react";
import { FaArrowLeft, FaHammer, FaSearch, FaUsers } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { SelectionFilterMenu } from "../../backtesting-common-frontend/filters";
import { isBacktestLicense } from "../../backtesting-common-frontend/http-utilities/auth/auth-http-utilities";
import { runNewsBacktests } from "../../backtesting-common-frontend/http-utilities/http-utilities/backtests/backtests-backend.service";
import { getHistoricalEvents } from "../../backtesting-common-frontend/http-utilities/http-utilities/dates/dates.http.service";
import { getCommodities, getDescription, getIndustries, getNewsAboveThreshold } from "../../backtesting-common-frontend/http-utilities/http-utilities/strategy/strategy";
import { TimeSeriesDTO } from "../../backtesting-common-frontend/methods";
import { StatusDisplayDTO } from "../../backtesting-common-frontend/status/error-handling";
import { CriteriaDateDTO } from "../../backtesting-common-frontend/techniques";
import { NavItem } from "../../components/shared/sidebar/sidebar";
import { RowTableDTO } from "../../components/table";
import TableComponent from "../../components/table/table";
import ChakraTagInput from '../../components/tags';
import { setLoading, updateMessage } from "../../store/backtests/backtests";
import { updateNavigationTo } from "../../store/tabs/tabs";

export default function NewsImpactScreen() {
    const dispatch = useDispatch();
    const [ searchValue, setSearchValue ] = useState<string>('');
    const [ impact, setImpact ] = useState<string>('Neutral');
    const [ hasFetched, setHasFetched ] = useState<boolean>(false);
    const [ descriptions, setDescriptions ] = useState<string[] | null>(null);
    const [ industries, setIndustries ] = useState<(SelectionFilterMenu | TimeSeriesDTO)[] | null>(null);
    const [ cryptocurrencies, setCryptocurrencies ] = useState<(SelectionFilterMenu | TimeSeriesDTO)[] | null>(null);
    const [ commodities, setCommodities ] = useState<(SelectionFilterMenu | TimeSeriesDTO)[] | null>(null);
    const [ news, setNews ] = useState<{savedTimeSeries: TimeSeriesDTO, timeSeries: TimeSeriesDTO[]} | null>(null);
    const [ historicalEvents, setHistoricalEvents ] = useState<CriteriaDateDTO[] | null>(null);
    const [ tags, setTags ] = useState<string[]>([  ]);
    const [ sample, setSample ] = useState<string>("Description" as string);
    const [ hasRunned, setHasRunned ] = useState<boolean>(false);

    const handleTagsChange = useCallback((event: SyntheticEvent, tags: string[]) => {
        setTags(tags);
    }, []);

    const fetchData = () => {
        setHasFetched(true);
        const fetchDescription = async() => {
            const result = await getDescription(searchValue);
            setDescriptions(result);
        };
    
        const fetchIndustries = async() => {
            const result = await getIndustries(searchValue, impact);
            setIndustries(result);
        };
    
        // const fetchCryptocurrencies = async() => {
        //     const result = await getCryptocurrencies(searchValue, impact);
        //     setCryptocurrencies(result);
        // };
    
        const fetchCommodities = async() => {
            const result = await getCommodities(searchValue, impact);
            setCommodities(result);
        };
    
        const fetchNews = async() => {
            const result = await getNewsAboveThreshold(searchValue);
            setNews(result);
        };

        const historicalEvents = async() => {
            const d = await getHistoricalEvents(searchValue).catch(() => {
                return [];
            });
            setHistoricalEvents(d);
        };
    
        fetchDescription();
        fetchIndustries();
        // fetchCryptocurrencies();
        fetchCommodities();
        fetchNews();
        historicalEvents();
    };

    const countValuesAboveThree = (timeSeries?: TimeSeriesDTO) => {
        return timeSeries?.graphValue?.filter((item) => item.value >= 3).length || 0;
    };

    const rows: RowTableDTO[] = [];
    historicalEvents?.forEach((date, index) => {
        rows.push({
            columns: [
                {
                    title: index === 0 ? "Year" : null,
                    value: date.year,
                },
                {
                    title: index === 0 ? "Months" : null,
                    value: date.months.length > 0 ? date.months.map(e => e.label ?? "").join(", ") : "",
                },
            ],
        });
    });
    
    const runBacktests = async() => {
        dispatch(setLoading(true));
        setHasRunned(true);
        runNewsBacktests(searchValue, impact, tags, sample).then(() => {
            // dispatch(updateNavigationTo("/backtesting"));
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    const runAll = () => {
        return (
            <Center py={6} pt={0}>
                <VStack>

                    <Box
                        maxW={'full'}
                        w={'full'}
                        bg={'gray.800'}
                        boxShadow={'2xl'}
                        rounded={'md'}
                        overflow={'hidden'}>
                        <Stack
                            textAlign={'center'}
                            p={6}
                            color={'white'}
                            align={'center'}>
                            <Stack direction={'row'} align={'center'} justify={'center'}>
                                <Text fontSize={'2xl'} fontWeight={800}>
                            Run combinations
                                </Text>
                            </Stack>
                        </Stack>
      
                        <Box bg={'gray.900'} px={6} py={10}>
                            <List spacing={3}>
                                <ListItem>
                                    <h2>Sample:</h2>
                                    <HStack>
                                        <FaUsers />
                                        <div style={{ width: '100%' }}>
                                            <Select 
                                                defaultValue={{ label: "Description (recommended)", value: "Description" }}
                                                options={[ { label: "Description (recommended)", value: "Description" }, 
                                                    { label: "Industries (not recommended)", value: "Industries" }, 
                                                    { label: "Commodities", value: "Commodities" } ]} 
                                                onChange={(e) => setSample((e as any).value)} />
                                        </div>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <h2>Explanatory variables:</h2>
                                    <HStack flex={1}>
                                        <FaHammer />
                                        <h2>Historical events</h2>
                                        <span>-</span>
                                        <h2>News mentions</h2>
                                    </HStack>
                                </ListItem>
                                {(sample === "Description" || sample === "Industries") && <ListItem>
                                    <h2>Screening method:</h2>
                                    <HStack flex={1}>
                                        <FaSearch />
                                        <h2>Top 10 companies with highest Beta within industry, per time unit</h2>
                                    </HStack>
                                </ListItem>}
                                <ListItem>
                                    <h2>Add tags:</h2>
                                    <ChakraTagInput
                                        tags={tags}
                                        placeholder='Add tags (hit enter to add)'
                                        onTagsChange={handleTagsChange}
                                        wrapProps={{ direction: 'column', align: 'start' }}
                                        wrapItemProps={(isInput) => (isInput ? { alignSelf: 'stretch' } as any : undefined)}
                                    />
                                </ListItem>
                            </List>
                            <h3>Term: {searchValue}</h3>
                            <Button
                                mt={10}
                                w={'full'}
                                bg={tags.length === 0 || searchValue === '' ? 'gray.400' : 'teal.400'}
                                color={'white'}
                                rounded={'xl'}
                                disabled={tags.length === 0 || searchValue === ''}
                                boxShadow={'0 5px 20px 0px rgb(98 98 98 / 43%)'}
                                _hover={{
                                    bg: tags.length === 0 || searchValue === '' ? 'gray.400' : 'teal.500',
                                }}
                                onClick={() => {
                                    if(tags.length === 0){
                                        const status = new StatusDisplayDTO("Please add tags", "error");
                                        dispatch(updateMessage(status));
                                        return;
                                    }
                                    if(searchValue === ''){
                                        const status = new StatusDisplayDTO("Please add a search value", "error");
                                        dispatch(updateMessage(status));
                                        return;
                                    }
                                    runBacktests();
                                }}
                                _focus={{
                                    bg: 'teal.500',
                                }}>
                                    Run backtests
                            </Button>
                        </Box>
                    </Box>
                    {hasRunned && <Box mt={5} bg={'gray.900'} px={6} py={10} boxShadow={'2xl'} rounded={'md'} overflow={'hidden'}>
                        <Text>Backtests have been runned</Text>
                        <Text>You can find the results in the home screen under 'News impact searcher', after backtest has finished.</Text>
                    </Box>}
                </VStack>
            </Center>
        );
    };

    const search = () => {
        return (
            <Center py={6}>
                <Box
                    minW={'430px'}
                    maxW={'430px'}
                    w={'full'}
                    bg={'gray.800'}
                    boxShadow={'2xl'}
                    rounded={'md'}
                    overflow={'hidden'}>
                    <Stack
                        textAlign={'center'}
                        p={6}
                        color={'white'}
                        align={'center'}>
                        <Stack direction={'row'} align={'center'} justify={'center'}>
                            <Text fontSize={'2xl'} fontWeight={800}>
                            News impact searcher
                            </Text>
                        </Stack>
                    </Stack>
      
                    <Box bg={'gray.900'} px={6} py={10}>
                        <List spacing={3}>
                            <ListItem>
                                <HStack>
                                    <ListIcon as={SearchIcon} color="blue.400" />
                                    <Input bg={'gray.600'}
                                        type="text"
                                        width={'full'}
                                        placeholder='bank crisis'
                                        borderColor={'gray.700'}
                                        value={searchValue}
                                        onChange={(event) => {
                                            setSearchValue(event.target.value);
                                        } } />
                                </HStack>
                            </ListItem>
                            <ListItem>
                                <HStack flex={1}>
                                    <ListIcon as={ArrowUpIcon} color="green.400" />
                                    <div style={{ width: '100%' }}>
                                        <Select options={[ { label: "Positive", value: "Positive" }, { label: "Negative", value: "Negative" }, { label: "Neutral", value: "Neutral" } ]} onChange={(e) => setImpact((e as any).value)} />
                                    </div>
                                </HStack>
                            </ListItem>
                        </List>
      
                        <Button
                            mt={10}
                            w={'full'}
                            bg={'blue.400'}
                            color={'white'}
                            rounded={'xl'}
                            boxShadow={'0 5px 20px 0px rgb(98 98 98 / 43%)'}
                            _hover={{
                                bg: 'blue.500',
                            }}
                            onClick={() => {
                                setNews(null);
                                setCommodities(null);
                                setCryptocurrencies(null);
                                setIndustries(null);
                                setDescriptions(null);
                                setHistoricalEvents(null);
                                fetchData();
                            }}
                            _focus={{
                                bg: 'blue.500',
                            }}>
                                    Search
                        </Button>
                    </Box>
                </Box>
            </Center>
        );
    };

    const result = (title: string, res: string[]) => {
        return (
            <Center py={6}>
                <Box
                    maxW={'full'}
                    w={'full'}
                    bg={'gray.800'}
                    boxShadow={'2xl'}
                    rounded={'md'}
                    overflow={'hidden'}>
                    <Stack
                        textAlign={'center'}
                        p={6}
                        color={'white'}
                        align={'center'}>
                        <Stack direction={'row'} align={'center'} justify={'center'}>
                            <Text fontSize={'2xl'} fontWeight={800}>
                                {title}
                            </Text>
                        </Stack>
                    </Stack>
      
                    <Box bg={'gray.900'} px={6} py={10}>
                        <List spacing={3}>
                            {res.map((r, index) => (
                                <ListItem key={index}>
                                    <HStack>
                                        <ListIcon as={SunIcon} color="blue.400" />
                                        <Text>{r}</Text>
                                    </HStack>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Center>
        );
    };

    return (
        <Grid
            templateColumns={{ base: '1fr', md: '7% 73% 20%' }}
            height={'100vh'}
        >
            <GridItem colSpan={1} bg={useColorModeValue('gray.300', '#292C31')} zIndex={3}>
                <Box
                    justifyContent={'center'} alignItems={'center'}
                    transition="3s ease"
                    bg={useColorModeValue('gray.300', '#292C31')}
                    borderRight="1px"
                    borderRightColor={useColorModeValue('gray.200', 'gray.700')}
                    w={{ base: 'full', md: 'full' }}
                    h="full">
                    <NavItem icon={<FaArrowLeft size={35} />} width={200} preventHover={false} onClick={() => {
                        if(!isBacktestLicense()){
                            return dispatch(updateNavigationTo("/"));
                        }
                        dispatch(updateNavigationTo("/backtesting"));
                    }}>
                        <Heading size="md" ml="2" justifyContent={'center'} alignItems={'center'} paddingLeft={5}>
                                        Back
                        </Heading>
                    </NavItem>
                    <Divider />
                </Box>
            </GridItem>
            <GridItem colSpan={1} bg={useColorModeValue('gray.300', '#292C31')} zIndex={2} borderRight="1px">
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    maxWidth: "1500px",
                    margin: '0 auto',
                }}>
                    {search()}
                    {hasFetched && <Box padding="4">
                        <h1>Results:</h1>
                        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={6}>
                            <GridItem p="4" bg={'gray.900'}>
                                <div>
                                    <h2>Sample:</h2>
                                    {descriptions ? (
                                        result("Descriptions", descriptions)
                                    ) : (
                                        <SkeletonText mt="4" noOfLines={4} spacing="4" />
                                    )}
                                </div>
                            </GridItem>
                            <GridItem p="4" bg={'gray.900'}>
                                <div>
                                    <h2>Sample:</h2>
                                    {industries ? (
                                        result("Industries", industries.map((industry) => industry.display))
                                    ) : (
                                        <Skeleton height="20px" />
                                    )}
                                </div>
                            </GridItem>
                            {/* <GridItem p="4" bg={'gray.900'}>
                                <div>
                                    <h2>Sample:</h2>
                                    {cryptocurrencies ? (
                                        result("Cryptocurrencies", cryptocurrencies.map((cryptocurrency) => cryptocurrency.display))
                                    ) : (
                                        <Skeleton height="20px" />
                                    )}
                                </div>
                            </GridItem> */}
                            <GridItem p="4" bg={'gray.900'}>
                                <div>
                                    <h2>Sample:</h2>
                                    {commodities ? (
                                        result("Commodities", commodities.map((commodity) => commodity.display))
                                    ) 
                                        : (
                                            <Skeleton height="20px" />
                                        )}
                                </div>
                            </GridItem>
                            <GridItem p="4" bg={'gray.900'}>
                                <div>
                                    <h2>Method:</h2>
                                    <h2>News:</h2>
                                    {news ? (
                                        <p>Number of observations mentioned above or equal 3 times: {countValuesAboveThree(news.timeSeries[0])} observations</p>
                                    ) : (
                                        <Skeleton height="20px" />
                                    )}
                                </div>
                            </GridItem>
                            <GridItem p="4" bg={'gray.900'}>
                                <div>
                                    <h2>Method:</h2>
                                    <h2>Historical events:</h2>
                                    {historicalEvents ? (
                                        <TableComponent rows={rows} />
                                    ) : (
                                        <Skeleton height="20px" />
                                    )}
                                </div>
                            </GridItem>
                        </Grid>
                    </Box>}
                </div>
            </GridItem>
            <GridItem colSpan={1} bg={useColorModeValue('gray.300', '#292C31')} borderLeft="1px"
                borderLeftColor={useColorModeValue('gray.200', 'gray.700')}>
                {runAll()}
            </GridItem>
        </Grid>
    );
}
