import { Backtest, GraphItem, Portfolio } from "..";
import { TimeSeriesDTO } from "../methods";
import { createId } from "../shared/utilites/string.utilities";

export type topp = "max" | "min";
export type baseFilterType = "googletrends";

export class BaseFilter {

    public toppType: topp = "max";

    public n = 100;
}

export class SelectionFilterToppListValue {
    public value: number;

    public symbol: string;
}

export class SelectionFilter {
    public _id: string;

    public toppType: topp;

    public timeSeries: TimeSeriesDTO;

    public toppList: SelectionFilterToppListValue[];

    public date: string;

    public display: string;
}

export class SelectionFilterParameter {
    public symbols: string[] = [];
    public historicalPortfolios: Portfolio[] = [];
}

export type position = "long" | "short";

export class SelectionFilterMenu extends TimeSeriesDTO {
    public _id: string;

    public key: string;

    public methodPath: string;

    public parameters: SelectionFilterParameter = new SelectionFilterParameter();

    public private = false;

    public lag = 0;

    public horizon = 1;

    public category = "selectionFilters";

    public isActive = false;

    public previewBacktest: Backtest = null;

    public frontendId: string = createId();

    public timeSeriesGraphResults: GraphItem = null;

    public isCategorySelectionFilter = false;

    public extras: string = null;

    public position: position = "long";

    public isHistoricalPortfolio = false;

    // helper
    public symbols: string[] = [];
}

export class SelectionFiltersSummaryDTO {
    public keys: string[] = [];

    public yearReturn = 0;

    public twoYearReturn = 0;

    public marketCap = 0;

    public PE = 0;

    public R: number[] = [];

    public date: string[] = [];
}

export class ResultSelectionFilter {
    public toppType: topp;

    public key: string;

    public timeseries: TimeSeriesDTO;

    public results: ResultsSelectionFilter[];
}

export class ResultsSelectionFilter {
    public symbol: string;

    public returns: SelectionFilterReturn[];

    public fundamental: SelectionFilterFundamentalSummary;
}

export class SelectionFilterReturn {
    public date: string;

    public value: number;
}

export class SelectionFilterFundamentalSummary {
    public marketCap: number;

    public PE: number;

    public yearReturn: number;

    public twoYearReturn: number;
}
