import {
    Flex,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { GraphItem } from '../../backtesting-common-frontend';
import { TimeSeriesResultsManager } from '../../managers/time-series/time-series-manager';
import { AppState } from '../../store/store';
import { LineChart } from './line-chart';

export function DisplayGraphItemsComponent() {
    const graphItems = useSelector(
        (state: AppState) => state.graphItems.graphItems
    );
    const theoryActive = useSelector(
        (state: AppState) => state.graphItems.theoryActive
    );
    const [ timeSeriesResultsManager ] = React.useState<TimeSeriesResultsManager>(new TimeSeriesResultsManager());
    const [ graphItemsToUse, setGraphItemsToUse ] = React.useState<GraphItem[] | null>(null);
    
    React.useEffect(() => {
        const graphItemsToUse = timeSeriesResultsManager.getGraphTheoryItems(graphItems, theoryActive);
        setGraphItemsToUse(graphItemsToUse);
    }, [ graphItems, theoryActive, timeSeriesResultsManager ]);

    return (
        <Flex width={'100%'} p={3} zIndex={0}>
            {graphItemsToUse && <LineChart TimeSeriesDTOs={graphItemsToUse.map(e => e.timeSeries)} colors={graphItemsToUse.map(e => e.color)} />}
        </Flex>
    );
}

export const DisplayGraphItems = React.memo(DisplayGraphItemsComponent);