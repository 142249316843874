// write a JSON.parse(JSON.stringify(obj)) return T
export function cloneDeep<T>(obj: T | null): T {
    if(obj == null) return null as unknown as T;
    const n = JSON.parse(JSON.stringify(obj)) as T;
    if(Array.isArray(n)) {
        return n;
    }
    // check if object
    if(typeof n === 'object') {
        return { ...n };
    }
    return n;
}

export function reallyDeepClone<T>(obj: T): T{
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }
      
    if (Array.isArray(obj)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newArray: any = [];
        for (const item of obj) {
            newArray.push(reallyDeepClone(item) as unknown);
        }
        return newArray;
    }
      
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newObject: any = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            newObject[key] = reallyDeepClone(obj[key]);
        }
    }
    return newObject;
}
