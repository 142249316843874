import { InProgressDTO } from "../../../status/inprogress-dto";
import { sendPost } from "../frontend/http-utilities";

export async function myProgress(): Promise<InProgressDTO[]> {
    return sendPost("progress/my-progress", {}).then((e) => {
        return e.data;
    });
}

export async function removeProgress(aProgress: InProgressDTO): Promise<InProgressDTO[]> {
    return sendPost("progress/delete", { inProgressId: aProgress._id }).then((e) => {
        return e.data;
    });
}
