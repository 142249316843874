import { ToastId, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateMessage } from "../../../store/backtests/backtests";
import { AppState } from "../../../store/store";

export default function StatusDisplayComponent() {
    const dispatch = useDispatch();
    const toast = useToast();
    const loading = useSelector(
        (state: AppState) => state.backtests.loading
    );
    const statusMessage = useSelector(
        (state: AppState) => state.backtests.statusMessage
    );
    const [ loadingIds, setLoadingIds ] = useState<ToastId[]>([]);
    const [ messageHistory, setMessageHistory ] = useState<{message: string, timestamp: number}[]>([]);
    const messageTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (loading && loadingIds.length === 0) {
            const toastId = toast({
                title: 'Loading',
                description: "Please wait...",
                status: "loading",
                duration: null,
                isClosable: true,
            });
            setLoadingIds([ ...loadingIds, toastId ]);
        } else if (!loading && loadingIds.length > 0) {
            loadingIds.forEach((id) => {
                toast.close(id);
            });
            setLoadingIds([]);
        }
    }, [ loading, loadingIds, toast ]);

    useEffect(() => {
        if (statusMessage != null && statusMessage?.message !== '') {
            const now = Date.now();
            const recentMessages = messageHistory.filter(msg => now - msg.timestamp < 10000);
            const isDuplicate = recentMessages.some(msg => msg.message === statusMessage.message);

            if (!isDuplicate) {
                toast({
                    title: '',
                    description: statusMessage.message,
                    status: statusMessage.type,
                    duration: 10000,
                    isClosable: true,
                });

                setMessageHistory([ ...recentMessages, { message: statusMessage.message, timestamp: now } ]);
            }

            if (messageTimeout.current) {
                clearTimeout(messageTimeout.current);
            }

            messageTimeout.current = setTimeout(() => {
                dispatch(updateMessage(null));
            }, 3000);
        }
    }, [ statusMessage, dispatch, toast, messageHistory ]);

    return <></>;
}
