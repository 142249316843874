import { GraphItem } from "../../..";
import { ImpreemMethodDTO, TimeSeriesDTO } from "../../../methods";
import { sendPost } from "../frontend/http-utilities";

export async function createGraphItem(timeseries: TimeSeriesDTO, method: ImpreemMethodDTO | null, methodKey: string | null | undefined, testId: string | null | undefined): Promise<GraphItem | null> {
    // This endpoint should never be called because everything goes through the preview-transformation endpoint
    // If you want to update, then delete and then go through the preview-transformation endpoint
    return sendPost("graph-items/create", { timeseries, methodKey, method, testId }).then((e) => {
        return e.data as GraphItem;
    }).catch(() => {
        return null;
    });
}

export async function readGraphItems(): Promise<GraphItem[] | null> {
    return sendPost("graph-items/read", { })
        .then((response) => response.data)
        .catch(() => null);
}

export async function updateGraphItem(graphItem: GraphItem): Promise<GraphItem | null> {
    // This endpoint should never be called because everything goes through the preview-transformation endpoint
    // If you want to update, then delete and then go through the preview-transformation endpoint
    return sendPost("graph-items/update", graphItem)
        .then((response) => response.data)
        .catch(() => null);
}

export async function deleteGraphItem(graphItem: GraphItem): Promise<void | null> {
    return sendPost("graph-items/delete", graphItem)
        .then(() => null)
        .catch(() => null);
}

// add bulk-update
export async function bulkGraphItems(graphItems: GraphItem[]): Promise<GraphItem[] | null> {
    return sendPost("graph-items/bulk-update", graphItems)
        .then((response) => response.data)
        .catch(() => null);
}