import axios from "axios";
import { env } from "../../../env";
import { AuthResponse } from "../../auth/auth-http-utilities";

// const exceptionPaths: string[] = [ 
//     "progress/my-progress", 
//     "methods/add",
//     "time-series/add",
//     "graph-items/delete",
//     "backtests/update-backtest",
// ];
const preventDuplicate: {path: string}[] = [];
export async function sendPost(path: string, data?: object): Promise<any> {
    // const pathDup = preventDuplicate.find(e => e.path === path);
    // const okPathDup = exceptionPaths.find(e => e === path);
    // if(pathDup && !okPathDup) {
    //     throw new Error("Duplicate request");
    // }
    // preventDuplicate.push({ path });
    // setTimeout(() => {
    //     preventDuplicate.splice(preventDuplicate.indexOf({ path }), 1);
    // }, 1200);
    const postResponse = await new Promise<any>((res, _) => {
        const item = localStorage.getItem("user");
        if (item) {
            const storeAuth = JSON.parse(item) as AuthResponse;
            if (storeAuth?.token == null) return res(null);
            return axios({
                method: "post",
                url: env.routerUrl + path,
                data: data,
                headers: {
                    authorization: "bearer " + storeAuth.token,
                },

            }).then(e => {
                res(e);
            }).catch(() => {
                res(null);
            });
        } else {
            res(null);
        }
    });
    return postResponse;
}

export async function sendGet(path: string, data?: object): Promise<any> {
    const postResponse = await new Promise<any>((res, _) => {
        const item = localStorage.getItem("user");
        if (item) {
            const storeAuth = JSON.parse(item) as AuthResponse;
            if (storeAuth?.token == null) return res(null);
            return axios({
                method: "get",
                url: env.routerUrl + path,
                data: data,
                headers: {
                    authorization: "bearer " + storeAuth.token,
                },

            }).then(e => {
                res(e);
            }).catch(() => {
                res(null);
            });
        } else {
            res(null);
        }
    });
    return postResponse;
}
